import React, { useContext, useRef } from "react"
import { FormProps } from "~/lib/types"
import Dropzone from "~/components/formFields/Dropzone"
import ImageUpload from "~/components/formFields/ImageUpload"
import Dropdown from "~/components/formFields/Dropdown"
import TextEdit from "~/components/formFields/TextEdit"
import TextInput from "~/components/formFields/TextInput"
import Textarea from "~/components/formFields/Textarea"
import VideoInput from "~/components/formFields/VideoInput"
import Radios from "~/components/formFields/Radios"
import LinkedData from "~/components/formFields/LinkedData"
import Categories from "./formFields/Categories"
import Benefits from "./formFields/Benefits"
import DateField from "./formFields/DateField"
import PDFUpload from "./formFields/PDFUpload"
import ImageUploadNews from "./formFields/ImageUploadNews"

export interface LookupElem {
  [key: string]: any
}

const FieldTypes: LookupElem = {
  linked: LinkedData,
  text: TextInput,
  email: TextInput,
  tel: TextInput,
  wysiwyg: TextEdit,
  file: Dropzone,
  video: VideoInput,
  select: Dropdown,
  categories: Categories,
  radio: Radios,
  benefits: Benefits,
  textarea: Textarea,
  date: DateField,
  heroBanner: ImageUpload,
  profilePicture: ImageUpload,
  postImage: ImageUploadNews,
  documents: PDFUpload,
}

const FormFields = ({
  fields,
  formSaved,
  post,
}: FormProps & { formSaved: boolean; post?: any }) => {
  return (
    <>
      {fields.map(field => {
        const Field = [
          "heroBanner",
          "profilePicture",
          "documents",
          "postImage",
        ].includes(field.name)
          ? FieldTypes[field.name as keyof LookupElem]
          : FieldTypes[field.type as keyof LookupElem]

        return (
          <div
            key={`${field.name}-${field.label}`}
            className={`relative ${field.type}-field`}
          >
            <label
              // className={`block ${
              //   field.type === "file" ||
              //   field.type === "benefits" ||
              //   field.type === "wysiwyg" ||
              //   field.type === "video"
              //     ? `mb-3 text-normal font-bold tracking-[-0.016em]`
              //     : `mb-2 text-caption font-medium`
              // }`}
              className={`mb-3 block text-normal font-bold tracking-[-0.016em]`}
              htmlFor={field.name}
            >
              {field.label}{" "}
              {field.required && <span className="text-theme-orange">*</span>}
            </label>

            {field.tip && (
              <div>
                <p
                  className={`mb-1 block text-caption font-extrabold uppercase tracking-[0.2em] text-theme-orange`}
                >
                  TIP
                </p>
                <p className={`mb-3 text-caption text-theme-gray-mid`}>
                  {field.tip}
                </p>
              </div>
            )}
            {Field ? (
              <Field {...field} formSaved={formSaved} post={post} />
            ) : (
              <p className="error-message">Field type not found</p>
            )}
          </div>
        )
      })}
    </>
  )
}

export default FormFields
