import React, { createContext, useEffect, useState } from "react"
import ClipLoader from "react-spinners/ClipLoader"
import { formatDistance } from "date-fns"
import { MdOutlineRemoveRedEye } from "react-icons/md"
import { FaCheck } from "react-icons/fa"
import Wrapper from "~/components/reusable/Wrapper"
import Spacer from "~/components/reusable/svgs/Spacer"
import FormFields from "~/components/FormFields"
import { formFieldSets } from "~/data/newsPostFields"
import { useUpdateSupplierNewsItemMutation } from "~/graphql/generated/graphql"
import Page from "../reusable/Page"
import PublishRequest from "../PublishRequest"
import { suppliersPortal } from "~/lib/constants"
import { GET_SUPPLIER_NEWS_ITEM } from "~/lib/queries"
import Status from "../PostStatus"
import { Slide, toast, ToastContainer } from "react-toastify"
import Form from "~/utils/Form"
import { usePostFormStore } from "~/lib/stores/forms"

export function needsApproval(fields: string[], status: boolean) {
  if (!fields) return status

  const fieldsToCheck = ["expiryDate", "publishedStatus"]

  if (fields && fields.length === 0) return status

  return fieldsToCheck.some(field => fields.includes(field))
}

export function cleanUpIds(ids: string) {
  if (!ids) return null
  return ids
    .split(",")
    .map((id: string) => id.trim())
    .filter((id: string) => id !== "")
    .toString()
}

type Props = {
  postData: any
}

interface PostContext {
  data: any[] | null
  loading: boolean | null
  error: any
}

// create context for page
export const PostContext = createContext<PostContext>({
  data: null,
  loading: null,
  error: null,
})

export default function EditSupplierPost({ postData }: Props) {
  const data = postData
  const [loading, setLoading] = useState(true)
  const [newsPostFields, setNewsPostFields] = useState(formFieldSets)
  const [fieldsHaveBeenSet, setFieldsHaveBeenSet] = useState(false)
  const [formSaved, setFormSaved] = useState(false)
  const { setDirtyFields, dirtyFields, setFormValues } = usePostFormStore(
    state => ({
      setDirtyFields: state.setDirtyFields,
      dirtyFields: state.dirtyFields,
      setFormValues: state.setValues,
    })
  )

  const [
    updateSupplierNewsItemMutation,
    { data: updatedData, loading: updating, error: updateError },
  ] = useUpdateSupplierNewsItemMutation()

  if (updateError) {
    console.error("updateError", updateError)
  }

  useEffect(() => {
    setFormValues(updatedData?.updateSupplierNews?.supplier_news)
  }, [updatedData])

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()

    const form = event.currentTarget || event.target
    const formData: FormData | any = new FormData(form)
    const values = Object.fromEntries(formData)

    if (!values || !data?.id) {
      return
    }

    const documents = cleanUpIds(values.documents)
    const docTitles =
      documents !== null
        ? documents.split(",").map(d => {
            const docTitle = values[`documentTitle-${d}`]
            return { id: parseInt(d), title: docTitle }
          })
        : null

    updateSupplierNewsItemMutation({
      variables: {
        id: data?.id,
        title: values.postTitle,
        expiryDate: values.expiryDate,
        publishedStatus: Boolean(values.publishedStatus === "true"),
        approvalStatus: values.approvalStatus
          ? Boolean(needsApproval(dirtyFields, values.approvalStatus))
          : false,
        inReview: false,
        image: values.postImage,
        // category: values.category,
        postContent: values.postContent,
        videoUrl: values.videoUrl,
        headerUrlLink: values.headerUrlLink,
        documents: documents,
        documentsTitles: JSON.stringify(docTitles),
      },
      refetchQueries: [
        { query: GET_SUPPLIER_NEWS_ITEM, variables: { slug: data?.slug } },
      ],
      onCompleted(data) {
        setFormSaved(true)
        setDirtyFields([])

        toast.success("Post updated successfully", {
          transition: Slide,
          autoClose: 3000,
          hideProgressBar: true,
          onClose: () => {},
        })
      },
      onQueryUpdated: () => {},
    })
  }

  // pre-populate form fields
  useEffect(() => {
    if (!data || fieldsHaveBeenSet) return
    let fields: any[] = []

    setFormValues(data)

    // update supplier fields
    newsPostFields.forEach((fieldSet: any) => {
      fieldSet.fields.forEach((field: any) => {
        if (field.dataField) {
          // check if field.dataField has . to separate nested fields
          if (field.dataField.includes(".")) {
            const nestedFields = field.dataField.split(".")
            // for nested fields, get the value from the nested object
            const value = nestedFields.reduce(
              (obj: { [x: string]: any }, key: string | number) => obj[key],
              data
            )
            field.value = value
          } else {
            field.value = data[field.dataField]
          }
          field.loaded = true
        }
      })
      fields.push(fieldSet)
    })
    setNewsPostFields(fields)
    setFieldsHaveBeenSet(true)
    setLoading(false)
  }, [fieldsHaveBeenSet, data, loading])

  const lastEdited = data?.modified
    ? formatDistance(new Date(data?.modified), new Date(), { addSuffix: true })
    : false

  const hasDirtyFields = dirtyFields && dirtyFields.length > 0

  return (
    <PostContext.Provider value={{ data, loading, error: updateError }}>
      <Page title={`Edit Post - ${data?.title}`}>
        <ToastContainer />
        <div className={`bg-theme-yellow-light pt-6`}>
          <Wrapper>
            <Form submitHandler={handleSubmit}>
              <div className="items-start md:flex">
                <div
                  className={`vs-shadow-light flex-set-0-0 top-8 mx-3 mb-8 h-fit basis-almost-1/4 overflow-hidden rounded-3xl bg-theme-white md:sticky`}
                >
                  <div
                    className={`text-sm bg-amber-500/30 text-center font-semibold uppercase tracking-wide text-amber-800 transition-all overflow-hidden ${
                      hasDirtyFields ? "h-14 p-4" : "opacity-0 h-0"
                    }`}
                  >
                    Unsaved changes
                  </div>
                  <h1 className="mt-8 mb-7 px-11 text-h4 font-bold md:text-h3">
                    Post Editor
                  </h1>
                  <div className="mx-11">
                    <Status post={data} />
                  </div>
                  {lastEdited && (
                    <p className={`mx-11 mb-12 block text-small`}>
                      Last Edited: {lastEdited}
                    </p>
                  )}
                  <button
                    type="submit"
                    className={`brew-button relative mx-auto mb-8 mt-10 table rounded-lg border-4 border-solid border-theme-green-lighter bg-theme-green-lighter px-8 py-3 text-[18px] font-bold leading-normal text-theme-black shadow-[0_8px_32px_rgb(0_0_0_/_16%)] transition-all before:absolute before:left-1 before:right-1 before:top-[-7px] before:block before:h-[6px] before:rounded-[50%] before:bg-theme-green-lighter before:transition-all after:absolute after:left-1 after:right-1 after:bottom-[-7px] after:block after:h-[6px] after:rounded-[50%] after:bg-theme-green-lighter after:transition-all hover:bg-transparent hover:shadow-none hover:before:top-[-3px] hover:before:h-[3px] hover:after:bottom-[-3px] hover:after:h-[3px]`}
                    disabled={updating}
                  >
                    <i
                      className={`mr-2 inline-block translate-y-[-2px] align-middle`}
                    >
                      <FaCheck />
                    </i>
                    {updating ? "Saving..." : "Save Edits"}
                  </button>
                  {data?.slug ? (
                    <a
                      className={`mx-auto mb-10 flex w-fit items-center text-normal font-bold`}
                      href={`${suppliersPortal}/posts/preview/${data.slug}`}
                    >
                      <i className={`relative mr-2 h-8 w-8`}>
                        <MdOutlineRemoveRedEye />
                      </i>
                      <span className={`underline`}>Preview Article</span>
                    </a>
                  ) : null}
                  <PublishRequest updating={updating} post={data} />
                </div>

                <div className="relative mx-auto basis-almost-7/12 @container/form-parent [&_.text-field]:@md:w-7/12 [&_.email-field]:@md:w-7/12 [&_.categories-field]:@md:w-7/12 [&_.linked-field]:@md:w-7/12 [&_.select-field]:@md:w-7/12 [&_.date-field]:@md:w-7/12 [&_.radio-field]:@md:w-7/12">
                  {loading && (
                    <div className="absolute -inset-2 z-50 text-theme-orange backdrop-blur-sm">
                      <div className="inset-0 z-50 flex h-[calc(100vh_-_10rem)] items-center justify-center">
                        <ClipLoader
                          size={120}
                          color={"currentColor"}
                          loading={!loading}
                        />
                      </div>
                    </div>
                  )}

                  {/* TODO change error message */}
                  {updateError ? (
                    updateError.message.includes(
                      "This username is already registered"
                    ) ? (
                      <p className="error-message">Supplier already exists</p>
                    ) : (
                      <p className="error-message">{updateError.message}</p>
                    )
                  ) : null}

                  {formFieldSets.map(({ name, label, fields }) => (
                    <fieldset
                      key={name}
                      disabled={updating}
                      aria-busy={updating}
                      className={`pt-4`}
                    >
                      <h2 className="mb-10 text-h3 md:text-h2">
                        News Post Editor
                      </h2>
                      <FormFields
                        fields={fields}
                        formSaved={formSaved}
                        post={data}
                      />
                    </fieldset>
                  ))}
                </div>
              </div>
            </Form>
          </Wrapper>
        </div>
        <Spacer top={`yellow-light`} bottom={`black`} />
      </Page>
    </PostContext.Provider>
  )
}
