export const formFieldSets = [
  {
    name: "allFields",
    label: "Business Details",
    fields: [
      {
        name: "publishedStatus",
        label: "Published Status",
        type: "radio",
        value: null,
        options: [
          {
            label: "Published",
            value: true,
          },
          {
            label: "Draft",
            value: false,
          },
        ],
        required: true,
        dataField: "newsPost.publishedStatus",
      },
      {
        name: "expiryDate",
        label: "Expiry Date",
        type: "date",
        value: null,
        required: true,
        dataField: "newsPost.expiryDate",
      },
      {
        name: "approvalStatus",
        label: "Approval Status",
        type: "radio",
        value: null,
        options: [
          {
            label: "Approved",
            value: true,
          },
          {
            label: "Unapproved",
            value: false,
          },
        ],
        required: true,
        disabled: true,
        dataField: "newsPost.approvalStatus",
      },
      {
        name: "supplier",
        label: "Supplier",
        type: "linked",
        value: "",
        required: true,
        disabled: true,
        dataField: "newsPost.linkedSupplier",
      },
      {
        name: "postTitle",
        label: "Post Title",
        type: "text",
        value: "",
        required: true,
        dataField: "title",
      },
      //{
      //  name: "category",
      //  label: "Category Selection",
      //  type: "select",
      //  placeholder: "Services",
      //  value: "", // TODO set up and get business category taxonomy
      //  required: false,
      //},
      {
        name: "postImage",
        label: "News Post Image",
        type: "file",
        value: "",
        required: false,
        tip: "Recommended dimensions for images are 1920x1080. JPEG, JPG and PNG are accepted file formats with a maximum file size of 1MB.",
        dataField: "featuredImage",
      },
      {
        name: "videoUrl",
        label: "Video Embed",
        type: "video",
        value: "",
        required: false,
        description:
          "Paste a URL link to the video content you want to display on your profile",
        dataField: "newsPost.videoUrl",
      },
      {
        name: "headerUrlLink",
        label: "Header URL Link",
        type: "text",
        value: "",
        required: false,
        tip: "Unless you add a custom link in the box below the header of this article will link to your VetShare supplier profile page.",
        dataField: "newsPost.headerUrlLink",
      },
      {
        name: "postContent",
        label: "Post Content",
        type: "wysiwyg",
        value: "",
        required: false,
        dataField: "newsPost.postContent",
      },
      {
        name: "documents",
        label: "Documents & Guides",
        type: "file",
        value: "",
        required: false,
        limit: 4,
        tip: "PDF are accepted file formats with a maximum file size of 25MB. Maximum of 4 documents can be uploaded",
        dataField: "newsPost.documents",
      },
    ],
  },
]
