import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BlockAttributesArray: any;
  BlockAttributesObject: any;
};

/** ACF Link field */
export type Acf_Link = {
  __typename?: 'ACF_Link';
  /** The target of the link (_blank, etc) */
  target?: Maybe<Scalars['String']>;
  /** The title of the link */
  title?: Maybe<Scalars['String']>;
  /** The url of the link */
  url?: Maybe<Scalars['String']>;
};

/** The account_manager type */
export type Account_Manager = ContentNode & DatabaseIdentifier & MenuItemLinkable & Node & NodeWithFeaturedImage & NodeWithTemplate & NodeWithTitle & UniformResourceIdentifiable & {
  __typename?: 'Account_manager';
  Meta?: Maybe<Account_Manager_Meta>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of the databaseId field
   */
  account_managerId: Scalars['Int'];
  /** Connection between the ContentNode type and the ContentType type */
  contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /** The name of the Content Type the node belongs to */
  contentTypeName: Scalars['String'];
  /** The unique resource identifier path */
  databaseId: Scalars['Int'];
  /** Post publishing date. */
  date?: Maybe<Scalars['String']>;
  /** The publishing date set in GMT. */
  dateGmt?: Maybe<Scalars['String']>;
  /** The desired slug of the post */
  desiredSlug?: Maybe<Scalars['String']>;
  details?: Maybe<Account_Manager_Details>;
  /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
  editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
  /** The RSS enclosure for the object */
  enclosure?: Maybe<Scalars['String']>;
  /** Connection between the ContentNode type and the EnqueuedScript type */
  enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
  /** Connection between the ContentNode type and the EnqueuedStylesheet type */
  enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /** Connection between the NodeWithFeaturedImage type and the MediaItem type */
  featuredImage?: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
  /** The database identifier for the featured image node assigned to the content node */
  featuredImageDatabaseId?: Maybe<Scalars['Int']>;
  /** Globally unique ID of the featured image assigned to the node */
  featuredImageId?: Maybe<Scalars['ID']>;
  /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
  guid?: Maybe<Scalars['String']>;
  /** The globally unique identifier of the account_manager object. */
  id: Scalars['ID'];
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean'];
  /** Whether the object is a node in the preview state */
  isPreview?: Maybe<Scalars['Boolean']>;
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']>;
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean'];
  /** The user that most recently edited the node */
  lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
  /** The permalink of the post */
  link?: Maybe<Scalars['String']>;
  /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
  modified?: Maybe<Scalars['String']>;
  /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
  modifiedGmt?: Maybe<Scalars['String']>;
  /** Connection between the account_manager type and the account_manager type */
  preview?: Maybe<Account_ManagerToPreviewConnectionEdge>;
  /** The database id of the preview node */
  previewRevisionDatabaseId?: Maybe<Scalars['Int']>;
  /** Whether the object is a node in the preview state */
  previewRevisionId?: Maybe<Scalars['ID']>;
  /** Connection between the account_manager type and the region type */
  regions?: Maybe<Account_ManagerToRegionConnection>;
  /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
  slug?: Maybe<Scalars['String']>;
  /** The current status of the object */
  status?: Maybe<Scalars['String']>;
  /** The template assigned to a node of content */
  template?: Maybe<ContentTemplate>;
  /** Connection between the account_manager type and the TermNode type */
  terms?: Maybe<Account_ManagerToTermNodeConnection>;
  /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
  title?: Maybe<Scalars['String']>;
  /** The unique resource identifier path */
  uri?: Maybe<Scalars['String']>;
};


/** The account_manager type */
export type Account_ManagerEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The account_manager type */
export type Account_ManagerEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The account_manager type */
export type Account_ManagerRegionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Account_ManagerToRegionConnectionWhereArgs>;
};


/** The account_manager type */
export type Account_ManagerTermsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Account_ManagerToTermNodeConnectionWhereArgs>;
};


/** The account_manager type */
export type Account_ManagerTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum Account_ManagerIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a resource by the URI. */
  Uri = 'URI'
}

/** Set relationships between the account_manager to regions */
export type Account_ManagerRegionsInput = {
  /** If true, this will append the region to existing related regions. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<Account_ManagerRegionsNodeInput>>>;
};

/** List of regions to connect the account_manager to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type Account_ManagerRegionsNodeInput = {
  /** The description of the region. This field is used to set a description of the region if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']>;
  /** The ID of the region. If present, this will be used to connect to the account_manager. If no existing region exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']>;
  /** The name of the region. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']>;
  /** The slug of the region. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']>;
};

/** Connection between the account_manager type and the account_manager type */
export type Account_ManagerToPreviewConnectionEdge = {
  __typename?: 'Account_managerToPreviewConnectionEdge';
  /** The node of the connection, without the edges */
  node?: Maybe<Account_Manager>;
};

/** Connection between the account_manager type and the region type */
export type Account_ManagerToRegionConnection = {
  __typename?: 'Account_managerToRegionConnection';
  /** Edges for the Account_managerToRegionConnection connection */
  edges?: Maybe<Array<Maybe<Account_ManagerToRegionConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Region>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type Account_ManagerToRegionConnectionEdge = {
  __typename?: 'Account_managerToRegionConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<Region>;
};

/** Arguments for filtering the Account_managerToRegionConnection connection */
export type Account_ManagerToRegionConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
};

/** Connection between the account_manager type and the TermNode type */
export type Account_ManagerToTermNodeConnection = {
  __typename?: 'Account_managerToTermNodeConnection';
  /** Edges for the Account_managerToTermNodeConnection connection */
  edges?: Maybe<Array<Maybe<Account_ManagerToTermNodeConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<TermNode>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type Account_ManagerToTermNodeConnectionEdge = {
  __typename?: 'Account_managerToTermNodeConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<TermNode>;
};

/** Arguments for filtering the Account_managerToTermNodeConnection connection */
export type Account_ManagerToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** The Taxonomy to filter terms by */
  taxonomies?: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
};

/** Field Group */
export type Account_Manager_Details = {
  __typename?: 'Account_manager_Details';
  emailAddress?: Maybe<Scalars['String']>;
  fieldGroupName?: Maybe<Scalars['String']>;
  forename?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
};

/** Field Group */
export type Account_Manager_Meta = {
  __typename?: 'Account_manager_Meta';
  fieldGroupName?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  /** If left blank the page or post title will be used. */
  metaTitle?: Maybe<Scalars['String']>;
  ogDescription?: Maybe<Scalars['String']>;
  ogImage?: Maybe<MediaItem>;
  ogTitle?: Maybe<Scalars['String']>;
  twitterCardType?: Maybe<Scalars['String']>;
  twitterDescription?: Maybe<Scalars['String']>;
  twitterImage?: Maybe<MediaItem>;
  twitterTitle?: Maybe<Scalars['String']>;
};

/** acf/background-container block */
export type AcfBackgroundContainerBlock = Block & {
  __typename?: 'AcfBackgroundContainerBlock';
  attributes?: Maybe<AcfBackgroundContainerBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  blockBackgroundContent?: Maybe<AcfBackgroundContainerBlock_Blockbackgroundcontent>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type AcfBackgroundContainerBlockAttributes = {
  __typename?: 'AcfBackgroundContainerBlockAttributes';
  align?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['BlockAttributesObject']>;
  id?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  mode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Field Group */
export type AcfBackgroundContainerBlock_Blockbackgroundcontent = {
  __typename?: 'AcfBackgroundContainerBlock_Blockbackgroundcontent';
  backgroundColour?: Maybe<Scalars['String']>;
  fieldGroupName?: Maybe<Scalars['String']>;
};

/** acf/background-image-container block */
export type AcfBackgroundImageContainerBlock = Block & {
  __typename?: 'AcfBackgroundImageContainerBlock';
  attributes?: Maybe<AcfBackgroundImageContainerBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  blockBackgroundImageContent?: Maybe<AcfBackgroundImageContainerBlock_Blockbackgroundimagecontent>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type AcfBackgroundImageContainerBlockAttributes = {
  __typename?: 'AcfBackgroundImageContainerBlockAttributes';
  align?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['BlockAttributesObject']>;
  id?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  mode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Field Group */
export type AcfBackgroundImageContainerBlock_Blockbackgroundimagecontent = {
  __typename?: 'AcfBackgroundImageContainerBlock_Blockbackgroundimagecontent';
  backgroundImage?: Maybe<MediaItem>;
  fieldGroupName?: Maybe<Scalars['String']>;
};

/** acf/banner block */
export type AcfBannerBlock = Block & {
  __typename?: 'AcfBannerBlock';
  attributes?: Maybe<AcfBannerBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  banner?: Maybe<AcfBannerBlock_Banner>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type AcfBannerBlockAttributes = {
  __typename?: 'AcfBannerBlockAttributes';
  align?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['BlockAttributesObject']>;
  id?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  mode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Field Group */
export type AcfBannerBlock_Banner = {
  __typename?: 'AcfBannerBlock_Banner';
  fieldGroupName?: Maybe<Scalars['String']>;
  image?: Maybe<MediaItem>;
};

/** acf/brew-gallery block */
export type AcfBrewGalleryBlock = Block & {
  __typename?: 'AcfBrewGalleryBlock';
  attributes?: Maybe<AcfBrewGalleryBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  blockBrewGallery?: Maybe<AcfBrewGalleryBlock_Blockbrewgallery>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type AcfBrewGalleryBlockAttributes = {
  __typename?: 'AcfBrewGalleryBlockAttributes';
  align?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['BlockAttributesObject']>;
  id?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  mode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Field Group */
export type AcfBrewGalleryBlock_Blockbrewgallery = {
  __typename?: 'AcfBrewGalleryBlock_Blockbrewgallery';
  /** add photos to gallery */
  brewGallery?: Maybe<Array<Maybe<MediaItem>>>;
  fieldGroupName?: Maybe<Scalars['String']>;
  /** Select number of columns to show images concurrently */
  numberOfColumns?: Maybe<Scalars['Int']>;
};

/** acf/button block */
export type AcfButtonBlock = Block & {
  __typename?: 'AcfButtonBlock';
  ButtonFields?: Maybe<AcfButtonBlock_Buttonfields>;
  attributes?: Maybe<AcfButtonBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type AcfButtonBlockAttributes = {
  __typename?: 'AcfButtonBlockAttributes';
  align?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['BlockAttributesObject']>;
  id?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  mode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Field Group */
export type AcfButtonBlock_Buttonfields = {
  __typename?: 'AcfButtonBlock_Buttonfields';
  buttonColor?: Maybe<Scalars['String']>;
  buttonLink?: Maybe<Acf_Link>;
  buttonStyle?: Maybe<Scalars['String']>;
  fieldGroupName?: Maybe<Scalars['String']>;
};

/** acf/callout-cta block */
export type AcfCalloutCtaBlock = Block & {
  __typename?: 'AcfCalloutCtaBlock';
  attributes?: Maybe<AcfCalloutCtaBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  calloutCTABlock?: Maybe<AcfCalloutCtaBlock_Calloutctablock>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type AcfCalloutCtaBlockAttributes = {
  __typename?: 'AcfCalloutCtaBlockAttributes';
  align?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['BlockAttributesObject']>;
  id?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  mode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Field Group */
export type AcfCalloutCtaBlock_Calloutctablock = {
  __typename?: 'AcfCalloutCtaBlock_Calloutctablock';
  backgroundColor?: Maybe<Scalars['String']>;
  fieldGroupName?: Maybe<Scalars['String']>;
};

/** acf/collapse-columns block */
export type AcfCollapseColumnsBlock = Block & {
  __typename?: 'AcfCollapseColumnsBlock';
  attributes?: Maybe<AcfCollapseColumnsBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  collapseColumnsBlock?: Maybe<AcfCollapseColumnsBlock_Collapsecolumnsblock>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type AcfCollapseColumnsBlockAttributes = {
  __typename?: 'AcfCollapseColumnsBlockAttributes';
  align?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['BlockAttributesObject']>;
  id?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  mode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Field Group */
export type AcfCollapseColumnsBlock_Collapsecolumnsblock = {
  __typename?: 'AcfCollapseColumnsBlock_Collapsecolumnsblock';
  columns?: Maybe<Array<Maybe<AcfCollapseColumnsBlock_Collapsecolumnsblock_Columns>>>;
  fieldGroupName?: Maybe<Scalars['String']>;
  heading?: Maybe<Scalars['String']>;
  image?: Maybe<MediaItem>;
};

/** Field Group */
export type AcfCollapseColumnsBlock_Collapsecolumnsblock_Columns = {
  __typename?: 'AcfCollapseColumnsBlock_Collapsecolumnsblock_columns';
  fieldGroupName?: Maybe<Scalars['String']>;
  heading?: Maybe<Scalars['String']>;
  paragraph?: Maybe<Scalars['String']>;
};

/** acf/fifty-fifty block */
export type AcfFiftyFiftyBlock = Block & {
  __typename?: 'AcfFiftyFiftyBlock';
  attributes?: Maybe<AcfFiftyFiftyBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  fiftyFiftyBlock?: Maybe<AcfFiftyFiftyBlock_Fiftyfiftyblock>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type AcfFiftyFiftyBlockAttributes = {
  __typename?: 'AcfFiftyFiftyBlockAttributes';
  align?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['BlockAttributesObject']>;
  id?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  mode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Field Group */
export type AcfFiftyFiftyBlock_Fiftyfiftyblock = {
  __typename?: 'AcfFiftyFiftyBlock_Fiftyfiftyblock';
  backgroundColor?: Maybe<Scalars['String']>;
  fieldGroupName?: Maybe<Scalars['String']>;
  image?: Maybe<MediaItem>;
};

/** acf/html block */
export type AcfHtmlBlock = Block & {
  __typename?: 'AcfHtmlBlock';
  attributes?: Maybe<AcfHtmlBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  blockHtml?: Maybe<AcfHtmlBlock_Blockhtml>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type AcfHtmlBlockAttributes = {
  __typename?: 'AcfHtmlBlockAttributes';
  align?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['BlockAttributesObject']>;
  id?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  mode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Field Group */
export type AcfHtmlBlock_Blockhtml = {
  __typename?: 'AcfHtmlBlock_Blockhtml';
  fieldGroupName?: Maybe<Scalars['String']>;
  markup?: Maybe<Scalars['String']>;
};

/** acf/intro block */
export type AcfIntroBlock = Block & {
  __typename?: 'AcfIntroBlock';
  attributes?: Maybe<AcfIntroBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  blockIntro?: Maybe<AcfIntroBlock_Blockintro>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type AcfIntroBlockAttributes = {
  __typename?: 'AcfIntroBlockAttributes';
  align?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['BlockAttributesObject']>;
  id?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  mode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Field Group */
export type AcfIntroBlock_Blockintro = {
  __typename?: 'AcfIntroBlock_Blockintro';
  backgroundColor?: Maybe<Scalars['String']>;
  fieldGroupName?: Maybe<Scalars['String']>;
  heading?: Maybe<Scalars['String']>;
  image?: Maybe<MediaItem>;
  showButton?: Maybe<Scalars['Boolean']>;
};

/** acf/logo-slider block */
export type AcfLogoSliderBlock = Block & {
  __typename?: 'AcfLogoSliderBlock';
  attributes?: Maybe<AcfLogoSliderBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  logoSlider?: Maybe<AcfLogoSliderBlock_Logoslider>;
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type AcfLogoSliderBlockAttributes = {
  __typename?: 'AcfLogoSliderBlockAttributes';
  align?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['BlockAttributesObject']>;
  id?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  mode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Field Group */
export type AcfLogoSliderBlock_Logoslider = {
  __typename?: 'AcfLogoSliderBlock_Logoslider';
  fieldGroupName?: Maybe<Scalars['String']>;
  /** add logos to slider */
  logos?: Maybe<Array<Maybe<MediaItem>>>;
};

/** acf/member-benefit block */
export type AcfMemberBenefitBlock = Block & {
  __typename?: 'AcfMemberBenefitBlock';
  attributes?: Maybe<AcfMemberBenefitBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  memberBenefitBlock?: Maybe<AcfMemberBenefitBlock_Memberbenefitblock>;
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type AcfMemberBenefitBlockAttributes = {
  __typename?: 'AcfMemberBenefitBlockAttributes';
  align?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['BlockAttributesObject']>;
  id?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  mode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Field Group */
export type AcfMemberBenefitBlock_Memberbenefitblock = {
  __typename?: 'AcfMemberBenefitBlock_Memberbenefitblock';
  fieldGroupName?: Maybe<Scalars['String']>;
  heading?: Maybe<Scalars['String']>;
};

/** acf/post-banner block */
export type AcfPostBannerBlock = Block & {
  __typename?: 'AcfPostBannerBlock';
  attributes?: Maybe<AcfPostBannerBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  blockPostBanner?: Maybe<AcfPostBannerBlock_Blockpostbanner>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type AcfPostBannerBlockAttributes = {
  __typename?: 'AcfPostBannerBlockAttributes';
  align?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['BlockAttributesObject']>;
  id?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  mode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Field Group */
export type AcfPostBannerBlock_Blockpostbanner = {
  __typename?: 'AcfPostBannerBlock_Blockpostbanner';
  backgroundImage?: Maybe<MediaItem>;
  fieldGroupName?: Maybe<Scalars['String']>;
  logo?: Maybe<MediaItem>;
};

/** acf/quick-columns block */
export type AcfQuickColumnsBlock = Block & {
  __typename?: 'AcfQuickColumnsBlock';
  attributes?: Maybe<AcfQuickColumnsBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  blockQuickColumns?: Maybe<AcfQuickColumnsBlock_Blockquickcolumns>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type AcfQuickColumnsBlockAttributes = {
  __typename?: 'AcfQuickColumnsBlockAttributes';
  align?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['BlockAttributesObject']>;
  id?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  mode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Field Group */
export type AcfQuickColumnsBlock_Blockquickcolumns = {
  __typename?: 'AcfQuickColumnsBlock_Blockquickcolumns';
  columns?: Maybe<Array<Maybe<AcfQuickColumnsBlock_Blockquickcolumns_Columns>>>;
  fieldGroupName?: Maybe<Scalars['String']>;
};

/** Field Group */
export type AcfQuickColumnsBlock_Blockquickcolumns_Columns = {
  __typename?: 'AcfQuickColumnsBlock_Blockquickcolumns_columns';
  content?: Maybe<Scalars['String']>;
  fieldGroupName?: Maybe<Scalars['String']>;
  heading?: Maybe<Scalars['String']>;
};

/** acf/slash-spacer block */
export type AcfSlashSpacerBlock = Block & {
  __typename?: 'AcfSlashSpacerBlock';
  attributes?: Maybe<AcfSlashSpacerBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  blockSlashSpacer?: Maybe<AcfSlashSpacerBlock_Blockslashspacer>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type AcfSlashSpacerBlockAttributes = {
  __typename?: 'AcfSlashSpacerBlockAttributes';
  align?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['BlockAttributesObject']>;
  id?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  mode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Field Group */
export type AcfSlashSpacerBlock_Blockslashspacer = {
  __typename?: 'AcfSlashSpacerBlock_Blockslashspacer';
  bottomColor?: Maybe<Scalars['String']>;
  fieldGroupName?: Maybe<Scalars['String']>;
  topColor?: Maybe<Scalars['String']>;
};

/** acf/testimonial-with-photo block */
export type AcfTestimonialWithPhotoBlock = Block & {
  __typename?: 'AcfTestimonialWithPhotoBlock';
  attributes?: Maybe<AcfTestimonialWithPhotoBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
  testimonialWithPhoto?: Maybe<AcfTestimonialWithPhotoBlock_Testimonialwithphoto>;
};

export type AcfTestimonialWithPhotoBlockAttributes = {
  __typename?: 'AcfTestimonialWithPhotoBlockAttributes';
  align?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['BlockAttributesObject']>;
  id?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  mode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Field Group */
export type AcfTestimonialWithPhotoBlock_Testimonialwithphoto = {
  __typename?: 'AcfTestimonialWithPhotoBlock_Testimonialwithphoto';
  fieldGroupName?: Maybe<Scalars['String']>;
  nameRole?: Maybe<Scalars['String']>;
  photo?: Maybe<MediaItem>;
};

/** acf/timed-container block */
export type AcfTimedContainerBlock = Block & {
  __typename?: 'AcfTimedContainerBlock';
  attributes?: Maybe<AcfTimedContainerBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  blockTimedContent?: Maybe<AcfTimedContainerBlock_Blocktimedcontent>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type AcfTimedContainerBlockAttributes = {
  __typename?: 'AcfTimedContainerBlockAttributes';
  align?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['BlockAttributesObject']>;
  id?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  mode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Field Group */
export type AcfTimedContainerBlock_Blocktimedcontent = {
  __typename?: 'AcfTimedContainerBlock_Blocktimedcontent';
  endDate?: Maybe<Scalars['String']>;
  fieldGroupName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
};

/** acf/twin-card block */
export type AcfTwinCardBlock = Block & {
  __typename?: 'AcfTwinCardBlock';
  attributes?: Maybe<AcfTwinCardBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
  twinCardBlock?: Maybe<AcfTwinCardBlock_Twincardblock>;
};

export type AcfTwinCardBlockAttributes = {
  __typename?: 'AcfTwinCardBlockAttributes';
  align?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['BlockAttributesObject']>;
  id?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  mode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Field Group */
export type AcfTwinCardBlock_Twincardblock = {
  __typename?: 'AcfTwinCardBlock_Twincardblock';
  fieldGroupName?: Maybe<Scalars['String']>;
  heading?: Maybe<Scalars['String']>;
};

/** acf/video block */
export type AcfVideoBlock = Block & {
  __typename?: 'AcfVideoBlock';
  attributes?: Maybe<AcfVideoBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
  video?: Maybe<AcfVideoBlock_Video>;
};

export type AcfVideoBlockAttributes = {
  __typename?: 'AcfVideoBlockAttributes';
  align?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['BlockAttributesObject']>;
  id?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  mode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Field Group */
export type AcfVideoBlock_Video = {
  __typename?: 'AcfVideoBlock_Video';
  fieldGroupName?: Maybe<Scalars['String']>;
  videoId?: Maybe<Scalars['String']>;
};

/** The ActionMonitorAction type */
export type ActionMonitorAction = ContentNode & DatabaseIdentifier & Node & NodeWithContentEditor & NodeWithTemplate & NodeWithTitle & UniformResourceIdentifiable & {
  __typename?: 'ActionMonitorAction';
  Meta?: Maybe<ActionMonitorAction_Meta>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of the databaseId field
   */
  actionMonitorActionId: Scalars['Int'];
  /** The type of action (CREATE, UPDATE, DELETE) */
  actionType?: Maybe<Scalars['String']>;
  /** The content of the post. */
  content?: Maybe<Scalars['String']>;
  /** Connection between the ContentNode type and the ContentType type */
  contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /** The name of the Content Type the node belongs to */
  contentTypeName: Scalars['String'];
  /** The unique identifier stored in the database */
  databaseId: Scalars['Int'];
  /** Post publishing date. */
  date?: Maybe<Scalars['String']>;
  /** The publishing date set in GMT. */
  dateGmt?: Maybe<Scalars['String']>;
  /** The desired slug of the post */
  desiredSlug?: Maybe<Scalars['String']>;
  /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
  editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
  /** The RSS enclosure for the object */
  enclosure?: Maybe<Scalars['String']>;
  /** Connection between the ContentNode type and the EnqueuedScript type */
  enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
  /** Connection between the ContentNode type and the EnqueuedStylesheet type */
  enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
  guid?: Maybe<Scalars['String']>;
  /** The globally unique identifier of the action_monitor object. */
  id: Scalars['ID'];
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean'];
  /** Whether the object is a node in the preview state */
  isPreview?: Maybe<Scalars['Boolean']>;
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']>;
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean'];
  /** The user that most recently edited the node */
  lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
  /** The permalink of the post */
  link?: Maybe<Scalars['String']>;
  /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
  modified?: Maybe<Scalars['String']>;
  /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
  modifiedGmt?: Maybe<Scalars['String']>;
  /** Connection between the ActionMonitorAction type and the ActionMonitorAction type */
  preview?: Maybe<ActionMonitorActionToPreviewConnectionEdge>;
  /** The preview data of the post that triggered this action. */
  previewData?: Maybe<GatsbyPreviewData>;
  /** The database id of the preview node */
  previewRevisionDatabaseId?: Maybe<Scalars['Int']>;
  /** Whether the object is a node in the preview state */
  previewRevisionId?: Maybe<Scalars['ID']>;
  /** The global relay ID of the post that triggered this action */
  referencedNodeGlobalRelayID?: Maybe<Scalars['String']>;
  /** The post ID of the post that triggered this action */
  referencedNodeID?: Maybe<Scalars['String']>;
  /** The WPGraphQL plural name of the referenced post */
  referencedNodePluralName?: Maybe<Scalars['String']>;
  /** The WPGraphQL single name of the referenced post */
  referencedNodeSingularName?: Maybe<Scalars['String']>;
  /** The post status of the post that triggered this action */
  referencedNodeStatus?: Maybe<Scalars['String']>;
  /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
  slug?: Maybe<Scalars['String']>;
  /** The current status of the object */
  status?: Maybe<Scalars['String']>;
  /** The template assigned to the node */
  template?: Maybe<ContentTemplate>;
  /** Connection between the ActionMonitorAction type and the TermNode type */
  terms?: Maybe<ActionMonitorActionToTermNodeConnection>;
  /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
  title?: Maybe<Scalars['String']>;
  /** The unique resource identifier path */
  uri?: Maybe<Scalars['String']>;
};


/** The ActionMonitorAction type */
export type ActionMonitorActionContentArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};


/** The ActionMonitorAction type */
export type ActionMonitorActionEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The ActionMonitorAction type */
export type ActionMonitorActionEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The ActionMonitorAction type */
export type ActionMonitorActionTermsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ActionMonitorActionToTermNodeConnectionWhereArgs>;
};


/** The ActionMonitorAction type */
export type ActionMonitorActionTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum ActionMonitorActionIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a resource by the URI. */
  Uri = 'URI'
}

/** Connection between the ActionMonitorAction type and the ActionMonitorAction type */
export type ActionMonitorActionToPreviewConnectionEdge = {
  __typename?: 'ActionMonitorActionToPreviewConnectionEdge';
  /** The node of the connection, without the edges */
  node?: Maybe<ActionMonitorAction>;
};

/** Connection between the ActionMonitorAction type and the TermNode type */
export type ActionMonitorActionToTermNodeConnection = {
  __typename?: 'ActionMonitorActionToTermNodeConnection';
  /** Edges for the ActionMonitorActionToTermNodeConnection connection */
  edges?: Maybe<Array<Maybe<ActionMonitorActionToTermNodeConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<TermNode>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type ActionMonitorActionToTermNodeConnectionEdge = {
  __typename?: 'ActionMonitorActionToTermNodeConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<TermNode>;
};

/** Arguments for filtering the ActionMonitorActionToTermNodeConnection connection */
export type ActionMonitorActionToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** The Taxonomy to filter terms by */
  taxonomies?: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
};

/** Field Group */
export type ActionMonitorAction_Meta = {
  __typename?: 'ActionMonitorAction_Meta';
  fieldGroupName?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  /** If left blank the page or post title will be used. */
  metaTitle?: Maybe<Scalars['String']>;
  ogDescription?: Maybe<Scalars['String']>;
  ogImage?: Maybe<MediaItem>;
  ogTitle?: Maybe<Scalars['String']>;
  twitterCardType?: Maybe<Scalars['String']>;
  twitterDescription?: Maybe<Scalars['String']>;
  twitterImage?: Maybe<MediaItem>;
  twitterTitle?: Maybe<Scalars['String']>;
};

/** A Gravity Forms   field. */
export type AddressField = FormField & {
  __typename?: 'AddressField';
  /** Determines the type of address to be displayed. */
  addressType?: Maybe<AddressFieldTypeEnum>;
  /** Address field value. */
  addressValues?: Maybe<AddressFieldValue>;
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** The field id of the field being used as the copy source. */
  copyValuesOptionFieldId?: Maybe<Scalars['Int']>;
  /** The label that appears next to the copy values option when the form is displayed. The default value is \“Same as previous\”. */
  copyValuesOptionLabel?: Maybe<Scalars['String']>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Contains the country that will be selected by default. Only applicable when &quot;addressType&quot; is set to &quot;INTERATIONAL&quot;. */
  defaultCountry?: Maybe<AddressFieldCountryEnum>;
  /** Contains the province that will be selected by default. Only applicable when &quot;addressType&quot; is set to &quot;CANADA&quot;. */
  defaultProvince?: Maybe<Scalars['String']>;
  /** Contains the state that will be selected by default. Only applicable when &quot;addressType&quot; is set to &quot;US&quot;. */
  defaultState?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Whether autocomplete should be enabled for this field. */
  hasAutocomplete?: Maybe<Scalars['Boolean']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']>;
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** An array of the available properties for each input of the address field. */
  inputs?: Maybe<Array<Maybe<AddressInputProperty>>>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** Indicates whether the copy values option can be used. This option allows users to skip filling out the field and use the same values as another. For example, if the mailing and billing address are the same. */
  shouldCopyValuesOption?: Maybe<Scalars['Boolean']>;
  /** The placement of the labels for the subfields within the group. This setting controls all of the subfields, they cannot be set individually. They may be aligned above or below the inputs. If this property is not set, the “Sub-Label Placement” setting on the Form Settings-&gt;Form Layout page is used. If no setting is specified, the default is above inputs. */
  subLabelPlacement?: Maybe<FormFieldSubLabelPlacementEnum>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** Countries supported by Gravity Forms Address Field. */
export enum AddressFieldCountryEnum {
  /** Andorra . */
  Ad = 'AD',
  /** United Arab Emirates . */
  Ae = 'AE',
  /** Afghanistan . */
  Af = 'AF',
  /** Antigua and Barbuda . */
  Ag = 'AG',
  /** Anguilla . */
  Ai = 'AI',
  /** Albania . */
  Al = 'AL',
  /** Armenia . */
  Am = 'AM',
  /** Angola . */
  Ao = 'AO',
  /** Antarctica . */
  Aq = 'AQ',
  /** Argentina . */
  Ar = 'AR',
  /** American Samoa . */
  As = 'AS',
  /** Austria . */
  At = 'AT',
  /** Australia . */
  Au = 'AU',
  /** Aruba . */
  Aw = 'AW',
  /** Åland Islands . */
  Ax = 'AX',
  /** Azerbaijan . */
  Az = 'AZ',
  /** Bosnia and Herzegovina . */
  Ba = 'BA',
  /** Barbados . */
  Bb = 'BB',
  /** Bangladesh . */
  Bd = 'BD',
  /** Belgium . */
  Be = 'BE',
  /** Burkina Faso . */
  Bf = 'BF',
  /** Bulgaria . */
  Bg = 'BG',
  /** Bahrain . */
  Bh = 'BH',
  /** Burundi . */
  Bi = 'BI',
  /** Benin . */
  Bj = 'BJ',
  /** Saint Barthélemy . */
  Bl = 'BL',
  /** Bermuda . */
  Bm = 'BM',
  /** Brunei Darussalam . */
  Bn = 'BN',
  /** Bolivia . */
  Bo = 'BO',
  /** Bonaire, Sint Eustatius and Saba . */
  Bq = 'BQ',
  /** Brazil . */
  Br = 'BR',
  /** Bahamas . */
  Bs = 'BS',
  /** Bhutan . */
  Bt = 'BT',
  /** Bouvet Island . */
  Bv = 'BV',
  /** Botswana . */
  Bw = 'BW',
  /** Belarus . */
  By = 'BY',
  /** Belize . */
  Bz = 'BZ',
  /** Canada . */
  Ca = 'CA',
  /** Cocos Islands . */
  Cc = 'CC',
  /** Congo, Democratic Republic of the . */
  Cd = 'CD',
  /** Central African Republic . */
  Cf = 'CF',
  /** Congo . */
  Cg = 'CG',
  /** Switzerland . */
  Ch = 'CH',
  /** Côte d'Ivoire . */
  Ci = 'CI',
  /** Cook Islands . */
  Ck = 'CK',
  /** Chile . */
  Cl = 'CL',
  /** Cameroon . */
  Cm = 'CM',
  /** China . */
  Cn = 'CN',
  /** Colombia . */
  Co = 'CO',
  /** Costa Rica . */
  Cr = 'CR',
  /** Cuba . */
  Cu = 'CU',
  /** Cabo Verde . */
  Cv = 'CV',
  /** Curaçao . */
  Cw = 'CW',
  /** Christmas Island . */
  Cx = 'CX',
  /** Cyprus . */
  Cy = 'CY',
  /** Czechia . */
  Cz = 'CZ',
  /** Germany . */
  De = 'DE',
  /** Djibouti . */
  Dj = 'DJ',
  /** Denmark . */
  Dk = 'DK',
  /** Dominica . */
  Dm = 'DM',
  /** Dominican Republic . */
  Do = 'DO',
  /** Algeria . */
  Dz = 'DZ',
  /** Ecuador . */
  Ec = 'EC',
  /** Estonia . */
  Ee = 'EE',
  /** Egypt . */
  Eg = 'EG',
  /** Western Sahara . */
  Eh = 'EH',
  /** Eritrea . */
  Er = 'ER',
  /** Spain . */
  Es = 'ES',
  /** Ethiopia . */
  Et = 'ET',
  /** Finland . */
  Fi = 'FI',
  /** Fiji . */
  Fj = 'FJ',
  /** Falkland Islands . */
  Fk = 'FK',
  /** Micronesia . */
  Fm = 'FM',
  /** Faroe Islands . */
  Fo = 'FO',
  /** France . */
  Fr = 'FR',
  /** Gabon . */
  Ga = 'GA',
  /** United Kingdom . */
  Gb = 'GB',
  /** Grenada . */
  Gd = 'GD',
  /** Georgia . */
  Ge = 'GE',
  /** French Guiana . */
  Gf = 'GF',
  /** Guernsey . */
  Gg = 'GG',
  /** Ghana . */
  Gh = 'GH',
  /** Gibraltar . */
  Gi = 'GI',
  /** Greenland . */
  Gl = 'GL',
  /** Gambia . */
  Gm = 'GM',
  /** Guinea . */
  Gn = 'GN',
  /** Guadeloupe . */
  Gp = 'GP',
  /** Equatorial Guinea . */
  Gq = 'GQ',
  /** Greece . */
  Gr = 'GR',
  /** South Georgia and the South Sandwich Islands . */
  Gs = 'GS',
  /** Guatemala . */
  Gt = 'GT',
  /** Guam . */
  Gu = 'GU',
  /** Guinea-Bissau . */
  Gw = 'GW',
  /** Guyana . */
  Gy = 'GY',
  /** Hong Kong . */
  Hk = 'HK',
  /** Heard Island and McDonald Islands . */
  Hm = 'HM',
  /** Honduras . */
  Hn = 'HN',
  /** Croatia . */
  Hr = 'HR',
  /** Haiti . */
  Ht = 'HT',
  /** Hungary . */
  Hu = 'HU',
  /** Indonesia . */
  Id = 'ID',
  /** Ireland . */
  Ie = 'IE',
  /** Israel . */
  Il = 'IL',
  /** Isle of Man . */
  Im = 'IM',
  /** India . */
  In = 'IN',
  /** British Indian Ocean Territory . */
  Io = 'IO',
  /** Iraq . */
  Iq = 'IQ',
  /** Iran . */
  Ir = 'IR',
  /** Iceland . */
  Is = 'IS',
  /** Italy . */
  It = 'IT',
  /** Jersey . */
  Je = 'JE',
  /** Jamaica . */
  Jm = 'JM',
  /** Jordan . */
  Jo = 'JO',
  /** Japan . */
  Jp = 'JP',
  /** Kenya . */
  Ke = 'KE',
  /** Kyrgyzstan . */
  Kg = 'KG',
  /** Cambodia . */
  Kh = 'KH',
  /** Kiribati . */
  Ki = 'KI',
  /** Comoros . */
  Km = 'KM',
  /** Saint Kitts and Nevis . */
  Kn = 'KN',
  /** Korea, Democratic People's Republic of . */
  Kp = 'KP',
  /** Korea, Republic of . */
  Kr = 'KR',
  /** Kuwait . */
  Kw = 'KW',
  /** Cayman Islands . */
  Ky = 'KY',
  /** Kazakhstan . */
  Kz = 'KZ',
  /** Lao People's Democratic Republic . */
  La = 'LA',
  /** Lebanon . */
  Lb = 'LB',
  /** Saint Lucia . */
  Lc = 'LC',
  /** Liechtenstein . */
  Li = 'LI',
  /** Sri Lanka . */
  Lk = 'LK',
  /** Liberia . */
  Lr = 'LR',
  /** Lesotho . */
  Ls = 'LS',
  /** Lithuania . */
  Lt = 'LT',
  /** Luxembourg . */
  Lu = 'LU',
  /** Latvia . */
  Lv = 'LV',
  /** Libya . */
  Ly = 'LY',
  /** Morocco . */
  Ma = 'MA',
  /** Monaco . */
  Mc = 'MC',
  /** Moldova . */
  Md = 'MD',
  /** Montenegro . */
  Me = 'ME',
  /** Saint Martin . */
  Mf = 'MF',
  /** Madagascar . */
  Mg = 'MG',
  /** Marshall Islands . */
  Mh = 'MH',
  /** North Macedonia . */
  Mk = 'MK',
  /** Mali . */
  Ml = 'ML',
  /** Myanmar . */
  Mm = 'MM',
  /** Mongolia . */
  Mn = 'MN',
  /** Macao . */
  Mo = 'MO',
  /** Northern Mariana Islands . */
  Mp = 'MP',
  /** Martinique . */
  Mq = 'MQ',
  /** Mauritania . */
  Mr = 'MR',
  /** Montserrat . */
  Ms = 'MS',
  /** Malta . */
  Mt = 'MT',
  /** Mauritius . */
  Mu = 'MU',
  /** Maldives . */
  Mv = 'MV',
  /** Malawi . */
  Mw = 'MW',
  /** Mexico . */
  Mx = 'MX',
  /** Malaysia . */
  My = 'MY',
  /** Mozambique . */
  Mz = 'MZ',
  /** Namibia . */
  Na = 'NA',
  /** New Caledonia . */
  Nc = 'NC',
  /** Niger . */
  Ne = 'NE',
  /** Norfolk Island . */
  Nf = 'NF',
  /** Nigeria . */
  Ng = 'NG',
  /** Nicaragua . */
  Ni = 'NI',
  /** Netherlands . */
  Nl = 'NL',
  /** Norway . */
  No = 'NO',
  /** Nepal . */
  Np = 'NP',
  /** Nauru . */
  Nr = 'NR',
  /** Niue . */
  Nu = 'NU',
  /** New Zealand . */
  Nz = 'NZ',
  /** Oman . */
  Om = 'OM',
  /** Panama . */
  Pa = 'PA',
  /** Peru . */
  Pe = 'PE',
  /** French Polynesia . */
  Pf = 'PF',
  /** Papua New Guinea . */
  Pg = 'PG',
  /** Philippines . */
  Ph = 'PH',
  /** Pakistan . */
  Pk = 'PK',
  /** Poland . */
  Pl = 'PL',
  /** Saint Pierre and Miquelon . */
  Pm = 'PM',
  /** Pitcairn . */
  Pn = 'PN',
  /** Puerto Rico . */
  Pr = 'PR',
  /** Palestine, State of . */
  Ps = 'PS',
  /** Portugal . */
  Pt = 'PT',
  /** Palau . */
  Pw = 'PW',
  /** Paraguay . */
  Py = 'PY',
  /** Qatar . */
  Qa = 'QA',
  /** Réunion . */
  Re = 'RE',
  /** Romania . */
  Ro = 'RO',
  /** Serbia . */
  Rs = 'RS',
  /** Russian Federation . */
  Ru = 'RU',
  /** Rwanda . */
  Rw = 'RW',
  /** Saudi Arabia . */
  Sa = 'SA',
  /** Solomon Islands . */
  Sb = 'SB',
  /** Seychelles . */
  Sc = 'SC',
  /** Sudan . */
  Sd = 'SD',
  /** Sweden . */
  Se = 'SE',
  /** Singapore . */
  Sg = 'SG',
  /** Saint Helena, Ascension and Tristan da Cunha . */
  Sh = 'SH',
  /** Slovenia . */
  Si = 'SI',
  /** Svalbard and Jan Mayen . */
  Sj = 'SJ',
  /** Slovakia . */
  Sk = 'SK',
  /** Sierra Leone . */
  Sl = 'SL',
  /** San Marino . */
  Sm = 'SM',
  /** Senegal . */
  Sn = 'SN',
  /** Somalia . */
  So = 'SO',
  /** Suriname . */
  Sr = 'SR',
  /** South Sudan . */
  Ss = 'SS',
  /** Sao Tome and Principe . */
  St = 'ST',
  /** El Salvador . */
  Sv = 'SV',
  /** Sint Maarten . */
  Sx = 'SX',
  /** Syria Arab Republic . */
  Sy = 'SY',
  /** Eswatini . */
  Sz = 'SZ',
  /** Turks and Caicos Islands . */
  Tc = 'TC',
  /** Chad . */
  Td = 'TD',
  /** French Southern Territories . */
  Tf = 'TF',
  /** Togo . */
  Tg = 'TG',
  /** Thailand . */
  Th = 'TH',
  /** Tajikistan . */
  Tj = 'TJ',
  /** Tokelau . */
  Tk = 'TK',
  /** Timor-Leste . */
  Tl = 'TL',
  /** Turkmenistan . */
  Tm = 'TM',
  /** Tunisia . */
  Tn = 'TN',
  /** Tonga . */
  To = 'TO',
  /** Türkiye . */
  Tr = 'TR',
  /** Trinidad and Tobago . */
  Tt = 'TT',
  /** Tuvalu . */
  Tv = 'TV',
  /** Taiwan . */
  Tw = 'TW',
  /** Tanzania, the United Republic of . */
  Tz = 'TZ',
  /** Ukraine . */
  Ua = 'UA',
  /** Uganda . */
  Ug = 'UG',
  /** US Minor Outlying Islands . */
  Um = 'UM',
  /** United States . */
  Us = 'US',
  /** Uruguay . */
  Uy = 'UY',
  /** Uzbekistan . */
  Uz = 'UZ',
  /** Holy See . */
  Va = 'VA',
  /** Saint Vincent and the Grenadines . */
  Vc = 'VC',
  /** Venezuela . */
  Ve = 'VE',
  /** Virgin Islands, British . */
  Vg = 'VG',
  /** Virgin Islands, U.S. . */
  Vi = 'VI',
  /** Viet Nam . */
  Vn = 'VN',
  /** Vanuatu . */
  Vu = 'VU',
  /** Wallis and Futuna . */
  Wf = 'WF',
  /** Samoa . */
  Ws = 'WS',
  /** Yemen . */
  Ye = 'YE',
  /** Mayotte . */
  Yt = 'YT',
  /** South Africa . */
  Za = 'ZA',
  /** Zambia . */
  Zm = 'ZM',
  /** Zimbabwe . */
  Zw = 'ZW'
}

/** Input fields for Address FormField. */
export type AddressFieldInput = {
  /** Address city. */
  city?: InputMaybe<Scalars['String']>;
  /** Address country. */
  country?: InputMaybe<AddressFieldCountryEnum>;
  /** Address line two. */
  lineTwo?: InputMaybe<Scalars['String']>;
  /** Address state/region/province name. */
  state?: InputMaybe<Scalars['String']>;
  /** Street address. */
  street?: InputMaybe<Scalars['String']>;
  /** Address zip code. */
  zip?: InputMaybe<Scalars['String']>;
};

/** Determines the type of address to be displayed. */
export enum AddressFieldTypeEnum {
  /** Canada address type. */
  Canada = 'CANADA',
  /** International address type. */
  International = 'INTERNATIONAL',
  /** United States address type. */
  Us = 'US'
}

/** The individual properties for each element of the address value field. */
export type AddressFieldValue = {
  __typename?: 'AddressFieldValue';
  /** Address city. */
  city?: Maybe<Scalars['String']>;
  /** Address country. */
  country?: Maybe<AddressFieldCountryEnum>;
  /** Address line two. */
  lineTwo?: Maybe<Scalars['String']>;
  /** Address state/region/province name. */
  state?: Maybe<Scalars['String']>;
  /** Street address. */
  street?: Maybe<Scalars['String']>;
  /** Address zip code. */
  zip?: Maybe<Scalars['String']>;
};

/** Address input values. */
export type AddressInputProperty = {
  __typename?: 'AddressInputProperty';
  /** The autocomplete attribute for the field. */
  autocompleteAttribute?: Maybe<Scalars['String']>;
  /** The custom label for the input. When set, this is used in place of the label. */
  customLabel?: Maybe<Scalars['String']>;
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']>;
  /** The input ID. Input IDs follow the following naming convention: FIELDID.INPUTID (i.e. 5.1), where FIELDID is the id of the containing field and INPUTID specifies the input field. */
  id?: Maybe<Scalars['Float']>;
  /** Whether or not this field should be hidden. */
  isHidden?: Maybe<Scalars['Boolean']>;
  /** Key used to identify this input. */
  key?: Maybe<Scalars['String']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  name?: Maybe<Scalars['String']>;
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']>;
};

/** Alert Box options */
export type AlertBox = {
  __typename?: 'AlertBox';
  AlertBox?: Maybe<AlertBox_Alertbox>;
  pageSlug?: Maybe<Scalars['String']>;
  pageTitle?: Maybe<Scalars['String']>;
};

/** Field Group */
export type AlertBox_Alertbox = {
  __typename?: 'AlertBox_Alertbox';
  alertContent?: Maybe<Scalars['String']>;
  fieldGroupName?: Maybe<Scalars['String']>;
  /** Note: This will be visible on every page of the site upon the user first landing on the site per session. */
  showAlert?: Maybe<Scalars['Boolean']>;
};

/** The AM or PM cycle in a 12-hour clock. */
export enum AmPmEnum {
  /** AM. The first 12-hour cycle of the day. */
  Am = 'AM',
  /** PM. The second 12-hour cycle of the day. */
  Pm = 'PM'
}

/** The areaOfSpecialism type */
export type AreaOfSpecialism = DatabaseIdentifier & HierarchicalTermNode & MenuItemLinkable & Node & TermNode & UniformResourceIdentifiable & {
  __typename?: 'AreaOfSpecialism';
  /** The ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
  ancestors?: Maybe<AreaOfSpecialismToAncestorsAreaOfSpecialismConnection>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of databaseId
   */
  areaOfSpecialismId?: Maybe<Scalars['Int']>;
  /** Connection between the areaOfSpecialism type and the areaOfSpecialism type */
  children?: Maybe<AreaOfSpecialismToAreaOfSpecialismConnection>;
  /** Connection between the areaOfSpecialism type and the ContentNode type */
  contentNodes?: Maybe<AreaOfSpecialismToContentNodeConnection>;
  /** The number of objects connected to the object */
  count?: Maybe<Scalars['Int']>;
  /** The unique resource identifier path */
  databaseId: Scalars['Int'];
  /** The description of the object */
  description?: Maybe<Scalars['String']>;
  /** Connection between the TermNode type and the EnqueuedScript type */
  enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>;
  /** Connection between the TermNode type and the EnqueuedStylesheet type */
  enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>;
  /** The unique resource identifier path */
  id: Scalars['ID'];
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean'];
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']>;
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean'];
  /** The link to the term */
  link?: Maybe<Scalars['String']>;
  /** Connection between the areaOfSpecialism type and the member type */
  members?: Maybe<AreaOfSpecialismToMemberConnection>;
  /** The human friendly name of the object. */
  name?: Maybe<Scalars['String']>;
  /** Connection between the areaOfSpecialism type and the areaOfSpecialism type */
  parent?: Maybe<AreaOfSpecialismToParentAreaOfSpecialismConnectionEdge>;
  /** Database id of the parent node */
  parentDatabaseId?: Maybe<Scalars['Int']>;
  /** The globally unique identifier of the parent node. */
  parentId?: Maybe<Scalars['ID']>;
  /** An alphanumeric identifier for the object unique to its type. */
  slug?: Maybe<Scalars['String']>;
  /** Connection between the areaOfSpecialism type and the Taxonomy type */
  taxonomy?: Maybe<AreaOfSpecialismToTaxonomyConnectionEdge>;
  /** The name of the taxonomy that the object is associated with */
  taxonomyName?: Maybe<Scalars['String']>;
  /** The ID of the term group that this term object belongs to */
  termGroupId?: Maybe<Scalars['Int']>;
  /** The taxonomy ID that the object is associated with */
  termTaxonomyId?: Maybe<Scalars['Int']>;
  /** The unique resource identifier path */
  uri?: Maybe<Scalars['String']>;
};


/** The areaOfSpecialism type */
export type AreaOfSpecialismAncestorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The areaOfSpecialism type */
export type AreaOfSpecialismChildrenArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AreaOfSpecialismToAreaOfSpecialismConnectionWhereArgs>;
};


/** The areaOfSpecialism type */
export type AreaOfSpecialismContentNodesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AreaOfSpecialismToContentNodeConnectionWhereArgs>;
};


/** The areaOfSpecialism type */
export type AreaOfSpecialismEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The areaOfSpecialism type */
export type AreaOfSpecialismEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The areaOfSpecialism type */
export type AreaOfSpecialismMembersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AreaOfSpecialismToMemberConnectionWhereArgs>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum AreaOfSpecialismIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI'
}

/** Connection between the areaOfSpecialism type and the areaOfSpecialism type */
export type AreaOfSpecialismToAncestorsAreaOfSpecialismConnection = {
  __typename?: 'AreaOfSpecialismToAncestorsAreaOfSpecialismConnection';
  /** Edges for the AreaOfSpecialismToAncestorsAreaOfSpecialismConnection connection */
  edges?: Maybe<Array<Maybe<AreaOfSpecialismToAncestorsAreaOfSpecialismConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<AreaOfSpecialism>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type AreaOfSpecialismToAncestorsAreaOfSpecialismConnectionEdge = {
  __typename?: 'AreaOfSpecialismToAncestorsAreaOfSpecialismConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<AreaOfSpecialism>;
};

/** Connection between the areaOfSpecialism type and the areaOfSpecialism type */
export type AreaOfSpecialismToAreaOfSpecialismConnection = {
  __typename?: 'AreaOfSpecialismToAreaOfSpecialismConnection';
  /** Edges for the AreaOfSpecialismToAreaOfSpecialismConnection connection */
  edges?: Maybe<Array<Maybe<AreaOfSpecialismToAreaOfSpecialismConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<AreaOfSpecialism>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type AreaOfSpecialismToAreaOfSpecialismConnectionEdge = {
  __typename?: 'AreaOfSpecialismToAreaOfSpecialismConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<AreaOfSpecialism>;
};

/** Arguments for filtering the AreaOfSpecialismToAreaOfSpecialismConnection connection */
export type AreaOfSpecialismToAreaOfSpecialismConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
};

/** Connection between the areaOfSpecialism type and the ContentNode type */
export type AreaOfSpecialismToContentNodeConnection = {
  __typename?: 'AreaOfSpecialismToContentNodeConnection';
  /** Edges for the AreaOfSpecialismToContentNodeConnection connection */
  edges?: Maybe<Array<Maybe<AreaOfSpecialismToContentNodeConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentNode>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type AreaOfSpecialismToContentNodeConnectionEdge = {
  __typename?: 'AreaOfSpecialismToContentNodeConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<ContentNode>;
};

/** Arguments for filtering the AreaOfSpecialismToContentNodeConnection connection */
export type AreaOfSpecialismToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfAreaOfSpecialismEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** Connection between the areaOfSpecialism type and the member type */
export type AreaOfSpecialismToMemberConnection = {
  __typename?: 'AreaOfSpecialismToMemberConnection';
  /** Edges for the AreaOfSpecialismToMemberConnection connection */
  edges?: Maybe<Array<Maybe<AreaOfSpecialismToMemberConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Member>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type AreaOfSpecialismToMemberConnectionEdge = {
  __typename?: 'AreaOfSpecialismToMemberConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<Member>;
};

/** Arguments for filtering the AreaOfSpecialismToMemberConnection connection */
export type AreaOfSpecialismToMemberConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** Connection between the areaOfSpecialism type and the areaOfSpecialism type */
export type AreaOfSpecialismToParentAreaOfSpecialismConnectionEdge = {
  __typename?: 'AreaOfSpecialismToParentAreaOfSpecialismConnectionEdge';
  /** The node of the connection, without the edges */
  node?: Maybe<AreaOfSpecialism>;
};

/** Connection between the areaOfSpecialism type and the Taxonomy type */
export type AreaOfSpecialismToTaxonomyConnectionEdge = {
  __typename?: 'AreaOfSpecialismToTaxonomyConnectionEdge';
  /** The node of the connection, without the edges */
  node?: Maybe<Taxonomy>;
};

/** Avatars are profile images for users. WordPress by default uses the Gravatar service to host and fetch avatars from. */
export type Avatar = {
  __typename?: 'Avatar';
  /** URL for the default image or a default type. Accepts &#039;404&#039; (return a 404 instead of a default image), &#039;retro&#039; (8bit), &#039;monsterid&#039; (monster), &#039;wavatar&#039; (cartoon face), &#039;indenticon&#039; (the &#039;quilt&#039;), &#039;mystery&#039;, &#039;mm&#039;, or &#039;mysteryman&#039; (The Oyster Man), &#039;blank&#039; (transparent GIF), or &#039;gravatar_default&#039; (the Gravatar logo). */
  default?: Maybe<Scalars['String']>;
  /** HTML attributes to insert in the IMG element. Is not sanitized. */
  extraAttr?: Maybe<Scalars['String']>;
  /** Whether to always show the default image, never the Gravatar. */
  forceDefault?: Maybe<Scalars['Boolean']>;
  /** Whether the avatar was successfully found. */
  foundAvatar?: Maybe<Scalars['Boolean']>;
  /** Height of the avatar image. */
  height?: Maybe<Scalars['Int']>;
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']>;
  /** What rating to display avatars up to. Accepts &#039;G&#039;, &#039;PG&#039;, &#039;R&#039;, &#039;X&#039;, and are judged in that order. */
  rating?: Maybe<Scalars['String']>;
  /** Type of url scheme to use. Typically HTTP vs. HTTPS. */
  scheme?: Maybe<Scalars['String']>;
  /** The size of the avatar in pixels. A value of 96 will match a 96px x 96px gravatar image. */
  size?: Maybe<Scalars['Int']>;
  /** URL for the gravatar image source. */
  url?: Maybe<Scalars['String']>;
  /** Width of the avatar image. */
  width?: Maybe<Scalars['Int']>;
};

/** What rating to display avatars up to. Accepts 'G', 'PG', 'R', 'X', and are judged in that order. Default is the value of the 'avatar_rating' option */
export enum AvatarRatingEnum {
  /** Indicates a G level avatar rating level. */
  G = 'G',
  /** Indicates a PG level avatar rating level. */
  Pg = 'PG',
  /** Indicates an R level avatar rating level. */
  R = 'R',
  /** Indicates an X level avatar rating level. */
  X = 'X'
}

/** bbl/button-group block */
export type BblButtonGroupBlock = Block & {
  __typename?: 'BblButtonGroupBlock';
  attributes?: Maybe<BblButtonGroupBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type BblButtonGroupBlockAttributes = {
  __typename?: 'BblButtonGroupBlockAttributes';
  className?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
};

/** bbl/card block */
export type BblCardBlock = Block & {
  __typename?: 'BblCardBlock';
  attributes?: Maybe<BblCardBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type BblCardBlockAttributes = {
  __typename?: 'BblCardBlockAttributes';
  className?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
};

/** bbl/latest-news-slider block */
export type BblLatestNewsSliderBlock = Block & {
  __typename?: 'BblLatestNewsSliderBlock';
  attributes?: Maybe<BblLatestNewsSliderBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type BblLatestNewsSliderBlockAttributes = {
  __typename?: 'BblLatestNewsSliderBlockAttributes';
  className?: Maybe<Scalars['String']>;
  latestNewsSliderHeading?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
};

/** bbl/member-benefit-card block */
export type BblMemberBenefitCardBlock = Block & {
  __typename?: 'BblMemberBenefitCardBlock';
  attributes?: Maybe<BblMemberBenefitCardBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type BblMemberBenefitCardBlockAttributes = {
  __typename?: 'BblMemberBenefitCardBlockAttributes';
  className?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
};

/** bbl/page-title block */
export type BblPageTitleBlock = Block & {
  __typename?: 'BblPageTitleBlock';
  attributes?: Maybe<BblPageTitleBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type BblPageTitleBlockAttributes = {
  __typename?: 'BblPageTitleBlockAttributes';
  className?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
};

/** bbl/post-wrap block */
export type BblPostWrapBlock = Block & {
  __typename?: 'BblPostWrapBlock';
  attributes?: Maybe<BblPostWrapBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type BblPostWrapBlockAttributes = {
  __typename?: 'BblPostWrapBlockAttributes';
  className?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
};

/** bbl/service-listing block */
export type BblServiceListingBlock = Block & {
  __typename?: 'BblServiceListingBlock';
  attributes?: Maybe<BblServiceListingBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type BblServiceListingBlockAttributes = {
  __typename?: 'BblServiceListingBlockAttributes';
  className?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
};

/** bbl/testimonial-slider block */
export type BblTestimonialSliderBlock = Block & {
  __typename?: 'BblTestimonialSliderBlock';
  attributes?: Maybe<BblTestimonialSliderBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type BblTestimonialSliderBlockAttributes = {
  __typename?: 'BblTestimonialSliderBlockAttributes';
  className?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  testimonialSliderHeading?: Maybe<Scalars['String']>;
};

/** bbl/thin-intro block */
export type BblThinIntroBlock = Block & {
  __typename?: 'BblThinIntroBlock';
  attributes?: Maybe<BblThinIntroBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type BblThinIntroBlockAttributes = {
  __typename?: 'BblThinIntroBlockAttributes';
  className?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
};

/** Gutenberg block interface */
export type Block = {
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

/** Gutenberg post interface */
export type BlockEditorContentNode = {
  /** Gutenberg blocks */
  blocks?: Maybe<Array<Block>>;
  /** Gutenberg blocks as json string */
  blocksJSON?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Previewed gutenberg blocks */
  previewBlocks?: Maybe<Array<Block>>;
  /** Previewed Gutenberg blocks as json string */
  previewBlocksJSON?: Maybe<Scalars['String']>;
};

/** Connection between the RootQuery type and the BlockEditorContentNode type */
export type BlockEditorContentNodeConnection = {
  __typename?: 'BlockEditorContentNodeConnection';
  /** Edges for the BlockEditorContentNodeConnection connection */
  edges?: Maybe<Array<Maybe<BlockEditorContentNodeConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<BlockEditorContentNode>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type BlockEditorContentNodeConnectionEdge = {
  __typename?: 'BlockEditorContentNodeConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<BlockEditorContentNode>;
};

/** Arguments for filtering the BlockEditorContentNodeConnection connection */
export type BlockEditorContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** The BlockEditorPreview type */
export type BlockEditorPreview = ContentNode & DatabaseIdentifier & Node & NodeWithAuthor & NodeWithContentEditor & NodeWithTemplate & NodeWithTitle & UniformResourceIdentifiable & {
  __typename?: 'BlockEditorPreview';
  Meta?: Maybe<BlockEditorPreview_Meta>;
  /** Connection between the NodeWithAuthor type and the User type */
  author?: Maybe<NodeWithAuthorToUserConnectionEdge>;
  /** The database identifier of the author of the node */
  authorDatabaseId?: Maybe<Scalars['Int']>;
  /** The globally unique identifier of the author of the node */
  authorId?: Maybe<Scalars['ID']>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of the databaseId field
   */
  blockEditorPreviewId: Scalars['Int'];
  blocks?: Maybe<Array<Block>>;
  blocksJSON?: Maybe<Scalars['String']>;
  /** The content of the post. */
  content?: Maybe<Scalars['String']>;
  /** Connection between the ContentNode type and the ContentType type */
  contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /** The name of the Content Type the node belongs to */
  contentTypeName: Scalars['String'];
  /** The unique identifier stored in the database */
  databaseId: Scalars['Int'];
  /** Post publishing date. */
  date?: Maybe<Scalars['String']>;
  /** The publishing date set in GMT. */
  dateGmt?: Maybe<Scalars['String']>;
  /** The desired slug of the post */
  desiredSlug?: Maybe<Scalars['String']>;
  /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
  editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
  /** The RSS enclosure for the object */
  enclosure?: Maybe<Scalars['String']>;
  /** Connection between the ContentNode type and the EnqueuedScript type */
  enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
  /** Connection between the ContentNode type and the EnqueuedStylesheet type */
  enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
  guid?: Maybe<Scalars['String']>;
  /** The globally unique identifier of the wgg_preview object. */
  id: Scalars['ID'];
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean'];
  /** Whether the object is a node in the preview state */
  isPreview?: Maybe<Scalars['Boolean']>;
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']>;
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean'];
  /** The user that most recently edited the node */
  lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
  lastUpdateTime?: Maybe<Scalars['String']>;
  /** The permalink of the post */
  link?: Maybe<Scalars['String']>;
  /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
  modified?: Maybe<Scalars['String']>;
  /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
  modifiedGmt?: Maybe<Scalars['String']>;
  /** Connection between the BlockEditorPreview type and the BlockEditorPreview type */
  preview?: Maybe<BlockEditorPreviewToPreviewConnectionEdge>;
  /** The database id of the preview node */
  previewRevisionDatabaseId?: Maybe<Scalars['Int']>;
  /** Whether the object is a node in the preview state */
  previewRevisionId?: Maybe<Scalars['ID']>;
  previewed?: Maybe<BlockEditorContentNode>;
  previewedDatabaseId?: Maybe<Scalars['Int']>;
  previewedParentDatabaseId?: Maybe<Scalars['Int']>;
  /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
  slug?: Maybe<Scalars['String']>;
  /** The current status of the object */
  status?: Maybe<Scalars['String']>;
  /** The template assigned to the node */
  template?: Maybe<ContentTemplate>;
  /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
  title?: Maybe<Scalars['String']>;
  /** The unique resource identifier path */
  uri?: Maybe<Scalars['String']>;
};


/** The BlockEditorPreview type */
export type BlockEditorPreviewContentArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};


/** The BlockEditorPreview type */
export type BlockEditorPreviewEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The BlockEditorPreview type */
export type BlockEditorPreviewEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The BlockEditorPreview type */
export type BlockEditorPreviewTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum BlockEditorPreviewIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a resource by the URI. */
  Uri = 'URI'
}

/** Connection between the BlockEditorPreview type and the BlockEditorPreview type */
export type BlockEditorPreviewToPreviewConnectionEdge = {
  __typename?: 'BlockEditorPreviewToPreviewConnectionEdge';
  /** The node of the connection, without the edges */
  node?: Maybe<BlockEditorPreview>;
};

/** Field Group */
export type BlockEditorPreview_Meta = {
  __typename?: 'BlockEditorPreview_Meta';
  fieldGroupName?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  /** If left blank the page or post title will be used. */
  metaTitle?: Maybe<Scalars['String']>;
  ogDescription?: Maybe<Scalars['String']>;
  ogImage?: Maybe<MediaItem>;
  ogTitle?: Maybe<Scalars['String']>;
  twitterCardType?: Maybe<Scalars['String']>;
  twitterDescription?: Maybe<Scalars['String']>;
  twitterImage?: Maybe<MediaItem>;
  twitterTitle?: Maybe<Scalars['String']>;
};

/** Business Information options */
export type BusinessInformation = {
  __typename?: 'BusinessInformation';
  LocationData?: Maybe<BusinessInformation_Locationdata>;
  pageSlug?: Maybe<Scalars['String']>;
  pageTitle?: Maybe<Scalars['String']>;
};

/** Field Group */
export type BusinessInformation_Locationdata = {
  __typename?: 'BusinessInformation_Locationdata';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fieldGroupName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  town?: Maybe<Scalars['String']>;
};

/** A Gravity Forms   field. */
export type CaptchaField = FormField & {
  __typename?: 'CaptchaField';
  /** The language used when the captcha is displayed. This property is available when the captchaType is “captcha”, the default. The possible values are the language codes used by WordPress. */
  captchaBadgePosition?: Maybe<CaptchaFieldBadgePositionEnum>;
  /** The language used when the captcha is displayed. This property is available when the captchaType is “captcha”, the default. The possible values are the language codes used by WordPress. */
  captchaLanguage?: Maybe<Scalars['String']>;
  /** Determines the theme to be used for the reCAPTCHA field. Only applicable to the recaptcha captcha type. */
  captchaTheme?: Maybe<CaptchaFieldThemeEnum>;
  /** Determines the type of CAPTCHA field to be used. */
  captchaType?: Maybe<CaptchaFieldTypeEnum>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** Determines the image’s background color, in HEX format (i.e. #CCCCCC). Only applicable to simple_captcha and math captcha types. */
  simpleCaptchaBackgroundColor?: Maybe<Scalars['String']>;
  /** Determines the image’s font color, in HEX format (i.e. #CCCCCC). Only applicable to simple_captcha and math captcha types. */
  simpleCaptchaFontColor?: Maybe<Scalars['String']>;
  /** Determines the CAPTCHA image size. Only applicable to simple_captcha and math captcha types. */
  simpleCaptchaSize?: Maybe<FormFieldSizeEnum>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** The position to place the (invisible) reCaptcha badge. */
export enum CaptchaFieldBadgePositionEnum {
  /** Bottom-left position. */
  BottomLeft = 'BOTTOM_LEFT',
  /** Bottom-right position. */
  BottomRight = 'BOTTOM_RIGHT',
  /** Inline position. */
  Inline = 'INLINE'
}

/** The theme to be used for the reCAPTCHA field. */
export enum CaptchaFieldThemeEnum {
  /** Dark reCAPTCHA theme. */
  Dark = 'DARK',
  /** Light reCAPTCHA theme. */
  Light = 'LIGHT'
}

/** Type of CAPTCHA field to be used. */
export enum CaptchaFieldTypeEnum {
  /** Math CAPTCHA type. */
  Math = 'MATH',
  /** reCAPTCHA type. */
  Recaptcha = 'RECAPTCHA',
  /** Simple CAPTCHA type. */
  Simple = 'SIMPLE'
}

/** The category type */
export type Category = DatabaseIdentifier & HierarchicalTermNode & MenuItemLinkable & Node & TermNode & UniformResourceIdentifiable & {
  __typename?: 'Category';
  /** The ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
  ancestors?: Maybe<CategoryToAncestorsCategoryConnection>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of databaseId
   */
  categoryId?: Maybe<Scalars['Int']>;
  /** Connection between the category type and the category type */
  children?: Maybe<CategoryToCategoryConnection>;
  /** Connection between the category type and the ContentNode type */
  contentNodes?: Maybe<CategoryToContentNodeConnection>;
  /** The number of objects connected to the object */
  count?: Maybe<Scalars['Int']>;
  /** The unique resource identifier path */
  databaseId: Scalars['Int'];
  /** The description of the object */
  description?: Maybe<Scalars['String']>;
  /** Connection between the TermNode type and the EnqueuedScript type */
  enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>;
  /** Connection between the TermNode type and the EnqueuedStylesheet type */
  enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>;
  /** The unique resource identifier path */
  id: Scalars['ID'];
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean'];
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']>;
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean'];
  /** The link to the term */
  link?: Maybe<Scalars['String']>;
  /** The human friendly name of the object. */
  name?: Maybe<Scalars['String']>;
  /** Connection between the category type and the category type */
  parent?: Maybe<CategoryToParentCategoryConnectionEdge>;
  /** Database id of the parent node */
  parentDatabaseId?: Maybe<Scalars['Int']>;
  /** The globally unique identifier of the parent node. */
  parentId?: Maybe<Scalars['ID']>;
  /** Connection between the category type and the post type */
  posts?: Maybe<CategoryToPostConnection>;
  /** An alphanumeric identifier for the object unique to its type. */
  slug?: Maybe<Scalars['String']>;
  /** Connection between the category type and the Taxonomy type */
  taxonomy?: Maybe<CategoryToTaxonomyConnectionEdge>;
  /** The name of the taxonomy that the object is associated with */
  taxonomyName?: Maybe<Scalars['String']>;
  /** The ID of the term group that this term object belongs to */
  termGroupId?: Maybe<Scalars['Int']>;
  /** The taxonomy ID that the object is associated with */
  termTaxonomyId?: Maybe<Scalars['Int']>;
  /** The unique resource identifier path */
  uri?: Maybe<Scalars['String']>;
};


/** The category type */
export type CategoryAncestorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The category type */
export type CategoryChildrenArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CategoryToCategoryConnectionWhereArgs>;
};


/** The category type */
export type CategoryContentNodesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CategoryToContentNodeConnectionWhereArgs>;
};


/** The category type */
export type CategoryEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The category type */
export type CategoryEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The category type */
export type CategoryPostsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CategoryToPostConnectionWhereArgs>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum CategoryIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI'
}

/** Connection between the category type and the category type */
export type CategoryToAncestorsCategoryConnection = {
  __typename?: 'CategoryToAncestorsCategoryConnection';
  /** Edges for the CategoryToAncestorsCategoryConnection connection */
  edges?: Maybe<Array<Maybe<CategoryToAncestorsCategoryConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Category>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type CategoryToAncestorsCategoryConnectionEdge = {
  __typename?: 'CategoryToAncestorsCategoryConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<Category>;
};

/** Connection between the category type and the category type */
export type CategoryToCategoryConnection = {
  __typename?: 'CategoryToCategoryConnection';
  /** Edges for the CategoryToCategoryConnection connection */
  edges?: Maybe<Array<Maybe<CategoryToCategoryConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Category>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type CategoryToCategoryConnectionEdge = {
  __typename?: 'CategoryToCategoryConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<Category>;
};

/** Arguments for filtering the CategoryToCategoryConnection connection */
export type CategoryToCategoryConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
};

/** Connection between the category type and the ContentNode type */
export type CategoryToContentNodeConnection = {
  __typename?: 'CategoryToContentNodeConnection';
  /** Edges for the CategoryToContentNodeConnection connection */
  edges?: Maybe<Array<Maybe<CategoryToContentNodeConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentNode>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type CategoryToContentNodeConnectionEdge = {
  __typename?: 'CategoryToContentNodeConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<ContentNode>;
};

/** Arguments for filtering the CategoryToContentNodeConnection connection */
export type CategoryToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfCategoryEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** Connection between the category type and the category type */
export type CategoryToParentCategoryConnectionEdge = {
  __typename?: 'CategoryToParentCategoryConnectionEdge';
  /** The node of the connection, without the edges */
  node?: Maybe<Category>;
};

/** Connection between the category type and the post type */
export type CategoryToPostConnection = {
  __typename?: 'CategoryToPostConnection';
  /** Edges for the CategoryToPostConnection connection */
  edges?: Maybe<Array<Maybe<CategoryToPostConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Post>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type CategoryToPostConnectionEdge = {
  __typename?: 'CategoryToPostConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<Post>;
};

/** Arguments for filtering the CategoryToPostConnection connection */
export type CategoryToPostConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Category ID */
  categoryId?: InputMaybe<Scalars['Int']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Use Category Slug */
  categoryName?: InputMaybe<Scalars['String']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars['String']>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars['String']>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of tag slugs, used to display objects from one tag OR another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of tag slugs, used to exclude objects in specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** Connection between the category type and the Taxonomy type */
export type CategoryToTaxonomyConnectionEdge = {
  __typename?: 'CategoryToTaxonomyConnectionEdge';
  /** The node of the connection, without the edges */
  node?: Maybe<Taxonomy>;
};

/** A Gravity Forms   field. */
export type CheckboxField = FormField & {
  __typename?: 'CheckboxField';
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** Checkbox field value. */
  checkboxValues?: Maybe<Array<Maybe<CheckboxFieldValue>>>;
  /** The available choices for the checkbox field. */
  choices?: Maybe<Array<Maybe<CheckboxFieldChoice>>>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
  hasChoiceValue?: Maybe<Scalars['Boolean']>;
  /** Whether the \&quot;select all\&quot; choice should be displayed. */
  hasSelectAll?: Maybe<Scalars['Boolean']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']>;
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** An array of the available properties for each input of the checkbox field. */
  inputs?: Maybe<Array<Maybe<CheckboxInputProperty>>>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** Checkbox choice values. */
export type CheckboxFieldChoice = {
  __typename?: 'CheckboxFieldChoice';
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']>;
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']>;
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']>;
};

/** Input fields for a single checkbox. */
export type CheckboxFieldInput = {
  /** Input ID. */
  inputId?: InputMaybe<Scalars['Float']>;
  /** Input value. */
  value?: InputMaybe<Scalars['String']>;
};

/** The individual properties for each element of the Checkbox value field. */
export type CheckboxFieldValue = {
  __typename?: 'CheckboxFieldValue';
  /** Input ID. */
  inputId?: Maybe<Scalars['Float']>;
  /** Input text. */
  text?: Maybe<Scalars['String']>;
  /** Input value. */
  value?: Maybe<Scalars['String']>;
};

/** Checkbox input values. */
export type CheckboxInputProperty = {
  __typename?: 'CheckboxInputProperty';
  /** The input ID. Input IDs follow the following naming convention: FIELDID.INPUTID (i.e. 5.1), where FIELDID is the id of the containing field and INPUTID specifies the input field. */
  id?: Maybe<Scalars['Float']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  name?: Maybe<Scalars['String']>;
};

/** Client Options options */
export type ClientOptions = {
  __typename?: 'ClientOptions';
  ClientOptions?: Maybe<ClientOptions_Clientoptions>;
  pageSlug?: Maybe<Scalars['String']>;
  pageTitle?: Maybe<Scalars['String']>;
  /** The name of the site */
  siteName?: Maybe<Scalars['String']>;
};

/** Field Group */
export type ClientOptions_Clientoptions = {
  __typename?: 'ClientOptions_Clientoptions';
  fieldGroupName?: Maybe<Scalars['String']>;
  /** Select the type of map, dynamic comes at an monthly additional cost. */
  map?: Maybe<Scalars['String']>;
  /** Select the active post types to display, this just removes them from the admin menu. */
  postTypes?: Maybe<ClientOptions_Clientoptions_PostTypes>;
  /** This is the frontend URL the site will be served from */
  siteUrl?: Maybe<Scalars['String']>;
};

/** Field Group */
export type ClientOptions_Clientoptions_PostTypes = {
  __typename?: 'ClientOptions_Clientoptions_PostTypes';
  branches?: Maybe<Scalars['Boolean']>;
  fieldGroupName?: Maybe<Scalars['String']>;
};

/** Client Settings options */
export type ClientSettings = {
  __typename?: 'ClientSettings';
  clientSettings?: Maybe<ClientSettings_Clientsettings>;
  pageSlug?: Maybe<Scalars['String']>;
  pageTitle?: Maybe<Scalars['String']>;
};

/** Field Group */
export type ClientSettings_Clientsettings = {
  __typename?: 'ClientSettings_Clientsettings';
  emailSettings?: Maybe<ClientSettings_Clientsettings_EmailSettings>;
  fieldGroupName?: Maybe<Scalars['String']>;
};

/** Field Group */
export type ClientSettings_Clientsettings_EmailSettings = {
  __typename?: 'ClientSettings_Clientsettings_EmailSettings';
  fieldGroupName?: Maybe<Scalars['String']>;
  postPublishReview?: Maybe<ClientSettings_Clientsettings_EmailSettings_PostPublishReview>;
  requestMemberBenefitsEdit?: Maybe<ClientSettings_Clientsettings_EmailSettings_RequestMemberBenefitsEdit>;
};

/** Field Group */
export type ClientSettings_Clientsettings_EmailSettings_PostPublishReview = {
  __typename?: 'ClientSettings_Clientsettings_EmailSettings_PostPublishReview';
  body?: Maybe<Scalars['String']>;
  fieldGroupName?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
};

/** Field Group */
export type ClientSettings_Clientsettings_EmailSettings_RequestMemberBenefitsEdit = {
  __typename?: 'ClientSettings_Clientsettings_EmailSettings_RequestMemberBenefitsEdit';
  body?: Maybe<Scalars['String']>;
  fieldGroupName?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
};

/** A Comment object */
export type Comment = DatabaseIdentifier & Node & {
  __typename?: 'Comment';
  /** User agent used to post the comment. This field is equivalent to WP_Comment-&gt;comment_agent and the value matching the &quot;comment_agent&quot; column in SQL. */
  agent?: Maybe<Scalars['String']>;
  /** The approval status of the comment. This field is equivalent to WP_Comment-&gt;comment_approved and the value matching the &quot;comment_approved&quot; column in SQL. */
  approved?: Maybe<Scalars['Boolean']>;
  /** The author of the comment */
  author?: Maybe<CommentToCommenterConnectionEdge>;
  /** IP address for the author. This field is equivalent to WP_Comment-&gt;comment_author_IP and the value matching the &quot;comment_author_IP&quot; column in SQL. */
  authorIp?: Maybe<Scalars['String']>;
  /**
   * ID for the comment, unique among comments.
   * @deprecated Deprecated in favor of databaseId
   */
  commentId?: Maybe<Scalars['Int']>;
  /** Connection between the Comment type and the ContentNode type */
  commentedOn?: Maybe<CommentToContentNodeConnectionEdge>;
  /** Content of the comment. This field is equivalent to WP_Comment-&gt;comment_content and the value matching the &quot;comment_content&quot; column in SQL. */
  content?: Maybe<Scalars['String']>;
  /** The unique identifier stored in the database */
  databaseId: Scalars['Int'];
  /** Date the comment was posted in local time. This field is equivalent to WP_Comment-&gt;date and the value matching the &quot;date&quot; column in SQL. */
  date?: Maybe<Scalars['String']>;
  /** Date the comment was posted in GMT. This field is equivalent to WP_Comment-&gt;date_gmt and the value matching the &quot;date_gmt&quot; column in SQL. */
  dateGmt?: Maybe<Scalars['String']>;
  /** The globally unique identifier for the comment object */
  id: Scalars['ID'];
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']>;
  /** Karma value for the comment. This field is equivalent to WP_Comment-&gt;comment_karma and the value matching the &quot;comment_karma&quot; column in SQL. */
  karma?: Maybe<Scalars['Int']>;
  /** Connection between the Comment type and the Comment type */
  parent?: Maybe<CommentToParentCommentConnectionEdge>;
  /** The database id of the parent comment node or null if it is the root comment */
  parentDatabaseId?: Maybe<Scalars['Int']>;
  /** The globally unique identifier of the parent comment node. */
  parentId?: Maybe<Scalars['ID']>;
  /** Connection between the Comment type and the Comment type */
  replies?: Maybe<CommentToCommentConnection>;
  /** Type of comment. This field is equivalent to WP_Comment-&gt;comment_type and the value matching the &quot;comment_type&quot; column in SQL. */
  type?: Maybe<Scalars['String']>;
};


/** A Comment object */
export type CommentContentArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};


/** A Comment object */
export type CommentParentArgs = {
  where?: InputMaybe<CommentToParentCommentConnectionWhereArgs>;
};


/** A Comment object */
export type CommentRepliesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CommentToCommentConnectionWhereArgs>;
};

/** A Comment Author object */
export type CommentAuthor = Commenter & Node & {
  __typename?: 'CommentAuthor';
  /** Avatar object for user. The avatar object can be retrieved in different sizes by specifying the size argument. */
  avatar?: Maybe<Avatar>;
  /** Identifies the primary key from the database. */
  databaseId: Scalars['Int'];
  /** The email for the comment author */
  email?: Maybe<Scalars['String']>;
  /** The globally unique identifier for the comment author object */
  id: Scalars['ID'];
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']>;
  /** The name for the comment author. */
  name?: Maybe<Scalars['String']>;
  /** The url the comment author. */
  url?: Maybe<Scalars['String']>;
};


/** A Comment Author object */
export type CommentAuthorAvatarArgs = {
  forceDefault?: InputMaybe<Scalars['Boolean']>;
  rating?: InputMaybe<AvatarRatingEnum>;
  size?: InputMaybe<Scalars['Int']>;
};

/** The Type of Identifier used to fetch a single comment node. Default is "ID". To be used along with the "id" field. */
export enum CommentNodeIdTypeEnum {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID'
}

/** Connection between the Comment type and the Comment type */
export type CommentToCommentConnection = {
  __typename?: 'CommentToCommentConnection';
  /** Edges for the CommentToCommentConnection connection */
  edges?: Maybe<Array<Maybe<CommentToCommentConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Comment>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type CommentToCommentConnectionEdge = {
  __typename?: 'CommentToCommentConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<Comment>;
};

/** Arguments for filtering the CommentToCommentConnection connection */
export type CommentToCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail?: InputMaybe<Scalars['String']>;
  /** Array of author IDs to include comments for. */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Comment author URL. */
  authorUrl?: InputMaybe<Scalars['String']>;
  /** Array of comment IDs to include. */
  commentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Include comments of a given type. */
  commentType?: InputMaybe<Scalars['String']>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: InputMaybe<Scalars['String']>;
  /** Content object author ID to limit results by. */
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: InputMaybe<Scalars['ID']>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Content object name to retrieve affiliated comments for. */
  contentName?: InputMaybe<Scalars['String']>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: InputMaybe<Scalars['Int']>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: InputMaybe<Scalars['Int']>;
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: InputMaybe<Scalars['String']>;
  /** Comment status to limit results by. */
  status?: InputMaybe<Scalars['String']>;
  /** Include comments for a specific user ID. */
  userId?: InputMaybe<Scalars['ID']>;
};

/** Connection between the Comment type and the Commenter type */
export type CommentToCommenterConnectionEdge = {
  __typename?: 'CommentToCommenterConnectionEdge';
  /** The node of the connection, without the edges */
  node?: Maybe<Commenter>;
};

/** Connection between the Comment type and the ContentNode type */
export type CommentToContentNodeConnectionEdge = {
  __typename?: 'CommentToContentNodeConnectionEdge';
  /** The node of the connection, without the edges */
  node?: Maybe<ContentNode>;
};

/** Connection between the Comment type and the Comment type */
export type CommentToParentCommentConnectionEdge = {
  __typename?: 'CommentToParentCommentConnectionEdge';
  /** The node of the connection, without the edges */
  node?: Maybe<Comment>;
};

/** Arguments for filtering the CommentToParentCommentConnection connection */
export type CommentToParentCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail?: InputMaybe<Scalars['String']>;
  /** Array of author IDs to include comments for. */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Comment author URL. */
  authorUrl?: InputMaybe<Scalars['String']>;
  /** Array of comment IDs to include. */
  commentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Include comments of a given type. */
  commentType?: InputMaybe<Scalars['String']>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: InputMaybe<Scalars['String']>;
  /** Content object author ID to limit results by. */
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: InputMaybe<Scalars['ID']>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Content object name to retrieve affiliated comments for. */
  contentName?: InputMaybe<Scalars['String']>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: InputMaybe<Scalars['Int']>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: InputMaybe<Scalars['Int']>;
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: InputMaybe<Scalars['String']>;
  /** Comment status to limit results by. */
  status?: InputMaybe<Scalars['String']>;
  /** Include comments for a specific user ID. */
  userId?: InputMaybe<Scalars['ID']>;
};

/** The author of a comment */
export type Commenter = {
  /** Avatar object for user. The avatar object can be retrieved in different sizes by specifying the size argument. */
  avatar?: Maybe<Avatar>;
  /** Identifies the primary key from the database. */
  databaseId: Scalars['Int'];
  /** The email address of the author of a comment. */
  email?: Maybe<Scalars['String']>;
  /** The globally unique identifier for the comment author. */
  id: Scalars['ID'];
  /** Whether the author information is considered restricted. (not fully public) */
  isRestricted?: Maybe<Scalars['Boolean']>;
  /** The name of the author of a comment. */
  name?: Maybe<Scalars['String']>;
  /** The url of the author of a comment. */
  url?: Maybe<Scalars['String']>;
};

/** Options for ordering the connection */
export enum CommentsConnectionOrderbyEnum {
  /** Order by browser user agent of the commenter. */
  CommentAgent = 'COMMENT_AGENT',
  /** Order by true/false approval of the comment. */
  CommentApproved = 'COMMENT_APPROVED',
  /** Order by name of the comment author. */
  CommentAuthor = 'COMMENT_AUTHOR',
  /** Order by e-mail of the comment author. */
  CommentAuthorEmail = 'COMMENT_AUTHOR_EMAIL',
  /** Order by IP address of the comment author. */
  CommentAuthorIp = 'COMMENT_AUTHOR_IP',
  /** Order by URL address of the comment author. */
  CommentAuthorUrl = 'COMMENT_AUTHOR_URL',
  /** Order by the comment contents. */
  CommentContent = 'COMMENT_CONTENT',
  /** Order by date/time timestamp of the comment. */
  CommentDate = 'COMMENT_DATE',
  /** Order by GMT timezone date/time timestamp of the comment. */
  CommentDateGmt = 'COMMENT_DATE_GMT',
  /** Order by the globally unique identifier for the comment object */
  CommentId = 'COMMENT_ID',
  /** Order by the array list of comment IDs listed in the where clause. */
  CommentIn = 'COMMENT_IN',
  /** Order by the comment karma score. */
  CommentKarma = 'COMMENT_KARMA',
  /** Order by the comment parent ID. */
  CommentParent = 'COMMENT_PARENT',
  /** Order by the post object ID. */
  CommentPostId = 'COMMENT_POST_ID',
  /** Order by the the type of comment, such as 'comment', 'pingback', or 'trackback'. */
  CommentType = 'COMMENT_TYPE',
  /** Order by the user ID. */
  UserId = 'USER_ID'
}

/** Gravity Forms conditional logic. */
export type ConditionalLogic = {
  __typename?: 'ConditionalLogic';
  /** The type of action the conditional logic will perform. */
  actionType?: Maybe<ConditionalLogicActionTypeEnum>;
  /** Determines how to the rules should be evaluated. */
  logicType?: Maybe<ConditionalLogicLogicTypeEnum>;
  /** Conditional logic rules. */
  rules?: Maybe<Array<Maybe<ConditionalLogicRule>>>;
};

/** The type of action the conditional logic will perform. */
export enum ConditionalLogicActionTypeEnum {
  /** Text button (default). */
  Hide = 'HIDE',
  /** Image button. */
  Show = 'SHOW'
}

/** Determines how to the rules should be evaluated. */
export enum ConditionalLogicLogicTypeEnum {
  /** Evaulate all logic rules. */
  All = 'ALL',
  /** Evaluate any logic rule. */
  Any = 'ANY'
}

/** Gravity Forms conditional logic rule. */
export type ConditionalLogicRule = {
  __typename?: 'ConditionalLogicRule';
  /** Target field Id. Field that will have it’s value compared with the value property to determine if this rule is a match. */
  fieldId?: Maybe<Scalars['Float']>;
  /** Operator to be used when evaluating this rule. */
  operator?: Maybe<FormRuleOperatorEnum>;
  /** The value to compare with field specified by fieldId. */
  value?: Maybe<Scalars['String']>;
};

/** A Gravity Forms   field. */
export type ConsentField = FormField & {
  __typename?: 'ConsentField';
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** Text of the consent checkbox. */
  checkboxLabel?: Maybe<Scalars['String']>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** Consent field value. This is `true` when consent is given, `false` when it is not. */
  consentValue?: Maybe<Scalars['Boolean']>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** Nodes used to manage content */
export type ContentNode = {
  /** Connection between the ContentNode type and the ContentType type */
  contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /** The name of the Content Type the node belongs to */
  contentTypeName: Scalars['String'];
  /** The ID of the node in the database. */
  databaseId: Scalars['Int'];
  /** Post publishing date. */
  date?: Maybe<Scalars['String']>;
  /** The publishing date set in GMT. */
  dateGmt?: Maybe<Scalars['String']>;
  /** The desired slug of the post */
  desiredSlug?: Maybe<Scalars['String']>;
  /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
  editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
  /** The RSS enclosure for the object */
  enclosure?: Maybe<Scalars['String']>;
  /** Connection between the ContentNode type and the EnqueuedScript type */
  enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
  /** Connection between the ContentNode type and the EnqueuedStylesheet type */
  enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
  guid?: Maybe<Scalars['String']>;
  /** The unique resource identifier path */
  id: Scalars['ID'];
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean'];
  /** Whether the object is a node in the preview state */
  isPreview?: Maybe<Scalars['Boolean']>;
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']>;
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean'];
  /** The user that most recently edited the node */
  lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
  /** The permalink of the post */
  link?: Maybe<Scalars['String']>;
  /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
  modified?: Maybe<Scalars['String']>;
  /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
  modifiedGmt?: Maybe<Scalars['String']>;
  /** The database id of the preview node */
  previewRevisionDatabaseId?: Maybe<Scalars['Int']>;
  /** Whether the object is a node in the preview state */
  previewRevisionId?: Maybe<Scalars['ID']>;
  /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
  slug?: Maybe<Scalars['String']>;
  /** The current status of the object */
  status?: Maybe<Scalars['String']>;
  /** The template assigned to a node of content */
  template?: Maybe<ContentTemplate>;
  /** The unique resource identifier path */
  uri?: Maybe<Scalars['String']>;
};


/** Nodes used to manage content */
export type ContentNodeEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** Nodes used to manage content */
export type ContentNodeEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum ContentNodeIdTypeEnum {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the URI. */
  Uri = 'URI'
}

/** Connection between the ContentNode type and the ContentType type */
export type ContentNodeToContentTypeConnectionEdge = {
  __typename?: 'ContentNodeToContentTypeConnectionEdge';
  /** The node of the connection, without the edges */
  node?: Maybe<ContentType>;
};

/** Connection between the ContentNode type and the User type */
export type ContentNodeToEditLastConnectionEdge = {
  __typename?: 'ContentNodeToEditLastConnectionEdge';
  /** The node of the connection, without the edges */
  node?: Maybe<User>;
};

/** Connection between the ContentNode type and the User type */
export type ContentNodeToEditLockConnectionEdge = {
  __typename?: 'ContentNodeToEditLockConnectionEdge';
  /** The timestamp for when the node was last edited */
  lockTimestamp?: Maybe<Scalars['String']>;
  /** The node of the connection, without the edges */
  node?: Maybe<User>;
};

/** Connection between the ContentNode type and the EnqueuedScript type */
export type ContentNodeToEnqueuedScriptConnection = {
  __typename?: 'ContentNodeToEnqueuedScriptConnection';
  /** Edges for the ContentNodeToEnqueuedScriptConnection connection */
  edges?: Maybe<Array<Maybe<ContentNodeToEnqueuedScriptConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<EnqueuedScript>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type ContentNodeToEnqueuedScriptConnectionEdge = {
  __typename?: 'ContentNodeToEnqueuedScriptConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<EnqueuedScript>;
};

/** Connection between the ContentNode type and the EnqueuedStylesheet type */
export type ContentNodeToEnqueuedStylesheetConnection = {
  __typename?: 'ContentNodeToEnqueuedStylesheetConnection';
  /** Edges for the ContentNodeToEnqueuedStylesheetConnection connection */
  edges?: Maybe<Array<Maybe<ContentNodeToEnqueuedStylesheetConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<EnqueuedStylesheet>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type ContentNodeToEnqueuedStylesheetConnectionEdge = {
  __typename?: 'ContentNodeToEnqueuedStylesheetConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<EnqueuedStylesheet>;
};

/** A union of Content Node Types that support revisions */
export type ContentRevisionUnion = Page | Post | ReusableBlock;

/** The template assigned to a node of content */
export type ContentTemplate = {
  /** The name of the template */
  templateName?: Maybe<Scalars['String']>;
};

/** An Post Type object */
export type ContentType = Node & UniformResourceIdentifiable & {
  __typename?: 'ContentType';
  /** The url path of the first page of the archive page for this content type. */
  archivePath?: Maybe<Scalars['String']>;
  /** Whether this content type should can be exported. */
  canExport?: Maybe<Scalars['Boolean']>;
  /** Connection between the ContentType type and the Taxonomy type */
  connectedTaxonomies?: Maybe<ContentTypeToTaxonomyConnection>;
  /** Connection between the ContentType type and the ContentNode type */
  contentNodes?: Maybe<ContentTypeToContentNodeConnection>;
  /** Whether content of this type should be deleted when the author of it is deleted from the system. */
  deleteWithUser?: Maybe<Scalars['Boolean']>;
  /** Description of the content type. */
  description?: Maybe<Scalars['String']>;
  /** Whether to exclude nodes of this content type from front end search results. */
  excludeFromSearch?: Maybe<Scalars['Boolean']>;
  /** The plural name of the content type within the GraphQL Schema. */
  graphqlPluralName?: Maybe<Scalars['String']>;
  /** The singular name of the content type within the GraphQL Schema. */
  graphqlSingleName?: Maybe<Scalars['String']>;
  /** Whether this content type should have archives. Content archives are generated by type and by date. */
  hasArchive?: Maybe<Scalars['Boolean']>;
  /** Whether the content type is hierarchical, for example pages. */
  hierarchical?: Maybe<Scalars['Boolean']>;
  /** The globally unique identifier of the post-type object. */
  id: Scalars['ID'];
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean'];
  /** Whether this page is set to the static front page. */
  isFrontPage: Scalars['Boolean'];
  /** Whether this page is set to the blog posts page. */
  isPostsPage: Scalars['Boolean'];
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']>;
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean'];
  /** Display name of the content type. */
  label?: Maybe<Scalars['String']>;
  /** Details about the content type labels. */
  labels?: Maybe<PostTypeLabelDetails>;
  /** The name of the icon file to display as a menu icon. */
  menuIcon?: Maybe<Scalars['String']>;
  /** The position of this post type in the menu. Only applies if show_in_menu is true. */
  menuPosition?: Maybe<Scalars['Int']>;
  /** The internal name of the post type. This should not be used for display purposes. */
  name?: Maybe<Scalars['String']>;
  /** Whether a content type is intended for use publicly either via the admin interface or by front-end users. While the default settings of exclude_from_search, publicly_queryable, show_ui, and show_in_nav_menus are inherited from public, each does not rely on this relationship and controls a very specific intention. */
  public?: Maybe<Scalars['Boolean']>;
  /** Whether queries can be performed on the front end for the content type as part of parse_request(). */
  publiclyQueryable?: Maybe<Scalars['Boolean']>;
  /** Name of content type to display in REST API &quot;wp/v2&quot; namespace. */
  restBase?: Maybe<Scalars['String']>;
  /** The REST Controller class assigned to handling this content type. */
  restControllerClass?: Maybe<Scalars['String']>;
  /** Makes this content type available via the admin bar. */
  showInAdminBar?: Maybe<Scalars['Boolean']>;
  /** Whether to add the content type to the GraphQL Schema. */
  showInGraphql?: Maybe<Scalars['Boolean']>;
  /** Where to show the content type in the admin menu. To work, $show_ui must be true. If true, the post type is shown in its own top level menu. If false, no menu is shown. If a string of an existing top level menu (eg. &quot;tools.php&quot; or &quot;edit.php?post_type=page&quot;), the post type will be placed as a sub-menu of that. */
  showInMenu?: Maybe<Scalars['Boolean']>;
  /** Makes this content type available for selection in navigation menus. */
  showInNavMenus?: Maybe<Scalars['Boolean']>;
  /** Whether the content type is associated with a route under the the REST API &quot;wp/v2&quot; namespace. */
  showInRest?: Maybe<Scalars['Boolean']>;
  /** Whether to generate and allow a UI for managing this content type in the admin. */
  showUi?: Maybe<Scalars['Boolean']>;
  /** The unique resource identifier path */
  uri?: Maybe<Scalars['String']>;
};


/** An Post Type object */
export type ContentTypeConnectedTaxonomiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** An Post Type object */
export type ContentTypeContentNodesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ContentTypeToContentNodeConnectionWhereArgs>;
};

/** Allowed Content Types */
export enum ContentTypeEnum {
  /** The Type of Content object */
  AccountManager = 'ACCOUNT_MANAGER',
  /** The Type of Content object */
  ActionMonitor = 'ACTION_MONITOR',
  /** The Type of Content object */
  Attachment = 'ATTACHMENT',
  /** The Type of Content object */
  Group = 'GROUP',
  /** The Type of Content object */
  Member = 'MEMBER',
  /** The Type of Content object */
  Page = 'PAGE',
  /** The Type of Content object */
  Post = 'POST',
  /** The Type of Content object */
  Review = 'REVIEW',
  /** The Type of Content object */
  Supplier = 'SUPPLIER',
  /** The Type of Content object */
  SupplierNews = 'SUPPLIER_NEWS',
  /** The Type of Content object */
  Testimonial = 'TESTIMONIAL',
  /** The Type of Content object */
  WggPreview = 'WGG_PREVIEW',
  /** The Type of Content object */
  WpBlock = 'WP_BLOCK'
}

/** The Type of Identifier used to fetch a single Content Type node. To be used along with the "id" field. Default is "ID". */
export enum ContentTypeIdTypeEnum {
  /** The globally unique ID */
  Id = 'ID',
  /** The name of the content type. */
  Name = 'NAME'
}

/** Connection between the ContentType type and the ContentNode type */
export type ContentTypeToContentNodeConnection = {
  __typename?: 'ContentTypeToContentNodeConnection';
  /** Edges for the ContentTypeToContentNodeConnection connection */
  edges?: Maybe<Array<Maybe<ContentTypeToContentNodeConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentNode>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type ContentTypeToContentNodeConnectionEdge = {
  __typename?: 'ContentTypeToContentNodeConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<ContentNode>;
};

/** Arguments for filtering the ContentTypeToContentNodeConnection connection */
export type ContentTypeToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** Connection between the ContentType type and the Taxonomy type */
export type ContentTypeToTaxonomyConnection = {
  __typename?: 'ContentTypeToTaxonomyConnection';
  /** Edges for the ContentTypeToTaxonomyConnection connection */
  edges?: Maybe<Array<Maybe<ContentTypeToTaxonomyConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Taxonomy>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type ContentTypeToTaxonomyConnectionEdge = {
  __typename?: 'ContentTypeToTaxonomyConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<Taxonomy>;
};

/** Allowed Content Types of the AreaOfSpecialism taxonomy. */
export enum ContentTypesOfAreaOfSpecialismEnum {
  /** The Type of Content object */
  Member = 'MEMBER'
}

/** Allowed Content Types of the Category taxonomy. */
export enum ContentTypesOfCategoryEnum {
  /** The Type of Content object */
  Post = 'POST'
}

/** Allowed Content Types of the PostFormat taxonomy. */
export enum ContentTypesOfPostFormatEnum {
  /** The Type of Content object */
  Post = 'POST'
}

/** Allowed Content Types of the Region taxonomy. */
export enum ContentTypesOfRegionEnum {
  /** The Type of Content object */
  AccountManager = 'ACCOUNT_MANAGER'
}

/** Allowed Content Types of the SupplierCategory taxonomy. */
export enum ContentTypesOfSupplierCategoryEnum {
  /** The Type of Content object */
  Supplier = 'SUPPLIER'
}

/** Allowed Content Types of the SupplierKeyword taxonomy. */
export enum ContentTypesOfSupplierKeywordEnum {
  /** The Type of Content object */
  Supplier = 'SUPPLIER'
}

/** Allowed Content Types of the Tag taxonomy. */
export enum ContentTypesOfTagEnum {
  /** The Type of Content object */
  Post = 'POST'
}

/** Allowed Content Types of the TradingTime taxonomy. */
export enum ContentTypesOfTradingTimeEnum {
  /** The Type of Content object */
  Member = 'MEMBER'
}

/** core/archives block */
export type CoreArchivesBlock = Block & {
  __typename?: 'CoreArchivesBlock';
  attributes?: Maybe<CoreArchivesBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreArchivesBlockAttributes = {
  __typename?: 'CoreArchivesBlockAttributes';
  align?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  displayAsDropdown: Scalars['Boolean'];
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  showPostCounts: Scalars['Boolean'];
  type: Scalars['String'];
};

/** core/audio block */
export type CoreAudioBlock = Block & {
  __typename?: 'CoreAudioBlock';
  attributes?: Maybe<CoreAudioBlockAttributesUnion>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreAudioBlockAttributes = {
  __typename?: 'CoreAudioBlockAttributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  autoplay?: Maybe<Scalars['Boolean']>;
  caption?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  loop?: Maybe<Scalars['Boolean']>;
  preload?: Maybe<Scalars['String']>;
  src?: Maybe<Scalars['String']>;
};

export type CoreAudioBlockAttributesUnion = CoreAudioBlockAttributes | CoreAudioBlockDeprecatedV1Attributes;

export type CoreAudioBlockDeprecatedV1Attributes = {
  __typename?: 'CoreAudioBlockDeprecatedV1Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  autoplay?: Maybe<Scalars['Boolean']>;
  caption?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  loop?: Maybe<Scalars['Boolean']>;
  preload?: Maybe<Scalars['String']>;
  src?: Maybe<Scalars['String']>;
};

/** core/avatar block */
export type CoreAvatarBlock = Block & {
  __typename?: 'CoreAvatarBlock';
  attributes?: Maybe<CoreAvatarBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreAvatarBlockAttributes = {
  __typename?: 'CoreAvatarBlockAttributes';
  align?: Maybe<Scalars['String']>;
  borderColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  isLink: Scalars['Boolean'];
  linkTarget: Scalars['String'];
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  size: Scalars['Float'];
  style?: Maybe<Scalars['BlockAttributesObject']>;
  userId?: Maybe<Scalars['Float']>;
};

/** core/block block */
export type CoreBlock = Block & {
  __typename?: 'CoreBlock';
  attributes?: Maybe<CoreBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  reusableBlock: Node;
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreBlockAttributes = {
  __typename?: 'CoreBlockAttributes';
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  ref?: Maybe<Scalars['Float']>;
};

/** core/button block */
export type CoreButtonBlock = Block & {
  __typename?: 'CoreButtonBlock';
  attributes?: Maybe<CoreButtonBlockAttributesUnion>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreButtonBlockAttributes = {
  __typename?: 'CoreButtonBlockAttributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontFamily?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  linkTarget?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  placeholder?: Maybe<Scalars['String']>;
  rel?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  text?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
};

export type CoreButtonBlockAttributesUnion = CoreButtonBlockAttributes | CoreButtonBlockDeprecatedV1Attributes | CoreButtonBlockDeprecatedV2Attributes | CoreButtonBlockDeprecatedV3Attributes | CoreButtonBlockDeprecatedV4Attributes | CoreButtonBlockDeprecatedV5Attributes | CoreButtonBlockDeprecatedV6Attributes | CoreButtonBlockDeprecatedV7Attributes | CoreButtonBlockDeprecatedV8Attributes | CoreButtonBlockDeprecatedV9Attributes | CoreButtonBlockDeprecatedV10Attributes;

export type CoreButtonBlockDeprecatedV1Attributes = {
  __typename?: 'CoreButtonBlockDeprecatedV1Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontFamily?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  linkTarget?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  placeholder?: Maybe<Scalars['String']>;
  rel?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  text?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
};

export type CoreButtonBlockDeprecatedV2Attributes = {
  __typename?: 'CoreButtonBlockDeprecatedV2Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontFamily?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  linkTarget?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  placeholder?: Maybe<Scalars['String']>;
  rel?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  text?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
};

export type CoreButtonBlockDeprecatedV3Attributes = {
  __typename?: 'CoreButtonBlockDeprecatedV3Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontFamily?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  linkTarget?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  placeholder?: Maybe<Scalars['String']>;
  rel?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  text?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
};

export type CoreButtonBlockDeprecatedV4Attributes = {
  __typename?: 'CoreButtonBlockDeprecatedV4Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontFamily?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  linkTarget?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  placeholder?: Maybe<Scalars['String']>;
  rel?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  text?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
};

export type CoreButtonBlockDeprecatedV5Attributes = {
  __typename?: 'CoreButtonBlockDeprecatedV5Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontFamily?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  linkTarget?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  placeholder?: Maybe<Scalars['String']>;
  rel?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  text?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
};

export type CoreButtonBlockDeprecatedV6Attributes = {
  __typename?: 'CoreButtonBlockDeprecatedV6Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontFamily?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  linkTarget?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  placeholder?: Maybe<Scalars['String']>;
  rel?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  text?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
};

export type CoreButtonBlockDeprecatedV7Attributes = {
  __typename?: 'CoreButtonBlockDeprecatedV7Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontFamily?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  linkTarget?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  placeholder?: Maybe<Scalars['String']>;
  rel?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  text?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
};

export type CoreButtonBlockDeprecatedV8Attributes = {
  __typename?: 'CoreButtonBlockDeprecatedV8Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontFamily?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  linkTarget?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  placeholder?: Maybe<Scalars['String']>;
  rel?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  text?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
};

export type CoreButtonBlockDeprecatedV9Attributes = {
  __typename?: 'CoreButtonBlockDeprecatedV9Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontFamily?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  linkTarget?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  placeholder?: Maybe<Scalars['String']>;
  rel?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  text?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
};

export type CoreButtonBlockDeprecatedV10Attributes = {
  __typename?: 'CoreButtonBlockDeprecatedV10Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontFamily?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  linkTarget?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  placeholder?: Maybe<Scalars['String']>;
  rel?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  text?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
};

/** core/buttons block */
export type CoreButtonsBlock = Block & {
  __typename?: 'CoreButtonsBlock';
  attributes?: Maybe<CoreButtonsBlockAttributesUnion>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreButtonsBlockAttributes = {
  __typename?: 'CoreButtonsBlockAttributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  layout?: Maybe<Scalars['BlockAttributesObject']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
};

export type CoreButtonsBlockAttributesUnion = CoreButtonsBlockAttributes | CoreButtonsBlockDeprecatedV1Attributes | CoreButtonsBlockDeprecatedV2Attributes;

export type CoreButtonsBlockDeprecatedV1Attributes = {
  __typename?: 'CoreButtonsBlockDeprecatedV1Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  layout?: Maybe<Scalars['BlockAttributesObject']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
};

export type CoreButtonsBlockDeprecatedV2Attributes = {
  __typename?: 'CoreButtonsBlockDeprecatedV2Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  layout?: Maybe<Scalars['BlockAttributesObject']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
};

/** core/calendar block */
export type CoreCalendarBlock = Block & {
  __typename?: 'CoreCalendarBlock';
  attributes?: Maybe<CoreCalendarBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreCalendarBlockAttributes = {
  __typename?: 'CoreCalendarBlockAttributes';
  align?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

/** core/categories block */
export type CoreCategoriesBlock = Block & {
  __typename?: 'CoreCategoriesBlock';
  attributes?: Maybe<CoreCategoriesBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreCategoriesBlockAttributes = {
  __typename?: 'CoreCategoriesBlockAttributes';
  align?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  displayAsDropdown: Scalars['Boolean'];
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  showHierarchy: Scalars['Boolean'];
  showOnlyTopLevel: Scalars['Boolean'];
  showPostCounts: Scalars['Boolean'];
};

/** core/code block */
export type CoreCodeBlock = Block & {
  __typename?: 'CoreCodeBlock';
  attributes?: Maybe<CoreCodeBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreCodeBlockAttributes = {
  __typename?: 'CoreCodeBlockAttributes';
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  borderColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
};

/** core/column block */
export type CoreColumnBlock = Block & {
  __typename?: 'CoreColumnBlock';
  attributes?: Maybe<CoreColumnBlockAttributesUnion>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreColumnBlockAttributes = {
  __typename?: 'CoreColumnBlockAttributes';
  allowedBlocks?: Maybe<Scalars['BlockAttributesArray']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  layout?: Maybe<Scalars['BlockAttributesObject']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
  verticalAlignment?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['String']>;
};

export type CoreColumnBlockAttributesUnion = CoreColumnBlockAttributes | CoreColumnBlockDeprecatedV1Attributes;

export type CoreColumnBlockDeprecatedV1Attributes = {
  __typename?: 'CoreColumnBlockDeprecatedV1Attributes';
  allowedBlocks?: Maybe<Scalars['BlockAttributesArray']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  layout?: Maybe<Scalars['BlockAttributesObject']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
  verticalAlignment?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['String']>;
};

/** core/columns block */
export type CoreColumnsBlock = Block & {
  __typename?: 'CoreColumnsBlock';
  attributes?: Maybe<CoreColumnsBlockAttributesUnion>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreColumnsBlockAttributes = {
  __typename?: 'CoreColumnsBlockAttributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  borderColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  isStackedOnMobile: Scalars['Boolean'];
  layout?: Maybe<Scalars['BlockAttributesObject']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
  verticalAlignment?: Maybe<Scalars['String']>;
};

export type CoreColumnsBlockAttributesUnion = CoreColumnsBlockAttributes | CoreColumnsBlockDeprecatedV1Attributes | CoreColumnsBlockDeprecatedV2Attributes | CoreColumnsBlockDeprecatedV3Attributes;

export type CoreColumnsBlockDeprecatedV1Attributes = {
  __typename?: 'CoreColumnsBlockDeprecatedV1Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  borderColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  isStackedOnMobile: Scalars['Boolean'];
  layout?: Maybe<Scalars['BlockAttributesObject']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
  verticalAlignment?: Maybe<Scalars['String']>;
};

export type CoreColumnsBlockDeprecatedV2Attributes = {
  __typename?: 'CoreColumnsBlockDeprecatedV2Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  borderColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  isStackedOnMobile: Scalars['Boolean'];
  layout?: Maybe<Scalars['BlockAttributesObject']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
  verticalAlignment?: Maybe<Scalars['String']>;
};

export type CoreColumnsBlockDeprecatedV3Attributes = {
  __typename?: 'CoreColumnsBlockDeprecatedV3Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  borderColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  isStackedOnMobile: Scalars['Boolean'];
  layout?: Maybe<Scalars['BlockAttributesObject']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
  verticalAlignment?: Maybe<Scalars['String']>;
};

/** core/comment-author-name block */
export type CoreCommentAuthorNameBlock = Block & {
  __typename?: 'CoreCommentAuthorNameBlock';
  attributes?: Maybe<CoreCommentAuthorNameBlockAttributesUnion>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreCommentAuthorNameBlockAttributes = {
  __typename?: 'CoreCommentAuthorNameBlockAttributes';
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontFamily?: Maybe<Scalars['String']>;
  fontSize: Scalars['String'];
  gradient?: Maybe<Scalars['String']>;
  isLink: Scalars['Boolean'];
  linkTarget: Scalars['String'];
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textAlign?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
};

export type CoreCommentAuthorNameBlockAttributesUnion = CoreCommentAuthorNameBlockAttributes | CoreCommentAuthorNameBlockDeprecatedV1Attributes;

export type CoreCommentAuthorNameBlockDeprecatedV1Attributes = {
  __typename?: 'CoreCommentAuthorNameBlockDeprecatedV1Attributes';
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontFamily?: Maybe<Scalars['String']>;
  fontSize: Scalars['String'];
  gradient?: Maybe<Scalars['String']>;
  isLink: Scalars['Boolean'];
  linkTarget: Scalars['String'];
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textAlign?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
};

/** core/comment-content block */
export type CoreCommentContentBlock = Block & {
  __typename?: 'CoreCommentContentBlock';
  attributes?: Maybe<CoreCommentContentBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreCommentContentBlockAttributes = {
  __typename?: 'CoreCommentContentBlockAttributes';
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontFamily?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textAlign?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
};

/** core/comment-date block */
export type CoreCommentDateBlock = Block & {
  __typename?: 'CoreCommentDateBlock';
  attributes?: Maybe<CoreCommentDateBlockAttributesUnion>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreCommentDateBlockAttributes = {
  __typename?: 'CoreCommentDateBlockAttributes';
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontFamily?: Maybe<Scalars['String']>;
  fontSize: Scalars['String'];
  format?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  isLink: Scalars['Boolean'];
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
};

export type CoreCommentDateBlockAttributesUnion = CoreCommentDateBlockAttributes | CoreCommentDateBlockDeprecatedV1Attributes;

export type CoreCommentDateBlockDeprecatedV1Attributes = {
  __typename?: 'CoreCommentDateBlockDeprecatedV1Attributes';
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontFamily?: Maybe<Scalars['String']>;
  fontSize: Scalars['String'];
  format?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  isLink: Scalars['Boolean'];
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
};

/** core/comment-edit-link block */
export type CoreCommentEditLinkBlock = Block & {
  __typename?: 'CoreCommentEditLinkBlock';
  attributes?: Maybe<CoreCommentEditLinkBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreCommentEditLinkBlockAttributes = {
  __typename?: 'CoreCommentEditLinkBlockAttributes';
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontFamily?: Maybe<Scalars['String']>;
  fontSize: Scalars['String'];
  gradient?: Maybe<Scalars['String']>;
  linkTarget: Scalars['String'];
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textAlign?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
};

/** core/comment-reply-link block */
export type CoreCommentReplyLinkBlock = Block & {
  __typename?: 'CoreCommentReplyLinkBlock';
  attributes?: Maybe<CoreCommentReplyLinkBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreCommentReplyLinkBlockAttributes = {
  __typename?: 'CoreCommentReplyLinkBlockAttributes';
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontFamily?: Maybe<Scalars['String']>;
  fontSize: Scalars['String'];
  gradient?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textAlign?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
};

/** core/comment-template block */
export type CoreCommentTemplateBlock = Block & {
  __typename?: 'CoreCommentTemplateBlock';
  attributes?: Maybe<CoreCommentTemplateBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreCommentTemplateBlockAttributes = {
  __typename?: 'CoreCommentTemplateBlockAttributes';
  align?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
};

/** core/comments-pagination block */
export type CoreCommentsPaginationBlock = Block & {
  __typename?: 'CoreCommentsPaginationBlock';
  attributes?: Maybe<CoreCommentsPaginationBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreCommentsPaginationBlockAttributes = {
  __typename?: 'CoreCommentsPaginationBlockAttributes';
  align?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  layout?: Maybe<Scalars['BlockAttributesObject']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  paginationArrow: Scalars['String'];
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
};

/** core/comments-pagination-next block */
export type CoreCommentsPaginationNextBlock = Block & {
  __typename?: 'CoreCommentsPaginationNextBlock';
  attributes?: Maybe<CoreCommentsPaginationNextBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreCommentsPaginationNextBlockAttributes = {
  __typename?: 'CoreCommentsPaginationNextBlockAttributes';
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
};

/** core/comments-pagination-numbers block */
export type CoreCommentsPaginationNumbersBlock = Block & {
  __typename?: 'CoreCommentsPaginationNumbersBlock';
  attributes?: Maybe<CoreCommentsPaginationNumbersBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreCommentsPaginationNumbersBlockAttributes = {
  __typename?: 'CoreCommentsPaginationNumbersBlockAttributes';
  className?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
};

/** core/comments-pagination-previous block */
export type CoreCommentsPaginationPreviousBlock = Block & {
  __typename?: 'CoreCommentsPaginationPreviousBlock';
  attributes?: Maybe<CoreCommentsPaginationPreviousBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreCommentsPaginationPreviousBlockAttributes = {
  __typename?: 'CoreCommentsPaginationPreviousBlockAttributes';
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
};

/** core/comments-query-loop block */
export type CoreCommentsQueryLoopBlock = Block & {
  __typename?: 'CoreCommentsQueryLoopBlock';
  attributes?: Maybe<CoreCommentsQueryLoopBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreCommentsQueryLoopBlockAttributes = {
  __typename?: 'CoreCommentsQueryLoopBlockAttributes';
  align?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  tagName: Scalars['String'];
  textColor?: Maybe<Scalars['String']>;
};

/** core/comments-title block */
export type CoreCommentsTitleBlock = Block & {
  __typename?: 'CoreCommentsTitleBlock';
  attributes?: Maybe<CoreCommentsTitleBlockAttributesUnion>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreCommentsTitleBlockAttributes = {
  __typename?: 'CoreCommentsTitleBlockAttributes';
  align?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  borderColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontFamily?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  level: Scalars['Float'];
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  showCommentsCount: Scalars['Boolean'];
  showPostTitle: Scalars['Boolean'];
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textAlign?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
};

export type CoreCommentsTitleBlockAttributesUnion = CoreCommentsTitleBlockAttributes | CoreCommentsTitleBlockDeprecatedV1Attributes;

export type CoreCommentsTitleBlockDeprecatedV1Attributes = {
  __typename?: 'CoreCommentsTitleBlockDeprecatedV1Attributes';
  align?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  borderColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontFamily?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  level: Scalars['Float'];
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  showCommentsCount: Scalars['Boolean'];
  showPostTitle: Scalars['Boolean'];
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textAlign?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
};

/** core/cover block */
export type CoreCoverBlock = Block & {
  __typename?: 'CoreCoverBlock';
  attributes?: Maybe<CoreCoverBlockAttributesUnion>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreCoverBlockAttributes = {
  __typename?: 'CoreCoverBlockAttributes';
  align?: Maybe<Scalars['String']>;
  allowedBlocks?: Maybe<Scalars['BlockAttributesArray']>;
  alt: Scalars['String'];
  anchor?: Maybe<Scalars['String']>;
  backgroundType: Scalars['String'];
  className?: Maybe<Scalars['String']>;
  contentPosition?: Maybe<Scalars['String']>;
  customGradient?: Maybe<Scalars['String']>;
  customOverlayColor?: Maybe<Scalars['String']>;
  dimRatio: Scalars['Float'];
  focalPoint?: Maybe<Scalars['BlockAttributesObject']>;
  gradient?: Maybe<Scalars['String']>;
  hasParallax: Scalars['Boolean'];
  id?: Maybe<Scalars['Float']>;
  isDark: Scalars['Boolean'];
  isRepeated: Scalars['Boolean'];
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  minHeight?: Maybe<Scalars['Float']>;
  minHeightUnit?: Maybe<Scalars['String']>;
  overlayColor?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  url?: Maybe<Scalars['String']>;
  useFeaturedImage: Scalars['Boolean'];
};

export type CoreCoverBlockAttributesUnion = CoreCoverBlockAttributes | CoreCoverBlockDeprecatedV1Attributes | CoreCoverBlockDeprecatedV2Attributes | CoreCoverBlockDeprecatedV3Attributes | CoreCoverBlockDeprecatedV4Attributes | CoreCoverBlockDeprecatedV5Attributes | CoreCoverBlockDeprecatedV6Attributes | CoreCoverBlockDeprecatedV7Attributes | CoreCoverBlockDeprecatedV8Attributes | CoreCoverBlockDeprecatedV9Attributes;

export type CoreCoverBlockDeprecatedV1Attributes = {
  __typename?: 'CoreCoverBlockDeprecatedV1Attributes';
  align?: Maybe<Scalars['String']>;
  allowedBlocks?: Maybe<Scalars['BlockAttributesArray']>;
  alt: Scalars['String'];
  anchor?: Maybe<Scalars['String']>;
  backgroundType: Scalars['String'];
  className?: Maybe<Scalars['String']>;
  contentPosition?: Maybe<Scalars['String']>;
  customGradient?: Maybe<Scalars['String']>;
  customOverlayColor?: Maybe<Scalars['String']>;
  dimRatio: Scalars['Float'];
  focalPoint?: Maybe<Scalars['BlockAttributesObject']>;
  gradient?: Maybe<Scalars['String']>;
  hasParallax: Scalars['Boolean'];
  id?: Maybe<Scalars['Float']>;
  isDark: Scalars['Boolean'];
  isRepeated: Scalars['Boolean'];
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  minHeight?: Maybe<Scalars['Float']>;
  minHeightUnit?: Maybe<Scalars['String']>;
  overlayColor?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  url?: Maybe<Scalars['String']>;
  useFeaturedImage: Scalars['Boolean'];
};

export type CoreCoverBlockDeprecatedV2Attributes = {
  __typename?: 'CoreCoverBlockDeprecatedV2Attributes';
  align?: Maybe<Scalars['String']>;
  allowedBlocks?: Maybe<Scalars['BlockAttributesArray']>;
  alt: Scalars['String'];
  anchor?: Maybe<Scalars['String']>;
  backgroundType: Scalars['String'];
  className?: Maybe<Scalars['String']>;
  contentPosition?: Maybe<Scalars['String']>;
  customGradient?: Maybe<Scalars['String']>;
  customOverlayColor?: Maybe<Scalars['String']>;
  dimRatio: Scalars['Float'];
  focalPoint?: Maybe<Scalars['BlockAttributesObject']>;
  gradient?: Maybe<Scalars['String']>;
  hasParallax: Scalars['Boolean'];
  id?: Maybe<Scalars['Float']>;
  isDark: Scalars['Boolean'];
  isRepeated: Scalars['Boolean'];
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  minHeight?: Maybe<Scalars['Float']>;
  minHeightUnit?: Maybe<Scalars['String']>;
  overlayColor?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  url?: Maybe<Scalars['String']>;
  useFeaturedImage: Scalars['Boolean'];
};

export type CoreCoverBlockDeprecatedV3Attributes = {
  __typename?: 'CoreCoverBlockDeprecatedV3Attributes';
  align?: Maybe<Scalars['String']>;
  allowedBlocks?: Maybe<Scalars['BlockAttributesArray']>;
  alt: Scalars['String'];
  anchor?: Maybe<Scalars['String']>;
  backgroundType: Scalars['String'];
  className?: Maybe<Scalars['String']>;
  contentPosition?: Maybe<Scalars['String']>;
  customGradient?: Maybe<Scalars['String']>;
  customOverlayColor?: Maybe<Scalars['String']>;
  dimRatio: Scalars['Float'];
  focalPoint?: Maybe<Scalars['BlockAttributesObject']>;
  gradient?: Maybe<Scalars['String']>;
  hasParallax: Scalars['Boolean'];
  id?: Maybe<Scalars['Float']>;
  isDark: Scalars['Boolean'];
  isRepeated: Scalars['Boolean'];
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  minHeight?: Maybe<Scalars['Float']>;
  minHeightUnit?: Maybe<Scalars['String']>;
  overlayColor?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  url?: Maybe<Scalars['String']>;
  useFeaturedImage: Scalars['Boolean'];
};

export type CoreCoverBlockDeprecatedV4Attributes = {
  __typename?: 'CoreCoverBlockDeprecatedV4Attributes';
  align?: Maybe<Scalars['String']>;
  allowedBlocks?: Maybe<Scalars['BlockAttributesArray']>;
  alt: Scalars['String'];
  anchor?: Maybe<Scalars['String']>;
  backgroundType: Scalars['String'];
  className?: Maybe<Scalars['String']>;
  contentPosition?: Maybe<Scalars['String']>;
  customGradient?: Maybe<Scalars['String']>;
  customOverlayColor?: Maybe<Scalars['String']>;
  dimRatio: Scalars['Float'];
  focalPoint?: Maybe<Scalars['BlockAttributesObject']>;
  gradient?: Maybe<Scalars['String']>;
  hasParallax: Scalars['Boolean'];
  id?: Maybe<Scalars['Float']>;
  isDark: Scalars['Boolean'];
  isRepeated: Scalars['Boolean'];
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  minHeight?: Maybe<Scalars['Float']>;
  minHeightUnit?: Maybe<Scalars['String']>;
  overlayColor?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  url?: Maybe<Scalars['String']>;
  useFeaturedImage: Scalars['Boolean'];
};

export type CoreCoverBlockDeprecatedV5Attributes = {
  __typename?: 'CoreCoverBlockDeprecatedV5Attributes';
  align?: Maybe<Scalars['String']>;
  allowedBlocks?: Maybe<Scalars['BlockAttributesArray']>;
  alt: Scalars['String'];
  anchor?: Maybe<Scalars['String']>;
  backgroundType: Scalars['String'];
  className?: Maybe<Scalars['String']>;
  contentPosition?: Maybe<Scalars['String']>;
  customGradient?: Maybe<Scalars['String']>;
  customOverlayColor?: Maybe<Scalars['String']>;
  dimRatio: Scalars['Float'];
  focalPoint?: Maybe<Scalars['BlockAttributesObject']>;
  gradient?: Maybe<Scalars['String']>;
  hasParallax: Scalars['Boolean'];
  id?: Maybe<Scalars['Float']>;
  isDark: Scalars['Boolean'];
  isRepeated: Scalars['Boolean'];
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  minHeight?: Maybe<Scalars['Float']>;
  minHeightUnit?: Maybe<Scalars['String']>;
  overlayColor?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  url?: Maybe<Scalars['String']>;
  useFeaturedImage: Scalars['Boolean'];
};

export type CoreCoverBlockDeprecatedV6Attributes = {
  __typename?: 'CoreCoverBlockDeprecatedV6Attributes';
  align?: Maybe<Scalars['String']>;
  allowedBlocks?: Maybe<Scalars['BlockAttributesArray']>;
  alt: Scalars['String'];
  anchor?: Maybe<Scalars['String']>;
  backgroundType: Scalars['String'];
  className?: Maybe<Scalars['String']>;
  contentPosition?: Maybe<Scalars['String']>;
  customGradient?: Maybe<Scalars['String']>;
  customOverlayColor?: Maybe<Scalars['String']>;
  dimRatio: Scalars['Float'];
  focalPoint?: Maybe<Scalars['BlockAttributesObject']>;
  gradient?: Maybe<Scalars['String']>;
  hasParallax: Scalars['Boolean'];
  id?: Maybe<Scalars['Float']>;
  isDark: Scalars['Boolean'];
  isRepeated: Scalars['Boolean'];
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  minHeight?: Maybe<Scalars['Float']>;
  minHeightUnit?: Maybe<Scalars['String']>;
  overlayColor?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  url?: Maybe<Scalars['String']>;
  useFeaturedImage: Scalars['Boolean'];
};

export type CoreCoverBlockDeprecatedV7Attributes = {
  __typename?: 'CoreCoverBlockDeprecatedV7Attributes';
  align?: Maybe<Scalars['String']>;
  allowedBlocks?: Maybe<Scalars['BlockAttributesArray']>;
  alt: Scalars['String'];
  anchor?: Maybe<Scalars['String']>;
  backgroundType: Scalars['String'];
  className?: Maybe<Scalars['String']>;
  contentPosition?: Maybe<Scalars['String']>;
  customGradient?: Maybe<Scalars['String']>;
  customOverlayColor?: Maybe<Scalars['String']>;
  dimRatio: Scalars['Float'];
  focalPoint?: Maybe<Scalars['BlockAttributesObject']>;
  gradient?: Maybe<Scalars['String']>;
  hasParallax: Scalars['Boolean'];
  id?: Maybe<Scalars['Float']>;
  isDark: Scalars['Boolean'];
  isRepeated: Scalars['Boolean'];
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  minHeight?: Maybe<Scalars['Float']>;
  minHeightUnit?: Maybe<Scalars['String']>;
  overlayColor?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  url?: Maybe<Scalars['String']>;
  useFeaturedImage: Scalars['Boolean'];
};

export type CoreCoverBlockDeprecatedV8Attributes = {
  __typename?: 'CoreCoverBlockDeprecatedV8Attributes';
  align?: Maybe<Scalars['String']>;
  allowedBlocks?: Maybe<Scalars['BlockAttributesArray']>;
  alt: Scalars['String'];
  anchor?: Maybe<Scalars['String']>;
  backgroundType: Scalars['String'];
  className?: Maybe<Scalars['String']>;
  contentPosition?: Maybe<Scalars['String']>;
  customGradient?: Maybe<Scalars['String']>;
  customOverlayColor?: Maybe<Scalars['String']>;
  dimRatio: Scalars['Float'];
  focalPoint?: Maybe<Scalars['BlockAttributesObject']>;
  gradient?: Maybe<Scalars['String']>;
  hasParallax: Scalars['Boolean'];
  id?: Maybe<Scalars['Float']>;
  isDark: Scalars['Boolean'];
  isRepeated: Scalars['Boolean'];
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  minHeight?: Maybe<Scalars['Float']>;
  minHeightUnit?: Maybe<Scalars['String']>;
  overlayColor?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  url?: Maybe<Scalars['String']>;
  useFeaturedImage: Scalars['Boolean'];
};

export type CoreCoverBlockDeprecatedV9Attributes = {
  __typename?: 'CoreCoverBlockDeprecatedV9Attributes';
  align?: Maybe<Scalars['String']>;
  allowedBlocks?: Maybe<Scalars['BlockAttributesArray']>;
  alt: Scalars['String'];
  anchor?: Maybe<Scalars['String']>;
  backgroundType: Scalars['String'];
  className?: Maybe<Scalars['String']>;
  contentPosition?: Maybe<Scalars['String']>;
  customGradient?: Maybe<Scalars['String']>;
  customOverlayColor?: Maybe<Scalars['String']>;
  dimRatio: Scalars['Float'];
  focalPoint?: Maybe<Scalars['BlockAttributesObject']>;
  gradient?: Maybe<Scalars['String']>;
  hasParallax: Scalars['Boolean'];
  id?: Maybe<Scalars['Float']>;
  isDark: Scalars['Boolean'];
  isRepeated: Scalars['Boolean'];
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  minHeight?: Maybe<Scalars['Float']>;
  minHeightUnit?: Maybe<Scalars['String']>;
  overlayColor?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  url?: Maybe<Scalars['String']>;
  useFeaturedImage: Scalars['Boolean'];
};

/** core/embed block */
export type CoreEmbedBlock = Block & {
  __typename?: 'CoreEmbedBlock';
  attributes?: Maybe<CoreEmbedBlockAttributesUnion>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreEmbedBlockAttributes = {
  __typename?: 'CoreEmbedBlockAttributes';
  align?: Maybe<Scalars['String']>;
  allowResponsive: Scalars['Boolean'];
  caption?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  previewable: Scalars['Boolean'];
  providerNameSlug?: Maybe<Scalars['String']>;
  responsive: Scalars['Boolean'];
  type?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type CoreEmbedBlockAttributesUnion = CoreEmbedBlockAttributes | CoreEmbedBlockDeprecatedV1Attributes;

export type CoreEmbedBlockDeprecatedV1Attributes = {
  __typename?: 'CoreEmbedBlockDeprecatedV1Attributes';
  align?: Maybe<Scalars['String']>;
  allowResponsive: Scalars['Boolean'];
  caption?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  previewable: Scalars['Boolean'];
  providerNameSlug?: Maybe<Scalars['String']>;
  responsive: Scalars['Boolean'];
  type?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** core/file block */
export type CoreFileBlock = Block & {
  __typename?: 'CoreFileBlock';
  attributes?: Maybe<CoreFileBlockAttributesUnion>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreFileBlockAttributes = {
  __typename?: 'CoreFileBlockAttributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  displayPreview?: Maybe<Scalars['Boolean']>;
  downloadButtonText?: Maybe<Scalars['String']>;
  fileId?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  href?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  previewHeight: Scalars['Float'];
  showDownloadButton: Scalars['Boolean'];
  textLinkHref?: Maybe<Scalars['String']>;
  textLinkTarget?: Maybe<Scalars['String']>;
};

export type CoreFileBlockAttributesUnion = CoreFileBlockAttributes | CoreFileBlockDeprecatedV1Attributes;

export type CoreFileBlockDeprecatedV1Attributes = {
  __typename?: 'CoreFileBlockDeprecatedV1Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  displayPreview?: Maybe<Scalars['Boolean']>;
  downloadButtonText?: Maybe<Scalars['String']>;
  fileId?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  href?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  previewHeight: Scalars['Float'];
  showDownloadButton: Scalars['Boolean'];
  textLinkHref?: Maybe<Scalars['String']>;
  textLinkTarget?: Maybe<Scalars['String']>;
};

/** core/freeform block */
export type CoreFreeformBlock = Block & {
  __typename?: 'CoreFreeformBlock';
  attributes?: Maybe<CoreFreeformBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreFreeformBlockAttributes = {
  __typename?: 'CoreFreeformBlockAttributes';
  content?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
};

/** core/gallery block */
export type CoreGalleryBlock = Block & {
  __typename?: 'CoreGalleryBlock';
  attributes?: Maybe<CoreGalleryBlockAttributesUnion>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreGalleryBlockAttributes = {
  __typename?: 'CoreGalleryBlockAttributes';
  align?: Maybe<Scalars['String']>;
  allowResize: Scalars['Boolean'];
  anchor?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  columns?: Maybe<Scalars['Float']>;
  fixedHeight: Scalars['Boolean'];
  ids: Array<Maybe<Scalars['Float']>>;
  imageCrop: Scalars['Boolean'];
  images: Array<Maybe<CoreGalleryBlockAttributesImages>>;
  layout?: Maybe<Scalars['BlockAttributesObject']>;
  linkTarget?: Maybe<Scalars['String']>;
  linkTo?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  shortCodeTransforms: Array<Maybe<Scalars['BlockAttributesObject']>>;
  sizeSlug: Scalars['String'];
  style?: Maybe<Scalars['BlockAttributesObject']>;
};

export type CoreGalleryBlockAttributesImages = {
  __typename?: 'CoreGalleryBlockAttributesImages';
  alt: Scalars['String'];
  caption?: Maybe<Scalars['String']>;
  fullUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type CoreGalleryBlockAttributesUnion = CoreGalleryBlockAttributes | CoreGalleryBlockDeprecatedV1Attributes | CoreGalleryBlockDeprecatedV2Attributes | CoreGalleryBlockDeprecatedV3Attributes | CoreGalleryBlockDeprecatedV4Attributes | CoreGalleryBlockDeprecatedV5Attributes | CoreGalleryBlockDeprecatedV6Attributes;

export type CoreGalleryBlockDeprecatedV1Attributes = {
  __typename?: 'CoreGalleryBlockDeprecatedV1Attributes';
  align?: Maybe<Scalars['String']>;
  allowResize: Scalars['Boolean'];
  anchor?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  columns?: Maybe<Scalars['Float']>;
  fixedHeight: Scalars['Boolean'];
  ids: Array<Maybe<Scalars['Float']>>;
  imageCrop: Scalars['Boolean'];
  images: Array<Maybe<CoreGalleryBlockDeprecatedV1AttributesImages>>;
  layout?: Maybe<Scalars['BlockAttributesObject']>;
  linkTarget?: Maybe<Scalars['String']>;
  linkTo?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  shortCodeTransforms: Array<Maybe<Scalars['BlockAttributesObject']>>;
  sizeSlug: Scalars['String'];
  style?: Maybe<Scalars['BlockAttributesObject']>;
};

export type CoreGalleryBlockDeprecatedV1AttributesImages = {
  __typename?: 'CoreGalleryBlockDeprecatedV1AttributesImages';
  alt: Scalars['String'];
  caption?: Maybe<Scalars['String']>;
  fullUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type CoreGalleryBlockDeprecatedV2Attributes = {
  __typename?: 'CoreGalleryBlockDeprecatedV2Attributes';
  align?: Maybe<Scalars['String']>;
  allowResize: Scalars['Boolean'];
  anchor?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  columns?: Maybe<Scalars['Float']>;
  fixedHeight: Scalars['Boolean'];
  ids: Array<Maybe<Scalars['Float']>>;
  imageCrop: Scalars['Boolean'];
  images: Array<Maybe<CoreGalleryBlockDeprecatedV2AttributesImages>>;
  layout?: Maybe<Scalars['BlockAttributesObject']>;
  linkTarget?: Maybe<Scalars['String']>;
  linkTo?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  shortCodeTransforms: Array<Maybe<Scalars['BlockAttributesObject']>>;
  sizeSlug: Scalars['String'];
  style?: Maybe<Scalars['BlockAttributesObject']>;
};

export type CoreGalleryBlockDeprecatedV2AttributesImages = {
  __typename?: 'CoreGalleryBlockDeprecatedV2AttributesImages';
  alt: Scalars['String'];
  caption?: Maybe<Scalars['String']>;
  fullUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type CoreGalleryBlockDeprecatedV3Attributes = {
  __typename?: 'CoreGalleryBlockDeprecatedV3Attributes';
  align?: Maybe<Scalars['String']>;
  allowResize: Scalars['Boolean'];
  anchor?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  columns?: Maybe<Scalars['Float']>;
  fixedHeight: Scalars['Boolean'];
  ids: Array<Maybe<Scalars['Float']>>;
  imageCrop: Scalars['Boolean'];
  images: Array<Maybe<CoreGalleryBlockDeprecatedV3AttributesImages>>;
  layout?: Maybe<Scalars['BlockAttributesObject']>;
  linkTarget?: Maybe<Scalars['String']>;
  linkTo?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  shortCodeTransforms: Array<Maybe<Scalars['BlockAttributesObject']>>;
  sizeSlug: Scalars['String'];
  style?: Maybe<Scalars['BlockAttributesObject']>;
};

export type CoreGalleryBlockDeprecatedV3AttributesImages = {
  __typename?: 'CoreGalleryBlockDeprecatedV3AttributesImages';
  alt: Scalars['String'];
  caption?: Maybe<Scalars['String']>;
  fullUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type CoreGalleryBlockDeprecatedV4Attributes = {
  __typename?: 'CoreGalleryBlockDeprecatedV4Attributes';
  align?: Maybe<Scalars['String']>;
  allowResize: Scalars['Boolean'];
  anchor?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  columns?: Maybe<Scalars['Float']>;
  fixedHeight: Scalars['Boolean'];
  ids: Array<Maybe<Scalars['Float']>>;
  imageCrop: Scalars['Boolean'];
  images: Array<Maybe<CoreGalleryBlockDeprecatedV4AttributesImages>>;
  layout?: Maybe<Scalars['BlockAttributesObject']>;
  linkTarget?: Maybe<Scalars['String']>;
  linkTo?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  shortCodeTransforms: Array<Maybe<Scalars['BlockAttributesObject']>>;
  sizeSlug: Scalars['String'];
  style?: Maybe<Scalars['BlockAttributesObject']>;
};

export type CoreGalleryBlockDeprecatedV4AttributesImages = {
  __typename?: 'CoreGalleryBlockDeprecatedV4AttributesImages';
  alt: Scalars['String'];
  caption?: Maybe<Scalars['String']>;
  fullUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type CoreGalleryBlockDeprecatedV5Attributes = {
  __typename?: 'CoreGalleryBlockDeprecatedV5Attributes';
  align?: Maybe<Scalars['String']>;
  allowResize: Scalars['Boolean'];
  anchor?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  columns?: Maybe<Scalars['Float']>;
  fixedHeight: Scalars['Boolean'];
  ids: Array<Maybe<Scalars['Float']>>;
  imageCrop: Scalars['Boolean'];
  images: Array<Maybe<CoreGalleryBlockDeprecatedV5AttributesImages>>;
  layout?: Maybe<Scalars['BlockAttributesObject']>;
  linkTarget?: Maybe<Scalars['String']>;
  linkTo?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  shortCodeTransforms: Array<Maybe<Scalars['BlockAttributesObject']>>;
  sizeSlug: Scalars['String'];
  style?: Maybe<Scalars['BlockAttributesObject']>;
};

export type CoreGalleryBlockDeprecatedV5AttributesImages = {
  __typename?: 'CoreGalleryBlockDeprecatedV5AttributesImages';
  alt: Scalars['String'];
  caption?: Maybe<Scalars['String']>;
  fullUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type CoreGalleryBlockDeprecatedV6Attributes = {
  __typename?: 'CoreGalleryBlockDeprecatedV6Attributes';
  align?: Maybe<Scalars['String']>;
  allowResize: Scalars['Boolean'];
  anchor?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  columns?: Maybe<Scalars['Float']>;
  fixedHeight: Scalars['Boolean'];
  ids: Array<Maybe<Scalars['Float']>>;
  imageCrop: Scalars['Boolean'];
  images: Array<Maybe<CoreGalleryBlockDeprecatedV6AttributesImages>>;
  layout?: Maybe<Scalars['BlockAttributesObject']>;
  linkTarget?: Maybe<Scalars['String']>;
  linkTo?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  shortCodeTransforms: Array<Maybe<Scalars['BlockAttributesObject']>>;
  sizeSlug: Scalars['String'];
  style?: Maybe<Scalars['BlockAttributesObject']>;
};

export type CoreGalleryBlockDeprecatedV6AttributesImages = {
  __typename?: 'CoreGalleryBlockDeprecatedV6AttributesImages';
  alt: Scalars['String'];
  caption?: Maybe<Scalars['String']>;
  fullUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** core/group block */
export type CoreGroupBlock = Block & {
  __typename?: 'CoreGroupBlock';
  attributes?: Maybe<CoreGroupBlockAttributesUnion>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreGroupBlockAttributes = {
  __typename?: 'CoreGroupBlockAttributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  borderColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  layout?: Maybe<Scalars['BlockAttributesObject']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  tagName: Scalars['String'];
  textColor?: Maybe<Scalars['String']>;
};

export type CoreGroupBlockAttributesUnion = CoreGroupBlockAttributes | CoreGroupBlockDeprecatedV1Attributes | CoreGroupBlockDeprecatedV2Attributes | CoreGroupBlockDeprecatedV3Attributes | CoreGroupBlockDeprecatedV4Attributes;

export type CoreGroupBlockDeprecatedV1Attributes = {
  __typename?: 'CoreGroupBlockDeprecatedV1Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  borderColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  layout?: Maybe<Scalars['BlockAttributesObject']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  tagName: Scalars['String'];
  textColor?: Maybe<Scalars['String']>;
};

export type CoreGroupBlockDeprecatedV2Attributes = {
  __typename?: 'CoreGroupBlockDeprecatedV2Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  borderColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  layout?: Maybe<Scalars['BlockAttributesObject']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  tagName: Scalars['String'];
  textColor?: Maybe<Scalars['String']>;
};

export type CoreGroupBlockDeprecatedV3Attributes = {
  __typename?: 'CoreGroupBlockDeprecatedV3Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  borderColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  layout?: Maybe<Scalars['BlockAttributesObject']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  tagName: Scalars['String'];
  textColor?: Maybe<Scalars['String']>;
};

export type CoreGroupBlockDeprecatedV4Attributes = {
  __typename?: 'CoreGroupBlockDeprecatedV4Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  borderColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  layout?: Maybe<Scalars['BlockAttributesObject']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  tagName: Scalars['String'];
  textColor?: Maybe<Scalars['String']>;
};

/** core/heading block */
export type CoreHeadingBlock = Block & {
  __typename?: 'CoreHeadingBlock';
  attributes?: Maybe<CoreHeadingBlockAttributesUnion>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreHeadingBlockAttributes = {
  __typename?: 'CoreHeadingBlockAttributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  content: Scalars['String'];
  fontSize?: Maybe<Scalars['String']>;
  level: Scalars['Float'];
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  placeholder?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textAlign?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
};

export type CoreHeadingBlockAttributesUnion = CoreHeadingBlockAttributes | CoreHeadingBlockDeprecatedV1Attributes | CoreHeadingBlockDeprecatedV2Attributes | CoreHeadingBlockDeprecatedV3Attributes | CoreHeadingBlockDeprecatedV4Attributes;

export type CoreHeadingBlockDeprecatedV1Attributes = {
  __typename?: 'CoreHeadingBlockDeprecatedV1Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  content: Scalars['String'];
  fontSize?: Maybe<Scalars['String']>;
  level: Scalars['Float'];
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  placeholder?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textAlign?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
};

export type CoreHeadingBlockDeprecatedV2Attributes = {
  __typename?: 'CoreHeadingBlockDeprecatedV2Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  content: Scalars['String'];
  fontSize?: Maybe<Scalars['String']>;
  level: Scalars['Float'];
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  placeholder?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textAlign?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
};

export type CoreHeadingBlockDeprecatedV3Attributes = {
  __typename?: 'CoreHeadingBlockDeprecatedV3Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  content: Scalars['String'];
  fontSize?: Maybe<Scalars['String']>;
  level: Scalars['Float'];
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  placeholder?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textAlign?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
};

export type CoreHeadingBlockDeprecatedV4Attributes = {
  __typename?: 'CoreHeadingBlockDeprecatedV4Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  content: Scalars['String'];
  fontSize?: Maybe<Scalars['String']>;
  level: Scalars['Float'];
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  placeholder?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textAlign?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
};

/** core/home-link block */
export type CoreHomeLinkBlock = Block & {
  __typename?: 'CoreHomeLinkBlock';
  attributes?: Maybe<CoreHomeLinkBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreHomeLinkBlockAttributes = {
  __typename?: 'CoreHomeLinkBlockAttributes';
  className?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
};

/** core/html block */
export type CoreHtmlBlock = Block & {
  __typename?: 'CoreHtmlBlock';
  attributes?: Maybe<CoreHtmlBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreHtmlBlockAttributes = {
  __typename?: 'CoreHtmlBlockAttributes';
  content?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
};

/** core/image block */
export type CoreImageBlock = Block & {
  __typename?: 'CoreImageBlock';
  attributes?: Maybe<CoreImageBlockAttributesUnion>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Connection between the CoreImageBlock type and the MediaItem type */
  mediaItem?: Maybe<CoreImageBlockToMediaItemConnectionEdge>;
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreImageBlockAttributes = {
  __typename?: 'CoreImageBlockAttributes';
  align?: Maybe<Scalars['String']>;
  alt: Scalars['String'];
  anchor?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  href?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  linkClass?: Maybe<Scalars['String']>;
  linkDestination?: Maybe<Scalars['String']>;
  linkTarget?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  rel?: Maybe<Scalars['String']>;
  sizeSlug?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
};

export type CoreImageBlockAttributesUnion = CoreImageBlockAttributes | CoreImageBlockDeprecatedV1Attributes | CoreImageBlockDeprecatedV2Attributes | CoreImageBlockDeprecatedV3Attributes | CoreImageBlockDeprecatedV4Attributes;

export type CoreImageBlockDeprecatedV1Attributes = {
  __typename?: 'CoreImageBlockDeprecatedV1Attributes';
  align?: Maybe<Scalars['String']>;
  alt: Scalars['String'];
  anchor?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  href?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  linkClass?: Maybe<Scalars['String']>;
  linkDestination?: Maybe<Scalars['String']>;
  linkTarget?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  rel?: Maybe<Scalars['String']>;
  sizeSlug?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
};

export type CoreImageBlockDeprecatedV2Attributes = {
  __typename?: 'CoreImageBlockDeprecatedV2Attributes';
  align?: Maybe<Scalars['String']>;
  alt: Scalars['String'];
  anchor?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  href?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  linkClass?: Maybe<Scalars['String']>;
  linkDestination?: Maybe<Scalars['String']>;
  linkTarget?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  rel?: Maybe<Scalars['String']>;
  sizeSlug?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
};

export type CoreImageBlockDeprecatedV3Attributes = {
  __typename?: 'CoreImageBlockDeprecatedV3Attributes';
  align?: Maybe<Scalars['String']>;
  alt: Scalars['String'];
  anchor?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  href?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  linkClass?: Maybe<Scalars['String']>;
  linkDestination?: Maybe<Scalars['String']>;
  linkTarget?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  rel?: Maybe<Scalars['String']>;
  sizeSlug?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
};

export type CoreImageBlockDeprecatedV4Attributes = {
  __typename?: 'CoreImageBlockDeprecatedV4Attributes';
  align?: Maybe<Scalars['String']>;
  alt: Scalars['String'];
  anchor?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  href?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  linkClass?: Maybe<Scalars['String']>;
  linkDestination?: Maybe<Scalars['String']>;
  linkTarget?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  rel?: Maybe<Scalars['String']>;
  sizeSlug?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
};

/** Connection between the CoreImageBlock type and the MediaItem type */
export type CoreImageBlockToMediaItemConnectionEdge = {
  __typename?: 'CoreImageBlockToMediaItemConnectionEdge';
  /** The node of the connection, without the edges */
  node?: Maybe<MediaItem>;
};

/** core/latest-comments block */
export type CoreLatestCommentsBlock = Block & {
  __typename?: 'CoreLatestCommentsBlock';
  attributes?: Maybe<CoreLatestCommentsBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreLatestCommentsBlockAttributes = {
  __typename?: 'CoreLatestCommentsBlockAttributes';
  align?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  commentsToShow: Scalars['Float'];
  displayAvatar: Scalars['Boolean'];
  displayDate: Scalars['Boolean'];
  displayExcerpt: Scalars['Boolean'];
  lock?: Maybe<Scalars['BlockAttributesObject']>;
};

/** core/latest-posts block */
export type CoreLatestPostsBlock = Block & {
  __typename?: 'CoreLatestPostsBlock';
  attributes?: Maybe<CoreLatestPostsBlockAttributesUnion>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreLatestPostsBlockAttributes = {
  __typename?: 'CoreLatestPostsBlockAttributes';
  addLinkToFeaturedImage: Scalars['Boolean'];
  align?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Maybe<Scalars['BlockAttributesObject']>>>;
  className?: Maybe<Scalars['String']>;
  columns: Scalars['Float'];
  displayAuthor: Scalars['Boolean'];
  displayFeaturedImage: Scalars['Boolean'];
  displayPostContent: Scalars['Boolean'];
  displayPostContentRadio: Scalars['String'];
  displayPostDate: Scalars['Boolean'];
  excerptLength: Scalars['Float'];
  featuredImageAlign?: Maybe<Scalars['String']>;
  featuredImageSizeHeight?: Maybe<Scalars['Float']>;
  featuredImageSizeSlug: Scalars['String'];
  featuredImageSizeWidth?: Maybe<Scalars['Float']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  order: Scalars['String'];
  orderBy: Scalars['String'];
  postLayout: Scalars['String'];
  postsToShow: Scalars['Float'];
  selectedAuthor?: Maybe<Scalars['Float']>;
};

export type CoreLatestPostsBlockAttributesUnion = CoreLatestPostsBlockAttributes | CoreLatestPostsBlockDeprecatedV1Attributes;

export type CoreLatestPostsBlockDeprecatedV1Attributes = {
  __typename?: 'CoreLatestPostsBlockDeprecatedV1Attributes';
  addLinkToFeaturedImage: Scalars['Boolean'];
  align?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Maybe<Scalars['BlockAttributesObject']>>>;
  className?: Maybe<Scalars['String']>;
  columns: Scalars['Float'];
  displayAuthor: Scalars['Boolean'];
  displayFeaturedImage: Scalars['Boolean'];
  displayPostContent: Scalars['Boolean'];
  displayPostContentRadio: Scalars['String'];
  displayPostDate: Scalars['Boolean'];
  excerptLength: Scalars['Float'];
  featuredImageAlign?: Maybe<Scalars['String']>;
  featuredImageSizeHeight?: Maybe<Scalars['Float']>;
  featuredImageSizeSlug: Scalars['String'];
  featuredImageSizeWidth?: Maybe<Scalars['Float']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  order: Scalars['String'];
  orderBy: Scalars['String'];
  postLayout: Scalars['String'];
  postsToShow: Scalars['Float'];
  selectedAuthor?: Maybe<Scalars['Float']>;
};

/** core/list block */
export type CoreListBlock = Block & {
  __typename?: 'CoreListBlock';
  attributes?: Maybe<CoreListBlockAttributesUnion>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreListBlockAttributes = {
  __typename?: 'CoreListBlockAttributes';
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontFamily?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  ordered: Scalars['Boolean'];
  placeholder?: Maybe<Scalars['String']>;
  reversed?: Maybe<Scalars['Boolean']>;
  start?: Maybe<Scalars['Float']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  values: Scalars['String'];
};

export type CoreListBlockAttributesUnion = CoreListBlockAttributes | CoreListBlockDeprecatedV1Attributes;

export type CoreListBlockDeprecatedV1Attributes = {
  __typename?: 'CoreListBlockDeprecatedV1Attributes';
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontFamily?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  ordered: Scalars['Boolean'];
  placeholder?: Maybe<Scalars['String']>;
  reversed?: Maybe<Scalars['Boolean']>;
  start?: Maybe<Scalars['Float']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  values: Scalars['String'];
};

/** core/loginout block */
export type CoreLoginoutBlock = Block & {
  __typename?: 'CoreLoginoutBlock';
  attributes?: Maybe<CoreLoginoutBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreLoginoutBlockAttributes = {
  __typename?: 'CoreLoginoutBlockAttributes';
  className?: Maybe<Scalars['String']>;
  displayLoginAsForm: Scalars['Boolean'];
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  redirectToCurrent: Scalars['Boolean'];
};

/** core/media-text block */
export type CoreMediaTextBlock = Block & {
  __typename?: 'CoreMediaTextBlock';
  attributes?: Maybe<CoreMediaTextBlockAttributesUnion>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreMediaTextBlockAttributes = {
  __typename?: 'CoreMediaTextBlockAttributes';
  align: Scalars['String'];
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  focalPoint?: Maybe<Scalars['BlockAttributesObject']>;
  gradient?: Maybe<Scalars['String']>;
  href?: Maybe<Scalars['String']>;
  imageFill?: Maybe<Scalars['Boolean']>;
  isStackedOnMobile: Scalars['Boolean'];
  linkClass?: Maybe<Scalars['String']>;
  linkDestination?: Maybe<Scalars['String']>;
  linkTarget?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  mediaAlt: Scalars['String'];
  mediaId?: Maybe<Scalars['Float']>;
  mediaLink?: Maybe<Scalars['String']>;
  mediaPosition: Scalars['String'];
  mediaSizeSlug?: Maybe<Scalars['String']>;
  mediaType?: Maybe<Scalars['String']>;
  mediaUrl?: Maybe<Scalars['String']>;
  mediaWidth: Scalars['Float'];
  rel?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
  verticalAlignment?: Maybe<Scalars['String']>;
};

export type CoreMediaTextBlockAttributesUnion = CoreMediaTextBlockAttributes | CoreMediaTextBlockDeprecatedV1Attributes | CoreMediaTextBlockDeprecatedV2Attributes | CoreMediaTextBlockDeprecatedV3Attributes;

export type CoreMediaTextBlockDeprecatedV1Attributes = {
  __typename?: 'CoreMediaTextBlockDeprecatedV1Attributes';
  align: Scalars['String'];
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  focalPoint?: Maybe<Scalars['BlockAttributesObject']>;
  gradient?: Maybe<Scalars['String']>;
  href?: Maybe<Scalars['String']>;
  imageFill?: Maybe<Scalars['Boolean']>;
  isStackedOnMobile: Scalars['Boolean'];
  linkClass?: Maybe<Scalars['String']>;
  linkDestination?: Maybe<Scalars['String']>;
  linkTarget?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  mediaAlt: Scalars['String'];
  mediaId?: Maybe<Scalars['Float']>;
  mediaLink?: Maybe<Scalars['String']>;
  mediaPosition: Scalars['String'];
  mediaSizeSlug?: Maybe<Scalars['String']>;
  mediaType?: Maybe<Scalars['String']>;
  mediaUrl?: Maybe<Scalars['String']>;
  mediaWidth: Scalars['Float'];
  rel?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
  verticalAlignment?: Maybe<Scalars['String']>;
};

export type CoreMediaTextBlockDeprecatedV2Attributes = {
  __typename?: 'CoreMediaTextBlockDeprecatedV2Attributes';
  align: Scalars['String'];
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  focalPoint?: Maybe<Scalars['BlockAttributesObject']>;
  gradient?: Maybe<Scalars['String']>;
  href?: Maybe<Scalars['String']>;
  imageFill?: Maybe<Scalars['Boolean']>;
  isStackedOnMobile: Scalars['Boolean'];
  linkClass?: Maybe<Scalars['String']>;
  linkDestination?: Maybe<Scalars['String']>;
  linkTarget?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  mediaAlt: Scalars['String'];
  mediaId?: Maybe<Scalars['Float']>;
  mediaLink?: Maybe<Scalars['String']>;
  mediaPosition: Scalars['String'];
  mediaSizeSlug?: Maybe<Scalars['String']>;
  mediaType?: Maybe<Scalars['String']>;
  mediaUrl?: Maybe<Scalars['String']>;
  mediaWidth: Scalars['Float'];
  rel?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
  verticalAlignment?: Maybe<Scalars['String']>;
};

export type CoreMediaTextBlockDeprecatedV3Attributes = {
  __typename?: 'CoreMediaTextBlockDeprecatedV3Attributes';
  align: Scalars['String'];
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  focalPoint?: Maybe<Scalars['BlockAttributesObject']>;
  gradient?: Maybe<Scalars['String']>;
  href?: Maybe<Scalars['String']>;
  imageFill?: Maybe<Scalars['Boolean']>;
  isStackedOnMobile: Scalars['Boolean'];
  linkClass?: Maybe<Scalars['String']>;
  linkDestination?: Maybe<Scalars['String']>;
  linkTarget?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  mediaAlt: Scalars['String'];
  mediaId?: Maybe<Scalars['Float']>;
  mediaLink?: Maybe<Scalars['String']>;
  mediaPosition: Scalars['String'];
  mediaSizeSlug?: Maybe<Scalars['String']>;
  mediaType?: Maybe<Scalars['String']>;
  mediaUrl?: Maybe<Scalars['String']>;
  mediaWidth: Scalars['Float'];
  rel?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
  verticalAlignment?: Maybe<Scalars['String']>;
};

/** core/missing block */
export type CoreMissingBlock = Block & {
  __typename?: 'CoreMissingBlock';
  attributes?: Maybe<CoreMissingBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreMissingBlockAttributes = {
  __typename?: 'CoreMissingBlockAttributes';
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  originalContent?: Maybe<Scalars['String']>;
  originalName?: Maybe<Scalars['String']>;
  originalUndelimitedContent?: Maybe<Scalars['String']>;
};

/** core/more block */
export type CoreMoreBlock = Block & {
  __typename?: 'CoreMoreBlock';
  attributes?: Maybe<CoreMoreBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreMoreBlockAttributes = {
  __typename?: 'CoreMoreBlockAttributes';
  customText?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  noTeaser: Scalars['Boolean'];
};

/** core/navigation block */
export type CoreNavigationBlock = Block & {
  __typename?: 'CoreNavigationBlock';
  attributes?: Maybe<CoreNavigationBlockAttributesUnion>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreNavigationBlockAttributes = {
  __typename?: 'CoreNavigationBlockAttributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  customBackgroundColor?: Maybe<Scalars['String']>;
  customOverlayBackgroundColor?: Maybe<Scalars['String']>;
  customOverlayTextColor?: Maybe<Scalars['String']>;
  customTextColor?: Maybe<Scalars['String']>;
  fontFamily?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  hasIcon: Scalars['Boolean'];
  layout?: Maybe<Scalars['BlockAttributesObject']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  maxNestingLevel: Scalars['Float'];
  openSubmenusOnClick: Scalars['Boolean'];
  overlayBackgroundColor?: Maybe<Scalars['String']>;
  overlayMenu: Scalars['String'];
  overlayTextColor?: Maybe<Scalars['String']>;
  ref?: Maybe<Scalars['Float']>;
  rgbBackgroundColor?: Maybe<Scalars['String']>;
  rgbTextColor?: Maybe<Scalars['String']>;
  showSubmenuIcon: Scalars['Boolean'];
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
  unstableLocation?: Maybe<Scalars['String']>;
};

export type CoreNavigationBlockAttributesUnion = CoreNavigationBlockAttributes | CoreNavigationBlockDeprecatedV1Attributes | CoreNavigationBlockDeprecatedV2Attributes | CoreNavigationBlockDeprecatedV3Attributes | CoreNavigationBlockDeprecatedV4Attributes | CoreNavigationBlockDeprecatedV5Attributes | CoreNavigationBlockDeprecatedV6Attributes;

export type CoreNavigationBlockDeprecatedV1Attributes = {
  __typename?: 'CoreNavigationBlockDeprecatedV1Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  customBackgroundColor?: Maybe<Scalars['String']>;
  customOverlayBackgroundColor?: Maybe<Scalars['String']>;
  customOverlayTextColor?: Maybe<Scalars['String']>;
  customTextColor?: Maybe<Scalars['String']>;
  fontFamily?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  hasIcon: Scalars['Boolean'];
  layout?: Maybe<Scalars['BlockAttributesObject']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  maxNestingLevel: Scalars['Float'];
  openSubmenusOnClick: Scalars['Boolean'];
  overlayBackgroundColor?: Maybe<Scalars['String']>;
  overlayMenu: Scalars['String'];
  overlayTextColor?: Maybe<Scalars['String']>;
  ref?: Maybe<Scalars['Float']>;
  rgbBackgroundColor?: Maybe<Scalars['String']>;
  rgbTextColor?: Maybe<Scalars['String']>;
  showSubmenuIcon: Scalars['Boolean'];
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
  unstableLocation?: Maybe<Scalars['String']>;
};

export type CoreNavigationBlockDeprecatedV2Attributes = {
  __typename?: 'CoreNavigationBlockDeprecatedV2Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  customBackgroundColor?: Maybe<Scalars['String']>;
  customOverlayBackgroundColor?: Maybe<Scalars['String']>;
  customOverlayTextColor?: Maybe<Scalars['String']>;
  customTextColor?: Maybe<Scalars['String']>;
  fontFamily?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  hasIcon: Scalars['Boolean'];
  layout?: Maybe<Scalars['BlockAttributesObject']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  maxNestingLevel: Scalars['Float'];
  openSubmenusOnClick: Scalars['Boolean'];
  overlayBackgroundColor?: Maybe<Scalars['String']>;
  overlayMenu: Scalars['String'];
  overlayTextColor?: Maybe<Scalars['String']>;
  ref?: Maybe<Scalars['Float']>;
  rgbBackgroundColor?: Maybe<Scalars['String']>;
  rgbTextColor?: Maybe<Scalars['String']>;
  showSubmenuIcon: Scalars['Boolean'];
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
  unstableLocation?: Maybe<Scalars['String']>;
};

export type CoreNavigationBlockDeprecatedV3Attributes = {
  __typename?: 'CoreNavigationBlockDeprecatedV3Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  customBackgroundColor?: Maybe<Scalars['String']>;
  customOverlayBackgroundColor?: Maybe<Scalars['String']>;
  customOverlayTextColor?: Maybe<Scalars['String']>;
  customTextColor?: Maybe<Scalars['String']>;
  fontFamily?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  hasIcon: Scalars['Boolean'];
  layout?: Maybe<Scalars['BlockAttributesObject']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  maxNestingLevel: Scalars['Float'];
  openSubmenusOnClick: Scalars['Boolean'];
  overlayBackgroundColor?: Maybe<Scalars['String']>;
  overlayMenu: Scalars['String'];
  overlayTextColor?: Maybe<Scalars['String']>;
  ref?: Maybe<Scalars['Float']>;
  rgbBackgroundColor?: Maybe<Scalars['String']>;
  rgbTextColor?: Maybe<Scalars['String']>;
  showSubmenuIcon: Scalars['Boolean'];
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
  unstableLocation?: Maybe<Scalars['String']>;
};

export type CoreNavigationBlockDeprecatedV4Attributes = {
  __typename?: 'CoreNavigationBlockDeprecatedV4Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  customBackgroundColor?: Maybe<Scalars['String']>;
  customOverlayBackgroundColor?: Maybe<Scalars['String']>;
  customOverlayTextColor?: Maybe<Scalars['String']>;
  customTextColor?: Maybe<Scalars['String']>;
  fontFamily?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  hasIcon: Scalars['Boolean'];
  layout?: Maybe<Scalars['BlockAttributesObject']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  maxNestingLevel: Scalars['Float'];
  openSubmenusOnClick: Scalars['Boolean'];
  overlayBackgroundColor?: Maybe<Scalars['String']>;
  overlayMenu: Scalars['String'];
  overlayTextColor?: Maybe<Scalars['String']>;
  ref?: Maybe<Scalars['Float']>;
  rgbBackgroundColor?: Maybe<Scalars['String']>;
  rgbTextColor?: Maybe<Scalars['String']>;
  showSubmenuIcon: Scalars['Boolean'];
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
  unstableLocation?: Maybe<Scalars['String']>;
};

export type CoreNavigationBlockDeprecatedV5Attributes = {
  __typename?: 'CoreNavigationBlockDeprecatedV5Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  customBackgroundColor?: Maybe<Scalars['String']>;
  customOverlayBackgroundColor?: Maybe<Scalars['String']>;
  customOverlayTextColor?: Maybe<Scalars['String']>;
  customTextColor?: Maybe<Scalars['String']>;
  fontFamily?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  hasIcon: Scalars['Boolean'];
  layout?: Maybe<Scalars['BlockAttributesObject']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  maxNestingLevel: Scalars['Float'];
  openSubmenusOnClick: Scalars['Boolean'];
  overlayBackgroundColor?: Maybe<Scalars['String']>;
  overlayMenu: Scalars['String'];
  overlayTextColor?: Maybe<Scalars['String']>;
  ref?: Maybe<Scalars['Float']>;
  rgbBackgroundColor?: Maybe<Scalars['String']>;
  rgbTextColor?: Maybe<Scalars['String']>;
  showSubmenuIcon: Scalars['Boolean'];
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
  unstableLocation?: Maybe<Scalars['String']>;
};

export type CoreNavigationBlockDeprecatedV6Attributes = {
  __typename?: 'CoreNavigationBlockDeprecatedV6Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  customBackgroundColor?: Maybe<Scalars['String']>;
  customOverlayBackgroundColor?: Maybe<Scalars['String']>;
  customOverlayTextColor?: Maybe<Scalars['String']>;
  customTextColor?: Maybe<Scalars['String']>;
  fontFamily?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  hasIcon: Scalars['Boolean'];
  layout?: Maybe<Scalars['BlockAttributesObject']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  maxNestingLevel: Scalars['Float'];
  openSubmenusOnClick: Scalars['Boolean'];
  overlayBackgroundColor?: Maybe<Scalars['String']>;
  overlayMenu: Scalars['String'];
  overlayTextColor?: Maybe<Scalars['String']>;
  ref?: Maybe<Scalars['Float']>;
  rgbBackgroundColor?: Maybe<Scalars['String']>;
  rgbTextColor?: Maybe<Scalars['String']>;
  showSubmenuIcon: Scalars['Boolean'];
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
  unstableLocation?: Maybe<Scalars['String']>;
};

/** core/navigation-link block */
export type CoreNavigationLinkBlock = Block & {
  __typename?: 'CoreNavigationLinkBlock';
  attributes?: Maybe<CoreNavigationLinkBlockAttributesUnion>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreNavigationLinkBlockAttributes = {
  __typename?: 'CoreNavigationLinkBlockAttributes';
  className?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  isTopLevelLink?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  opensInNewTab: Scalars['Boolean'];
  rel?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type CoreNavigationLinkBlockAttributesUnion = CoreNavigationLinkBlockAttributes | CoreNavigationLinkBlockDeprecatedV1Attributes;

export type CoreNavigationLinkBlockDeprecatedV1Attributes = {
  __typename?: 'CoreNavigationLinkBlockDeprecatedV1Attributes';
  className?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  isTopLevelLink?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  opensInNewTab: Scalars['Boolean'];
  rel?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** core/navigation-submenu block */
export type CoreNavigationSubmenuBlock = Block & {
  __typename?: 'CoreNavigationSubmenuBlock';
  attributes?: Maybe<CoreNavigationSubmenuBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreNavigationSubmenuBlockAttributes = {
  __typename?: 'CoreNavigationSubmenuBlockAttributes';
  className?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  isTopLevelItem?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  opensInNewTab: Scalars['Boolean'];
  rel?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** core/nextpage block */
export type CoreNextpageBlock = Block & {
  __typename?: 'CoreNextpageBlock';
  attributes?: Maybe<CoreNextpageBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreNextpageBlockAttributes = {
  __typename?: 'CoreNextpageBlockAttributes';
  lock?: Maybe<Scalars['BlockAttributesObject']>;
};

/** core/page-list block */
export type CorePageListBlock = Block & {
  __typename?: 'CorePageListBlock';
  attributes?: Maybe<CorePageListBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CorePageListBlockAttributes = {
  __typename?: 'CorePageListBlockAttributes';
  className?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  unstableMaxPages?: Maybe<Scalars['Float']>;
};

/** core/paragraph block */
export type CoreParagraphBlock = Block & {
  __typename?: 'CoreParagraphBlock';
  attributes?: Maybe<CoreParagraphBlockAttributesUnion>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreParagraphBlockAttributes = {
  __typename?: 'CoreParagraphBlockAttributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  content: Scalars['String'];
  direction?: Maybe<Scalars['String']>;
  dropCap: Scalars['Boolean'];
  fontSize?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  placeholder?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
};

export type CoreParagraphBlockAttributesUnion = CoreParagraphBlockAttributes | CoreParagraphBlockDeprecatedV1Attributes | CoreParagraphBlockDeprecatedV2Attributes | CoreParagraphBlockDeprecatedV3Attributes | CoreParagraphBlockDeprecatedV4Attributes | CoreParagraphBlockDeprecatedV5Attributes;

export type CoreParagraphBlockDeprecatedV1Attributes = {
  __typename?: 'CoreParagraphBlockDeprecatedV1Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  content: Scalars['String'];
  direction?: Maybe<Scalars['String']>;
  dropCap: Scalars['Boolean'];
  fontSize?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  placeholder?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
};

export type CoreParagraphBlockDeprecatedV2Attributes = {
  __typename?: 'CoreParagraphBlockDeprecatedV2Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  content: Scalars['String'];
  direction?: Maybe<Scalars['String']>;
  dropCap: Scalars['Boolean'];
  fontSize?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  placeholder?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
};

export type CoreParagraphBlockDeprecatedV3Attributes = {
  __typename?: 'CoreParagraphBlockDeprecatedV3Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  content: Scalars['String'];
  direction?: Maybe<Scalars['String']>;
  dropCap: Scalars['Boolean'];
  fontSize?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  placeholder?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
};

export type CoreParagraphBlockDeprecatedV4Attributes = {
  __typename?: 'CoreParagraphBlockDeprecatedV4Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  content: Scalars['String'];
  direction?: Maybe<Scalars['String']>;
  dropCap: Scalars['Boolean'];
  fontSize?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  placeholder?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
};

export type CoreParagraphBlockDeprecatedV5Attributes = {
  __typename?: 'CoreParagraphBlockDeprecatedV5Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  content: Scalars['String'];
  direction?: Maybe<Scalars['String']>;
  dropCap: Scalars['Boolean'];
  fontSize?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  placeholder?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
};

/** core/pattern block */
export type CorePatternBlock = Block & {
  __typename?: 'CorePatternBlock';
  attributes?: Maybe<CorePatternBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CorePatternBlockAttributes = {
  __typename?: 'CorePatternBlockAttributes';
  className?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  slug?: Maybe<Scalars['String']>;
};

/** core/post-author-biography block */
export type CorePostAuthorBiographyBlock = Block & {
  __typename?: 'CorePostAuthorBiographyBlock';
  attributes?: Maybe<CorePostAuthorBiographyBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CorePostAuthorBiographyBlockAttributes = {
  __typename?: 'CorePostAuthorBiographyBlockAttributes';
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontFamily?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textAlign?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
};

/** core/post-author block */
export type CorePostAuthorBlock = Block & {
  __typename?: 'CorePostAuthorBlock';
  attributes?: Maybe<CorePostAuthorBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CorePostAuthorBlockAttributes = {
  __typename?: 'CorePostAuthorBlockAttributes';
  avatarSize: Scalars['Float'];
  backgroundColor?: Maybe<Scalars['String']>;
  byline?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  showAvatar: Scalars['Boolean'];
  showBio?: Maybe<Scalars['Boolean']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textAlign?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
};

/** core/post-comments block */
export type CorePostCommentsBlock = Block & {
  __typename?: 'CorePostCommentsBlock';
  attributes?: Maybe<CorePostCommentsBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CorePostCommentsBlockAttributes = {
  __typename?: 'CorePostCommentsBlockAttributes';
  align?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textAlign?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
};

/** core/post-comments-form block */
export type CorePostCommentsFormBlock = Block & {
  __typename?: 'CorePostCommentsFormBlock';
  attributes?: Maybe<CorePostCommentsFormBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CorePostCommentsFormBlockAttributes = {
  __typename?: 'CorePostCommentsFormBlockAttributes';
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textAlign?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
};

/** core/post-content block */
export type CorePostContentBlock = Block & {
  __typename?: 'CorePostContentBlock';
  attributes?: Maybe<CorePostContentBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CorePostContentBlockAttributes = {
  __typename?: 'CorePostContentBlockAttributes';
  align?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  layout?: Maybe<Scalars['BlockAttributesObject']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
};

/** core/post-date block */
export type CorePostDateBlock = Block & {
  __typename?: 'CorePostDateBlock';
  attributes?: Maybe<CorePostDateBlockAttributesUnion>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CorePostDateBlockAttributes = {
  __typename?: 'CorePostDateBlockAttributes';
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontFamily?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  isLink: Scalars['Boolean'];
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textAlign?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
};

export type CorePostDateBlockAttributesUnion = CorePostDateBlockAttributes | CorePostDateBlockDeprecatedV1Attributes;

export type CorePostDateBlockDeprecatedV1Attributes = {
  __typename?: 'CorePostDateBlockDeprecatedV1Attributes';
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontFamily?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  isLink: Scalars['Boolean'];
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textAlign?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
};

/** core/post-excerpt block */
export type CorePostExcerptBlock = Block & {
  __typename?: 'CorePostExcerptBlock';
  attributes?: Maybe<CorePostExcerptBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CorePostExcerptBlockAttributes = {
  __typename?: 'CorePostExcerptBlockAttributes';
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  moreText?: Maybe<Scalars['String']>;
  showMoreOnNewLine: Scalars['Boolean'];
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textAlign?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
};

/** core/post-featured-image block */
export type CorePostFeaturedImageBlock = Block & {
  __typename?: 'CorePostFeaturedImageBlock';
  attributes?: Maybe<CorePostFeaturedImageBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CorePostFeaturedImageBlockAttributes = {
  __typename?: 'CorePostFeaturedImageBlockAttributes';
  align?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  isLink: Scalars['Boolean'];
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  scale: Scalars['String'];
  sizeSlug?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  width?: Maybe<Scalars['String']>;
};

/** core/post-navigation-link block */
export type CorePostNavigationLinkBlock = Block & {
  __typename?: 'CorePostNavigationLinkBlock';
  attributes?: Maybe<CorePostNavigationLinkBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CorePostNavigationLinkBlockAttributes = {
  __typename?: 'CorePostNavigationLinkBlockAttributes';
  className?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  linkLabel: Scalars['Boolean'];
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  showTitle: Scalars['Boolean'];
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textAlign?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

/** core/post-template block */
export type CorePostTemplateBlock = Block & {
  __typename?: 'CorePostTemplateBlock';
  attributes?: Maybe<CorePostTemplateBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CorePostTemplateBlockAttributes = {
  __typename?: 'CorePostTemplateBlockAttributes';
  align?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  layout?: Maybe<Scalars['BlockAttributesObject']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
};

/** core/post-terms block */
export type CorePostTermsBlock = Block & {
  __typename?: 'CorePostTermsBlock';
  attributes?: Maybe<CorePostTermsBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CorePostTermsBlockAttributes = {
  __typename?: 'CorePostTermsBlockAttributes';
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  separator: Scalars['String'];
  style?: Maybe<Scalars['BlockAttributesObject']>;
  term?: Maybe<Scalars['String']>;
  textAlign?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
};

/** core/post-title block */
export type CorePostTitleBlock = Block & {
  __typename?: 'CorePostTitleBlock';
  attributes?: Maybe<CorePostTitleBlockAttributesUnion>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CorePostTitleBlockAttributes = {
  __typename?: 'CorePostTitleBlockAttributes';
  align?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontFamily?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  isLink: Scalars['Boolean'];
  level: Scalars['Float'];
  linkTarget: Scalars['String'];
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  rel: Scalars['String'];
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textAlign?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
};

export type CorePostTitleBlockAttributesUnion = CorePostTitleBlockAttributes | CorePostTitleBlockDeprecatedV1Attributes;

export type CorePostTitleBlockDeprecatedV1Attributes = {
  __typename?: 'CorePostTitleBlockDeprecatedV1Attributes';
  align?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontFamily?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  isLink: Scalars['Boolean'];
  level: Scalars['Float'];
  linkTarget: Scalars['String'];
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  rel: Scalars['String'];
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textAlign?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
};

/** core/preformatted block */
export type CorePreformattedBlock = Block & {
  __typename?: 'CorePreformattedBlock';
  attributes?: Maybe<CorePreformattedBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CorePreformattedBlockAttributes = {
  __typename?: 'CorePreformattedBlockAttributes';
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  content: Scalars['String'];
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
};

/** core/pullquote block */
export type CorePullquoteBlock = Block & {
  __typename?: 'CorePullquoteBlock';
  attributes?: Maybe<CorePullquoteBlockAttributesUnion>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CorePullquoteBlockAttributes = {
  __typename?: 'CorePullquoteBlockAttributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  borderColor?: Maybe<Scalars['String']>;
  citation: Scalars['String'];
  className?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textAlign?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type CorePullquoteBlockAttributesUnion = CorePullquoteBlockAttributes | CorePullquoteBlockDeprecatedV1Attributes | CorePullquoteBlockDeprecatedV2Attributes | CorePullquoteBlockDeprecatedV3Attributes | CorePullquoteBlockDeprecatedV4Attributes | CorePullquoteBlockDeprecatedV5Attributes;

export type CorePullquoteBlockDeprecatedV1Attributes = {
  __typename?: 'CorePullquoteBlockDeprecatedV1Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  borderColor?: Maybe<Scalars['String']>;
  citation: Scalars['String'];
  className?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textAlign?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type CorePullquoteBlockDeprecatedV2Attributes = {
  __typename?: 'CorePullquoteBlockDeprecatedV2Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  borderColor?: Maybe<Scalars['String']>;
  citation: Scalars['String'];
  className?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textAlign?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type CorePullquoteBlockDeprecatedV3Attributes = {
  __typename?: 'CorePullquoteBlockDeprecatedV3Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  borderColor?: Maybe<Scalars['String']>;
  citation: Scalars['String'];
  className?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textAlign?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type CorePullquoteBlockDeprecatedV4Attributes = {
  __typename?: 'CorePullquoteBlockDeprecatedV4Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  borderColor?: Maybe<Scalars['String']>;
  citation: Scalars['String'];
  className?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textAlign?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type CorePullquoteBlockDeprecatedV5Attributes = {
  __typename?: 'CorePullquoteBlockDeprecatedV5Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  borderColor?: Maybe<Scalars['String']>;
  citation: Scalars['String'];
  className?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textAlign?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** core/query block */
export type CoreQueryBlock = Block & {
  __typename?: 'CoreQueryBlock';
  attributes?: Maybe<CoreQueryBlockAttributesUnion>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreQueryBlockAttributes = {
  __typename?: 'CoreQueryBlockAttributes';
  align?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  displayLayout: Scalars['BlockAttributesObject'];
  gradient?: Maybe<Scalars['String']>;
  layout?: Maybe<Scalars['BlockAttributesObject']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  query: Scalars['BlockAttributesObject'];
  queryId?: Maybe<Scalars['Float']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  tagName: Scalars['String'];
  textColor?: Maybe<Scalars['String']>;
};

export type CoreQueryBlockAttributesUnion = CoreQueryBlockAttributes | CoreQueryBlockDeprecatedV1Attributes | CoreQueryBlockDeprecatedV2Attributes;

export type CoreQueryBlockDeprecatedV1Attributes = {
  __typename?: 'CoreQueryBlockDeprecatedV1Attributes';
  align?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  displayLayout: Scalars['BlockAttributesObject'];
  gradient?: Maybe<Scalars['String']>;
  layout?: Maybe<Scalars['BlockAttributesObject']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  query: Scalars['BlockAttributesObject'];
  queryId?: Maybe<Scalars['Float']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  tagName: Scalars['String'];
  textColor?: Maybe<Scalars['String']>;
};

export type CoreQueryBlockDeprecatedV2Attributes = {
  __typename?: 'CoreQueryBlockDeprecatedV2Attributes';
  align?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  displayLayout: Scalars['BlockAttributesObject'];
  gradient?: Maybe<Scalars['String']>;
  layout?: Maybe<Scalars['BlockAttributesObject']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  query: Scalars['BlockAttributesObject'];
  queryId?: Maybe<Scalars['Float']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  tagName: Scalars['String'];
  textColor?: Maybe<Scalars['String']>;
};

/** core/query-no-results block */
export type CoreQueryNoResultsBlock = Block & {
  __typename?: 'CoreQueryNoResultsBlock';
  attributes?: Maybe<CoreQueryNoResultsBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreQueryNoResultsBlockAttributes = {
  __typename?: 'CoreQueryNoResultsBlockAttributes';
  align?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
};

/** core/query-pagination block */
export type CoreQueryPaginationBlock = Block & {
  __typename?: 'CoreQueryPaginationBlock';
  attributes?: Maybe<CoreQueryPaginationBlockAttributesUnion>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreQueryPaginationBlockAttributes = {
  __typename?: 'CoreQueryPaginationBlockAttributes';
  align?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  layout?: Maybe<Scalars['BlockAttributesObject']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  paginationArrow: Scalars['String'];
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
};

export type CoreQueryPaginationBlockAttributesUnion = CoreQueryPaginationBlockAttributes | CoreQueryPaginationBlockDeprecatedV1Attributes;

export type CoreQueryPaginationBlockDeprecatedV1Attributes = {
  __typename?: 'CoreQueryPaginationBlockDeprecatedV1Attributes';
  align?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  layout?: Maybe<Scalars['BlockAttributesObject']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  paginationArrow: Scalars['String'];
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
};

/** core/query-pagination-next block */
export type CoreQueryPaginationNextBlock = Block & {
  __typename?: 'CoreQueryPaginationNextBlock';
  attributes?: Maybe<CoreQueryPaginationNextBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreQueryPaginationNextBlockAttributes = {
  __typename?: 'CoreQueryPaginationNextBlockAttributes';
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
};

/** core/query-pagination-numbers block */
export type CoreQueryPaginationNumbersBlock = Block & {
  __typename?: 'CoreQueryPaginationNumbersBlock';
  attributes?: Maybe<CoreQueryPaginationNumbersBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreQueryPaginationNumbersBlockAttributes = {
  __typename?: 'CoreQueryPaginationNumbersBlockAttributes';
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
};

/** core/query-pagination-previous block */
export type CoreQueryPaginationPreviousBlock = Block & {
  __typename?: 'CoreQueryPaginationPreviousBlock';
  attributes?: Maybe<CoreQueryPaginationPreviousBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreQueryPaginationPreviousBlockAttributes = {
  __typename?: 'CoreQueryPaginationPreviousBlockAttributes';
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
};

/** core/query-title block */
export type CoreQueryTitleBlock = Block & {
  __typename?: 'CoreQueryTitleBlock';
  attributes?: Maybe<CoreQueryTitleBlockAttributesUnion>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreQueryTitleBlockAttributes = {
  __typename?: 'CoreQueryTitleBlockAttributes';
  align?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontFamily?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  level: Scalars['Float'];
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textAlign?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type CoreQueryTitleBlockAttributesUnion = CoreQueryTitleBlockAttributes | CoreQueryTitleBlockDeprecatedV1Attributes;

export type CoreQueryTitleBlockDeprecatedV1Attributes = {
  __typename?: 'CoreQueryTitleBlockDeprecatedV1Attributes';
  align?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontFamily?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  level: Scalars['Float'];
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textAlign?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** core/quote block */
export type CoreQuoteBlock = Block & {
  __typename?: 'CoreQuoteBlock';
  attributes?: Maybe<CoreQuoteBlockAttributesUnion>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreQuoteBlockAttributes = {
  __typename?: 'CoreQuoteBlockAttributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  citation: Scalars['String'];
  className?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

export type CoreQuoteBlockAttributesUnion = CoreQuoteBlockAttributes | CoreQuoteBlockDeprecatedV1Attributes | CoreQuoteBlockDeprecatedV2Attributes | CoreQuoteBlockDeprecatedV3Attributes;

export type CoreQuoteBlockDeprecatedV1Attributes = {
  __typename?: 'CoreQuoteBlockDeprecatedV1Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  citation: Scalars['String'];
  className?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

export type CoreQuoteBlockDeprecatedV2Attributes = {
  __typename?: 'CoreQuoteBlockDeprecatedV2Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  citation: Scalars['String'];
  className?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

export type CoreQuoteBlockDeprecatedV3Attributes = {
  __typename?: 'CoreQuoteBlockDeprecatedV3Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  citation: Scalars['String'];
  className?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** core/read-more block */
export type CoreReadMoreBlock = Block & {
  __typename?: 'CoreReadMoreBlock';
  attributes?: Maybe<CoreReadMoreBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreReadMoreBlockAttributes = {
  __typename?: 'CoreReadMoreBlockAttributes';
  backgroundColor?: Maybe<Scalars['String']>;
  borderColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  fontFamily?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  linkTarget: Scalars['String'];
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
};

/** core/rss block */
export type CoreRssBlock = Block & {
  __typename?: 'CoreRssBlock';
  attributes?: Maybe<CoreRssBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreRssBlockAttributes = {
  __typename?: 'CoreRssBlockAttributes';
  align?: Maybe<Scalars['String']>;
  blockLayout: Scalars['String'];
  className?: Maybe<Scalars['String']>;
  columns: Scalars['Float'];
  displayAuthor: Scalars['Boolean'];
  displayDate: Scalars['Boolean'];
  displayExcerpt: Scalars['Boolean'];
  excerptLength: Scalars['Float'];
  feedURL: Scalars['String'];
  itemsToShow: Scalars['Float'];
  lock?: Maybe<Scalars['BlockAttributesObject']>;
};

/** core/search block */
export type CoreSearchBlock = Block & {
  __typename?: 'CoreSearchBlock';
  attributes?: Maybe<CoreSearchBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreSearchBlockAttributes = {
  __typename?: 'CoreSearchBlockAttributes';
  align?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  borderColor?: Maybe<Scalars['String']>;
  buttonPosition: Scalars['String'];
  buttonText?: Maybe<Scalars['String']>;
  buttonUseIcon: Scalars['Boolean'];
  className?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  placeholder: Scalars['String'];
  showLabel: Scalars['Boolean'];
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
  widthUnit?: Maybe<Scalars['String']>;
};

/** core/separator block */
export type CoreSeparatorBlock = Block & {
  __typename?: 'CoreSeparatorBlock';
  attributes?: Maybe<CoreSeparatorBlockAttributesUnion>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreSeparatorBlockAttributes = {
  __typename?: 'CoreSeparatorBlockAttributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  opacity: Scalars['String'];
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
};

export type CoreSeparatorBlockAttributesUnion = CoreSeparatorBlockAttributes | CoreSeparatorBlockDeprecatedV1Attributes;

export type CoreSeparatorBlockDeprecatedV1Attributes = {
  __typename?: 'CoreSeparatorBlockDeprecatedV1Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  opacity: Scalars['String'];
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
};

/** core/shortcode block */
export type CoreShortcodeBlock = Block & {
  __typename?: 'CoreShortcodeBlock';
  attributes?: Maybe<CoreShortcodeBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreShortcodeBlockAttributes = {
  __typename?: 'CoreShortcodeBlockAttributes';
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  text?: Maybe<Scalars['String']>;
};

/** core/site-logo block */
export type CoreSiteLogoBlock = Block & {
  __typename?: 'CoreSiteLogoBlock';
  attributes?: Maybe<CoreSiteLogoBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreSiteLogoBlockAttributes = {
  __typename?: 'CoreSiteLogoBlockAttributes';
  align?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  isLink: Scalars['Boolean'];
  linkTarget: Scalars['String'];
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  shouldSyncIcon?: Maybe<Scalars['Boolean']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  width?: Maybe<Scalars['Float']>;
};

/** core/site-tagline block */
export type CoreSiteTaglineBlock = Block & {
  __typename?: 'CoreSiteTaglineBlock';
  attributes?: Maybe<CoreSiteTaglineBlockAttributesUnion>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreSiteTaglineBlockAttributes = {
  __typename?: 'CoreSiteTaglineBlockAttributes';
  align?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontFamily?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textAlign?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
};

export type CoreSiteTaglineBlockAttributesUnion = CoreSiteTaglineBlockAttributes | CoreSiteTaglineBlockDeprecatedV1Attributes;

export type CoreSiteTaglineBlockDeprecatedV1Attributes = {
  __typename?: 'CoreSiteTaglineBlockDeprecatedV1Attributes';
  align?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontFamily?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textAlign?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
};

/** core/site-title block */
export type CoreSiteTitleBlock = Block & {
  __typename?: 'CoreSiteTitleBlock';
  attributes?: Maybe<CoreSiteTitleBlockAttributesUnion>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreSiteTitleBlockAttributes = {
  __typename?: 'CoreSiteTitleBlockAttributes';
  align?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontFamily?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  isLink: Scalars['Boolean'];
  level: Scalars['Float'];
  linkTarget: Scalars['String'];
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textAlign?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
};

export type CoreSiteTitleBlockAttributesUnion = CoreSiteTitleBlockAttributes | CoreSiteTitleBlockDeprecatedV1Attributes;

export type CoreSiteTitleBlockDeprecatedV1Attributes = {
  __typename?: 'CoreSiteTitleBlockDeprecatedV1Attributes';
  align?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontFamily?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  isLink: Scalars['Boolean'];
  level: Scalars['Float'];
  linkTarget: Scalars['String'];
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textAlign?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
};

/** core/social-link block */
export type CoreSocialLinkBlock = Block & {
  __typename?: 'CoreSocialLinkBlock';
  attributes?: Maybe<CoreSocialLinkBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreSocialLinkBlockAttributes = {
  __typename?: 'CoreSocialLinkBlockAttributes';
  className?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  service?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** core/social-links block */
export type CoreSocialLinksBlock = Block & {
  __typename?: 'CoreSocialLinksBlock';
  attributes?: Maybe<CoreSocialLinksBlockAttributesUnion>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreSocialLinksBlockAttributes = {
  __typename?: 'CoreSocialLinksBlockAttributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  customIconBackgroundColor?: Maybe<Scalars['String']>;
  customIconColor?: Maybe<Scalars['String']>;
  iconBackgroundColor?: Maybe<Scalars['String']>;
  iconBackgroundColorValue?: Maybe<Scalars['String']>;
  iconColor?: Maybe<Scalars['String']>;
  iconColorValue?: Maybe<Scalars['String']>;
  layout?: Maybe<Scalars['BlockAttributesObject']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  openInNewTab: Scalars['Boolean'];
  showLabels: Scalars['Boolean'];
  size?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
};

export type CoreSocialLinksBlockAttributesUnion = CoreSocialLinksBlockAttributes | CoreSocialLinksBlockDeprecatedV1Attributes;

export type CoreSocialLinksBlockDeprecatedV1Attributes = {
  __typename?: 'CoreSocialLinksBlockDeprecatedV1Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  customIconBackgroundColor?: Maybe<Scalars['String']>;
  customIconColor?: Maybe<Scalars['String']>;
  iconBackgroundColor?: Maybe<Scalars['String']>;
  iconBackgroundColorValue?: Maybe<Scalars['String']>;
  iconColor?: Maybe<Scalars['String']>;
  iconColorValue?: Maybe<Scalars['String']>;
  layout?: Maybe<Scalars['BlockAttributesObject']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  openInNewTab: Scalars['Boolean'];
  showLabels: Scalars['Boolean'];
  size?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
};

/** core/spacer block */
export type CoreSpacerBlock = Block & {
  __typename?: 'CoreSpacerBlock';
  attributes?: Maybe<CoreSpacerBlockAttributesUnion>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreSpacerBlockAttributes = {
  __typename?: 'CoreSpacerBlockAttributes';
  anchor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  height: Scalars['String'];
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  width?: Maybe<Scalars['String']>;
};

export type CoreSpacerBlockAttributesUnion = CoreSpacerBlockAttributes | CoreSpacerBlockDeprecatedV1Attributes;

export type CoreSpacerBlockDeprecatedV1Attributes = {
  __typename?: 'CoreSpacerBlockDeprecatedV1Attributes';
  anchor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  height: Scalars['String'];
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  width?: Maybe<Scalars['String']>;
};

/** core/table block */
export type CoreTableBlock = Block & {
  __typename?: 'CoreTableBlock';
  attributes?: Maybe<CoreTableBlockAttributesUnion>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreTableBlockAttributes = {
  __typename?: 'CoreTableBlockAttributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  body: Array<Maybe<CoreTableBlockAttributesBody>>;
  borderColor?: Maybe<Scalars['String']>;
  caption: Scalars['String'];
  className?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  foot: Array<Maybe<CoreTableBlockAttributesFoot>>;
  gradient?: Maybe<Scalars['String']>;
  hasFixedLayout: Scalars['Boolean'];
  head: Array<Maybe<CoreTableBlockAttributesHead>>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
};

export type CoreTableBlockAttributesBody = {
  __typename?: 'CoreTableBlockAttributesBody';
  cells: Array<Maybe<CoreTableBlockAttributesBodyCells>>;
};

export type CoreTableBlockAttributesBodyCells = {
  __typename?: 'CoreTableBlockAttributesBodyCells';
  align?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
  tag: Scalars['String'];
};

export type CoreTableBlockAttributesFoot = {
  __typename?: 'CoreTableBlockAttributesFoot';
  cells: Array<Maybe<CoreTableBlockAttributesFootCells>>;
};

export type CoreTableBlockAttributesFootCells = {
  __typename?: 'CoreTableBlockAttributesFootCells';
  align?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
  tag: Scalars['String'];
};

export type CoreTableBlockAttributesHead = {
  __typename?: 'CoreTableBlockAttributesHead';
  cells: Array<Maybe<CoreTableBlockAttributesHeadCells>>;
};

export type CoreTableBlockAttributesHeadCells = {
  __typename?: 'CoreTableBlockAttributesHeadCells';
  align?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
  tag: Scalars['String'];
};

export type CoreTableBlockAttributesUnion = CoreTableBlockAttributes | CoreTableBlockDeprecatedV1Attributes | CoreTableBlockDeprecatedV2Attributes;

export type CoreTableBlockDeprecatedV1Attributes = {
  __typename?: 'CoreTableBlockDeprecatedV1Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  body: Array<Maybe<CoreTableBlockDeprecatedV1AttributesBody>>;
  borderColor?: Maybe<Scalars['String']>;
  caption: Scalars['String'];
  className?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  foot: Array<Maybe<CoreTableBlockDeprecatedV1AttributesFoot>>;
  gradient?: Maybe<Scalars['String']>;
  hasFixedLayout: Scalars['Boolean'];
  head: Array<Maybe<CoreTableBlockDeprecatedV1AttributesHead>>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
};

export type CoreTableBlockDeprecatedV1AttributesBody = {
  __typename?: 'CoreTableBlockDeprecatedV1AttributesBody';
  cells: Array<Maybe<CoreTableBlockDeprecatedV1AttributesBodyCells>>;
};

export type CoreTableBlockDeprecatedV1AttributesBodyCells = {
  __typename?: 'CoreTableBlockDeprecatedV1AttributesBodyCells';
  align?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
  tag: Scalars['String'];
};

export type CoreTableBlockDeprecatedV1AttributesFoot = {
  __typename?: 'CoreTableBlockDeprecatedV1AttributesFoot';
  cells: Array<Maybe<CoreTableBlockDeprecatedV1AttributesFootCells>>;
};

export type CoreTableBlockDeprecatedV1AttributesFootCells = {
  __typename?: 'CoreTableBlockDeprecatedV1AttributesFootCells';
  align?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
  tag: Scalars['String'];
};

export type CoreTableBlockDeprecatedV1AttributesHead = {
  __typename?: 'CoreTableBlockDeprecatedV1AttributesHead';
  cells: Array<Maybe<CoreTableBlockDeprecatedV1AttributesHeadCells>>;
};

export type CoreTableBlockDeprecatedV1AttributesHeadCells = {
  __typename?: 'CoreTableBlockDeprecatedV1AttributesHeadCells';
  align?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
  tag: Scalars['String'];
};

export type CoreTableBlockDeprecatedV2Attributes = {
  __typename?: 'CoreTableBlockDeprecatedV2Attributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  body: Array<Maybe<CoreTableBlockDeprecatedV2AttributesBody>>;
  borderColor?: Maybe<Scalars['String']>;
  caption: Scalars['String'];
  className?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  foot: Array<Maybe<CoreTableBlockDeprecatedV2AttributesFoot>>;
  gradient?: Maybe<Scalars['String']>;
  hasFixedLayout: Scalars['Boolean'];
  head: Array<Maybe<CoreTableBlockDeprecatedV2AttributesHead>>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textColor?: Maybe<Scalars['String']>;
};

export type CoreTableBlockDeprecatedV2AttributesBody = {
  __typename?: 'CoreTableBlockDeprecatedV2AttributesBody';
  cells: Array<Maybe<CoreTableBlockDeprecatedV2AttributesBodyCells>>;
};

export type CoreTableBlockDeprecatedV2AttributesBodyCells = {
  __typename?: 'CoreTableBlockDeprecatedV2AttributesBodyCells';
  align?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
  tag: Scalars['String'];
};

export type CoreTableBlockDeprecatedV2AttributesFoot = {
  __typename?: 'CoreTableBlockDeprecatedV2AttributesFoot';
  cells: Array<Maybe<CoreTableBlockDeprecatedV2AttributesFootCells>>;
};

export type CoreTableBlockDeprecatedV2AttributesFootCells = {
  __typename?: 'CoreTableBlockDeprecatedV2AttributesFootCells';
  align?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
  tag: Scalars['String'];
};

export type CoreTableBlockDeprecatedV2AttributesHead = {
  __typename?: 'CoreTableBlockDeprecatedV2AttributesHead';
  cells: Array<Maybe<CoreTableBlockDeprecatedV2AttributesHeadCells>>;
};

export type CoreTableBlockDeprecatedV2AttributesHeadCells = {
  __typename?: 'CoreTableBlockDeprecatedV2AttributesHeadCells';
  align?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
  tag: Scalars['String'];
};

/** core/tag-cloud block */
export type CoreTagCloudBlock = Block & {
  __typename?: 'CoreTagCloudBlock';
  attributes?: Maybe<CoreTagCloudBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreTagCloudBlockAttributes = {
  __typename?: 'CoreTagCloudBlockAttributes';
  align?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  largestFontSize: Scalars['String'];
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  numberOfTags: Scalars['Float'];
  showTagCounts: Scalars['Boolean'];
  smallestFontSize: Scalars['String'];
  taxonomy: Scalars['String'];
};

/** core/template-part block */
export type CoreTemplatePartBlock = Block & {
  __typename?: 'CoreTemplatePartBlock';
  attributes?: Maybe<CoreTemplatePartBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreTemplatePartBlockAttributes = {
  __typename?: 'CoreTemplatePartBlockAttributes';
  align?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  slug?: Maybe<Scalars['String']>;
  tagName?: Maybe<Scalars['String']>;
  theme?: Maybe<Scalars['String']>;
};

/** core/term-description block */
export type CoreTermDescriptionBlock = Block & {
  __typename?: 'CoreTermDescriptionBlock';
  attributes?: Maybe<CoreTermDescriptionBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreTermDescriptionBlockAttributes = {
  __typename?: 'CoreTermDescriptionBlockAttributes';
  align?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textAlign?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
};

/** core/text-columns block */
export type CoreTextColumnsBlock = Block & {
  __typename?: 'CoreTextColumnsBlock';
  attributes?: Maybe<CoreTextColumnsBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreTextColumnsBlockAttributes = {
  __typename?: 'CoreTextColumnsBlockAttributes';
  className?: Maybe<Scalars['String']>;
  columns: Scalars['Float'];
  content: Array<Maybe<CoreTextColumnsBlockAttributesContent>>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  width?: Maybe<Scalars['String']>;
};

export type CoreTextColumnsBlockAttributesContent = {
  __typename?: 'CoreTextColumnsBlockAttributesContent';
  children?: Maybe<Scalars['String']>;
};

/** core/verse block */
export type CoreVerseBlock = Block & {
  __typename?: 'CoreVerseBlock';
  attributes?: Maybe<CoreVerseBlockAttributesUnion>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreVerseBlockAttributes = {
  __typename?: 'CoreVerseBlockAttributes';
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  content: Scalars['String'];
  fontFamily?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textAlign?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
};

export type CoreVerseBlockAttributesUnion = CoreVerseBlockAttributes | CoreVerseBlockDeprecatedV1Attributes | CoreVerseBlockDeprecatedV2Attributes;

export type CoreVerseBlockDeprecatedV1Attributes = {
  __typename?: 'CoreVerseBlockDeprecatedV1Attributes';
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  content: Scalars['String'];
  fontFamily?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textAlign?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
};

export type CoreVerseBlockDeprecatedV2Attributes = {
  __typename?: 'CoreVerseBlockDeprecatedV2Attributes';
  anchor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  content: Scalars['String'];
  fontFamily?: Maybe<Scalars['String']>;
  fontSize?: Maybe<Scalars['String']>;
  gradient?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  style?: Maybe<Scalars['BlockAttributesObject']>;
  textAlign?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
};

/** core/video block */
export type CoreVideoBlock = Block & {
  __typename?: 'CoreVideoBlock';
  attributes?: Maybe<CoreVideoBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type CoreVideoBlockAttributes = {
  __typename?: 'CoreVideoBlockAttributes';
  align?: Maybe<Scalars['String']>;
  anchor?: Maybe<Scalars['String']>;
  autoplay?: Maybe<Scalars['Boolean']>;
  caption?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  controls: Scalars['Boolean'];
  id?: Maybe<Scalars['Float']>;
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  loop?: Maybe<Scalars['Boolean']>;
  muted?: Maybe<Scalars['Boolean']>;
  playsInline?: Maybe<Scalars['Boolean']>;
  poster?: Maybe<Scalars['String']>;
  preload: Scalars['String'];
  src?: Maybe<Scalars['String']>;
  tracks: Array<Maybe<Scalars['BlockAttributesObject']>>;
};

/** Input for the createAccount_manager mutation */
export type CreateAccount_ManagerInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']>;
  /** Set connections between the account_manager and regions */
  regions?: InputMaybe<Account_ManagerRegionsInput>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** The payload for the createAccount_manager mutation */
export type CreateAccount_ManagerPayload = {
  __typename?: 'CreateAccount_managerPayload';
  /** The Post object mutation type. */
  account_manager?: Maybe<Account_Manager>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Input for the createActionMonitorAction mutation */
export type CreateActionMonitorActionInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The content of the object */
  content?: InputMaybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** The payload for the createActionMonitorAction mutation */
export type CreateActionMonitorActionPayload = {
  __typename?: 'CreateActionMonitorActionPayload';
  /** The Post object mutation type. */
  actionMonitorAction?: Maybe<ActionMonitorAction>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Input for the createAreaOfSpecialism mutation */
export type CreateAreaOfSpecialismInput = {
  /** The slug that the member_specialism will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The description of the member_specialism object */
  description?: InputMaybe<Scalars['String']>;
  /** The name of the member_specialism object to mutate */
  name: Scalars['String'];
  /** The ID of the member_specialism that should be set as the parent */
  parentId?: InputMaybe<Scalars['ID']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']>;
};

/** The payload for the createAreaOfSpecialism mutation */
export type CreateAreaOfSpecialismPayload = {
  __typename?: 'CreateAreaOfSpecialismPayload';
  /** The created member_specialism */
  areaOfSpecialism?: Maybe<AreaOfSpecialism>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Input for the createCategory mutation */
export type CreateCategoryInput = {
  /** The slug that the category will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The description of the category object */
  description?: InputMaybe<Scalars['String']>;
  /** The name of the category object to mutate */
  name: Scalars['String'];
  /** The ID of the category that should be set as the parent */
  parentId?: InputMaybe<Scalars['ID']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']>;
};

/** The payload for the createCategory mutation */
export type CreateCategoryPayload = {
  __typename?: 'CreateCategoryPayload';
  /** The created category */
  category?: Maybe<Category>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Input for the createComment mutation */
export type CreateCommentInput = {
  /** The approval status of the comment. */
  approved?: InputMaybe<Scalars['String']>;
  /** The name of the comment's author. */
  author?: InputMaybe<Scalars['String']>;
  /** The email of the comment's author. */
  authorEmail?: InputMaybe<Scalars['String']>;
  /** The url of the comment's author. */
  authorUrl?: InputMaybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The database ID of the post object the comment belongs to. */
  commentOn?: InputMaybe<Scalars['Int']>;
  /** Content of the comment. */
  content?: InputMaybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day ( e.g. 01/31/2017 ) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']>;
  /** Parent comment ID of current comment. */
  parent?: InputMaybe<Scalars['ID']>;
  /** Type of comment. */
  type?: InputMaybe<Scalars['String']>;
};

/** The payload for the createComment mutation */
export type CreateCommentPayload = {
  __typename?: 'CreateCommentPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The comment that was created */
  comment?: Maybe<Comment>;
  /** Whether the mutation succeeded. If the comment is not approved, the server will not return the comment to a non authenticated user, but a success message can be returned if the create succeeded, and the client can optimistically add the comment to the client cache */
  success?: Maybe<Scalars['Boolean']>;
};

/** Input for the createGroup mutation */
export type CreateGroupInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The content of the object */
  content?: InputMaybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** The payload for the createGroup mutation */
export type CreateGroupPayload = {
  __typename?: 'CreateGroupPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The Post object mutation type. */
  group?: Maybe<Group>;
};

/** Input for the createMediaItem mutation */
export type CreateMediaItemInput = {
  /** Alternative text to display when mediaItem is not displayed */
  altText?: InputMaybe<Scalars['String']>;
  /** The userId to assign as the author of the mediaItem */
  authorId?: InputMaybe<Scalars['ID']>;
  /** The caption for the mediaItem */
  caption?: InputMaybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The comment status for the mediaItem */
  commentStatus?: InputMaybe<Scalars['String']>;
  /** The date of the mediaItem */
  date?: InputMaybe<Scalars['String']>;
  /** The date (in GMT zone) of the mediaItem */
  dateGmt?: InputMaybe<Scalars['String']>;
  /** Description of the mediaItem */
  description?: InputMaybe<Scalars['String']>;
  /** The file name of the mediaItem */
  filePath?: InputMaybe<Scalars['String']>;
  /** The file type of the mediaItem */
  fileType?: InputMaybe<MimeTypeEnum>;
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars['ID']>;
  /** The ping status for the mediaItem */
  pingStatus?: InputMaybe<Scalars['String']>;
  /** The slug of the mediaItem */
  slug?: InputMaybe<Scalars['String']>;
  /** The status of the mediaItem */
  status?: InputMaybe<MediaItemStatusEnum>;
  /** The title of the mediaItem */
  title?: InputMaybe<Scalars['String']>;
};

/** The payload for the createMediaItem mutation */
export type CreateMediaItemPayload = {
  __typename?: 'CreateMediaItemPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The MediaItem object mutation type. */
  mediaItem?: Maybe<MediaItem>;
};

/** Input for the createMember mutation */
export type CreateMemberInput = {
  /** Set connections between the member and areasOfSpecialism */
  areasOfSpecialism?: InputMaybe<MemberAreasOfSpecialismInput>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']>;
  /** Set connections between the member and tradingTimes */
  tradingTimes?: InputMaybe<MemberTradingTimesInput>;
};

/** The payload for the createMember mutation */
export type CreateMemberPayload = {
  __typename?: 'CreateMemberPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The Post object mutation type. */
  member?: Maybe<Member>;
};

/** Input for the createPage mutation */
export type CreatePageInput = {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars['ID']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The content of the object */
  content?: InputMaybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']>;
  /** The excerpt of the object */
  excerpt?: InputMaybe<Scalars['String']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']>;
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars['ID']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** The payload for the createPage mutation */
export type CreatePagePayload = {
  __typename?: 'CreatePagePayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The Post object mutation type. */
  page?: Maybe<Page>;
};

/** Input for the createPostFormat mutation */
export type CreatePostFormatInput = {
  /** The slug that the post_format will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The description of the post_format object */
  description?: InputMaybe<Scalars['String']>;
  /** The name of the post_format object to mutate */
  name: Scalars['String'];
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']>;
};

/** The payload for the createPostFormat mutation */
export type CreatePostFormatPayload = {
  __typename?: 'CreatePostFormatPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The created post_format */
  postFormat?: Maybe<PostFormat>;
};

/** Input for the createPost mutation */
export type CreatePostInput = {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars['ID']>;
  /** Set connections between the post and categories */
  categories?: InputMaybe<PostCategoriesInput>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The content of the object */
  content?: InputMaybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']>;
  /** The excerpt of the object */
  excerpt?: InputMaybe<Scalars['String']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']>;
  /** The ping status for the object */
  pingStatus?: InputMaybe<Scalars['String']>;
  /** URLs that have been pinged. */
  pinged?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Set connections between the post and postFormats */
  postFormats?: InputMaybe<PostPostFormatsInput>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** Set connections between the post and tags */
  tags?: InputMaybe<PostTagsInput>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']>;
  /** URLs queued to be pinged. */
  toPing?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** The payload for the createPost mutation */
export type CreatePostPayload = {
  __typename?: 'CreatePostPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The Post object mutation type. */
  post?: Maybe<Post>;
};

/** Input for the createRegion mutation */
export type CreateRegionInput = {
  /** The slug that the account_manager_region will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The description of the account_manager_region object */
  description?: InputMaybe<Scalars['String']>;
  /** The name of the account_manager_region object to mutate */
  name: Scalars['String'];
  /** The ID of the account_manager_region that should be set as the parent */
  parentId?: InputMaybe<Scalars['ID']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']>;
};

/** The payload for the createRegion mutation */
export type CreateRegionPayload = {
  __typename?: 'CreateRegionPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The created account_manager_region */
  region?: Maybe<Region>;
};

/** Input for the createReusableBlock mutation */
export type CreateReusableBlockInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The content of the object */
  content?: InputMaybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** The payload for the createReusableBlock mutation */
export type CreateReusableBlockPayload = {
  __typename?: 'CreateReusableBlockPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The Post object mutation type. */
  reusableBlock?: Maybe<ReusableBlock>;
};

/** Input for the createReview mutation */
export type CreateReviewInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The content of the object */
  content?: InputMaybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** The payload for the createReview mutation */
export type CreateReviewPayload = {
  __typename?: 'CreateReviewPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The Post object mutation type. */
  review?: Maybe<Review>;
};

/** Input for the createSupplierCategory mutation */
export type CreateSupplierCategoryInput = {
  /** The slug that the supplier_category will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The description of the supplier_category object */
  description?: InputMaybe<Scalars['String']>;
  /** The name of the supplier_category object to mutate */
  name: Scalars['String'];
  /** The ID of the supplier_category that should be set as the parent */
  parentId?: InputMaybe<Scalars['ID']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']>;
};

/** The payload for the createSupplierCategory mutation */
export type CreateSupplierCategoryPayload = {
  __typename?: 'CreateSupplierCategoryPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The created supplier_category */
  supplierCategory?: Maybe<SupplierCategory>;
};

/** Input for the createSupplier mutation */
export type CreateSupplierInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** Set connections between the supplier and supplierCategories */
  supplierCategories?: InputMaybe<SupplierSupplierCategoriesInput>;
  /** Set connections between the supplier and supplierKeywords */
  supplierKeywords?: InputMaybe<SupplierSupplierKeywordsInput>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** Input for the createSupplierKeyword mutation */
export type CreateSupplierKeywordInput = {
  /** The slug that the supplier_keywords will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The description of the supplier_keywords object */
  description?: InputMaybe<Scalars['String']>;
  /** The name of the supplier_keywords object to mutate */
  name: Scalars['String'];
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']>;
};

/** The payload for the createSupplierKeyword mutation */
export type CreateSupplierKeywordPayload = {
  __typename?: 'CreateSupplierKeywordPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The created supplier_keywords */
  supplierKeyword?: Maybe<SupplierKeyword>;
};

/** The payload for the createSupplier mutation */
export type CreateSupplierPayload = {
  __typename?: 'CreateSupplierPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The Post object mutation type. */
  supplier?: Maybe<Supplier>;
};

/** Input for the createSupplier_news mutation */
export type CreateSupplier_NewsInput = {
  /** Supplier News approval status */
  approvalStatus?: InputMaybe<Scalars['Boolean']>;
  /** Supplier News category */
  category?: InputMaybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']>;
  /** Supplier News documents */
  documents?: InputMaybe<Scalars['String']>;
  /** Supplier News document titles */
  documentsTitles?: InputMaybe<Scalars['String']>;
  /** Supplier News expiry date */
  expiryDate?: InputMaybe<Scalars['String']>;
  /** Supplier News header url link */
  headerUrlLink?: InputMaybe<Scalars['String']>;
  /** Supplier News image */
  image?: InputMaybe<Scalars['String']>;
  /** Supplier News review status */
  inReview?: InputMaybe<Scalars['Boolean']>;
  /** Supplier News linked supplier */
  linkedSupplier?: InputMaybe<Scalars['ID']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']>;
  /** Supplier News post content */
  postContent?: InputMaybe<Scalars['String']>;
  /** Supplier News published status */
  publishedStatus?: InputMaybe<Scalars['Boolean']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']>;
  /** Supplier News video url */
  videoUrl?: InputMaybe<Scalars['String']>;
};

/** The payload for the createSupplier_news mutation */
export type CreateSupplier_NewsPayload = {
  __typename?: 'CreateSupplier_newsPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The Post object mutation type. */
  supplier_news?: Maybe<Supplier_News>;
};

/** Input for the createTag mutation */
export type CreateTagInput = {
  /** The slug that the post_tag will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The description of the post_tag object */
  description?: InputMaybe<Scalars['String']>;
  /** The name of the post_tag object to mutate */
  name: Scalars['String'];
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']>;
};

/** The payload for the createTag mutation */
export type CreateTagPayload = {
  __typename?: 'CreateTagPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The created post_tag */
  tag?: Maybe<Tag>;
};

/** Input for the createTestimonial mutation */
export type CreateTestimonialInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** The payload for the createTestimonial mutation */
export type CreateTestimonialPayload = {
  __typename?: 'CreateTestimonialPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The Post object mutation type. */
  testimonial?: Maybe<Testimonial>;
};

/** Input for the createTradingTime mutation */
export type CreateTradingTimeInput = {
  /** The slug that the member_trading_time will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The description of the member_trading_time object */
  description?: InputMaybe<Scalars['String']>;
  /** The name of the member_trading_time object to mutate */
  name: Scalars['String'];
  /** The ID of the member_trading_time that should be set as the parent */
  parentId?: InputMaybe<Scalars['ID']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']>;
};

/** The payload for the createTradingTime mutation */
export type CreateTradingTimePayload = {
  __typename?: 'CreateTradingTimePayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The created member_trading_time */
  tradingTime?: Maybe<TradingTime>;
};

/** Input for the createUser mutation */
export type CreateUserInput = {
  /** User's AOL IM account. */
  aim?: InputMaybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** A string containing content about the user. */
  description?: InputMaybe<Scalars['String']>;
  /** A string that will be shown on the site. Defaults to user's username. It is likely that you will want to change this, for both appearance and security through obscurity (that is if you dont use and delete the default admin user). */
  displayName?: InputMaybe<Scalars['String']>;
  /** A string containing the user's email address. */
  email?: InputMaybe<Scalars['String']>;
  /** 	The user's first name. */
  firstName?: InputMaybe<Scalars['String']>;
  /** User's Jabber account. */
  jabber?: InputMaybe<Scalars['String']>;
  /** The user's last name. */
  lastName?: InputMaybe<Scalars['String']>;
  /** User's locale. */
  locale?: InputMaybe<Scalars['String']>;
  /** A string that contains a URL-friendly name for the user. The default is the user's username. */
  nicename?: InputMaybe<Scalars['String']>;
  /** The user's nickname, defaults to the user's username. */
  nickname?: InputMaybe<Scalars['String']>;
  /** A string that contains the plain text password for the user. */
  password?: InputMaybe<Scalars['String']>;
  /** If true, this will refresh the users JWT secret. */
  refreshJwtUserSecret?: InputMaybe<Scalars['Boolean']>;
  /** The date the user registered. Format is Y-m-d H:i:s. */
  registered?: InputMaybe<Scalars['String']>;
  /** If true, this will revoke the users JWT secret. If false, this will unrevoke the JWT secret AND issue a new one. To revoke, the user must have proper capabilities to edit users JWT secrets. */
  revokeJwtUserSecret?: InputMaybe<Scalars['Boolean']>;
  /** A string for whether to enable the rich editor or not. False if not empty. */
  richEditing?: InputMaybe<Scalars['String']>;
  /** An array of roles to be assigned to the user. */
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** A string that contains the user's username for logging in. */
  username: Scalars['String'];
  /** A string containing the user's URL for the user's web site. */
  websiteUrl?: InputMaybe<Scalars['String']>;
  /** User's Yahoo IM account. */
  yim?: InputMaybe<Scalars['String']>;
};

/** The payload for the createUser mutation */
export type CreateUserPayload = {
  __typename?: 'CreateUserPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The User object mutation type. */
  user?: Maybe<User>;
};

/** Object that can be identified with a Database ID */
export type DatabaseIdentifier = {
  /** The unique identifier stored in the database */
  databaseId: Scalars['Int'];
};

/** A Gravity Forms   field. */
export type DateField = FormField & {
  __typename?: 'DateField';
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** Determines how the date field displays it’s calendar icon. */
  calendarIconType?: Maybe<FormFieldCalendarIconTypeEnum>;
  /** Contains the URL to the custom calendar icon. Only applicable when calendarIconType is set to custom. */
  calendarIconUrl?: Maybe<Scalars['String']>;
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Determines how the date is displayed. */
  dateFormat?: Maybe<DateFieldFormatEnum>;
  /** The type of date field to display. */
  dateType?: Maybe<DateFieldTypeEnum>;
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']>;
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** An array of the available properties for each input of the date field. */
  inputs?: Maybe<Array<Maybe<DateInputProperty>>>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']>;
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']>;
  /** The placement of the labels for the subfields within the group. This setting controls all of the subfields, they cannot be set individually. They may be aligned above or below the inputs. If this property is not set, the “Sub-Label Placement” setting on the Form Settings-&gt;Form Layout page is used. If no setting is specified, the default is above inputs. */
  subLabelPlacement?: Maybe<FormFieldSubLabelPlacementEnum>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** How the DateField date is displayed. */
export enum DateFieldFormatEnum {
  /** dd/mm/yyyy format. */
  Dmy = 'DMY',
  /** dd-mm-yyyy format. */
  DmyDash = 'DMY_DASH',
  /** dd.mm.yyyy format. */
  DmyDot = 'DMY_DOT',
  /** mm/dd/yyyy format. */
  Mdy = 'MDY',
  /** yyyy/mm/dd format. */
  YmdDash = 'YMD_DASH',
  /** yyyy.mm.dd format. */
  YmdDot = 'YMD_DOT',
  /** yyyy/mm/dd format. */
  YmdSlash = 'YMD_SLASH'
}

/** Type of date field to display. */
export enum DateFieldTypeEnum {
  /** A date dropdown. */
  Dropdown = 'DROPDOWN',
  /** A simple date field. */
  Field = 'FIELD',
  /** A datepicker. */
  Picker = 'PICKER'
}

/** Date values */
export type DateInput = {
  /** Day of the month (from 1 to 31) */
  day?: InputMaybe<Scalars['Int']>;
  /** Month number (from 1 to 12) */
  month?: InputMaybe<Scalars['Int']>;
  /** 4 digit year (e.g. 2017) */
  year?: InputMaybe<Scalars['Int']>;
};

/** Date input values. */
export type DateInputProperty = {
  __typename?: 'DateInputProperty';
  /** The autocomplete attribute for the field. */
  autocompleteAttribute?: Maybe<Scalars['String']>;
  /** The custom label for the input. When set, this is used in place of the label. */
  customLabel?: Maybe<Scalars['String']>;
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']>;
  /** The input ID. Input IDs follow the following naming convention: FIELDID.INPUTID (i.e. 5.1), where FIELDID is the id of the containing field and INPUTID specifies the input field. */
  id?: Maybe<Scalars['Float']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']>;
};

/** Filter the connection based on input */
export type DateQueryInput = {
  /** Nodes should be returned after this date */
  after?: InputMaybe<DateInput>;
  /** Nodes should be returned before this date */
  before?: InputMaybe<DateInput>;
  /** Column to query against */
  column?: InputMaybe<PostObjectsConnectionDateColumnEnum>;
  /** For after/before, whether exact value should be matched or not */
  compare?: InputMaybe<Scalars['String']>;
  /** Day of the month (from 1 to 31) */
  day?: InputMaybe<Scalars['Int']>;
  /** Hour (from 0 to 23) */
  hour?: InputMaybe<Scalars['Int']>;
  /** For after/before, whether exact value should be matched or not */
  inclusive?: InputMaybe<Scalars['Boolean']>;
  /** Minute (from 0 to 59) */
  minute?: InputMaybe<Scalars['Int']>;
  /** Month number (from 1 to 12) */
  month?: InputMaybe<Scalars['Int']>;
  /** OR or AND, how the sub-arrays should be compared */
  relation?: InputMaybe<RelationEnum>;
  /** Second (0 to 59) */
  second?: InputMaybe<Scalars['Int']>;
  /** Week of the year (from 0 to 53) */
  week?: InputMaybe<Scalars['Int']>;
  /** 4 digit year (e.g. 2017) */
  year?: InputMaybe<Scalars['Int']>;
};

/** The template assigned to the node */
export type DefaultTemplate = ContentTemplate & {
  __typename?: 'DefaultTemplate';
  /** The name of the template */
  templateName?: Maybe<Scalars['String']>;
};

/** Input for the deleteAccount_manager mutation */
export type DeleteAccount_ManagerInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the account_manager to delete */
  id: Scalars['ID'];
};

/** The payload for the deleteAccount_manager mutation */
export type DeleteAccount_ManagerPayload = {
  __typename?: 'DeleteAccount_managerPayload';
  /** The object before it was deleted */
  account_manager?: Maybe<Account_Manager>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']>;
};

/** Input for the deleteActionMonitorAction mutation */
export type DeleteActionMonitorActionInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the ActionMonitorAction to delete */
  id: Scalars['ID'];
};

/** The payload for the deleteActionMonitorAction mutation */
export type DeleteActionMonitorActionPayload = {
  __typename?: 'DeleteActionMonitorActionPayload';
  /** The object before it was deleted */
  actionMonitorAction?: Maybe<ActionMonitorAction>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']>;
};

/** Input for the deleteAreaOfSpecialism mutation */
export type DeleteAreaOfSpecialismInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The ID of the areaOfSpecialism to delete */
  id: Scalars['ID'];
};

/** The payload for the deleteAreaOfSpecialism mutation */
export type DeleteAreaOfSpecialismPayload = {
  __typename?: 'DeleteAreaOfSpecialismPayload';
  /** The deteted term object */
  areaOfSpecialism?: Maybe<AreaOfSpecialism>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']>;
};

/** Input for the deleteCategory mutation */
export type DeleteCategoryInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The ID of the category to delete */
  id: Scalars['ID'];
};

/** The payload for the deleteCategory mutation */
export type DeleteCategoryPayload = {
  __typename?: 'DeleteCategoryPayload';
  /** The deteted term object */
  category?: Maybe<Category>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']>;
};

/** Input for the deleteComment mutation */
export type DeleteCommentInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Whether the comment should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']>;
  /** The deleted comment ID */
  id: Scalars['ID'];
};

/** The payload for the deleteComment mutation */
export type DeleteCommentPayload = {
  __typename?: 'DeleteCommentPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The deleted comment object */
  comment?: Maybe<Comment>;
  /** The deleted comment ID */
  deletedId?: Maybe<Scalars['ID']>;
};

/** Input for the deleteGfDraftEntry mutation */
export type DeleteGfDraftEntryInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Either the global ID of the draft entry, or its resume token. */
  id: Scalars['ID'];
  /** The ID type for the draft entry. Defaults to `ID` . */
  idType?: InputMaybe<DraftEntryIdTypeEnum>;
};

/** The payload for the deleteGfDraftEntry mutation */
export type DeleteGfDraftEntryPayload = {
  __typename?: 'DeleteGfDraftEntryPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The global ID of the draft entry that was deleted. */
  deletedId?: Maybe<Scalars['ID']>;
  /** The draft entry object before it was deleted. */
  draftEntry?: Maybe<GfDraftEntry>;
};

/** Input for the deleteGfEntry mutation */
export type DeleteGfEntryInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Whether the entry should be force deleted instead of being moved to the trash. */
  forceDelete?: InputMaybe<Scalars['Boolean']>;
  /** ID of the entry to delete, either a global or database ID. */
  id: Scalars['ID'];
};

/** The payload for the deleteGfEntry mutation */
export type DeleteGfEntryPayload = {
  __typename?: 'DeleteGfEntryPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The global ID of the draft entry that was deleted. */
  deletedId?: Maybe<Scalars['ID']>;
  /** The entry object before it was deleted. */
  entry?: Maybe<GfSubmittedEntry>;
};

/** Input for the deleteGroup mutation */
export type DeleteGroupInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the group to delete */
  id: Scalars['ID'];
};

/** The payload for the deleteGroup mutation */
export type DeleteGroupPayload = {
  __typename?: 'DeleteGroupPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']>;
  /** The object before it was deleted */
  group?: Maybe<Group>;
};

/** Input for the deleteMediaItem mutation */
export type DeleteMediaItemInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Whether the mediaItem should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the mediaItem to delete */
  id: Scalars['ID'];
};

/** The payload for the deleteMediaItem mutation */
export type DeleteMediaItemPayload = {
  __typename?: 'DeleteMediaItemPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the deleted mediaItem */
  deletedId?: Maybe<Scalars['ID']>;
  /** The mediaItem before it was deleted */
  mediaItem?: Maybe<MediaItem>;
};

/** Input for the deleteMember mutation */
export type DeleteMemberInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the member to delete */
  id: Scalars['ID'];
};

/** The payload for the deleteMember mutation */
export type DeleteMemberPayload = {
  __typename?: 'DeleteMemberPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']>;
  /** The object before it was deleted */
  member?: Maybe<Member>;
};

/** Input for the deletePage mutation */
export type DeletePageInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the page to delete */
  id: Scalars['ID'];
};

/** The payload for the deletePage mutation */
export type DeletePagePayload = {
  __typename?: 'DeletePagePayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']>;
  /** The object before it was deleted */
  page?: Maybe<Page>;
};

/** Input for the deletePostFormat mutation */
export type DeletePostFormatInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The ID of the postFormat to delete */
  id: Scalars['ID'];
};

/** The payload for the deletePostFormat mutation */
export type DeletePostFormatPayload = {
  __typename?: 'DeletePostFormatPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']>;
  /** The deteted term object */
  postFormat?: Maybe<PostFormat>;
};

/** Input for the deletePost mutation */
export type DeletePostInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the post to delete */
  id: Scalars['ID'];
};

/** The payload for the deletePost mutation */
export type DeletePostPayload = {
  __typename?: 'DeletePostPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']>;
  /** The object before it was deleted */
  post?: Maybe<Post>;
};

/** Input for the deleteRegion mutation */
export type DeleteRegionInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The ID of the region to delete */
  id: Scalars['ID'];
};

/** The payload for the deleteRegion mutation */
export type DeleteRegionPayload = {
  __typename?: 'DeleteRegionPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']>;
  /** The deteted term object */
  region?: Maybe<Region>;
};

/** Input for the deleteReusableBlock mutation */
export type DeleteReusableBlockInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the ReusableBlock to delete */
  id: Scalars['ID'];
};

/** The payload for the deleteReusableBlock mutation */
export type DeleteReusableBlockPayload = {
  __typename?: 'DeleteReusableBlockPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']>;
  /** The object before it was deleted */
  reusableBlock?: Maybe<ReusableBlock>;
};

/** Input for the deleteReview mutation */
export type DeleteReviewInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the review to delete */
  id: Scalars['ID'];
};

/** The payload for the deleteReview mutation */
export type DeleteReviewPayload = {
  __typename?: 'DeleteReviewPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']>;
  /** The object before it was deleted */
  review?: Maybe<Review>;
};

/** Input for the deleteSupplierCategory mutation */
export type DeleteSupplierCategoryInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The ID of the supplierCategory to delete */
  id: Scalars['ID'];
};

/** The payload for the deleteSupplierCategory mutation */
export type DeleteSupplierCategoryPayload = {
  __typename?: 'DeleteSupplierCategoryPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']>;
  /** The deteted term object */
  supplierCategory?: Maybe<SupplierCategory>;
};

/** Input for the deleteSupplier mutation */
export type DeleteSupplierInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the supplier to delete */
  id: Scalars['ID'];
};

/** Input for the deleteSupplierKeyword mutation */
export type DeleteSupplierKeywordInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The ID of the supplierKeyword to delete */
  id: Scalars['ID'];
};

/** The payload for the deleteSupplierKeyword mutation */
export type DeleteSupplierKeywordPayload = {
  __typename?: 'DeleteSupplierKeywordPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']>;
  /** The deteted term object */
  supplierKeyword?: Maybe<SupplierKeyword>;
};

/** The payload for the deleteSupplier mutation */
export type DeleteSupplierPayload = {
  __typename?: 'DeleteSupplierPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']>;
  /** The object before it was deleted */
  supplier?: Maybe<Supplier>;
};

/** Input for the deleteSupplier_news mutation */
export type DeleteSupplier_NewsInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the supplier_news to delete */
  id: Scalars['ID'];
};

/** The payload for the deleteSupplier_news mutation */
export type DeleteSupplier_NewsPayload = {
  __typename?: 'DeleteSupplier_newsPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']>;
  /** The object before it was deleted */
  supplier_news?: Maybe<Supplier_News>;
};

/** Input for the deleteTag mutation */
export type DeleteTagInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The ID of the tag to delete */
  id: Scalars['ID'];
};

/** The payload for the deleteTag mutation */
export type DeleteTagPayload = {
  __typename?: 'DeleteTagPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']>;
  /** The deteted term object */
  tag?: Maybe<Tag>;
};

/** Input for the deleteTestimonial mutation */
export type DeleteTestimonialInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the testimonial to delete */
  id: Scalars['ID'];
};

/** The payload for the deleteTestimonial mutation */
export type DeleteTestimonialPayload = {
  __typename?: 'DeleteTestimonialPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']>;
  /** The object before it was deleted */
  testimonial?: Maybe<Testimonial>;
};

/** Input for the deleteTradingTime mutation */
export type DeleteTradingTimeInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The ID of the tradingTime to delete */
  id: Scalars['ID'];
};

/** The payload for the deleteTradingTime mutation */
export type DeleteTradingTimePayload = {
  __typename?: 'DeleteTradingTimePayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']>;
  /** The deteted term object */
  tradingTime?: Maybe<TradingTime>;
};

/** Input for the deleteUser mutation */
export type DeleteUserInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The ID of the user you want to delete */
  id: Scalars['ID'];
  /** Reassign posts and links to new User ID. */
  reassignId?: InputMaybe<Scalars['ID']>;
};

/** The payload for the deleteUser mutation */
export type DeleteUserPayload = {
  __typename?: 'DeleteUserPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the user that you just deleted */
  deletedId?: Maybe<Scalars['ID']>;
  /** The deleted user object */
  user?: Maybe<User>;
};

/** The discussion setting type */
export type DiscussionSettings = {
  __typename?: 'DiscussionSettings';
  /** Allow people to submit comments on new posts. */
  defaultCommentStatus?: Maybe<Scalars['String']>;
  /** Allow link notifications from other blogs (pingbacks and trackbacks) on new articles. */
  defaultPingStatus?: Maybe<Scalars['String']>;
};

/** The Type of Identifier used to fetch a single resource. */
export enum DraftEntryIdTypeEnum {
  /** Unique global ID for the object. */
  Id = 'ID',
  /** The resume token assigned by Gravity Forms. Used by draft entries. */
  ResumeToken = 'RESUME_TOKEN'
}

/** A Gravity Forms   field. */
export type EmailField = FormField & {
  __typename?: 'EmailField';
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Whether autocomplete should be enabled for this field. */
  hasAutocomplete?: Maybe<Scalars['Boolean']>;
  /** Determines whether the Confirm Email field is active. */
  hasEmailConfirmation?: Maybe<Scalars['Boolean']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** An array of the available properties for each input of the email field. */
  inputs?: Maybe<Array<Maybe<EmailInputProperty>>>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']>;
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']>;
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>;
  /** The placement of the labels for the subfields within the group. This setting controls all of the subfields, they cannot be set individually. They may be aligned above or below the inputs. If this property is not set, the “Sub-Label Placement” setting on the Form Settings-&gt;Form Layout page is used. If no setting is specified, the default is above inputs. */
  subLabelPlacement?: Maybe<FormFieldSubLabelPlacementEnum>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** Input fields for email field. */
export type EmailFieldInput = {
  /** Email confirmation input value. Only used when email confirmation is enabled. */
  confirmationValue?: InputMaybe<Scalars['String']>;
  /** Email input value. */
  value?: InputMaybe<Scalars['String']>;
};

/** Email input values. */
export type EmailInputProperty = {
  __typename?: 'EmailInputProperty';
  /** The autocomplete attribute for the field. */
  autocompleteAttribute?: Maybe<Scalars['String']>;
  /** The custom label for the input. When set, this is used in place of the label. */
  customLabel?: Maybe<Scalars['String']>;
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']>;
  /** The input ID. Input IDs follow the following naming convention: FIELDID.INPUTID (i.e. 5.1), where FIELDID is the id of the containing field and INPUTID specifies the input field. */
  id?: Maybe<Scalars['Float']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  name?: Maybe<Scalars['String']>;
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']>;
};

/** Asset enqueued by the CMS */
export type EnqueuedAsset = {
  /** @todo */
  args?: Maybe<Scalars['Boolean']>;
  /** Dependencies needed to use this asset */
  dependencies?: Maybe<Array<Maybe<EnqueuedScript>>>;
  /** Extra information needed for the script */
  extra?: Maybe<Scalars['String']>;
  /** The handle of the enqueued asset */
  handle?: Maybe<Scalars['String']>;
  /** The ID of the enqueued asset */
  id: Scalars['ID'];
  /** The source of the asset */
  src?: Maybe<Scalars['String']>;
  /** The version of the enqueued asset */
  version?: Maybe<Scalars['String']>;
};

/** Script enqueued by the CMS */
export type EnqueuedScript = EnqueuedAsset & Node & {
  __typename?: 'EnqueuedScript';
  /** @todo */
  args?: Maybe<Scalars['Boolean']>;
  /** Dependencies needed to use this asset */
  dependencies?: Maybe<Array<Maybe<EnqueuedScript>>>;
  /** Extra information needed for the script */
  extra?: Maybe<Scalars['String']>;
  /** The handle of the enqueued asset */
  handle?: Maybe<Scalars['String']>;
  /** The ID of the enqueued asset */
  id: Scalars['ID'];
  /** The source of the asset */
  src?: Maybe<Scalars['String']>;
  /** The version of the enqueued asset */
  version?: Maybe<Scalars['String']>;
};

/** Stylesheet enqueued by the CMS */
export type EnqueuedStylesheet = EnqueuedAsset & Node & {
  __typename?: 'EnqueuedStylesheet';
  /** @todo */
  args?: Maybe<Scalars['Boolean']>;
  /** Dependencies needed to use this asset */
  dependencies?: Maybe<Array<Maybe<EnqueuedScript>>>;
  /** Extra information needed for the script */
  extra?: Maybe<Scalars['String']>;
  /** The handle of the enqueued asset */
  handle?: Maybe<Scalars['String']>;
  /** The ID of the enqueued asset */
  id: Scalars['ID'];
  /** The source of the asset */
  src?: Maybe<Scalars['String']>;
  /** The version of the enqueued asset */
  version?: Maybe<Scalars['String']>;
};

/** Options for ordering the connection. */
export type EntriesConnectionOrderbyInput = {
  /** The field name used to sort the results. */
  field?: InputMaybe<Scalars['String']>;
  /** Whether the sorting field's values are numeric. */
  isNumeric?: InputMaybe<Scalars['Boolean']>;
  /** The cardinality of the order of the connection. */
  order?: InputMaybe<OrderEnum>;
};

/** Date Filters input fields for Entries queries. */
export type EntriesDateFiltersInput = {
  /** End date in Y-m-d H:i:s format. */
  endDate?: InputMaybe<Scalars['String']>;
  /** Start date in Y-m-d H:i:s format. */
  startDate?: InputMaybe<Scalars['String']>;
};

/** Field Filters input fields for Entries queries. */
export type EntriesFieldFiltersInput = {
  /** The field value(s) to filter by. Must be boolean values. If using this field, do not also use stringValues, intValues or floatValues. */
  boolValues?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  /** The field value(s) to filter by. Must be float values. If using this field, do not also use stringValues, intValues or boolValues. */
  floatValues?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  /** The field value(s) to filter by. Must be integer values. If using this field, do not also use stringValues, floatValues or boolValues. */
  intValues?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** Optional. The entry meta key to filter by. You can use the ID of the form field, or the names of any of the columns in Gravity Form's database table for entries, such as "date_created", "is_read, "created_by", etc. If omitted, the value will be checked against all meta keys. . */
  key?: InputMaybe<Scalars['String']>;
  /** The operator to use for filtering. */
  operator?: InputMaybe<FieldFiltersOperatorEnum>;
  /** The field value(s) to filter by. Must be string values. If using this field, do not also use intValues, floatValues or boolValues. */
  stringValues?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** The Type of Identifier used to fetch a single resource. */
export enum EntryIdTypeEnum {
  /** The database ID assigned by Gravity Forms. Used by submitted entries. */
  DatabaseId = 'DATABASE_ID',
  /** Unique global ID for the object. */
  Id = 'ID',
  /** The resume token assigned by Gravity Forms. Used by draft entries. */
  ResumeToken = 'RESUME_TOKEN'
}

/** Status of entries to get. Default is ACTIVE. */
export enum EntryStatusEnum {
  /** Active entries (default). */
  Active = 'ACTIVE',
  /** All entries. */
  All = 'ALL',
  /** Spam entries. */
  Spam = 'SPAM',
  /** Entries in the trash. */
  Trash = 'TRASH'
}

/** The type of Gravity Forms entry. */
export enum EntryTypeEnum {
  /** A Gravity Forms draft entry. */
  Draft = 'DRAFT',
  /** A Gravity Forms partial entry. */
  Partial = 'PARTIAL',
  /** A submitted Gravity Forms entry. */
  Submitted = 'SUBMITTED'
}

/** Field error. */
export type FieldError = {
  __typename?: 'FieldError';
  /** The field with the associated error message. */
  id?: Maybe<Scalars['Float']>;
  /** Error message. */
  message?: Maybe<Scalars['String']>;
};

/** Whether to filter by ALL or ANY of the field filters. Default is ALL. */
export enum FieldFiltersModeEnum {
  /** All field filters (default). */
  All = 'ALL',
  /** Any field filters. */
  Any = 'ANY'
}

/** The operator to use for filtering. */
export enum FieldFiltersOperatorEnum {
  /** Find field values that contain the passed value. Only one value may be passed when using this operator. SQL Equivalent: `LIKE %value%`. */
  Contains = 'CONTAINS',
  /** Default. Find field values that are equal to one of the values in the passed array. Default. */
  In = 'IN',
  /** Find field values that are an exact match for the passed value. Only one value may be passed when using this operator. SQL Equivalent: `=`. */
  Is = 'IS',
  /** Find field values that are NOT an exact match for the passed value. Only one value may be passed when using this operator. SQL Equivalent: `NOT`. */
  IsNot = 'IS_NOT',
  /** Find field values that are an exact match for the passed value. SQL wildcards are supported. Only one value may be passed when using this operator. SQL Equivalent: `LIKE`. */
  Like = 'LIKE',
  /** Find field values that do NOT match those in the values array. */
  NotIn = 'NOT_IN'
}

/** A Gravity Forms   field. */
export type FileUploadField = FormField & {
  __typename?: 'FileUploadField';
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** A comma-delimited list of the file extensions which may be uploaded. */
  allowedExtensions?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Indicates whether multiple files may be uploaded. */
  canAcceptMultipleFiles?: Maybe<Scalars['Boolean']>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** File upload value */
  fileUploadValues?: Maybe<Array<Maybe<FileUploadFieldValue>>>;
  /** Field ID. */
  id: Scalars['Int'];
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The maximum size (in MB) an uploaded file may be . */
  maxFileSize?: Maybe<Scalars['Int']>;
  /** When the field is set to allow multiple files to be uploaded, this property is available to set a limit on how many may be uploaded. */
  maxFiles?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /**
   * An array of field values.
   * @deprecated Use `fileUploadValues` instead.
   */
  values?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** The individual file properties from an uploaded file. */
export type FileUploadFieldValue = {
  __typename?: 'FileUploadFieldValue';
  /** The path to the parent directory of the file. */
  basePath?: Maybe<Scalars['String']>;
  /** The base url to the parent directory of the file. */
  baseUrl?: Maybe<Scalars['String']>;
  /** The filename. */
  filename?: Maybe<Scalars['String']>;
  /** The url to the file. */
  url?: Maybe<Scalars['String']>;
};

/** Gravity Forms button. */
export type FormButton = {
  __typename?: 'FormButton';
  /** Controls when the form button should be visible based on values selected on the form. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** Contains the URL for the image button. Only applicable when type is set to image. */
  imageUrl?: Maybe<Scalars['String']>;
  /** Contains the button text. Only applicable when type is set to text. */
  text?: Maybe<Scalars['String']>;
  /** Specifies the type of button to be displayed. Defaults to TEXT. */
  type?: Maybe<FormButtonTypeEnum>;
};

/** Type of button to be displayed. Default is TEXT. */
export enum FormButtonTypeEnum {
  /** Image button. */
  Image = 'IMAGE',
  /** Text button (default). */
  Text = 'TEXT'
}

/** Properties for all the email notifications which exist for a form. */
export type FormConfirmation = {
  __typename?: 'FormConfirmation';
  /** Controls which form confirmation message should be displayed. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** ID. */
  id?: Maybe<Scalars['String']>;
  /** Whether the confirmation is active or inactive. The default confirmation is always active. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** Whether the confirmation message should be formatted so that paragraphs are automatically added for new lines. */
  isAutoformatted?: Maybe<Scalars['Boolean']>;
  /** Whether this is the default confirmation. */
  isDefault?: Maybe<Scalars['Boolean']>;
  /** Contains the confirmation message that will be displayed. Only applicable when type is set to &quot;MESSAGE&quot;. */
  message?: Maybe<Scalars['String']>;
  /** The confirmation name. */
  name?: Maybe<Scalars['String']>;
  /** Connection between the FormConfirmation type and the Page type */
  page?: Maybe<FormConfirmationToPageConnectionEdge>;
  /** Contains the Id of the WordPress page that the browser will be redirected to. Only applicable when type is set to `PAGE`. */
  pageId?: Maybe<Scalars['Int']>;
  /** Contains the query string to be appended to the redirection url. Only applicable when type is set to `REDIRECT`. */
  queryString?: Maybe<Scalars['String']>;
  /** Determines the type of confirmation to be used. */
  type?: Maybe<FormConfirmationTypeEnum>;
  /** Contains the URL that the browser will be redirected to. Only applicable when type is set to `REDIRECT`. */
  url?: Maybe<Scalars['String']>;
};

/** Connection between the FormConfirmation type and the Page type */
export type FormConfirmationToPageConnectionEdge = {
  __typename?: 'FormConfirmationToPageConnectionEdge';
  /** The node of the connection, without the edges */
  node?: Maybe<Page>;
};

/** Type of form confirmation to be used. */
export enum FormConfirmationTypeEnum {
  /** Use a confirmation "message". */
  Message = 'MESSAGE',
  /** Use a redirect to a different WordPress "page". */
  Page = 'PAGE',
  /** Use a "redirect" to a given URL. */
  Redirect = 'REDIRECT'
}

/** The policies governing which entry data to include when erasing and exporting personal data. */
export type FormDataPolicies = {
  __typename?: 'FormDataPolicies';
  /** Whether entry data from this form is included when erasing and exporting personal data. */
  canExportAndErase?: Maybe<Scalars['Boolean']>;
  /** The individual entry data exporting and erasing policies. */
  entryData?: Maybe<Array<Maybe<FormEntryDataPolicy>>>;
  /** The database ID of the Gravity Forms field used to identify the user. */
  identificationFieldDatabaseId?: Maybe<Scalars['Int']>;
};

/** Determines where the field description is displayed relative to the field. */
export enum FormDescriptionPlacementEnum {
  /** The field description is displayed above the field input (i.e. immediately after the field label). */
  Above = 'ABOVE',
  /** The field description is displayed below the field input. */
  Below = 'BELOW'
}

/** The individual entry data exporting and erasing policies. */
export type FormEntryDataPolicy = {
  __typename?: 'FormEntryDataPolicy';
  /** The array key for the Gravity Forms Entry. */
  key?: Maybe<Scalars['String']>;
  /** Whether this field should be included when erasing personal data. */
  shouldErase?: Maybe<Scalars['Boolean']>;
  /** Whether this field should be included when exporting personal data. */
  shouldExport?: Maybe<Scalars['Boolean']>;
};

/** Gravity Forms form entry limititation details. */
export type FormEntryLimits = {
  __typename?: 'FormEntryLimits';
  /** Whether the form has a limit on the number of submissions. */
  hasLimit?: Maybe<Scalars['Boolean']>;
  /** Message that will be displayed when the maximum number of submissions have been reached. */
  limitReachedMessage?: Maybe<Scalars['String']>;
  /** The time period during which submissions are allowed. */
  limitationPeriod?: Maybe<FormLimitEntriesPeriodEnum>;
  /** The number of submissions allowed. */
  maxEntries?: Maybe<Scalars['Int']>;
};

/** Gravity Forms field. */
export type FormField = {
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** How the date field displays its calendar icon. */
export enum FormFieldCalendarIconTypeEnum {
  /** Default calendar icon. */
  Calendar = 'CALENDAR',
  /** Custom calendar icon. */
  Custom = 'CUSTOM',
  /** No calendar icon. */
  None = 'NONE'
}

/** The form field-specifc policies for exporting and erasing personal data. */
export type FormFieldDataPolicy = {
  __typename?: 'FormFieldDataPolicy';
  /** Whether this field is used to identify the user&#039;s personal data. */
  isIdentificationField?: Maybe<Scalars['Boolean']>;
  /** Whether this field should be included when erasing personal data. */
  shouldErase?: Maybe<Scalars['Boolean']>;
  /** Whether this field should be included when exporting personal data. */
  shouldExport?: Maybe<Scalars['Boolean']>;
};

/** Determines where the field description is displayed relative to the field. */
export enum FormFieldDescriptionPlacementEnum {
  /** The field description is displayed above the field input (i.e. immediately after the field label). */
  Above = 'ABOVE',
  /** The field description is displayed below the field input. */
  Below = 'BELOW',
  /** The field description is inherited from the form default settings. */
  Inherit = 'INHERIT'
}

/** The field label position. Empty when using the form defaults or a value of "hidden_label". */
export enum FormFieldLabelPlacementEnum {
  /** Field label is hidden. */
  Hidden = 'HIDDEN',
  /** Field label is inherited from the form defaults. */
  Inherit = 'INHERIT',
  /** Field label is displayed beside the fields and aligned to the left. */
  Left = 'LEFT',
  /** Field label is displayed beside the fields and aligned to the right. */
  Right = 'RIGHT',
  /** Field label is displayed on top of the fields. */
  Top = 'TOP'
}

/** Type of indicator to use when field is required. */
export enum FormFieldRequiredIndicatorEnum {
  /** Asterisk (*) indicator. */
  Asterisk = 'ASTERISK',
  /** Custom indicator. */
  Custom = 'CUSTOM',
  /** Text (Required) indicator (default). */
  Text = 'TEXT'
}

/** The size of the field when displayed on the page. */
export enum FormFieldSizeEnum {
  /** Large field size. */
  Large = 'LARGE',
  /** Medium field size. */
  Medium = 'MEDIUM',
  /** Small field size. */
  Small = 'SMALL'
}

/** Determines how sub-labels are aligned. */
export enum FormFieldSubLabelPlacementEnum {
  /** The sub-label is displayed above the sub-field input (i.e. immediately after the field label). */
  Above = 'ABOVE',
  /** The sub-label is displayed below the sub-field input. */
  Below = 'BELOW',
  /** Field label is inherited from the form defaults. */
  Inherit = 'INHERIT'
}

/** Gravity Forms Field Type. */
export enum FormFieldTypeEnum {
  /** A Gravity Forms address field. */
  Address = 'ADDRESS',
  /** A Gravity Forms calculation field. */
  Calculation = 'CALCULATION',
  /** A Gravity Forms captcha field. */
  Captcha = 'CAPTCHA',
  /** A Gravity Forms checkbox field. */
  Checkbox = 'CHECKBOX',
  /** A Gravity Forms consent field. */
  Consent = 'CONSENT',
  /** A Gravity Forms date field. */
  Date = 'DATE',
  /** A Gravity Forms email field. */
  Email = 'EMAIL',
  /** A Gravity Forms fileupload field. */
  Fileupload = 'FILEUPLOAD',
  /** A Gravity Forms hidden field. */
  Hidden = 'HIDDEN',
  /** A Gravity Forms hiddenproduct field. */
  Hiddenproduct = 'HIDDENPRODUCT',
  /** A Gravity Forms html field. */
  Html = 'HTML',
  /** A Gravity Forms list field. */
  List = 'LIST',
  /** A Gravity Forms multiselect field. */
  Multiselect = 'MULTISELECT',
  /** A Gravity Forms name field. */
  Name = 'NAME',
  /** A Gravity Forms number field. */
  Number = 'NUMBER',
  /** A Gravity Forms page field. */
  Page = 'PAGE',
  /** A Gravity Forms password field. */
  Password = 'PASSWORD',
  /** A Gravity Forms phone field. */
  Phone = 'PHONE',
  /** A Gravity Forms post_category field. */
  PostCategory = 'POST_CATEGORY',
  /** A Gravity Forms post_content field. */
  PostContent = 'POST_CONTENT',
  /** A Gravity Forms post_custom_field field. */
  PostCustomField = 'POST_CUSTOM_FIELD',
  /** A Gravity Forms post_excerpt field. */
  PostExcerpt = 'POST_EXCERPT',
  /** A Gravity Forms post_image field. */
  PostImage = 'POST_IMAGE',
  /** A Gravity Forms post_tags field. */
  PostTags = 'POST_TAGS',
  /** A Gravity Forms post_title field. */
  PostTitle = 'POST_TITLE',
  /** A Gravity Forms radio field. */
  Radio = 'RADIO',
  /** A Gravity Forms section field. */
  Section = 'SECTION',
  /** A Gravity Forms select field. */
  Select = 'SELECT',
  /** A Gravity Forms singleproduct field. */
  Singleproduct = 'SINGLEPRODUCT',
  /** A Gravity Forms singleshipping field. */
  Singleshipping = 'SINGLESHIPPING',
  /** A Gravity Forms submit field. */
  Submit = 'SUBMIT',
  /** A Gravity Forms text field. */
  Text = 'TEXT',
  /** A Gravity Forms textarea field. */
  Textarea = 'TEXTAREA',
  /** A Gravity Forms time field. */
  Time = 'TIME',
  /** A Gravity Forms website field. */
  Website = 'WEBSITE'
}

/** Field values input. Includes a field id, and a valid value Input. */
export type FormFieldValuesInput = {
  /** The form field values for Address fields. */
  addressValues?: InputMaybe<AddressFieldInput>;
  /** The form field values for Checkbox fields. */
  checkboxValues?: InputMaybe<Array<InputMaybe<CheckboxFieldInput>>>;
  /** The form field values for Email fields. */
  emailValues?: InputMaybe<EmailFieldInput>;
  /** The field id. */
  id: Scalars['Int'];
  /** The form field values for List fields. */
  listValues?: InputMaybe<Array<InputMaybe<ListFieldInput>>>;
  /** The form field values for Name fields. */
  nameValues?: InputMaybe<NameFieldInput>;
  /** The form field values for basic fields. */
  value?: InputMaybe<Scalars['String']>;
  /** The form field values for fields that accept multiple string values. Used by MultiSelect, Post Category, Post Custom, and Post Tags fields. */
  values?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Field visibility. */
export enum FormFieldVisibilityEnum {
  /** The field is for "administrative" use. */
  Administrative = 'ADMINISTRATIVE',
  /** The field is "hidden". */
  Hidden = 'HIDDEN',
  /** The field is "visible". */
  Visible = 'VISIBLE'
}

/** The Type of Identifier used to fetch a single resource. */
export enum FormIdTypeEnum {
  /** The database ID assigned by Gravity Forms. */
  DatabaseId = 'DATABASE_ID',
  /** Unique global ID for the object. */
  Id = 'ID'
}

/** Determines where the field labels should be placed in relation to the field. */
export enum FormLabelPlacementEnum {
  /** Field labels are displayed beside the fields and aligned to the left. */
  Left = 'LEFT',
  /** Field labels are displayed beside the fields and aligned to the right. */
  Right = 'RIGHT',
  /** Field labels are displayed on top of the fields. */
  Top = 'TOP'
}

/** Gravity Forms button. */
export type FormLastPageButton = {
  __typename?: 'FormLastPageButton';
  /** Contains the URL for the image button. Only applicable when type is set to image. */
  imageUrl?: Maybe<Scalars['String']>;
  /** Contains the button text. Only applicable when type is set to text. */
  text?: Maybe<Scalars['String']>;
  /** Specifies the type of button to be displayed. Defaults to TEXT. */
  type?: Maybe<FormButtonTypeEnum>;
};

/** When limitEntries is set to 1, this property specifies the time period during which submissions are allowed. */
export enum FormLimitEntriesPeriodEnum {
  /** Limit entries by "day". */
  Day = 'DAY',
  /** Limit entries by "month". */
  Month = 'MONTH',
  /** Limit entries by "week". */
  Week = 'WEEK',
  /** Limit entries by "year". */
  Year = 'YEAR'
}

/** Gravity Forms form login requirements data. */
export type FormLogin = {
  __typename?: 'FormLogin';
  /** Whether the form is configured to be displayed only to logged in users. */
  isLoginRequired?: Maybe<Scalars['Boolean']>;
  /** When `isLoginRequired` is set to true, this controls the message displayed when non-logged in user tries to access the form. */
  loginRequiredMessage?: Maybe<Scalars['String']>;
};

/** Properties for all the email notifications which exist for a form. */
export type FormNotification = {
  __typename?: 'FormNotification';
  /** The email or merge tags to be used as the email bcc address. */
  bcc?: Maybe<Scalars['String']>;
  /** An associative array containing the conditional logic rules. See the Conditional Logic Object for more details. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** The notification event. Default is form_submission. */
  event?: Maybe<Scalars['String']>;
  /** The email or merge tag to be used as the email from address. */
  from?: Maybe<Scalars['String']>;
  /** The text or merge tag to be used as the email from name. */
  fromName?: Maybe<Scalars['String']>;
  /** The notification ID. A 13 character unique ID. */
  id?: Maybe<Scalars['String']>;
  /** Is the notification active or inactive. The default is true (active). */
  isActive?: Maybe<Scalars['Boolean']>;
  /** Whether the email message should be formatted so that paragraphs are automatically added for new lines. */
  isAutoformatted?: Maybe<Scalars['Boolean']>;
  /** The email body/content. Merge tags supported. */
  message?: Maybe<Scalars['String']>;
  /** The notification name. */
  name?: Maybe<Scalars['String']>;
  /** The email or merge tags to be used as the email reply to address. */
  replyTo?: Maybe<Scalars['String']>;
  /** Routing rules. */
  routing?: Maybe<Array<Maybe<FormNotificationRouting>>>;
  /** The name of the service to be used when sending this notification. Default is wordpress. */
  service?: Maybe<Scalars['String']>;
  /** Determines if files uploaded on the form should be included when the notification is sent. */
  shouldSendAttachments?: Maybe<Scalars['Boolean']>;
  /** The email subject line. Merge tags supported. */
  subject?: Maybe<Scalars['String']>;
  /** The ID of an email field, an email address or merge tag to be used as the email to address. */
  to?: Maybe<Scalars['String']>;
  /** Identifies what to use for the notification &quot;to&quot;. */
  toType?: Maybe<FormNotificationToTypeEnum>;
};

/** Properties for all the email notifications which exist for a form. */
export type FormNotificationRouting = {
  __typename?: 'FormNotificationRouting';
  /** The email or merge tag to be used as the email To address if this rule is a match. */
  email?: Maybe<Scalars['String']>;
  /** Target field ID. The field that will have it’s value compared with the value property to determine if this rule is a match. */
  fieldId?: Maybe<Scalars['Int']>;
  /** Operator to be used when evaluating this rule. */
  operator?: Maybe<FormRuleOperatorEnum>;
  /** The value to compare with the field specified by fieldId. */
  value?: Maybe<Scalars['String']>;
};

/** What to use for the notification "to". */
export enum FormNotificationToTypeEnum {
  /** Email address. */
  Email = 'EMAIL',
  /** Form field. */
  Field = 'FIELD',
  /** Hidden. */
  Hidden = 'HIDDEN',
  /** Routing using conditional rules. */
  Routing = 'ROUTING'
}

/** Style of progress bar. */
export enum FormPageProgressStyleEnum {
  /** Blue progress bar style. */
  Blue = 'BLUE',
  /** Custom progress bar style. */
  Custom = 'CUSTOM',
  /** Green progress bar style. */
  Green = 'GREEN',
  /** Grey progress bar style. */
  Grey = 'GREY',
  /** Orange progress bar style. */
  Orange = 'ORANGE',
  /** Red progress bar style. */
  Red = 'RED'
}

/** Type of page progress indicator to be displayed. */
export enum FormPageProgressTypeEnum {
  /** Don't show a page progress indicator. */
  None = 'NONE',
  /** Show page progress indicator as a percentage. */
  Percentage = 'PERCENTAGE',
  /** Show page progress indicator as steps. */
  Steps = 'STEPS'
}

/** Gravity Forms form pagination data. */
export type FormPagination = {
  __typename?: 'FormPagination';
  /** Progress bar background color. Can be any CSS color value. Only applies when `style` is set to &quot;CUSTOM&quot;. */
  backgroundColor?: Maybe<Scalars['String']>;
  /** Progress bar text color. Can be any CSS color value. Only applies when `style` is set to &quot;CUSTOM&quot;. */
  color?: Maybe<Scalars['String']>;
  /** Whether the confirmation bar should be displayed with the confirmation text. */
  hasProgressbarOnConfirmation?: Maybe<Scalars['Boolean']>;
  /** Last page button data. */
  lastPageButton?: Maybe<FormLastPageButton>;
  /** Names of the form&#039;s pages. */
  pageNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The confirmation text to display once the end of the progress bar has been reached. Only applies when `hasProgressbarOnConfirmation` is set to true. */
  progressbarCompletionText?: Maybe<Scalars['String']>;
  /** Style of progress bar. */
  style?: Maybe<FormPageProgressStyleEnum>;
  /** Type of progress indicator. */
  type?: Maybe<FormPageProgressTypeEnum>;
};

/** Gravity Forms form Personal Data settings. */
export type FormPersonalData = {
  __typename?: 'FormPersonalData';
  /** The policies governing which entry data to include when erasing and exporting personal data. */
  dataPolicies?: Maybe<FormDataPolicies>;
  /** The number of days to retain entries. `null` if `retentionPolicy` is set to `RETAIN` entries indefinitely. */
  daysToRetain?: Maybe<Scalars['Int']>;
  /** The policy for retaining old entry data. */
  retentionPolicy?: Maybe<FormRetentionPolicyEnum>;
  /** Whether the IP address should be saved to the form submission. */
  shouldSaveIP?: Maybe<Scalars['Boolean']>;
};

/** Gravity Forms form entry limititation details. */
export type FormPostCreation = {
  __typename?: 'FormPostCreation';
  /** When `useCurrentUserAsAuthor` is `false`, this contains the User object for the author. */
  author?: Maybe<User>;
  /** When `useCurrentUserAsAuthor` is `false`, this property contains the user database that will be used as the Post author. */
  authorDatabaseId?: Maybe<Scalars['Int']>;
  /** When `useCurrentUserAsAuthor` is `false`, this property contains the user ID that will be used as the Post author. */
  authorId?: Maybe<Scalars['ID']>;
  /** Form forms with Post fields, but without a Post Category field, this property contains the default category database ID the post will be associated with when created. */
  categoryDatabaseId?: Maybe<Scalars['Int']>;
  /** Template to be used when creating the post content. Field variables (i.e. {Name:3} ) can be added to the template to insert user submitted values into the post content. Only applicable when `hasContentTemplate` is `true`. */
  contentTemplate?: Maybe<Scalars['String']>;
  /** Determines the format that the Post should be created with. */
  format?: Maybe<PostFormatTypeEnum>;
  /** Determines if the post template functionality is enabled. When enabled, the post content will be created based on the template specified by `contentTemplate`. */
  hasContentTemplate?: Maybe<Scalars['Boolean']>;
  /** Determines if the post title template functionality is enabled. When enabled, the post title will be created based on the template specified by `titleTemplate`. */
  hasTitleTemplate?: Maybe<Scalars['Boolean']>;
  /** For forms with Post fields, this determines if the post should be created using the current logged in user as the author. */
  shouldUseCurrentUserAsAuthor?: Maybe<Scalars['Boolean']>;
  /** For forms with Post fields, determines the status that the Post should be created with. */
  status?: Maybe<Scalars['String']>;
  /** Template to be used when creating the post title. Field variables (i.e. {Name:3} ) can be added to the template to insert user submitted values into the post title. Only applicable when `hasTitleTemplate` is `true`. */
  titleTemplate?: Maybe<Scalars['String']>;
};

/** The Personal Data retention policy. */
export enum FormRetentionPolicyEnum {
  /** Entries will be deleted automatically after a specified number of days. */
  Delete = 'DELETE',
  /** Entries will be retain indefinitely. */
  Retain = 'RETAIN',
  /** Entries will be trashed automatically after a specified number of days. */
  Trash = 'TRASH'
}

/** Operator to be used when evaluating logic rules. */
export enum FormRuleOperatorEnum {
  /** Evaluates values that CONTAIN the comparison value. */
  Contains = 'CONTAINS',
  /** Evaluates values that END with the comparison value. */
  EndsWith = 'ENDS_WITH',
  /** Evaluates values that are GREATER than the comparison value. */
  GreaterThan = 'GREATER_THAN',
  /** Evaluates values that match the comparison value. */
  Is = 'IS',
  /** Evaluates values that do NOT match the comparison value. */
  IsNot = 'IS_NOT',
  /** Evaluates values that are LESS than the comparison value. */
  LessThan = 'LESS_THAN',
  /** Evaluates values that START with the comparison value. */
  StartsWith = 'STARTS_WITH'
}

/** Gravity Forms form Save and Continue data. */
export type FormSaveAndContinue = {
  __typename?: 'FormSaveAndContinue';
  /** Contains the save button text. */
  buttonText?: Maybe<Scalars['String']>;
  /** Whether the Save And Continue feature is enabled. */
  hasSaveAndContinue?: Maybe<Scalars['Boolean']>;
};

/** Gravity Forms form scheduling data. */
export type FormSchedule = {
  __typename?: 'FormSchedule';
  /** Message to be displayed when form is no longer available. */
  closedMessage?: Maybe<Scalars['String']>;
  /** The Date/time details when the form will become inactive. */
  endDetails?: Maybe<FormScheduleDetails>;
  /** Specifies if this form is scheduled to be displayed only during a certain configured date/time. */
  hasSchedule?: Maybe<Scalars['Boolean']>;
  /** Message to be displayed when form is not yet available. */
  pendingMessage?: Maybe<Scalars['String']>;
  /** The Date/time details when the form will become active/visible. */
  startDetails?: Maybe<FormScheduleDetails>;
};

/** Gravity Forms form scheduling data. */
export type FormScheduleDetails = {
  __typename?: 'FormScheduleDetails';
  /** Whether the date is in the AM or PM of a 12-hour clock. */
  amPm?: Maybe<AmPmEnum>;
  /** The schedule date in local time. */
  date?: Maybe<Scalars['String']>;
  /** The schedule date in GMT. */
  dateGmt?: Maybe<Scalars['String']>;
  /** The hour (1-12). */
  hour?: Maybe<Scalars['Int']>;
  /** The minute. */
  minute?: Maybe<Scalars['Int']>;
};

/** Status of forms to get. Default is ACTIVE. */
export enum FormStatusEnum {
  /** Active forms (default). */
  Active = 'ACTIVE',
  /** Inactive forms. */
  Inactive = 'INACTIVE',
  /** Inactive forms in the trash. */
  InactiveTrashed = 'INACTIVE_TRASHED',
  /** Active forms in the trash. */
  Trashed = 'TRASHED'
}

/** Determines how sub-labels are aligned. */
export enum FormSubLabelPlacementEnum {
  /** The sub-label is displayed above the sub-field input (i.e. immediately after the field label). */
  Above = 'ABOVE',
  /** The sub-label is displayed below the sub-field input. */
  Below = 'BELOW'
}

/** Gravity Forms submit button. */
export type FormSubmitButton = {
  __typename?: 'FormSubmitButton';
  /** Controls when the form button should be visible based on values selected on the form. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** Contains the URL for the image button. Only applicable when type is set to image. */
  imageUrl?: Maybe<Scalars['String']>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** Where the submit button should be located. */
  location?: Maybe<FormSubmitButtonLocationEnum>;
  /** Contains the button text. Only applicable when type is set to text. */
  text?: Maybe<Scalars['String']>;
  /** Specifies the type of button to be displayed. Defaults to TEXT. */
  type?: Maybe<FormButtonTypeEnum>;
  /** The width of the submit button element. */
  width?: Maybe<FormSubmitButtonWidthEnum>;
};

/** Where the submit button should be located. */
export enum FormSubmitButtonLocationEnum {
  /** The submit button will be placed in a new row after all fields of the form. */
  Bottom = 'BOTTOM',
  /** The submit button will be placed on the last row of the form where it will fill the remaining space left by field columns. */
  Inline = 'INLINE'
}

/** Submit button width. */
export enum FormSubmitButtonWidthEnum {
  /** The width is set to match that of the button text. */
  Auto = 'AUTO',
  /** The width is set to fill 100% of the container. */
  Full = 'FULL'
}

/** Options for ordering the connection. */
export type FormsConnectionOrderbyInput = {
  /** The field name used to sort the results. */
  field?: InputMaybe<Scalars['String']>;
  /** The cardinality of the order of the connection. */
  order?: InputMaybe<OrderEnum>;
};

/** Gatsby Preview webhook data. */
export type GatsbyPreviewData = {
  __typename?: 'GatsbyPreviewData';
  /** The Relay id of the previewed node. */
  id?: Maybe<Scalars['ID']>;
  /** Wether or not the preview is a draft. */
  isDraft?: Maybe<Scalars['Boolean']>;
  /** A list of manifest ID&#039;s a preview action has seen during it&#039;s lifetime. */
  manifestIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The modified time of the previewed node. */
  modified?: Maybe<Scalars['String']>;
  /** The WordPress database ID of the preview. If this is a draft it will potentially return 0, if it&#039;s a revision of a post, it will return the ID of the original post that this is a revision of. */
  parentDatabaseId?: Maybe<Scalars['Int']>;
  /** The WordPress database ID of the preview. Could be a revision or draft ID. */
  previewDatabaseId?: Maybe<Scalars['Int']>;
  /** The WP url at the time of the preview. */
  remoteUrl?: Maybe<Scalars['String']>;
  /** The GraphQL single field name for the type of the preview. */
  singleName?: Maybe<Scalars['String']>;
  /** The database ID of the user who made the original preview. */
  userDatabaseId?: Maybe<Scalars['Int']>;
};

/** The general setting type */
export type GeneralSettings = {
  __typename?: 'GeneralSettings';
  /** A date format for all date strings. */
  dateFormat?: Maybe<Scalars['String']>;
  /** Site tagline. */
  description?: Maybe<Scalars['String']>;
  /** This address is used for admin purposes, like new user notification. */
  email?: Maybe<Scalars['String']>;
  /** WordPress locale code. */
  language?: Maybe<Scalars['String']>;
  /** A day number of the week that the week should start on. */
  startOfWeek?: Maybe<Scalars['Int']>;
  /** A time format for all time strings. */
  timeFormat?: Maybe<Scalars['String']>;
  /** A city in the same timezone as you. */
  timezone?: Maybe<Scalars['String']>;
  /** Site title. */
  title?: Maybe<Scalars['String']>;
  /** Site URL. */
  url?: Maybe<Scalars['String']>;
};

/** Currencies supported by Gravity Forms. */
export enum GfCurrencyEnum {
  /** Australian Dollar . */
  Aud = 'AUD',
  /** Brazilian Real . */
  Brl = 'BRL',
  /** Canadian Dollar . */
  Cad = 'CAD',
  /** Swiss Franc . */
  Chf = 'CHF',
  /** Czech Koruna . */
  Czk = 'CZK',
  /** Danish Krone . */
  Dkk = 'DKK',
  /** Euro . */
  Eur = 'EUR',
  /** Pound Sterling . */
  Gbp = 'GBP',
  /** Hong Kong Dollar . */
  Hkd = 'HKD',
  /** Hungarian Forint . */
  Huf = 'HUF',
  /** Israeli New Sheqel . */
  Ils = 'ILS',
  /** Japanese Yen . */
  Jpy = 'JPY',
  /** Mexican Peso . */
  Mxn = 'MXN',
  /** Malaysian Ringgit . */
  Myr = 'MYR',
  /** Norwegian Krone . */
  Nok = 'NOK',
  /** New Zealand Dollar . */
  Nzd = 'NZD',
  /** Philippine Peso . */
  Php = 'PHP',
  /** Polish Zloty . */
  Pln = 'PLN',
  /** Russian Ruble . */
  Rub = 'RUB',
  /** Swedish Krona . */
  Sek = 'SEK',
  /** Singapore Dollar . */
  Sgd = 'SGD',
  /** Thai Baht . */
  Thb = 'THB',
  /** Taiwan New Dollar . */
  Twd = 'TWD',
  /** U.S. Dollar . */
  Usd = 'USD',
  /** South African Rand . */
  Zar = 'ZAR'
}

/** A Gravity Forms draft entry. */
export type GfDraftEntry = GfEntry & Node & NodeWithForm & {
  __typename?: 'GfDraftEntry';
  /** The user who created the entry. */
  createdBy?: Maybe<User>;
  /** Database ID of the user that submitted of the form if a logged in user submitted the form. */
  createdByDatabaseId?: Maybe<Scalars['Int']>;
  /** Global ID of the user that submitted of the form if a logged in user submitted the form. */
  createdById?: Maybe<Scalars['ID']>;
  /** The date and time that the entry was created in local time. */
  dateCreated?: Maybe<Scalars['String']>;
  /** The date and time that the entry was created in GMT. */
  dateCreatedGmt?: Maybe<Scalars['String']>;
  /** The date and time that the entry was created in local time. */
  dateUpdated?: Maybe<Scalars['String']>;
  /** The date and time that the entry was updated in GMT. */
  dateUpdatedGmt?: Maybe<Scalars['String']>;
  /** The form object of the node. */
  form?: Maybe<GfForm>;
  /** The database identifier of the form of the node. */
  formDatabaseId?: Maybe<Scalars['Int']>;
  /** Connection between the GfEntry type and the FormField type */
  formFields?: Maybe<GfEntryToFormFieldConnection>;
  /** The globally unique identifier of the form of the node. */
  formId?: Maybe<Scalars['ID']>;
  /** The globally unique ID for the object */
  id: Scalars['ID'];
  /** Client IP of user who submitted the form. */
  ip?: Maybe<Scalars['String']>;
  /** Whether the entry is a draft. */
  isDraft?: Maybe<Scalars['Boolean']>;
  /** Whether the entry has been submitted. */
  isSubmitted?: Maybe<Scalars['Boolean']>;
  /** The resume token. Only applies to draft entries. */
  resumeToken?: Maybe<Scalars['String']>;
  /** Source URL of page that contained the form when it was submitted. */
  sourceUrl?: Maybe<Scalars['String']>;
  /** Provides the name and version of both the browser and operating system from which the entry was submitted. */
  userAgent?: Maybe<Scalars['String']>;
};


/** A Gravity Forms draft entry. */
export type GfDraftEntryFormFieldsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GfEntryToFormFieldConnectionWhereArgs>;
};

/** Gravity Forms entry interface. */
export type GfEntry = {
  /** The user who created the entry. */
  createdBy?: Maybe<User>;
  /** Database ID of the user that submitted of the form if a logged in user submitted the form. */
  createdByDatabaseId?: Maybe<Scalars['Int']>;
  /** Global ID of the user that submitted of the form if a logged in user submitted the form. */
  createdById?: Maybe<Scalars['ID']>;
  /** The date and time that the entry was created in local time. */
  dateCreated?: Maybe<Scalars['String']>;
  /** The date and time that the entry was created in GMT. */
  dateCreatedGmt?: Maybe<Scalars['String']>;
  /** The date and time that the entry was created in local time. */
  dateUpdated?: Maybe<Scalars['String']>;
  /** The date and time that the entry was updated in GMT. */
  dateUpdatedGmt?: Maybe<Scalars['String']>;
  /** The form object of the node. */
  form?: Maybe<GfForm>;
  /** The database identifier of the form of the node. */
  formDatabaseId?: Maybe<Scalars['Int']>;
  /** Connection between the GfEntry type and the FormField type */
  formFields?: Maybe<GfEntryToFormFieldConnection>;
  /** The globally unique identifier of the form of the node. */
  formId?: Maybe<Scalars['ID']>;
  /** The globally unique ID for the object */
  id: Scalars['ID'];
  /** Client IP of user who submitted the form. */
  ip?: Maybe<Scalars['String']>;
  /** Whether the entry is a draft. */
  isDraft?: Maybe<Scalars['Boolean']>;
  /** Whether the entry has been submitted. */
  isSubmitted?: Maybe<Scalars['Boolean']>;
  /** Source URL of page that contained the form when it was submitted. */
  sourceUrl?: Maybe<Scalars['String']>;
  /** Provides the name and version of both the browser and operating system from which the entry was submitted. */
  userAgent?: Maybe<Scalars['String']>;
};


/** Gravity Forms entry interface. */
export type GfEntryFormFieldsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GfEntryToFormFieldConnectionWhereArgs>;
};

/** Connection between the GfEntry type and the FormField type */
export type GfEntryToFormFieldConnection = {
  __typename?: 'GfEntryToFormFieldConnection';
  /** Edges for the GfEntryToFormFieldConnection connection */
  edges?: Maybe<Array<Maybe<GfEntryToFormFieldConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<FormField>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type GfEntryToFormFieldConnectionEdge = {
  __typename?: 'GfEntryToFormFieldConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<FormField>;
};

/** Arguments for filtering the GfEntryToFormFieldConnection connection */
export type GfEntryToFormFieldConnectionWhereArgs = {
  /** Array of form field adminLabels to return. */
  adminLabels?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of Gravity Forms Field types to return. */
  fieldTypes?: InputMaybe<Array<InputMaybe<FormFieldTypeEnum>>>;
  /** Array of form field IDs to return. */
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** The form page number to return. */
  pageNumber?: InputMaybe<Scalars['Int']>;
};

/** Gravity Forms form. */
export type GfForm = DatabaseIdentifier & Node & {
  __typename?: 'GfForm';
  /**
   * Contains the form button settings such as the button text or image button source.
   * @deprecated Use `submitButton` field instead
   */
  button?: Maybe<FormSubmitButton>;
  /** Contains the form confirmation settings such as confirmation text or redirect URL. */
  confirmations?: Maybe<Array<Maybe<FormConfirmation>>>;
  /** String containing the custom CSS classes to be added to the &lt;form&gt; tag. */
  cssClass?: Maybe<Scalars['String']>;
  /** The custom text to use to indicate a field is required. */
  customRequiredIndicator?: Maybe<Scalars['String']>;
  /** The unique identifier stored in the database */
  databaseId: Scalars['Int'];
  /** The date and time that the entry was created in local time. */
  dateCreated?: Maybe<Scalars['String']>;
  /** The date and time that the entry was created in GMT. */
  dateCreatedGmt?: Maybe<Scalars['String']>;
  /** Form description. */
  description?: Maybe<Scalars['String']>;
  /** Determines if the field description is displayed above the field input (i.e. immediately after the field label) or below the field input. */
  descriptionPlacement?: Maybe<FormDescriptionPlacementEnum>;
  /** Connection between the GfForm type and the GfEntry type */
  entries?: Maybe<GfFormToGfEntryConnection>;
  /** The entry limit settings. */
  entryLimits?: Maybe<FormEntryLimits>;
  /** CSS class for the first page. */
  firstPageCssClass?: Maybe<Scalars['String']>;
  /** Connection between the GfForm type and the FormField type */
  formFields?: Maybe<GfFormToFormFieldConnection>;
  /**
   * Form ID.
   * @deprecated Deprecated in favor of the databaseId field.
   */
  formId?: Maybe<Scalars['Int']>;
  /** When enabled, conditional logic hide/show operation will be performed with a jQuery slide animation. Only applicable to forms with conditional logic. */
  hasConditionalLogicAnimation?: Maybe<Scalars['Boolean']>;
  /** Specifies if the form has the Honeypot spam-protection feature. */
  hasHoneypot?: Maybe<Scalars['Boolean']>;
  /** If enabled, will show a summary that lists form validation errors at the top of the form when a user attempts a failed submission. */
  hasValidationSummary?: Maybe<Scalars['Boolean']>;
  /** The globally unique ID for the object */
  id: Scalars['ID'];
  /** Determines whether the form is active. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** Determines whether the form is in the trash. */
  isTrash?: Maybe<Scalars['Boolean']>;
  /** Determines where the field labels should be placed in relation to the field. */
  labelPlacement?: Maybe<FormLabelPlacementEnum>;
  /**
   * Last page button data.
   * @deprecated Use `pagination.lastPageButton` instead
   */
  lastPageButton?: Maybe<FormLastPageButton>;
  /** Login requirements data. */
  login?: Maybe<FormLogin>;
  /** The Gravity Forms markup version. */
  markupVersion?: Maybe<Scalars['Int']>;
  /** The ID to assign to the next field that is added to the form. */
  nextFieldId?: Maybe<Scalars['Int']>;
  /** The properties for all the email notifications which exist for a form. */
  notifications?: Maybe<Array<Maybe<FormNotification>>>;
  /** Pagination data. */
  pagination?: Maybe<FormPagination>;
  /** Personal data settings. */
  personalData?: Maybe<FormPersonalData>;
  /** Post creation data. */
  postCreation?: Maybe<FormPostCreation>;
  /** Type of indicator to use when field is required. */
  requiredIndicator?: Maybe<FormFieldRequiredIndicatorEnum>;
  /** \&quot;Save and Continue\&quot; data. */
  saveAndContinue?: Maybe<FormSaveAndContinue>;
  /** Form scheduling data. */
  scheduling?: Maybe<FormSchedule>;
  /** How sub-labels are aligned. */
  subLabelPlacement?: Maybe<FormSubLabelPlacementEnum>;
  /** Contains the form button settings such as the button text or image button source. */
  submitButton?: Maybe<FormSubmitButton>;
  /** Form title. */
  title?: Maybe<Scalars['String']>;
  /** The version of Gravity Forms used to create this form. */
  version?: Maybe<Scalars['String']>;
};


/** Gravity Forms form. */
export type GfFormEntriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GfFormToGfEntryConnectionWhereArgs>;
};


/** Gravity Forms form. */
export type GfFormFormFieldsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GfFormToFormFieldConnectionWhereArgs>;
};

/** Connection between the GfForm type and the FormField type */
export type GfFormToFormFieldConnection = {
  __typename?: 'GfFormToFormFieldConnection';
  /** Edges for the GfFormToFormFieldConnection connection */
  edges?: Maybe<Array<Maybe<GfFormToFormFieldConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<FormField>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type GfFormToFormFieldConnectionEdge = {
  __typename?: 'GfFormToFormFieldConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<FormField>;
};

/** Arguments for filtering the GfFormToFormFieldConnection connection */
export type GfFormToFormFieldConnectionWhereArgs = {
  /** Array of form field adminLabels to return. */
  adminLabels?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of Gravity Forms Field types to return. */
  fieldTypes?: InputMaybe<Array<InputMaybe<FormFieldTypeEnum>>>;
  /** Array of form field IDs to return. */
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** The form page number to return. */
  pageNumber?: InputMaybe<Scalars['Int']>;
};

/** Connection between the GfForm type and the GfEntry type */
export type GfFormToGfEntryConnection = {
  __typename?: 'GfFormToGfEntryConnection';
  /** The number of (filtered) entries submitted to the form. */
  count?: Maybe<Scalars['Int']>;
  /** Edges for the GfFormToGfEntryConnection connection */
  edges?: Maybe<Array<Maybe<GfFormToGfEntryConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<GfEntry>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type GfFormToGfEntryConnectionEdge = {
  __typename?: 'GfFormToGfEntryConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<GfEntry>;
};

/** Arguments for filtering the GfFormToGfEntryConnection connection */
export type GfFormToGfEntryConnectionWhereArgs = {
  /** Date filters to apply. */
  dateFilters?: InputMaybe<EntriesDateFiltersInput>;
  /** Field-specific filters to apply. */
  fieldFilters?: InputMaybe<Array<InputMaybe<EntriesFieldFiltersInput>>>;
  /** Whether to filter by ALL or ANY of the field filters. Default is ALL. */
  fieldFiltersMode?: InputMaybe<FieldFiltersModeEnum>;
  /** How to sort the entries. */
  orderby?: InputMaybe<EntriesConnectionOrderbyInput>;
  /** Entry status. Default is "ACTIVE". */
  status?: InputMaybe<EntryStatusEnum>;
};

/** Gravity Forms Logging Settings. */
export type GfLogger = {
  __typename?: 'GfLogger';
  /** Whether the logger is enabled. */
  isEnabled?: Maybe<Scalars['Boolean']>;
  /** The name of the Gravity Forms logger. */
  name?: Maybe<Scalars['String']>;
};

/** Gravity Forms Settings. */
export type GfSettings = {
  __typename?: 'GfSettings';
  /** The default currency for your forms. Used for product, credit card, and other fields. */
  currency?: Maybe<GfCurrencyEnum>;
  /** Whether Gravity Forms to download and install bug fixes and security updates automatically in the background. Requires a valid license key. */
  hasBackgroundUpdates?: Maybe<Scalars['Boolean']>;
  /** Whether to output Gravity Forms&#039; default CSS. */
  hasDefaultCss?: Maybe<Scalars['Boolean']>;
  /** Whether to display the forms menu in the WordPress top toolbar. The forms menu will display the ten forms recently opened in the form editor. */
  hasToolbar?: Maybe<Scalars['Boolean']>;
  /** Whether the server-generated form markup uses HTML5. */
  isHtml5Enabled?: Maybe<Scalars['Boolean']>;
  /** Enable to prevent extraneous scripts and styles from being printed on a Gravity Forms admin pages, reducing conflicts with other plugins and themes. */
  isNoConflictModeEnabled?: Maybe<Scalars['Boolean']>;
  /** Logging settings. */
  logging?: Maybe<GfSettingsLogging>;
  /** Recaptcha settings. */
  recaptcha?: Maybe<GfSettingsRecaptcha>;
};

/** Gravity Forms Logging Settings. */
export type GfSettingsLogging = {
  __typename?: 'GfSettingsLogging';
  /** Whether Gravity Forms internal logging is enabled. Logging allows you to easily debug the inner workings of Gravity Forms to solve any possible issues. */
  isLoggingEnabled?: Maybe<Scalars['Boolean']>;
  /** A list of registered Gravity Forms loggers and their configurations. */
  loggers?: Maybe<Array<Maybe<GfLogger>>>;
};

/** Gravity Forms reCAPTCHA Settings. */
export type GfSettingsRecaptcha = {
  __typename?: 'GfSettingsRecaptcha';
  /** The public reCAPTCHA site key. */
  publicKey?: Maybe<Scalars['String']>;
  /** The type of of reCAPTCHA v2 to be used */
  type?: Maybe<RecaptchaTypeEnum>;
};

/** A Gravity Forms submitted entry. */
export type GfSubmittedEntry = DatabaseIdentifier & GfEntry & Node & NodeWithForm & {
  __typename?: 'GfSubmittedEntry';
  /** The user who created the entry. */
  createdBy?: Maybe<User>;
  /** Database ID of the user that submitted of the form if a logged in user submitted the form. */
  createdByDatabaseId?: Maybe<Scalars['Int']>;
  /** Global ID of the user that submitted of the form if a logged in user submitted the form. */
  createdById?: Maybe<Scalars['ID']>;
  /** The unique identifier stored in the database */
  databaseId: Scalars['Int'];
  /** The date and time that the entry was created in local time. */
  dateCreated?: Maybe<Scalars['String']>;
  /** The date and time that the entry was created in GMT. */
  dateCreatedGmt?: Maybe<Scalars['String']>;
  /** The date and time that the entry was created in local time. */
  dateUpdated?: Maybe<Scalars['String']>;
  /** The date and time that the entry was updated in GMT. */
  dateUpdatedGmt?: Maybe<Scalars['String']>;
  /**
   * The entry ID. Returns null for draft entries.
   * @deprecated Deprecated in favor of the databaseId field.
   */
  entryId?: Maybe<Scalars['Int']>;
  /** The form object of the node. */
  form?: Maybe<GfForm>;
  /** The database identifier of the form of the node. */
  formDatabaseId?: Maybe<Scalars['Int']>;
  /** Connection between the GfEntry type and the FormField type */
  formFields?: Maybe<GfEntryToFormFieldConnection>;
  /** The globally unique identifier of the form of the node. */
  formId?: Maybe<Scalars['ID']>;
  /** The globally unique ID for the object */
  id: Scalars['ID'];
  /** Client IP of user who submitted the form. */
  ip?: Maybe<Scalars['String']>;
  /** Whether the entry is a draft. */
  isDraft?: Maybe<Scalars['Boolean']>;
  /** Whether the entry has been read. */
  isRead?: Maybe<Scalars['Boolean']>;
  /** Indicates if the entry has been starred (i.e marked with a star). */
  isStarred?: Maybe<Scalars['Boolean']>;
  /** Whether the entry has been submitted. */
  isSubmitted?: Maybe<Scalars['Boolean']>;
  /** For forms with Post fields, this is the post object that was created. */
  post?: Maybe<Post>;
  /** For forms with Post fields, this property contains the Id of the Post that was created. */
  postDatabaseId?: Maybe<Scalars['Int']>;
  /** Source URL of page that contained the form when it was submitted. */
  sourceUrl?: Maybe<Scalars['String']>;
  /** The current status of the entry. */
  status?: Maybe<EntryStatusEnum>;
  /** Provides the name and version of both the browser and operating system from which the entry was submitted. */
  userAgent?: Maybe<Scalars['String']>;
};


/** A Gravity Forms submitted entry. */
export type GfSubmittedEntryFormFieldsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GfEntryToFormFieldConnectionWhereArgs>;
};

/** gravityforms/form block */
export type GravityformsFormBlock = Block & {
  __typename?: 'GravityformsFormBlock';
  attributes?: Maybe<GravityformsFormBlockAttributes>;
  /** Block attributes, JSON encoded */
  attributesJSON?: Maybe<Scalars['String']>;
  /** Server side rendered content. */
  dynamicContent?: Maybe<Scalars['String']>;
  /** Gutenberg blocks */
  innerBlocks?: Maybe<Array<Block>>;
  /** Is block rendered server side. */
  isDynamic: Scalars['Boolean'];
  /** Name of the block. */
  name: Scalars['String'];
  order: Scalars['Int'];
  /** Original HTML content. */
  originalContent?: Maybe<Scalars['String']>;
  /** Parent post. */
  parentNode: Node;
  /** Parent post id. */
  parentNodeDatabaseId: Scalars['Int'];
  /** Original HTML content with inner blocks. */
  saveContent?: Maybe<Scalars['String']>;
};

export type GravityformsFormBlockAttributes = {
  __typename?: 'GravityformsFormBlockAttributes';
  ajax: Scalars['Boolean'];
  description: Scalars['Boolean'];
  fieldValues?: Maybe<Scalars['String']>;
  formId?: Maybe<Scalars['String']>;
  formPreview: Scalars['Boolean'];
  imgPreview: Scalars['Boolean'];
  lock?: Maybe<Scalars['BlockAttributesObject']>;
  tabindex?: Maybe<Scalars['String']>;
  title: Scalars['Boolean'];
};

/** The group type */
export type Group = BlockEditorContentNode & ContentNode & DatabaseIdentifier & MenuItemLinkable & Node & NodeWithContentEditor & NodeWithTemplate & NodeWithTitle & UniformResourceIdentifiable & {
  __typename?: 'Group';
  Meta?: Maybe<Group_Meta>;
  /** Gutenberg blocks */
  blocks?: Maybe<Array<Block>>;
  /** Gutenberg blocks as json string */
  blocksJSON?: Maybe<Scalars['String']>;
  /** The content of the post. */
  content?: Maybe<Scalars['String']>;
  /** Connection between the ContentNode type and the ContentType type */
  contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /** The name of the Content Type the node belongs to */
  contentTypeName: Scalars['String'];
  /** The unique resource identifier path */
  databaseId: Scalars['Int'];
  /** Post publishing date. */
  date?: Maybe<Scalars['String']>;
  /** The publishing date set in GMT. */
  dateGmt?: Maybe<Scalars['String']>;
  /** The desired slug of the post */
  desiredSlug?: Maybe<Scalars['String']>;
  /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
  editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
  /** The RSS enclosure for the object */
  enclosure?: Maybe<Scalars['String']>;
  /** Connection between the ContentNode type and the EnqueuedScript type */
  enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
  /** Connection between the ContentNode type and the EnqueuedStylesheet type */
  enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of the databaseId field
   */
  groupId: Scalars['Int'];
  /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
  guid?: Maybe<Scalars['String']>;
  /** The globally unique identifier of the group object. */
  id: Scalars['ID'];
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean'];
  /** Whether the object is a node in the preview state */
  isPreview?: Maybe<Scalars['Boolean']>;
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']>;
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean'];
  /** The user that most recently edited the node */
  lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
  /** The permalink of the post */
  link?: Maybe<Scalars['String']>;
  /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
  modified?: Maybe<Scalars['String']>;
  /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
  modifiedGmt?: Maybe<Scalars['String']>;
  /** Connection between the group type and the group type */
  preview?: Maybe<GroupToPreviewConnectionEdge>;
  /** Previewed gutenberg blocks */
  previewBlocks?: Maybe<Array<Block>>;
  /** Previewed Gutenberg blocks as json string */
  previewBlocksJSON?: Maybe<Scalars['String']>;
  /** The database id of the preview node */
  previewRevisionDatabaseId?: Maybe<Scalars['Int']>;
  /** Whether the object is a node in the preview state */
  previewRevisionId?: Maybe<Scalars['ID']>;
  /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
  slug?: Maybe<Scalars['String']>;
  /** The current status of the object */
  status?: Maybe<Scalars['String']>;
  /** The template assigned to the node */
  template?: Maybe<ContentTemplate>;
  /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
  title?: Maybe<Scalars['String']>;
  /** The unique resource identifier path */
  uri?: Maybe<Scalars['String']>;
};


/** The group type */
export type GroupContentArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};


/** The group type */
export type GroupEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The group type */
export type GroupEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The group type */
export type GroupTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum GroupIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a resource by the URI. */
  Uri = 'URI'
}

/** Connection between the group type and the group type */
export type GroupToPreviewConnectionEdge = {
  __typename?: 'GroupToPreviewConnectionEdge';
  /** The node of the connection, without the edges */
  node?: Maybe<Group>;
};

/** Field Group */
export type Group_Meta = {
  __typename?: 'Group_Meta';
  fieldGroupName?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  /** If left blank the page or post title will be used. */
  metaTitle?: Maybe<Scalars['String']>;
  ogDescription?: Maybe<Scalars['String']>;
  ogImage?: Maybe<MediaItem>;
  ogTitle?: Maybe<Scalars['String']>;
  twitterCardType?: Maybe<Scalars['String']>;
  twitterDescription?: Maybe<Scalars['String']>;
  twitterImage?: Maybe<MediaItem>;
  twitterTitle?: Maybe<Scalars['String']>;
};

/** A Gravity Forms   field. */
export type HiddenField = FormField & {
  __typename?: 'HiddenField';
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']>;
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** Content node with hierarchical (parent/child) relationships */
export type HierarchicalContentNode = {
  /** Returns ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
  ancestors?: Maybe<HierarchicalContentNodeToContentNodeAncestorsConnection>;
  /** Connection between the HierarchicalContentNode type and the ContentNode type */
  children?: Maybe<HierarchicalContentNodeToContentNodeChildrenConnection>;
  /** The parent of the node. The parent object can be of various types */
  parent?: Maybe<HierarchicalContentNodeToParentContentNodeConnectionEdge>;
  /** Database id of the parent node */
  parentDatabaseId?: Maybe<Scalars['Int']>;
  /** The globally unique identifier of the parent node. */
  parentId?: Maybe<Scalars['ID']>;
};


/** Content node with hierarchical (parent/child) relationships */
export type HierarchicalContentNodeAncestorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs>;
};


/** Content node with hierarchical (parent/child) relationships */
export type HierarchicalContentNodeChildrenArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs>;
};

/** Connection between the HierarchicalContentNode type and the ContentNode type */
export type HierarchicalContentNodeToContentNodeAncestorsConnection = {
  __typename?: 'HierarchicalContentNodeToContentNodeAncestorsConnection';
  /** Edges for the HierarchicalContentNodeToContentNodeAncestorsConnection connection */
  edges?: Maybe<Array<Maybe<HierarchicalContentNodeToContentNodeAncestorsConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentNode>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type HierarchicalContentNodeToContentNodeAncestorsConnectionEdge = {
  __typename?: 'HierarchicalContentNodeToContentNodeAncestorsConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<ContentNode>;
};

/** Arguments for filtering the HierarchicalContentNodeToContentNodeAncestorsConnection connection */
export type HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** Connection between the HierarchicalContentNode type and the ContentNode type */
export type HierarchicalContentNodeToContentNodeChildrenConnection = {
  __typename?: 'HierarchicalContentNodeToContentNodeChildrenConnection';
  /** Edges for the HierarchicalContentNodeToContentNodeChildrenConnection connection */
  edges?: Maybe<Array<Maybe<HierarchicalContentNodeToContentNodeChildrenConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentNode>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type HierarchicalContentNodeToContentNodeChildrenConnectionEdge = {
  __typename?: 'HierarchicalContentNodeToContentNodeChildrenConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<ContentNode>;
};

/** Arguments for filtering the HierarchicalContentNodeToContentNodeChildrenConnection connection */
export type HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** Connection between the HierarchicalContentNode type and the ContentNode type */
export type HierarchicalContentNodeToParentContentNodeConnectionEdge = {
  __typename?: 'HierarchicalContentNodeToParentContentNodeConnectionEdge';
  /** The node of the connection, without the edges */
  node?: Maybe<ContentNode>;
};

/** Term node with hierarchical (parent/child) relationships */
export type HierarchicalTermNode = {
  /** Database id of the parent node */
  parentDatabaseId?: Maybe<Scalars['Int']>;
  /** The globally unique identifier of the parent node. */
  parentId?: Maybe<Scalars['ID']>;
};

/** A Gravity Forms   field. */
export type HtmlField = FormField & {
  __typename?: 'HtmlField';
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** Content of an HTML block field to be displayed on the form. */
  content?: Maybe<Scalars['String']>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the default margins are turned on to align the HTML content with other fields. */
  hasMargins?: Maybe<Scalars['Boolean']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** The individual properties for each element of the PostImage value field. */
export type ImageFieldValue = {
  __typename?: 'ImageFieldValue';
  /** The image alt text. */
  altText?: Maybe<Scalars['String']>;
  /** The path to the parent directory of the file. */
  basePath?: Maybe<Scalars['String']>;
  /** The base url to the parent directory of the file. */
  baseUrl?: Maybe<Scalars['String']>;
  /** The image caption. */
  caption?: Maybe<Scalars['String']>;
  /** The image description. */
  description?: Maybe<Scalars['String']>;
  /** The filename. */
  filename?: Maybe<Scalars['String']>;
  /** The image title. */
  title?: Maybe<Scalars['String']>;
  /** The url to the file. */
  url?: Maybe<Scalars['String']>;
};

/** A Gravity Forms   field. */
export type ListField = FormField & {
  __typename?: 'ListField';
  /** The URL of the image to be used for the add row button. */
  addIconUrl?: Maybe<Scalars['String']>;
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** The available choices for the list field. */
  choices?: Maybe<Array<Maybe<ListFieldChoice>>>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** The URL of the image to be used for the delete row button. */
  deleteIconUrl?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Determines if the field should use multiple columns. Default is false. */
  hasColumns?: Maybe<Scalars['Boolean']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']>;
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** List field value. */
  listValues?: Maybe<Array<Maybe<ListFieldValue>>>;
  /** The maximum number of rows the user can add to the field. */
  maxRows?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** List choice values. */
export type ListFieldChoice = {
  __typename?: 'ListFieldChoice';
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']>;
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']>;
};

/** Input fields for a single List field item. */
export type ListFieldInput = {
  /** Input values for the specific listField row. */
  rowValues?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** The individual properties for each element of the List value field. */
export type ListFieldValue = {
  __typename?: 'ListFieldValue';
  /** Input values. */
  values?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Input for the login mutation */
export type LoginInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The plain-text password for the user logging in. */
  password: Scalars['String'];
  /** The username used for login. Typically a unique or email address depending on specific configuration */
  username: Scalars['String'];
};

/** The payload for the login mutation */
export type LoginPayload = {
  __typename?: 'LoginPayload';
  /** JWT Token that can be used in future requests for Authentication */
  authToken?: Maybe<Scalars['String']>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** A JWT token that can be used in future requests to get a refreshed jwtAuthToken. If the refresh token used in a request is revoked or otherwise invalid, a valid Auth token will NOT be issued in the response headers. */
  refreshToken?: Maybe<Scalars['String']>;
  /** The user that was logged in */
  user?: Maybe<User>;
};

/** Input for the loginWithCookies mutation */
export type LoginWithCookiesInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Input your user/e-mail. */
  login: Scalars['String'];
  /** Input your password. */
  password: Scalars['String'];
  /** Whether to "remember" the user. Increases the time that the cookie will be kept. Default false. */
  rememberMe?: InputMaybe<Scalars['Boolean']>;
};

/** The payload for the loginWithCookies mutation */
export type LoginWithCookiesPayload = {
  __typename?: 'LoginWithCookiesPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Login operation status */
  status?: Maybe<Scalars['String']>;
};

/** Input for the logout mutation */
export type LogoutInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The payload for the logout mutation */
export type LogoutPayload = {
  __typename?: 'LogoutPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Logout operation status */
  status?: Maybe<Scalars['String']>;
};

/** File details for a Media Item */
export type MediaDetails = {
  __typename?: 'MediaDetails';
  /** The filename of the mediaItem */
  file?: Maybe<Scalars['String']>;
  /** The height of the mediaItem */
  height?: Maybe<Scalars['Int']>;
  /** Meta information associated with the mediaItem */
  meta?: Maybe<MediaItemMeta>;
  /** The available sizes of the mediaItem */
  sizes?: Maybe<Array<Maybe<MediaSize>>>;
  /** The width of the mediaItem */
  width?: Maybe<Scalars['Int']>;
};


/** File details for a Media Item */
export type MediaDetailsSizesArgs = {
  exclude?: InputMaybe<Array<InputMaybe<MediaItemSizeEnum>>>;
  include?: InputMaybe<Array<InputMaybe<MediaItemSizeEnum>>>;
};

/** The mediaItem type */
export type MediaItem = ContentNode & DatabaseIdentifier & HierarchicalContentNode & Node & NodeWithAuthor & NodeWithComments & NodeWithTemplate & NodeWithTitle & UniformResourceIdentifiable & {
  __typename?: 'MediaItem';
  Meta?: Maybe<MediaItem_Meta>;
  /** Alternative text to display when resource is not displayed */
  altText?: Maybe<Scalars['String']>;
  /** Returns ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
  ancestors?: Maybe<HierarchicalContentNodeToContentNodeAncestorsConnection>;
  /** Connection between the NodeWithAuthor type and the User type */
  author?: Maybe<NodeWithAuthorToUserConnectionEdge>;
  /** The database identifier of the author of the node */
  authorDatabaseId?: Maybe<Scalars['Int']>;
  /** The globally unique identifier of the author of the node */
  authorId?: Maybe<Scalars['ID']>;
  /** The caption for the resource */
  caption?: Maybe<Scalars['String']>;
  /** Connection between the HierarchicalContentNode type and the ContentNode type */
  children?: Maybe<HierarchicalContentNodeToContentNodeChildrenConnection>;
  /** The number of comments. Even though WPGraphQL denotes this field as an integer, in WordPress this field should be saved as a numeric string for compatibility. */
  commentCount?: Maybe<Scalars['Int']>;
  /** Whether the comments are open or closed for this particular post. */
  commentStatus?: Maybe<Scalars['String']>;
  /** Connection between the mediaItem type and the Comment type */
  comments?: Maybe<MediaItemToCommentConnection>;
  /** Connection between the ContentNode type and the ContentType type */
  contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /** The name of the Content Type the node belongs to */
  contentTypeName: Scalars['String'];
  /** The unique identifier stored in the database */
  databaseId: Scalars['Int'];
  /** Post publishing date. */
  date?: Maybe<Scalars['String']>;
  /** The publishing date set in GMT. */
  dateGmt?: Maybe<Scalars['String']>;
  /** Description of the image (stored as post_content) */
  description?: Maybe<Scalars['String']>;
  /** The desired slug of the post */
  desiredSlug?: Maybe<Scalars['String']>;
  /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
  editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
  /** The RSS enclosure for the object */
  enclosure?: Maybe<Scalars['String']>;
  /** Connection between the ContentNode type and the EnqueuedScript type */
  enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
  /** Connection between the ContentNode type and the EnqueuedStylesheet type */
  enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /** Source file name */
  fileName?: Maybe<Scalars['String']>;
  /** The filesize in bytes of the resource */
  fileSize?: Maybe<Scalars['Int']>;
  /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
  guid?: Maybe<Scalars['String']>;
  /** The globally unique identifier of the attachment object. */
  id: Scalars['ID'];
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean'];
  /** Whether the object is a node in the preview state */
  isPreview?: Maybe<Scalars['Boolean']>;
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']>;
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean'];
  /** The user that most recently edited the node */
  lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
  /** The permalink of the post */
  link?: Maybe<Scalars['String']>;
  /** Details about the mediaItem */
  mediaDetails?: Maybe<MediaDetails>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of the databaseId field
   */
  mediaItemId: Scalars['Int'];
  /** Url of the mediaItem */
  mediaItemUrl?: Maybe<Scalars['String']>;
  /** Type of resource */
  mediaType?: Maybe<Scalars['String']>;
  /** The mime type of the mediaItem */
  mimeType?: Maybe<Scalars['String']>;
  /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
  modified?: Maybe<Scalars['String']>;
  /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
  modifiedGmt?: Maybe<Scalars['String']>;
  /** The parent of the node. The parent object can be of various types */
  parent?: Maybe<HierarchicalContentNodeToParentContentNodeConnectionEdge>;
  /** Database id of the parent node */
  parentDatabaseId?: Maybe<Scalars['Int']>;
  /** The globally unique identifier of the parent node. */
  parentId?: Maybe<Scalars['ID']>;
  /** The database id of the preview node */
  previewRevisionDatabaseId?: Maybe<Scalars['Int']>;
  /** Whether the object is a node in the preview state */
  previewRevisionId?: Maybe<Scalars['ID']>;
  /** The sizes attribute value for an image. */
  sizes?: Maybe<Scalars['String']>;
  /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
  slug?: Maybe<Scalars['String']>;
  /** Url of the mediaItem */
  sourceUrl?: Maybe<Scalars['String']>;
  /** The srcset attribute specifies the URL of the image to use in different situations. It is a comma separated string of urls and their widths. */
  srcSet?: Maybe<Scalars['String']>;
  /** The current status of the object */
  status?: Maybe<Scalars['String']>;
  /** The template assigned to the node */
  template?: Maybe<ContentTemplate>;
  /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
  title?: Maybe<Scalars['String']>;
  /** The unique resource identifier path */
  uri?: Maybe<Scalars['String']>;
};


/** The mediaItem type */
export type MediaItemAncestorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs>;
};


/** The mediaItem type */
export type MediaItemCaptionArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};


/** The mediaItem type */
export type MediaItemChildrenArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs>;
};


/** The mediaItem type */
export type MediaItemCommentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MediaItemToCommentConnectionWhereArgs>;
};


/** The mediaItem type */
export type MediaItemDescriptionArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};


/** The mediaItem type */
export type MediaItemEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The mediaItem type */
export type MediaItemEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The mediaItem type */
export type MediaItemFileSizeArgs = {
  size?: InputMaybe<MediaItemSizeEnum>;
};


/** The mediaItem type */
export type MediaItemSizesArgs = {
  size?: InputMaybe<MediaItemSizeEnum>;
};


/** The mediaItem type */
export type MediaItemSourceUrlArgs = {
  size?: InputMaybe<MediaItemSizeEnum>;
};


/** The mediaItem type */
export type MediaItemSrcSetArgs = {
  size?: InputMaybe<MediaItemSizeEnum>;
};


/** The mediaItem type */
export type MediaItemTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum MediaItemIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a media item by its source url */
  SourceUrl = 'SOURCE_URL',
  /** Identify a resource by the URI. */
  Uri = 'URI'
}

/** Meta connected to a MediaItem */
export type MediaItemMeta = {
  __typename?: 'MediaItemMeta';
  /** Aperture measurement of the media item. */
  aperture?: Maybe<Scalars['Float']>;
  /** Information about the camera used to create the media item. */
  camera?: Maybe<Scalars['String']>;
  /** The text string description associated with the media item. */
  caption?: Maybe<Scalars['String']>;
  /** Copyright information associated with the media item. */
  copyright?: Maybe<Scalars['String']>;
  /** The date/time when the media was created. */
  createdTimestamp?: Maybe<Scalars['Int']>;
  /** The original creator of the media item. */
  credit?: Maybe<Scalars['String']>;
  /** The focal length value of the media item. */
  focalLength?: Maybe<Scalars['Float']>;
  /** The ISO (International Organization for Standardization) value of the media item. */
  iso?: Maybe<Scalars['Int']>;
  /** List of keywords used to describe or identfy the media item. */
  keywords?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The vertical or horizontal aspect of the media item. */
  orientation?: Maybe<Scalars['String']>;
  /** The shutter speed information of the media item. */
  shutterSpeed?: Maybe<Scalars['Float']>;
  /** A useful title for the media item. */
  title?: Maybe<Scalars['String']>;
};

/** The size of the media item object. */
export enum MediaItemSizeEnum {
  /** MediaItem with the large size */
  Large = 'LARGE',
  /** MediaItem with the medium size */
  Medium = 'MEDIUM',
  /** MediaItem with the medium_large size */
  MediumLarge = 'MEDIUM_LARGE',
  /** MediaItem with the thumbnail size */
  Thumbnail = 'THUMBNAIL',
  /** MediaItem with the 1536x1536 size */
  '1536X1536' = '_1536X1536',
  /** MediaItem with the 2048x2048 size */
  '2048X2048' = '_2048X2048'
}

/** The status of the media item object. */
export enum MediaItemStatusEnum {
  /** Objects with the auto-draft status */
  AutoDraft = 'AUTO_DRAFT',
  /** Objects with the inherit status */
  Inherit = 'INHERIT',
  /** Objects with the private status */
  Private = 'PRIVATE',
  /** Objects with the trash status */
  Trash = 'TRASH'
}

/** Connection between the mediaItem type and the Comment type */
export type MediaItemToCommentConnection = {
  __typename?: 'MediaItemToCommentConnection';
  /** Edges for the MediaItemToCommentConnection connection */
  edges?: Maybe<Array<Maybe<MediaItemToCommentConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Comment>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type MediaItemToCommentConnectionEdge = {
  __typename?: 'MediaItemToCommentConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<Comment>;
};

/** Arguments for filtering the MediaItemToCommentConnection connection */
export type MediaItemToCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail?: InputMaybe<Scalars['String']>;
  /** Array of author IDs to include comments for. */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Comment author URL. */
  authorUrl?: InputMaybe<Scalars['String']>;
  /** Array of comment IDs to include. */
  commentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Include comments of a given type. */
  commentType?: InputMaybe<Scalars['String']>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: InputMaybe<Scalars['String']>;
  /** Content object author ID to limit results by. */
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: InputMaybe<Scalars['ID']>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Content object name to retrieve affiliated comments for. */
  contentName?: InputMaybe<Scalars['String']>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: InputMaybe<Scalars['Int']>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: InputMaybe<Scalars['Int']>;
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: InputMaybe<Scalars['String']>;
  /** Comment status to limit results by. */
  status?: InputMaybe<Scalars['String']>;
  /** Include comments for a specific user ID. */
  userId?: InputMaybe<Scalars['ID']>;
};

/** Field Group */
export type MediaItem_Meta = {
  __typename?: 'MediaItem_Meta';
  fieldGroupName?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  /** If left blank the page or post title will be used. */
  metaTitle?: Maybe<Scalars['String']>;
  ogDescription?: Maybe<Scalars['String']>;
  ogImage?: Maybe<MediaItem>;
  ogTitle?: Maybe<Scalars['String']>;
  twitterCardType?: Maybe<Scalars['String']>;
  twitterDescription?: Maybe<Scalars['String']>;
  twitterImage?: Maybe<MediaItem>;
  twitterTitle?: Maybe<Scalars['String']>;
};

/** Details of an available size for a media item */
export type MediaSize = {
  __typename?: 'MediaSize';
  /** The filename of the referenced size */
  file?: Maybe<Scalars['String']>;
  /** The filesize of the resource */
  fileSize?: Maybe<Scalars['Int']>;
  /** The height of the referenced size */
  height?: Maybe<Scalars['String']>;
  /** The mime type of the referenced size */
  mimeType?: Maybe<Scalars['String']>;
  /** The referenced size name */
  name?: Maybe<Scalars['String']>;
  /** The url of the referenced size */
  sourceUrl?: Maybe<Scalars['String']>;
  /** The width of the referenced size */
  width?: Maybe<Scalars['String']>;
};

/** The member type */
export type Member = ContentNode & DatabaseIdentifier & MenuItemLinkable & Node & NodeWithFeaturedImage & NodeWithTemplate & NodeWithTitle & UniformResourceIdentifiable & {
  __typename?: 'Member';
  Meta?: Maybe<Member_Meta>;
  /** Connection between the member type and the areaOfSpecialism type */
  areasOfSpecialism?: Maybe<MemberToAreaOfSpecialismConnection>;
  /** Connection between the ContentNode type and the ContentType type */
  contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /** The name of the Content Type the node belongs to */
  contentTypeName: Scalars['String'];
  /** The unique resource identifier path */
  databaseId: Scalars['Int'];
  /** Post publishing date. */
  date?: Maybe<Scalars['String']>;
  /** The publishing date set in GMT. */
  dateGmt?: Maybe<Scalars['String']>;
  /** The desired slug of the post */
  desiredSlug?: Maybe<Scalars['String']>;
  /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
  editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
  /** The RSS enclosure for the object */
  enclosure?: Maybe<Scalars['String']>;
  /** Connection between the ContentNode type and the EnqueuedScript type */
  enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
  /** Connection between the ContentNode type and the EnqueuedStylesheet type */
  enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /** Connection between the NodeWithFeaturedImage type and the MediaItem type */
  featuredImage?: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
  /** The database identifier for the featured image node assigned to the content node */
  featuredImageDatabaseId?: Maybe<Scalars['Int']>;
  /** Globally unique ID of the featured image assigned to the node */
  featuredImageId?: Maybe<Scalars['ID']>;
  /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
  guid?: Maybe<Scalars['String']>;
  /** The globally unique identifier of the member object. */
  id: Scalars['ID'];
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean'];
  /** Whether the object is a node in the preview state */
  isPreview?: Maybe<Scalars['Boolean']>;
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']>;
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean'];
  /** The user that most recently edited the node */
  lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
  /** The permalink of the post */
  link?: Maybe<Scalars['String']>;
  memberDetails?: Maybe<Member_Memberdetails>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of the databaseId field
   */
  memberId: Scalars['Int'];
  /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
  modified?: Maybe<Scalars['String']>;
  /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
  modifiedGmt?: Maybe<Scalars['String']>;
  /** Connection between the member type and the member type */
  preview?: Maybe<MemberToPreviewConnectionEdge>;
  /** The database id of the preview node */
  previewRevisionDatabaseId?: Maybe<Scalars['Int']>;
  /** Whether the object is a node in the preview state */
  previewRevisionId?: Maybe<Scalars['ID']>;
  /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
  slug?: Maybe<Scalars['String']>;
  /** The current status of the object */
  status?: Maybe<Scalars['String']>;
  /** The template assigned to a node of content */
  template?: Maybe<ContentTemplate>;
  /** Connection between the member type and the TermNode type */
  terms?: Maybe<MemberToTermNodeConnection>;
  /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
  title?: Maybe<Scalars['String']>;
  /** Connection between the member type and the tradingTime type */
  tradingTimes?: Maybe<MemberToTradingTimeConnection>;
  /** The unique resource identifier path */
  uri?: Maybe<Scalars['String']>;
};


/** The member type */
export type MemberAreasOfSpecialismArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MemberToAreaOfSpecialismConnectionWhereArgs>;
};


/** The member type */
export type MemberEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The member type */
export type MemberEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The member type */
export type MemberTermsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MemberToTermNodeConnectionWhereArgs>;
};


/** The member type */
export type MemberTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};


/** The member type */
export type MemberTradingTimesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MemberToTradingTimeConnectionWhereArgs>;
};

/** Set relationships between the member to areasOfSpecialism */
export type MemberAreasOfSpecialismInput = {
  /** If true, this will append the areaOfSpecialism to existing related areasOfSpecialism. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<MemberAreasOfSpecialismNodeInput>>>;
};

/** List of areasOfSpecialism to connect the member to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type MemberAreasOfSpecialismNodeInput = {
  /** The description of the areaOfSpecialism. This field is used to set a description of the areaOfSpecialism if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']>;
  /** The ID of the areaOfSpecialism. If present, this will be used to connect to the member. If no existing areaOfSpecialism exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']>;
  /** The name of the areaOfSpecialism. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']>;
  /** The slug of the areaOfSpecialism. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum MemberIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a resource by the URI. */
  Uri = 'URI'
}

/** Connection between the member type and the areaOfSpecialism type */
export type MemberToAreaOfSpecialismConnection = {
  __typename?: 'MemberToAreaOfSpecialismConnection';
  /** Edges for the MemberToAreaOfSpecialismConnection connection */
  edges?: Maybe<Array<Maybe<MemberToAreaOfSpecialismConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<AreaOfSpecialism>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type MemberToAreaOfSpecialismConnectionEdge = {
  __typename?: 'MemberToAreaOfSpecialismConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<AreaOfSpecialism>;
};

/** Arguments for filtering the MemberToAreaOfSpecialismConnection connection */
export type MemberToAreaOfSpecialismConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
};

/** Connection between the member type and the member type */
export type MemberToPreviewConnectionEdge = {
  __typename?: 'MemberToPreviewConnectionEdge';
  /** The node of the connection, without the edges */
  node?: Maybe<Member>;
};

/** Connection between the member type and the TermNode type */
export type MemberToTermNodeConnection = {
  __typename?: 'MemberToTermNodeConnection';
  /** Edges for the MemberToTermNodeConnection connection */
  edges?: Maybe<Array<Maybe<MemberToTermNodeConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<TermNode>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type MemberToTermNodeConnectionEdge = {
  __typename?: 'MemberToTermNodeConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<TermNode>;
};

/** Arguments for filtering the MemberToTermNodeConnection connection */
export type MemberToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** The Taxonomy to filter terms by */
  taxonomies?: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
};

/** Connection between the member type and the tradingTime type */
export type MemberToTradingTimeConnection = {
  __typename?: 'MemberToTradingTimeConnection';
  /** Edges for the MemberToTradingTimeConnection connection */
  edges?: Maybe<Array<Maybe<MemberToTradingTimeConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<TradingTime>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type MemberToTradingTimeConnectionEdge = {
  __typename?: 'MemberToTradingTimeConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<TradingTime>;
};

/** Arguments for filtering the MemberToTradingTimeConnection connection */
export type MemberToTradingTimeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
};

/** Set relationships between the member to tradingTimes */
export type MemberTradingTimesInput = {
  /** If true, this will append the tradingTime to existing related tradingTimes. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<MemberTradingTimesNodeInput>>>;
};

/** List of tradingTimes to connect the member to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type MemberTradingTimesNodeInput = {
  /** The description of the tradingTime. This field is used to set a description of the tradingTime if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']>;
  /** The ID of the tradingTime. If present, this will be used to connect to the member. If no existing tradingTime exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']>;
  /** The name of the tradingTime. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']>;
  /** The slug of the tradingTime. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']>;
};

/** Field Group */
export type Member_Memberdetails = {
  __typename?: 'Member_Memberdetails';
  clinicId?: Maybe<Scalars['String']>;
  fieldGroupName?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
};

/** Field Group */
export type Member_Meta = {
  __typename?: 'Member_Meta';
  fieldGroupName?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  /** If left blank the page or post title will be used. */
  metaTitle?: Maybe<Scalars['String']>;
  ogDescription?: Maybe<Scalars['String']>;
  ogImage?: Maybe<MediaItem>;
  ogTitle?: Maybe<Scalars['String']>;
  twitterCardType?: Maybe<Scalars['String']>;
  twitterDescription?: Maybe<Scalars['String']>;
  twitterImage?: Maybe<MediaItem>;
  twitterTitle?: Maybe<Scalars['String']>;
};

/** Menus are the containers for navigation items. Menus can be assigned to menu locations, which are typically registered by the active theme. */
export type Menu = DatabaseIdentifier & Node & {
  __typename?: 'Menu';
  /** The number of items in the menu */
  count?: Maybe<Scalars['Int']>;
  /** The unique identifier stored in the database */
  databaseId: Scalars['Int'];
  /** The globally unique identifier of the nav menu object. */
  id: Scalars['ID'];
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']>;
  /** The locations a menu is assigned to */
  locations?: Maybe<Array<Maybe<MenuLocationEnum>>>;
  /**
   * WP ID of the nav menu.
   * @deprecated Deprecated in favor of the databaseId field
   */
  menuId?: Maybe<Scalars['Int']>;
  /** Connection between the Menu type and the MenuItem type */
  menuItems?: Maybe<MenuToMenuItemConnection>;
  /** Display name of the menu. Equivalent to WP_Term-&gt;name. */
  name?: Maybe<Scalars['String']>;
  /** The url friendly name of the menu. Equivalent to WP_Term-&gt;slug */
  slug?: Maybe<Scalars['String']>;
};


/** Menus are the containers for navigation items. Menus can be assigned to menu locations, which are typically registered by the active theme. */
export type MenuMenuItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MenuToMenuItemConnectionWhereArgs>;
};

/** Navigation menu items are the individual items assigned to a menu. These are rendered as the links in a navigation menu. */
export type MenuItem = DatabaseIdentifier & Node & {
  __typename?: 'MenuItem';
  /** Added to the GraphQL Schema because the ACF Field Group &quot;Nav Image&quot; was assigned to Menu Items */
  NavMenuImage?: Maybe<MenuItem_Navmenuimage>;
  /** Connection between the MenuItem type and the MenuItem type */
  childItems?: Maybe<MenuItemToMenuItemConnection>;
  /** Connection from MenuItem to it&#039;s connected node */
  connectedNode?: Maybe<MenuItemToMenuItemLinkableConnectionEdge>;
  /**
   * The object connected to this menu item.
   * @deprecated Deprecated in favor of the connectedNode field
   */
  connectedObject?: Maybe<MenuItemObjectUnion>;
  /** Class attribute for the menu item link */
  cssClasses?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The unique identifier stored in the database */
  databaseId: Scalars['Int'];
  /** Description of the menu item. */
  description?: Maybe<Scalars['String']>;
  /** The globally unique identifier of the nav menu item object. */
  id: Scalars['ID'];
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']>;
  /** Label or title of the menu item. */
  label?: Maybe<Scalars['String']>;
  /** Link relationship (XFN) of the menu item. */
  linkRelationship?: Maybe<Scalars['String']>;
  /** The locations the menu item&#039;s Menu is assigned to */
  locations?: Maybe<Array<Maybe<MenuLocationEnum>>>;
  /** The Menu a MenuItem is part of */
  menu?: Maybe<MenuItemToMenuConnectionEdge>;
  /**
   * WP ID of the menu item.
   * @deprecated Deprecated in favor of the databaseId field
   */
  menuItemId?: Maybe<Scalars['Int']>;
  /** Menu item order */
  order?: Maybe<Scalars['Int']>;
  /** The database id of the parent menu item or null if it is the root */
  parentDatabaseId?: Maybe<Scalars['Int']>;
  /** The globally unique identifier of the parent nav menu item object. */
  parentId?: Maybe<Scalars['ID']>;
  /** Path for the resource. Relative path for internal resources. Absolute path for external resources. */
  path?: Maybe<Scalars['String']>;
  /** Target attribute for the menu item link. */
  target?: Maybe<Scalars['String']>;
  /** Title attribute for the menu item link */
  title?: Maybe<Scalars['String']>;
  /** The uri of the resource the menu item links to */
  uri?: Maybe<Scalars['String']>;
  /** URL or destination of the menu item. */
  url?: Maybe<Scalars['String']>;
};


/** Navigation menu items are the individual items assigned to a menu. These are rendered as the links in a navigation menu. */
export type MenuItemChildItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MenuItemToMenuItemConnectionWhereArgs>;
};

/** Nodes that can be linked to as Menu Items */
export type MenuItemLinkable = {
  /** The unique resource identifier path */
  databaseId: Scalars['Int'];
  /** The unique resource identifier path */
  id: Scalars['ID'];
  /** The unique resource identifier path */
  uri?: Maybe<Scalars['String']>;
};

/** The Type of Identifier used to fetch a single node. Default is "ID". To be used along with the "id" field. */
export enum MenuItemNodeIdTypeEnum {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID'
}

/** Deprecated in favor of MenuItemLinkeable Interface */
export type MenuItemObjectUnion = Account_Manager | AreaOfSpecialism | Category | Group | Member | Page | Post | Region | Supplier | SupplierCategory | SupplierKeyword | Supplier_News | Tag | Testimonial | TradingTime;

/** Connection between the MenuItem type and the Menu type */
export type MenuItemToMenuConnectionEdge = {
  __typename?: 'MenuItemToMenuConnectionEdge';
  /** The node of the connection, without the edges */
  node?: Maybe<Menu>;
};

/** Connection between the MenuItem type and the MenuItem type */
export type MenuItemToMenuItemConnection = {
  __typename?: 'MenuItemToMenuItemConnection';
  /** Edges for the MenuItemToMenuItemConnection connection */
  edges?: Maybe<Array<Maybe<MenuItemToMenuItemConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<MenuItem>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type MenuItemToMenuItemConnectionEdge = {
  __typename?: 'MenuItemToMenuItemConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<MenuItem>;
};

/** Arguments for filtering the MenuItemToMenuItemConnection connection */
export type MenuItemToMenuItemConnectionWhereArgs = {
  /** The database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** The menu location for the menu being queried */
  location?: InputMaybe<MenuLocationEnum>;
  /** The database ID of the parent menu object */
  parentDatabaseId?: InputMaybe<Scalars['Int']>;
  /** The ID of the parent menu object */
  parentId?: InputMaybe<Scalars['ID']>;
};

/** Connection between the MenuItem type and the MenuItemLinkable type */
export type MenuItemToMenuItemLinkableConnectionEdge = {
  __typename?: 'MenuItemToMenuItemLinkableConnectionEdge';
  /** The node of the connection, without the edges */
  node?: Maybe<MenuItemLinkable>;
};

/** Field Group */
export type MenuItem_Navmenuimage = {
  __typename?: 'MenuItem_Navmenuimage';
  fieldGroupName?: Maybe<Scalars['String']>;
  image?: Maybe<MediaItem>;
};

/** Registered menu locations */
export enum MenuLocationEnum {
  /** Put the menu in the footer-menu location */
  FooterMenu = 'FOOTER_MENU',
  /** Put the menu in the header-menu location */
  HeaderMenu = 'HEADER_MENU',
  /** Put the menu in the member-menu location */
  MemberMenu = 'MEMBER_MENU',
  /** Put the menu in the supplier-menu location */
  SupplierMenu = 'SUPPLIER_MENU'
}

/** The Type of Identifier used to fetch a single node. Default is "ID". To be used along with the "id" field. */
export enum MenuNodeIdTypeEnum {
  /** Identify a menu node by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a menu node by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a menu node by the slug of menu location to which it is assigned */
  Location = 'LOCATION',
  /** Identify a menu node by its name */
  Name = 'NAME',
  /** Identify a menu node by its slug */
  Slug = 'SLUG'
}

/** Connection between the Menu type and the MenuItem type */
export type MenuToMenuItemConnection = {
  __typename?: 'MenuToMenuItemConnection';
  /** Edges for the MenuToMenuItemConnection connection */
  edges?: Maybe<Array<Maybe<MenuToMenuItemConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<MenuItem>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type MenuToMenuItemConnectionEdge = {
  __typename?: 'MenuToMenuItemConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<MenuItem>;
};

/** Arguments for filtering the MenuToMenuItemConnection connection */
export type MenuToMenuItemConnectionWhereArgs = {
  /** The database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** The menu location for the menu being queried */
  location?: InputMaybe<MenuLocationEnum>;
  /** The database ID of the parent menu object */
  parentDatabaseId?: InputMaybe<Scalars['Int']>;
  /** The ID of the parent menu object */
  parentId?: InputMaybe<Scalars['ID']>;
};

/** The MimeType of the object */
export enum MimeTypeEnum {
  /** MimeType application/java */
  ApplicationJava = 'APPLICATION_JAVA',
  /** MimeType application/msword */
  ApplicationMsword = 'APPLICATION_MSWORD',
  /** MimeType application/octet-stream */
  ApplicationOctetStream = 'APPLICATION_OCTET_STREAM',
  /** MimeType application/onenote */
  ApplicationOnenote = 'APPLICATION_ONENOTE',
  /** MimeType application/oxps */
  ApplicationOxps = 'APPLICATION_OXPS',
  /** MimeType application/pdf */
  ApplicationPdf = 'APPLICATION_PDF',
  /** MimeType application/rar */
  ApplicationRar = 'APPLICATION_RAR',
  /** MimeType application/rtf */
  ApplicationRtf = 'APPLICATION_RTF',
  /** MimeType application/ttaf+xml */
  ApplicationTtafXml = 'APPLICATION_TTAF_XML',
  /** MimeType application/vnd.apple.keynote */
  ApplicationVndAppleKeynote = 'APPLICATION_VND_APPLE_KEYNOTE',
  /** MimeType application/vnd.apple.numbers */
  ApplicationVndAppleNumbers = 'APPLICATION_VND_APPLE_NUMBERS',
  /** MimeType application/vnd.apple.pages */
  ApplicationVndApplePages = 'APPLICATION_VND_APPLE_PAGES',
  /** MimeType application/vnd.ms-access */
  ApplicationVndMsAccess = 'APPLICATION_VND_MS_ACCESS',
  /** MimeType application/vnd.ms-excel */
  ApplicationVndMsExcel = 'APPLICATION_VND_MS_EXCEL',
  /** MimeType application/vnd.ms-excel.addin.macroEnabled.12 */
  ApplicationVndMsExcelAddinMacroenabled_12 = 'APPLICATION_VND_MS_EXCEL_ADDIN_MACROENABLED_12',
  /** MimeType application/vnd.ms-excel.sheet.binary.macroEnabled.12 */
  ApplicationVndMsExcelSheetBinaryMacroenabled_12 = 'APPLICATION_VND_MS_EXCEL_SHEET_BINARY_MACROENABLED_12',
  /** MimeType application/vnd.ms-excel.sheet.macroEnabled.12 */
  ApplicationVndMsExcelSheetMacroenabled_12 = 'APPLICATION_VND_MS_EXCEL_SHEET_MACROENABLED_12',
  /** MimeType application/vnd.ms-excel.template.macroEnabled.12 */
  ApplicationVndMsExcelTemplateMacroenabled_12 = 'APPLICATION_VND_MS_EXCEL_TEMPLATE_MACROENABLED_12',
  /** MimeType application/vnd.ms-powerpoint */
  ApplicationVndMsPowerpoint = 'APPLICATION_VND_MS_POWERPOINT',
  /** MimeType application/vnd.ms-powerpoint.addin.macroEnabled.12 */
  ApplicationVndMsPowerpointAddinMacroenabled_12 = 'APPLICATION_VND_MS_POWERPOINT_ADDIN_MACROENABLED_12',
  /** MimeType application/vnd.ms-powerpoint.presentation.macroEnabled.12 */
  ApplicationVndMsPowerpointPresentationMacroenabled_12 = 'APPLICATION_VND_MS_POWERPOINT_PRESENTATION_MACROENABLED_12',
  /** MimeType application/vnd.ms-powerpoint.slideshow.macroEnabled.12 */
  ApplicationVndMsPowerpointSlideshowMacroenabled_12 = 'APPLICATION_VND_MS_POWERPOINT_SLIDESHOW_MACROENABLED_12',
  /** MimeType application/vnd.ms-powerpoint.slide.macroEnabled.12 */
  ApplicationVndMsPowerpointSlideMacroenabled_12 = 'APPLICATION_VND_MS_POWERPOINT_SLIDE_MACROENABLED_12',
  /** MimeType application/vnd.ms-powerpoint.template.macroEnabled.12 */
  ApplicationVndMsPowerpointTemplateMacroenabled_12 = 'APPLICATION_VND_MS_POWERPOINT_TEMPLATE_MACROENABLED_12',
  /** MimeType application/vnd.ms-project */
  ApplicationVndMsProject = 'APPLICATION_VND_MS_PROJECT',
  /** MimeType application/vnd.ms-word.document.macroEnabled.12 */
  ApplicationVndMsWordDocumentMacroenabled_12 = 'APPLICATION_VND_MS_WORD_DOCUMENT_MACROENABLED_12',
  /** MimeType application/vnd.ms-word.template.macroEnabled.12 */
  ApplicationVndMsWordTemplateMacroenabled_12 = 'APPLICATION_VND_MS_WORD_TEMPLATE_MACROENABLED_12',
  /** MimeType application/vnd.ms-write */
  ApplicationVndMsWrite = 'APPLICATION_VND_MS_WRITE',
  /** MimeType application/vnd.ms-xpsdocument */
  ApplicationVndMsXpsdocument = 'APPLICATION_VND_MS_XPSDOCUMENT',
  /** MimeType application/vnd.oasis.opendocument.chart */
  ApplicationVndOasisOpendocumentChart = 'APPLICATION_VND_OASIS_OPENDOCUMENT_CHART',
  /** MimeType application/vnd.oasis.opendocument.database */
  ApplicationVndOasisOpendocumentDatabase = 'APPLICATION_VND_OASIS_OPENDOCUMENT_DATABASE',
  /** MimeType application/vnd.oasis.opendocument.formula */
  ApplicationVndOasisOpendocumentFormula = 'APPLICATION_VND_OASIS_OPENDOCUMENT_FORMULA',
  /** MimeType application/vnd.oasis.opendocument.graphics */
  ApplicationVndOasisOpendocumentGraphics = 'APPLICATION_VND_OASIS_OPENDOCUMENT_GRAPHICS',
  /** MimeType application/vnd.oasis.opendocument.presentation */
  ApplicationVndOasisOpendocumentPresentation = 'APPLICATION_VND_OASIS_OPENDOCUMENT_PRESENTATION',
  /** MimeType application/vnd.oasis.opendocument.spreadsheet */
  ApplicationVndOasisOpendocumentSpreadsheet = 'APPLICATION_VND_OASIS_OPENDOCUMENT_SPREADSHEET',
  /** MimeType application/vnd.oasis.opendocument.text */
  ApplicationVndOasisOpendocumentText = 'APPLICATION_VND_OASIS_OPENDOCUMENT_TEXT',
  /** MimeType application/vnd.openxmlformats-officedocument.presentationml.presentation */
  ApplicationVndOpenxmlformatsOfficedocumentPresentationmlPresentation = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_PRESENTATION',
  /** MimeType application/vnd.openxmlformats-officedocument.presentationml.slide */
  ApplicationVndOpenxmlformatsOfficedocumentPresentationmlSlide = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_SLIDE',
  /** MimeType application/vnd.openxmlformats-officedocument.presentationml.slideshow */
  ApplicationVndOpenxmlformatsOfficedocumentPresentationmlSlideshow = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_SLIDESHOW',
  /** MimeType application/vnd.openxmlformats-officedocument.presentationml.template */
  ApplicationVndOpenxmlformatsOfficedocumentPresentationmlTemplate = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_TEMPLATE',
  /** MimeType application/vnd.openxmlformats-officedocument.spreadsheetml.sheet */
  ApplicationVndOpenxmlformatsOfficedocumentSpreadsheetmlSheet = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_SPREADSHEETML_SHEET',
  /** MimeType application/vnd.openxmlformats-officedocument.spreadsheetml.template */
  ApplicationVndOpenxmlformatsOfficedocumentSpreadsheetmlTemplate = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_SPREADSHEETML_TEMPLATE',
  /** MimeType application/vnd.openxmlformats-officedocument.wordprocessingml.document */
  ApplicationVndOpenxmlformatsOfficedocumentWordprocessingmlDocument = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_WORDPROCESSINGML_DOCUMENT',
  /** MimeType application/vnd.openxmlformats-officedocument.wordprocessingml.template */
  ApplicationVndOpenxmlformatsOfficedocumentWordprocessingmlTemplate = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_WORDPROCESSINGML_TEMPLATE',
  /** MimeType application/wordperfect */
  ApplicationWordperfect = 'APPLICATION_WORDPERFECT',
  /** MimeType application/x-7z-compressed */
  ApplicationX_7ZCompressed = 'APPLICATION_X_7Z_COMPRESSED',
  /** MimeType application/x-gzip */
  ApplicationXGzip = 'APPLICATION_X_GZIP',
  /** MimeType application/x-tar */
  ApplicationXTar = 'APPLICATION_X_TAR',
  /** MimeType application/zip */
  ApplicationZip = 'APPLICATION_ZIP',
  /** MimeType audio/aac */
  AudioAac = 'AUDIO_AAC',
  /** MimeType audio/flac */
  AudioFlac = 'AUDIO_FLAC',
  /** MimeType audio/midi */
  AudioMidi = 'AUDIO_MIDI',
  /** MimeType audio/mpeg */
  AudioMpeg = 'AUDIO_MPEG',
  /** MimeType audio/ogg */
  AudioOgg = 'AUDIO_OGG',
  /** MimeType audio/wav */
  AudioWav = 'AUDIO_WAV',
  /** MimeType audio/x-matroska */
  AudioXMatroska = 'AUDIO_X_MATROSKA',
  /** MimeType audio/x-ms-wax */
  AudioXMsWax = 'AUDIO_X_MS_WAX',
  /** MimeType audio/x-ms-wma */
  AudioXMsWma = 'AUDIO_X_MS_WMA',
  /** MimeType audio/x-realaudio */
  AudioXRealaudio = 'AUDIO_X_REALAUDIO',
  /** MimeType image/bmp */
  ImageBmp = 'IMAGE_BMP',
  /** MimeType image/gif */
  ImageGif = 'IMAGE_GIF',
  /** MimeType image/heic */
  ImageHeic = 'IMAGE_HEIC',
  /** MimeType image/jpeg */
  ImageJpeg = 'IMAGE_JPEG',
  /** MimeType image/png */
  ImagePng = 'IMAGE_PNG',
  /** MimeType image/tiff */
  ImageTiff = 'IMAGE_TIFF',
  /** MimeType image/webp */
  ImageWebp = 'IMAGE_WEBP',
  /** MimeType image/x-icon */
  ImageXIcon = 'IMAGE_X_ICON',
  /** MimeType text/calendar */
  TextCalendar = 'TEXT_CALENDAR',
  /** MimeType text/css */
  TextCss = 'TEXT_CSS',
  /** MimeType text/csv */
  TextCsv = 'TEXT_CSV',
  /** MimeType text/plain */
  TextPlain = 'TEXT_PLAIN',
  /** MimeType text/richtext */
  TextRichtext = 'TEXT_RICHTEXT',
  /** MimeType text/tab-separated-values */
  TextTabSeparatedValues = 'TEXT_TAB_SEPARATED_VALUES',
  /** MimeType text/vtt */
  TextVtt = 'TEXT_VTT',
  /** MimeType video/3gpp */
  Video_3Gpp = 'VIDEO_3GPP',
  /** MimeType video/3gpp2 */
  Video_3Gpp2 = 'VIDEO_3GPP2',
  /** MimeType video/avi */
  VideoAvi = 'VIDEO_AVI',
  /** MimeType video/divx */
  VideoDivx = 'VIDEO_DIVX',
  /** MimeType video/mp4 */
  VideoMp4 = 'VIDEO_MP4',
  /** MimeType video/mpeg */
  VideoMpeg = 'VIDEO_MPEG',
  /** MimeType video/ogg */
  VideoOgg = 'VIDEO_OGG',
  /** MimeType video/quicktime */
  VideoQuicktime = 'VIDEO_QUICKTIME',
  /** MimeType video/webm */
  VideoWebm = 'VIDEO_WEBM',
  /** MimeType video/x-flv */
  VideoXFlv = 'VIDEO_X_FLV',
  /** MimeType video/x-matroska */
  VideoXMatroska = 'VIDEO_X_MATROSKA',
  /** MimeType video/x-ms-asf */
  VideoXMsAsf = 'VIDEO_X_MS_ASF',
  /** MimeType video/x-ms-wm */
  VideoXMsWm = 'VIDEO_X_MS_WM',
  /** MimeType video/x-ms-wmv */
  VideoXMsWmv = 'VIDEO_X_MS_WMV',
  /** MimeType video/x-ms-wmx */
  VideoXMsWmx = 'VIDEO_X_MS_WMX'
}

/** A Gravity Forms   field. */
export type MultiSelectField = FormField & {
  __typename?: 'MultiSelectField';
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** The available choices for the multiselect field. */
  choices?: Maybe<Array<Maybe<MultiSelectFieldChoice>>>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
  hasChoiceValue?: Maybe<Scalars['Boolean']>;
  /** When set to true, the &quot;Chosen&quot; jQuery script will be applied to this field, enabling search capabilities to Drop Down fields and a more user-friendly interface for Multi Select fields. */
  hasEnhancedUI?: Maybe<Scalars['Boolean']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']>;
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** An array of field values. */
  values?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** Multiselect choice values. */
export type MultiSelectFieldChoice = {
  __typename?: 'MultiSelectFieldChoice';
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']>;
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']>;
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']>;
};

/** A Gravity Forms   field. */
export type NameField = FormField & {
  __typename?: 'NameField';
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Whether autocomplete should be enabled for this field. */
  hasAutocomplete?: Maybe<Scalars['Boolean']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']>;
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** An array of the available properties for each input of the name field. */
  inputs?: Maybe<Array<Maybe<NameInputProperty>>>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** Name field value. */
  nameValues?: Maybe<NameFieldValue>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** The placement of the labels for the subfields within the group. This setting controls all of the subfields, they cannot be set individually. They may be aligned above or below the inputs. If this property is not set, the “Sub-Label Placement” setting on the Form Settings-&gt;Form Layout page is used. If no setting is specified, the default is above inputs. */
  subLabelPlacement?: Maybe<FormFieldSubLabelPlacementEnum>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** Name choice values. */
export type NameFieldChoice = {
  __typename?: 'NameFieldChoice';
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']>;
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']>;
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']>;
};

/** Input fields for name field. */
export type NameFieldInput = {
  /** First name. */
  first?: InputMaybe<Scalars['String']>;
  /** Last name. */
  last?: InputMaybe<Scalars['String']>;
  /** Middle name. */
  middle?: InputMaybe<Scalars['String']>;
  /** Prefix, such as Mr., Mrs. etc. */
  prefix?: InputMaybe<Scalars['String']>;
  /** Suffix, such as Sr., Jr. etc. */
  suffix?: InputMaybe<Scalars['String']>;
};

/** The individual properties for each element of the Name value field. */
export type NameFieldValue = {
  __typename?: 'NameFieldValue';
  /** First name. */
  first?: Maybe<Scalars['String']>;
  /** Last name. */
  last?: Maybe<Scalars['String']>;
  /** Middle name. */
  middle?: Maybe<Scalars['String']>;
  /** Prefix, such as Mr., Mrs. etc. */
  prefix?: Maybe<Scalars['String']>;
  /** Suffix, such as Sr., Jr. etc. */
  suffix?: Maybe<Scalars['String']>;
};

/** Name input values. */
export type NameInputProperty = {
  __typename?: 'NameInputProperty';
  /** The autocomplete attribute for the field. */
  autocompleteAttribute?: Maybe<Scalars['String']>;
  /** The available choices for the name field. */
  choices?: Maybe<Array<Maybe<NameFieldChoice>>>;
  /** The custom label for the input. When set, this is used in place of the label. */
  customLabel?: Maybe<Scalars['String']>;
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']>;
  /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
  hasChoiceValue?: Maybe<Scalars['Boolean']>;
  /** The input ID. Input IDs follow the following naming convention: FIELDID.INPUTID (i.e. 5.1), where FIELDID is the id of the containing field and INPUTID specifies the input field. */
  id?: Maybe<Scalars['Float']>;
  /** Whether or not this field should be hidden. */
  isHidden?: Maybe<Scalars['Boolean']>;
  /** Key used to identify this input. */
  key?: Maybe<Scalars['String']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  name?: Maybe<Scalars['String']>;
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']>;
};

/** An object with an ID */
export type Node = {
  /** The globally unique ID for the object */
  id: Scalars['ID'];
};

/** A node that can have an author assigned to it */
export type NodeWithAuthor = {
  /** Connection between the NodeWithAuthor type and the User type */
  author?: Maybe<NodeWithAuthorToUserConnectionEdge>;
  /** The database identifier of the author of the node */
  authorDatabaseId?: Maybe<Scalars['Int']>;
  /** The globally unique identifier of the author of the node */
  authorId?: Maybe<Scalars['ID']>;
};

/** Connection between the NodeWithAuthor type and the User type */
export type NodeWithAuthorToUserConnectionEdge = {
  __typename?: 'NodeWithAuthorToUserConnectionEdge';
  /** The node of the connection, without the edges */
  node?: Maybe<User>;
};

/** A node that can have comments associated with it */
export type NodeWithComments = {
  /** The number of comments. Even though WPGraphQL denotes this field as an integer, in WordPress this field should be saved as a numeric string for compatibility. */
  commentCount?: Maybe<Scalars['Int']>;
  /** Whether the comments are open or closed for this particular post. */
  commentStatus?: Maybe<Scalars['String']>;
};

/** A node that supports the content editor */
export type NodeWithContentEditor = {
  /** The content of the post. */
  content?: Maybe<Scalars['String']>;
};


/** A node that supports the content editor */
export type NodeWithContentEditorContentArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** A node that can have an excerpt */
export type NodeWithExcerpt = {
  /** The excerpt of the post. */
  excerpt?: Maybe<Scalars['String']>;
};


/** A node that can have an excerpt */
export type NodeWithExcerptExcerptArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** A node that can have a featured image set */
export type NodeWithFeaturedImage = {
  /** Connection between the ContentNode type and the ContentType type */
  contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /** The name of the Content Type the node belongs to */
  contentTypeName: Scalars['String'];
  /** The unique identifier stored in the database */
  databaseId: Scalars['Int'];
  /** Post publishing date. */
  date?: Maybe<Scalars['String']>;
  /** The publishing date set in GMT. */
  dateGmt?: Maybe<Scalars['String']>;
  /** The desired slug of the post */
  desiredSlug?: Maybe<Scalars['String']>;
  /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
  editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
  /** The RSS enclosure for the object */
  enclosure?: Maybe<Scalars['String']>;
  /** Connection between the ContentNode type and the EnqueuedScript type */
  enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
  /** Connection between the ContentNode type and the EnqueuedStylesheet type */
  enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /** Connection between the NodeWithFeaturedImage type and the MediaItem type */
  featuredImage?: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
  /** The database identifier for the featured image node assigned to the content node */
  featuredImageDatabaseId?: Maybe<Scalars['Int']>;
  /** Globally unique ID of the featured image assigned to the node */
  featuredImageId?: Maybe<Scalars['ID']>;
  /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
  guid?: Maybe<Scalars['String']>;
  /** The unique resource identifier path */
  id: Scalars['ID'];
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean'];
  /** Whether the object is a node in the preview state */
  isPreview?: Maybe<Scalars['Boolean']>;
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']>;
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean'];
  /** The user that most recently edited the node */
  lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
  /** The permalink of the post */
  link?: Maybe<Scalars['String']>;
  /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
  modified?: Maybe<Scalars['String']>;
  /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
  modifiedGmt?: Maybe<Scalars['String']>;
  /** The database id of the preview node */
  previewRevisionDatabaseId?: Maybe<Scalars['Int']>;
  /** Whether the object is a node in the preview state */
  previewRevisionId?: Maybe<Scalars['ID']>;
  /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
  slug?: Maybe<Scalars['String']>;
  /** The current status of the object */
  status?: Maybe<Scalars['String']>;
  /** The template assigned to a node of content */
  template?: Maybe<ContentTemplate>;
  /** The unique resource identifier path */
  uri?: Maybe<Scalars['String']>;
};


/** A node that can have a featured image set */
export type NodeWithFeaturedImageEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** A node that can have a featured image set */
export type NodeWithFeaturedImageEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** Connection between the NodeWithFeaturedImage type and the MediaItem type */
export type NodeWithFeaturedImageToMediaItemConnectionEdge = {
  __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge';
  /** The node of the connection, without the edges */
  node?: Maybe<MediaItem>;
};

/** A node that can have a Gravity Forms form assigned to it. */
export type NodeWithForm = {
  /** The form object of the node. */
  form?: Maybe<GfForm>;
  /** The database identifier of the form of the node. */
  formDatabaseId?: Maybe<Scalars['Int']>;
  /** The globally unique identifier of the form of the node. */
  formId?: Maybe<Scalars['ID']>;
};

/** A node that can have page attributes */
export type NodeWithPageAttributes = {
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: Maybe<Scalars['Int']>;
};

/** A node that can have revisions */
export type NodeWithRevisions = {
  /** True if the node is a revision of another node */
  isRevision?: Maybe<Scalars['Boolean']>;
  /** If the current node is a revision, this field exposes the node this is a revision of. Returns null if the node is not a revision of another node. */
  revisionOf?: Maybe<NodeWithRevisionsToContentNodeConnectionEdge>;
};

/** Connection between the NodeWithRevisions type and the ContentNode type */
export type NodeWithRevisionsToContentNodeConnectionEdge = {
  __typename?: 'NodeWithRevisionsToContentNodeConnectionEdge';
  /** The node of the connection, without the edges */
  node?: Maybe<ContentNode>;
};

/** A node that can have a template associated with it */
export type NodeWithTemplate = {
  /** The template assigned to the node */
  template?: Maybe<ContentTemplate>;
};

/** A node that NodeWith a title */
export type NodeWithTitle = {
  /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
  title?: Maybe<Scalars['String']>;
};


/** A node that NodeWith a title */
export type NodeWithTitleTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** A node that can have trackbacks and pingbacks */
export type NodeWithTrackbacks = {
  /** Whether the pings are open or closed for this particular post. */
  pingStatus?: Maybe<Scalars['String']>;
  /** URLs that have been pinged. */
  pinged?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** URLs queued to be pinged. */
  toPing?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** A Gravity Forms   field. */
export type NumberField = FormField & {
  __typename?: 'NumberField';
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** The autocomplete attribute for the field. */
  autocompleteAttribute?: Maybe<Scalars['String']>;
  /** The formula used for the number field. */
  calculationFormula?: Maybe<Scalars['String']>;
  /** Specifies to how many decimal places the number should be rounded. This is available when isCalculation is true, but is not available when the chosen format is “Currency”. */
  calculationRounding?: Maybe<Scalars['Int']>;
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Whether autocomplete should be enabled for this field. */
  hasAutocomplete?: Maybe<Scalars['Boolean']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']>;
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** Indicates whether the number field is a calculation. */
  isCalculation?: Maybe<Scalars['Boolean']>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** Specifies the format allowed for the number field. */
  numberFormat?: Maybe<NumberFieldFormatEnum>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']>;
  /** Maximum allowed value for a number field. Values higher than the number specified by this property will cause the field to fail validation. */
  rangeMax?: Maybe<Scalars['Float']>;
  /** Minimum allowed value for a number field. Values lower than the number specified by this property will cause the field to fail validation. */
  rangeMin?: Maybe<Scalars['Float']>;
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']>;
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** The format allowed for the number field. . */
export enum NumberFieldFormatEnum {
  /** Currency format. */
  Currency = 'CURRENCY',
  /** Decimal-comma format (e.g. 9.999,99). */
  DecimalComma = 'DECIMAL_COMMA',
  /** Decimal-dot format (e.g. 9,999.99). */
  DecimalDot = 'DECIMAL_DOT'
}

/** The cardinality of the connection order */
export enum OrderEnum {
  /** Sort the query result set in an ascending order */
  Asc = 'ASC',
  /** Sort the query result set in a descending order */
  Desc = 'DESC'
}

/** The page type */
export type Page = BlockEditorContentNode & ContentNode & DatabaseIdentifier & HierarchicalContentNode & MenuItemLinkable & Node & NodeWithAuthor & NodeWithContentEditor & NodeWithExcerpt & NodeWithFeaturedImage & NodeWithPageAttributes & NodeWithRevisions & NodeWithTemplate & NodeWithTitle & UniformResourceIdentifiable & {
  __typename?: 'Page';
  Meta?: Maybe<Page_Meta>;
  /** Returns ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
  ancestors?: Maybe<HierarchicalContentNodeToContentNodeAncestorsConnection>;
  /** Connection between the NodeWithAuthor type and the User type */
  author?: Maybe<NodeWithAuthorToUserConnectionEdge>;
  /** The database identifier of the author of the node */
  authorDatabaseId?: Maybe<Scalars['Int']>;
  /** The globally unique identifier of the author of the node */
  authorId?: Maybe<Scalars['ID']>;
  /** Gutenberg blocks */
  blocks?: Maybe<Array<Block>>;
  /** Gutenberg blocks as json string */
  blocksJSON?: Maybe<Scalars['String']>;
  /** Connection between the HierarchicalContentNode type and the ContentNode type */
  children?: Maybe<HierarchicalContentNodeToContentNodeChildrenConnection>;
  /** The content of the post. */
  content?: Maybe<Scalars['String']>;
  /** Connection between the ContentNode type and the ContentType type */
  contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /** The name of the Content Type the node belongs to */
  contentTypeName: Scalars['String'];
  /** The unique resource identifier path */
  databaseId: Scalars['Int'];
  /** Post publishing date. */
  date?: Maybe<Scalars['String']>;
  /** The publishing date set in GMT. */
  dateGmt?: Maybe<Scalars['String']>;
  /** The desired slug of the post */
  desiredSlug?: Maybe<Scalars['String']>;
  /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
  editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
  /** The RSS enclosure for the object */
  enclosure?: Maybe<Scalars['String']>;
  /** Connection between the ContentNode type and the EnqueuedScript type */
  enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
  /** Connection between the ContentNode type and the EnqueuedStylesheet type */
  enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /** The excerpt of the post. */
  excerpt?: Maybe<Scalars['String']>;
  /** Connection between the NodeWithFeaturedImage type and the MediaItem type */
  featuredImage?: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
  /** The database identifier for the featured image node assigned to the content node */
  featuredImageDatabaseId?: Maybe<Scalars['Int']>;
  /** Globally unique ID of the featured image assigned to the node */
  featuredImageId?: Maybe<Scalars['ID']>;
  /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
  guid?: Maybe<Scalars['String']>;
  /** The globally unique identifier of the page object. */
  id: Scalars['ID'];
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean'];
  /** Whether this page is set to the static front page. */
  isFrontPage: Scalars['Boolean'];
  /** Whether this page is set to the blog posts page. */
  isPostsPage: Scalars['Boolean'];
  /** Whether the object is a node in the preview state */
  isPreview?: Maybe<Scalars['Boolean']>;
  /** Whether this page is set to the privacy page. */
  isPrivacyPage: Scalars['Boolean'];
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']>;
  /** True if the node is a revision of another node */
  isRevision?: Maybe<Scalars['Boolean']>;
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean'];
  /** The user that most recently edited the node */
  lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
  /** The permalink of the post */
  link?: Maybe<Scalars['String']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: Maybe<Scalars['Int']>;
  /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
  modified?: Maybe<Scalars['String']>;
  /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
  modifiedGmt?: Maybe<Scalars['String']>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of the databaseId field
   */
  pageId: Scalars['Int'];
  /** The parent of the node. The parent object can be of various types */
  parent?: Maybe<HierarchicalContentNodeToParentContentNodeConnectionEdge>;
  /** Database id of the parent node */
  parentDatabaseId?: Maybe<Scalars['Int']>;
  /** The globally unique identifier of the parent node. */
  parentId?: Maybe<Scalars['ID']>;
  /** Connection between the page type and the page type */
  preview?: Maybe<PageToPreviewConnectionEdge>;
  /** Previewed gutenberg blocks */
  previewBlocks?: Maybe<Array<Block>>;
  /** Previewed Gutenberg blocks as json string */
  previewBlocksJSON?: Maybe<Scalars['String']>;
  /** The database id of the preview node */
  previewRevisionDatabaseId?: Maybe<Scalars['Int']>;
  /** Whether the object is a node in the preview state */
  previewRevisionId?: Maybe<Scalars['ID']>;
  /** If the current node is a revision, this field exposes the node this is a revision of. Returns null if the node is not a revision of another node. */
  revisionOf?: Maybe<NodeWithRevisionsToContentNodeConnectionEdge>;
  /** Connection between the page type and the page type */
  revisions?: Maybe<PageToRevisionConnection>;
  /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
  slug?: Maybe<Scalars['String']>;
  /** The current status of the object */
  status?: Maybe<Scalars['String']>;
  /** The template assigned to a node of content */
  template?: Maybe<ContentTemplate>;
  /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
  title?: Maybe<Scalars['String']>;
  /** The unique resource identifier path */
  uri?: Maybe<Scalars['String']>;
};


/** The page type */
export type PageAncestorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs>;
};


/** The page type */
export type PageChildrenArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs>;
};


/** The page type */
export type PageContentArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};


/** The page type */
export type PageEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The page type */
export type PageEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The page type */
export type PageExcerptArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};


/** The page type */
export type PageRevisionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PageToRevisionConnectionWhereArgs>;
};


/** The page type */
export type PageTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** A Gravity Forms   field. */
export type PageField = FormField & {
  __typename?: 'PageField';
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** An array containing the the individual properties for the &quot;Next&quot; button. */
  nextButton?: Maybe<FormButton>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** An array containing the the individual properties for the &quot;Previous&quot; button. */
  previousButton?: Maybe<FormButton>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum PageIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the URI. */
  Uri = 'URI'
}

/** Connection between the page type and the page type */
export type PageToPreviewConnectionEdge = {
  __typename?: 'PageToPreviewConnectionEdge';
  /** The node of the connection, without the edges */
  node?: Maybe<Page>;
};

/** Connection between the page type and the page type */
export type PageToRevisionConnection = {
  __typename?: 'PageToRevisionConnection';
  /** Edges for the pageToRevisionConnection connection */
  edges?: Maybe<Array<Maybe<PageToRevisionConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Page>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type PageToRevisionConnectionEdge = {
  __typename?: 'PageToRevisionConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<Page>;
};

/** Arguments for filtering the pageToRevisionConnection connection */
export type PageToRevisionConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** Field Group */
export type Page_Meta = {
  __typename?: 'Page_Meta';
  fieldGroupName?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  /** If left blank the page or post title will be used. */
  metaTitle?: Maybe<Scalars['String']>;
  ogDescription?: Maybe<Scalars['String']>;
  ogImage?: Maybe<MediaItem>;
  ogTitle?: Maybe<Scalars['String']>;
  twitterCardType?: Maybe<Scalars['String']>;
  twitterDescription?: Maybe<Scalars['String']>;
  twitterImage?: Maybe<MediaItem>;
  twitterTitle?: Maybe<Scalars['String']>;
};

/** A Gravity Forms   field. */
export type PasswordField = FormField & {
  __typename?: 'PasswordField';
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Indicates whether the field displays the password strength indicator. */
  hasPasswordStrengthIndicator?: Maybe<Scalars['Boolean']>;
  /** Whether the Password visibility toggle should be enabled for this field. */
  hasPasswordVisibilityToggle?: Maybe<Scalars['Boolean']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** An array of the available properties for each input of the password field. */
  inputs?: Maybe<Array<Maybe<PasswordInputProperty>>>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** Indicates how strong the password should be. */
  minPasswordStrength?: Maybe<PasswordFieldMinStrengthEnum>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>;
  /** The placement of the labels for the subfields within the group. This setting controls all of the subfields, they cannot be set individually. They may be aligned above or below the inputs. If this property is not set, the “Sub-Label Placement” setting on the Form Settings-&gt;Form Layout page is used. If no setting is specified, the default is above inputs. */
  subLabelPlacement?: Maybe<FormFieldSubLabelPlacementEnum>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** Indicates how strong the password should be. */
export enum PasswordFieldMinStrengthEnum {
  /** The password strength must be "bad" or better. */
  Bad = 'BAD',
  /** The password strength must be "good" or better. */
  Good = 'GOOD',
  /** The password strength must be "short" or better. */
  Short = 'SHORT',
  /** The password strength must be "strong". */
  Strong = 'STRONG'
}

/** Password input values. */
export type PasswordInputProperty = {
  __typename?: 'PasswordInputProperty';
  /** The custom label for the input. When set, this is used in place of the label. */
  customLabel?: Maybe<Scalars['String']>;
  /** The input ID. Input IDs follow the following naming convention: FIELDID.INPUTID (i.e. 5.1), where FIELDID is the id of the containing field and INPUTID specifies the input field. */
  id?: Maybe<Scalars['Float']>;
  /** Whether or not this field should be hidden. */
  isHidden?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']>;
};

/** A Gravity Forms   field. */
export type PhoneField = FormField & {
  __typename?: 'PhoneField';
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** The autocomplete attribute for the field. */
  autocompleteAttribute?: Maybe<Scalars['String']>;
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Whether autocomplete should be enabled for this field. */
  hasAutocomplete?: Maybe<Scalars['Boolean']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']>;
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** Determines the allowed format for phones. If the phone value does not conform with the specified format, the field will fail validation. */
  phoneFormat?: Maybe<PhoneFieldFormatEnum>;
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']>;
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']>;
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** Tthe allowed format for phone numbers. */
export enum PhoneFieldFormatEnum {
  /** International phone number format. */
  International = 'INTERNATIONAL',
  /** Standard phone number format. */
  Standard = 'STANDARD'
}

/** An plugin object */
export type Plugin = Node & {
  __typename?: 'Plugin';
  /** Name of the plugin author(s), may also be a company name. */
  author?: Maybe<Scalars['String']>;
  /** URI for the related author(s)/company website. */
  authorUri?: Maybe<Scalars['String']>;
  /** Description of the plugin. */
  description?: Maybe<Scalars['String']>;
  /** The globally unique identifier of the plugin object. */
  id: Scalars['ID'];
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']>;
  /** Display name of the plugin. */
  name?: Maybe<Scalars['String']>;
  /** Plugin path. */
  path?: Maybe<Scalars['String']>;
  /** URI for the plugin website. This is useful for directing users for support requests etc. */
  pluginUri?: Maybe<Scalars['String']>;
  /** Current version of the plugin. */
  version?: Maybe<Scalars['String']>;
};

/** The status of the WordPress plugin. */
export enum PluginStatusEnum {
  /** The plugin is currently active. */
  Active = 'ACTIVE',
  /** The plugin is a drop-in plugin. */
  DropIn = 'DROP_IN',
  /** The plugin is currently inactive. */
  Inactive = 'INACTIVE',
  /** The plugin is a must-use plugin. */
  MustUse = 'MUST_USE',
  /** The plugin is technically active but was paused while loading. */
  Paused = 'PAUSED',
  /** The plugin was active recently. */
  RecentlyActive = 'RECENTLY_ACTIVE',
  /** The plugin has an upgrade available. */
  Upgrade = 'UPGRADE'
}

/** The post type */
export type Post = BlockEditorContentNode & ContentNode & DatabaseIdentifier & MenuItemLinkable & Node & NodeWithAuthor & NodeWithContentEditor & NodeWithExcerpt & NodeWithFeaturedImage & NodeWithRevisions & NodeWithTemplate & NodeWithTitle & NodeWithTrackbacks & UniformResourceIdentifiable & {
  __typename?: 'Post';
  Meta?: Maybe<Post_Meta>;
  /** Connection between the NodeWithAuthor type and the User type */
  author?: Maybe<NodeWithAuthorToUserConnectionEdge>;
  /** The database identifier of the author of the node */
  authorDatabaseId?: Maybe<Scalars['Int']>;
  /** The globally unique identifier of the author of the node */
  authorId?: Maybe<Scalars['ID']>;
  /** Gutenberg blocks */
  blocks?: Maybe<Array<Block>>;
  /** Gutenberg blocks as json string */
  blocksJSON?: Maybe<Scalars['String']>;
  /** Connection between the post type and the category type */
  categories?: Maybe<PostToCategoryConnection>;
  /** The content of the post. */
  content?: Maybe<Scalars['String']>;
  /** Connection between the ContentNode type and the ContentType type */
  contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /** The name of the Content Type the node belongs to */
  contentTypeName: Scalars['String'];
  /** The unique resource identifier path */
  databaseId: Scalars['Int'];
  /** Post publishing date. */
  date?: Maybe<Scalars['String']>;
  /** The publishing date set in GMT. */
  dateGmt?: Maybe<Scalars['String']>;
  /** The desired slug of the post */
  desiredSlug?: Maybe<Scalars['String']>;
  /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
  editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
  /** The RSS enclosure for the object */
  enclosure?: Maybe<Scalars['String']>;
  /** Connection between the ContentNode type and the EnqueuedScript type */
  enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
  /** Connection between the ContentNode type and the EnqueuedStylesheet type */
  enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /** The excerpt of the post. */
  excerpt?: Maybe<Scalars['String']>;
  /** Connection between the NodeWithFeaturedImage type and the MediaItem type */
  featuredImage?: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
  /** The database identifier for the featured image node assigned to the content node */
  featuredImageDatabaseId?: Maybe<Scalars['Int']>;
  /** Globally unique ID of the featured image assigned to the node */
  featuredImageId?: Maybe<Scalars['ID']>;
  /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
  guid?: Maybe<Scalars['String']>;
  /** The globally unique identifier of the post object. */
  id: Scalars['ID'];
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean'];
  /** Whether the object is a node in the preview state */
  isPreview?: Maybe<Scalars['Boolean']>;
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']>;
  /** True if the node is a revision of another node */
  isRevision?: Maybe<Scalars['Boolean']>;
  /** Whether this page is sticky */
  isSticky: Scalars['Boolean'];
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean'];
  /** The user that most recently edited the node */
  lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
  /** The permalink of the post */
  link?: Maybe<Scalars['String']>;
  /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
  modified?: Maybe<Scalars['String']>;
  /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
  modifiedGmt?: Maybe<Scalars['String']>;
  newsPostDetails?: Maybe<Post_Newspostdetails>;
  /** Whether the pings are open or closed for this particular post. */
  pingStatus?: Maybe<Scalars['String']>;
  /** URLs that have been pinged. */
  pinged?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Connection between the post type and the postFormat type */
  postFormats?: Maybe<PostToPostFormatConnection>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of the databaseId field
   */
  postId: Scalars['Int'];
  /** Connection between the post type and the post type */
  preview?: Maybe<PostToPreviewConnectionEdge>;
  /** Previewed gutenberg blocks */
  previewBlocks?: Maybe<Array<Block>>;
  /** Previewed Gutenberg blocks as json string */
  previewBlocksJSON?: Maybe<Scalars['String']>;
  /** The database id of the preview node */
  previewRevisionDatabaseId?: Maybe<Scalars['Int']>;
  /** Whether the object is a node in the preview state */
  previewRevisionId?: Maybe<Scalars['ID']>;
  /** If the current node is a revision, this field exposes the node this is a revision of. Returns null if the node is not a revision of another node. */
  revisionOf?: Maybe<NodeWithRevisionsToContentNodeConnectionEdge>;
  /** Connection between the post type and the post type */
  revisions?: Maybe<PostToRevisionConnection>;
  /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
  slug?: Maybe<Scalars['String']>;
  /** The current status of the object */
  status?: Maybe<Scalars['String']>;
  /** Connection between the post type and the tag type */
  tags?: Maybe<PostToTagConnection>;
  /** The template assigned to a node of content */
  template?: Maybe<ContentTemplate>;
  /** Connection between the post type and the TermNode type */
  terms?: Maybe<PostToTermNodeConnection>;
  /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
  title?: Maybe<Scalars['String']>;
  /** URLs queued to be pinged. */
  toPing?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The unique resource identifier path */
  uri?: Maybe<Scalars['String']>;
};


/** The post type */
export type PostCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostToCategoryConnectionWhereArgs>;
};


/** The post type */
export type PostContentArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};


/** The post type */
export type PostEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The post type */
export type PostEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The post type */
export type PostExcerptArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};


/** The post type */
export type PostPostFormatsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostToPostFormatConnectionWhereArgs>;
};


/** The post type */
export type PostRevisionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostToRevisionConnectionWhereArgs>;
};


/** The post type */
export type PostTagsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostToTagConnectionWhereArgs>;
};


/** The post type */
export type PostTermsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostToTermNodeConnectionWhereArgs>;
};


/** The post type */
export type PostTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** Set relationships between the post to categories */
export type PostCategoriesInput = {
  /** If true, this will append the category to existing related categories. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<PostCategoriesNodeInput>>>;
};

/** List of categories to connect the post to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type PostCategoriesNodeInput = {
  /** The description of the category. This field is used to set a description of the category if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']>;
  /** The ID of the category. If present, this will be used to connect to the post. If no existing category exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']>;
  /** The name of the category. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']>;
  /** The slug of the category. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']>;
};

/** A Gravity Forms  post_category field. */
export type PostCategoryCheckboxField = FormField & PostCategoryField & {
  __typename?: 'PostCategoryCheckboxField';
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** Checkbox field value. */
  checkboxValues?: Maybe<Array<Maybe<CheckboxFieldValue>>>;
  /** The available choices for the post_category field. */
  choices?: Maybe<Array<Maybe<PostCategoryFieldChoice>>>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** The dropdown placeholder for the field. */
  dropdownPlaceholder?: Maybe<Scalars['String']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Determines if all categories should be displayed on the Post Category drop down. If this property is true (display all categories), the Post Category drop down will display the categories hierarchically. */
  hasAllCategories?: Maybe<Scalars['Boolean']>;
  /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
  hasChoiceValue?: Maybe<Scalars['Boolean']>;
  /** Whether the \&quot;select all\&quot; choice should be displayed. */
  hasSelectAll?: Maybe<Scalars['Boolean']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']>;
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** An array of the available properties for each input of the post_category field. */
  inputs?: Maybe<Array<Maybe<PostCategoryInputProperty>>>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']>;
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** A Gravity Forms   field. */
export type PostCategoryField = {
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** The available choices for the post_category field. */
  choices?: Maybe<Array<Maybe<PostCategoryFieldChoice>>>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** The dropdown placeholder for the field. */
  dropdownPlaceholder?: Maybe<Scalars['String']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Determines if all categories should be displayed on the Post Category drop down. If this property is true (display all categories), the Post Category drop down will display the categories hierarchically. */
  hasAllCategories?: Maybe<Scalars['Boolean']>;
  /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
  hasChoiceValue?: Maybe<Scalars['Boolean']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']>;
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']>;
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** Post_category choice values. */
export type PostCategoryFieldChoice = {
  __typename?: 'PostCategoryFieldChoice';
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']>;
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']>;
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']>;
};

/** Post_category input values. */
export type PostCategoryInputProperty = {
  __typename?: 'PostCategoryInputProperty';
  /** The input ID. Input IDs follow the following naming convention: FIELDID.INPUTID (i.e. 5.1), where FIELDID is the id of the containing field and INPUTID specifies the input field. */
  id?: Maybe<Scalars['Float']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  name?: Maybe<Scalars['String']>;
};

/** A Gravity Forms  post_category field. */
export type PostCategoryMultiSelectField = FormField & PostCategoryField & {
  __typename?: 'PostCategoryMultiSelectField';
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** The available choices for the post_category field. */
  choices?: Maybe<Array<Maybe<PostCategoryFieldChoice>>>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** The dropdown placeholder for the field. */
  dropdownPlaceholder?: Maybe<Scalars['String']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Determines if all categories should be displayed on the Post Category drop down. If this property is true (display all categories), the Post Category drop down will display the categories hierarchically. */
  hasAllCategories?: Maybe<Scalars['Boolean']>;
  /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
  hasChoiceValue?: Maybe<Scalars['Boolean']>;
  /** When set to true, the &quot;Chosen&quot; jQuery script will be applied to this field, enabling search capabilities to Drop Down fields and a more user-friendly interface for Multi Select fields. */
  hasEnhancedUI?: Maybe<Scalars['Boolean']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']>;
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']>;
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** An array of field values. */
  values?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** A Gravity Forms  post_category field. */
export type PostCategoryRadioField = FormField & PostCategoryField & {
  __typename?: 'PostCategoryRadioField';
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** The available choices for the post_category field. */
  choices?: Maybe<Array<Maybe<PostCategoryFieldChoice>>>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** The dropdown placeholder for the field. */
  dropdownPlaceholder?: Maybe<Scalars['String']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Determines if all categories should be displayed on the Post Category drop down. If this property is true (display all categories), the Post Category drop down will display the categories hierarchically. */
  hasAllCategories?: Maybe<Scalars['Boolean']>;
  /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
  hasChoiceValue?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the &#039;Enable &quot;other&quot; choice&#039; option is checked in the editor. */
  hasOtherChoice?: Maybe<Scalars['Boolean']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']>;
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']>;
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** A Gravity Forms  post_category field. */
export type PostCategorySelectField = FormField & PostCategoryField & {
  __typename?: 'PostCategorySelectField';
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** The autocomplete attribute for the field. */
  autocompleteAttribute?: Maybe<Scalars['String']>;
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** The available choices for the post_category field. */
  choices?: Maybe<Array<Maybe<PostCategoryFieldChoice>>>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** The dropdown placeholder for the field. */
  dropdownPlaceholder?: Maybe<Scalars['String']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Determines if all categories should be displayed on the Post Category drop down. If this property is true (display all categories), the Post Category drop down will display the categories hierarchically. */
  hasAllCategories?: Maybe<Scalars['Boolean']>;
  /** Whether autocomplete should be enabled for this field. */
  hasAutocomplete?: Maybe<Scalars['Boolean']>;
  /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
  hasChoiceValue?: Maybe<Scalars['Boolean']>;
  /** When set to true, the &quot;Chosen&quot; jQuery script will be applied to this field, enabling search capabilities to Drop Down fields and a more user-friendly interface for Multi Select fields. */
  hasEnhancedUI?: Maybe<Scalars['Boolean']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']>;
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']>;
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']>;
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** A Gravity Forms   field. */
export type PostContentField = FormField & {
  __typename?: 'PostContentField';
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Indicates whether the field uses the rich text editor interface. */
  hasRichTextEditor?: Maybe<Scalars['Boolean']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']>;
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** Specifies the maximum number of characters allowed in a text or textarea (paragraph) field. */
  maxLength?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']>;
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** A Gravity Forms  post_custom_field field. */
export type PostCustomCheckboxField = FormField & PostCustomField & {
  __typename?: 'PostCustomCheckboxField';
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** Checkbox field value. */
  checkboxValues?: Maybe<Array<Maybe<CheckboxFieldValue>>>;
  /** The available choices for the post_custom_field field. */
  choices?: Maybe<Array<Maybe<PostCustomCheckboxFieldChoice>>>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
  hasChoiceValue?: Maybe<Scalars['Boolean']>;
  /** Whether the \&quot;select all\&quot; choice should be displayed. */
  hasSelectAll?: Maybe<Scalars['Boolean']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']>;
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** An array of the available properties for each input of the post_custom_field field. */
  inputs?: Maybe<Array<Maybe<PostCustomInputProperty>>>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** The post meta key to which the value should be assigned. */
  postMetaFieldName?: Maybe<Scalars['String']>;
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']>;
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** Post_custom_field choice values. */
export type PostCustomCheckboxFieldChoice = {
  __typename?: 'PostCustomCheckboxFieldChoice';
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']>;
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']>;
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']>;
};

/** A Gravity Forms  post_custom_field field. */
export type PostCustomDateField = FormField & PostCustomField & {
  __typename?: 'PostCustomDateField';
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** Determines how the date field displays it’s calendar icon. */
  calendarIconType?: Maybe<FormFieldCalendarIconTypeEnum>;
  /** Contains the URL to the custom calendar icon. Only applicable when calendarIconType is set to custom. */
  calendarIconUrl?: Maybe<Scalars['String']>;
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Determines how the date is displayed. */
  dateFormat?: Maybe<DateFieldFormatEnum>;
  /** The type of date field to display. */
  dateType?: Maybe<DateFieldTypeEnum>;
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']>;
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** An array of the available properties for each input of the post_custom_field field. */
  inputs?: Maybe<Array<Maybe<PostCustomInputProperty>>>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']>;
  /** The post meta key to which the value should be assigned. */
  postMetaFieldName?: Maybe<Scalars['String']>;
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']>;
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>;
  /** The placement of the labels for the subfields within the group. This setting controls all of the subfields, they cannot be set individually. They may be aligned above or below the inputs. If this property is not set, the “Sub-Label Placement” setting on the Form Settings-&gt;Form Layout page is used. If no setting is specified, the default is above inputs. */
  subLabelPlacement?: Maybe<FormFieldSubLabelPlacementEnum>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** A Gravity Forms  post_custom_field field. */
export type PostCustomEmailField = FormField & PostCustomField & {
  __typename?: 'PostCustomEmailField';
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** The autocomplete attribute for the field. */
  autocompleteAttribute?: Maybe<Scalars['String']>;
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Whether autocomplete should be enabled for this field. */
  hasAutocomplete?: Maybe<Scalars['Boolean']>;
  /** Determines whether the Confirm Email field is active. */
  hasEmailConfirmation?: Maybe<Scalars['Boolean']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']>;
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** An array of the available properties for each input of the post_custom_field field. */
  inputs?: Maybe<Array<Maybe<PostCustomInputProperty>>>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']>;
  /** The post meta key to which the value should be assigned. */
  postMetaFieldName?: Maybe<Scalars['String']>;
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']>;
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>;
  /** The placement of the labels for the subfields within the group. This setting controls all of the subfields, they cannot be set individually. They may be aligned above or below the inputs. If this property is not set, the “Sub-Label Placement” setting on the Form Settings-&gt;Form Layout page is used. If no setting is specified, the default is above inputs. */
  subLabelPlacement?: Maybe<FormFieldSubLabelPlacementEnum>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** A Gravity Forms   field. */
export type PostCustomField = {
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']>;
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** The post meta key to which the value should be assigned. */
  postMetaFieldName?: Maybe<Scalars['String']>;
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']>;
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** A Gravity Forms  post_custom_field field. */
export type PostCustomFileuploadField = FormField & PostCustomField & {
  __typename?: 'PostCustomFileuploadField';
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** A comma-delimited list of the file extensions which may be uploaded. */
  allowedExtensions?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Indicates whether multiple files may be uploaded. */
  canAcceptMultipleFiles?: Maybe<Scalars['Boolean']>;
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** File upload value */
  fileUploadValues?: Maybe<Array<Maybe<FileUploadFieldValue>>>;
  /** Field ID. */
  id: Scalars['Int'];
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']>;
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The maximum size (in MB) an uploaded file may be . */
  maxFileSize?: Maybe<Scalars['Int']>;
  /** When the field is set to allow multiple files to be uploaded, this property is available to set a limit on how many may be uploaded. */
  maxFiles?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** The post meta key to which the value should be assigned. */
  postMetaFieldName?: Maybe<Scalars['String']>;
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']>;
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /**
   * An array of field values.
   * @deprecated Use `fileUploadValues` instead.
   */
  values?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** A Gravity Forms  post_custom_field field. */
export type PostCustomHiddenField = FormField & PostCustomField & {
  __typename?: 'PostCustomHiddenField';
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']>;
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** The post meta key to which the value should be assigned. */
  postMetaFieldName?: Maybe<Scalars['String']>;
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']>;
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** Post_custom_field input values. */
export type PostCustomInputProperty = {
  __typename?: 'PostCustomInputProperty';
  /** The input ID. Input IDs follow the following naming convention: FIELDID.INPUTID (i.e. 5.1), where FIELDID is the id of the containing field and INPUTID specifies the input field. */
  id?: Maybe<Scalars['Float']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  name?: Maybe<Scalars['String']>;
};

/** A Gravity Forms  post_custom_field field. */
export type PostCustomListField = FormField & PostCustomField & {
  __typename?: 'PostCustomListField';
  /** The URL of the image to be used for the add row button. */
  addIconUrl?: Maybe<Scalars['String']>;
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** The available choices for the post_custom_field field. */
  choices?: Maybe<Array<Maybe<PostCustomListFieldChoice>>>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** The URL of the image to be used for the delete row button. */
  deleteIconUrl?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Determines if the field should use multiple columns. Default is false. */
  hasColumns?: Maybe<Scalars['Boolean']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']>;
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** List field value. */
  listValues?: Maybe<Array<Maybe<ListFieldValue>>>;
  /** The maximum number of rows the user can add to the field. */
  maxRows?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** The post meta key to which the value should be assigned. */
  postMetaFieldName?: Maybe<Scalars['String']>;
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']>;
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** Post_custom_field choice values. */
export type PostCustomListFieldChoice = {
  __typename?: 'PostCustomListFieldChoice';
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']>;
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']>;
};

/** A Gravity Forms  post_custom_field field. */
export type PostCustomMultiSelectField = FormField & PostCustomField & {
  __typename?: 'PostCustomMultiSelectField';
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** The available choices for the post_custom_field field. */
  choices?: Maybe<Array<Maybe<PostCustomMultiSelectFieldChoice>>>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
  hasChoiceValue?: Maybe<Scalars['Boolean']>;
  /** When set to true, the &quot;Chosen&quot; jQuery script will be applied to this field, enabling search capabilities to Drop Down fields and a more user-friendly interface for Multi Select fields. */
  hasEnhancedUI?: Maybe<Scalars['Boolean']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']>;
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** The post meta key to which the value should be assigned. */
  postMetaFieldName?: Maybe<Scalars['String']>;
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']>;
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** An array of field values. */
  values?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** Post_custom_field choice values. */
export type PostCustomMultiSelectFieldChoice = {
  __typename?: 'PostCustomMultiSelectFieldChoice';
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']>;
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']>;
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']>;
};

/** A Gravity Forms  post_custom_field field. */
export type PostCustomNumberField = FormField & PostCustomField & {
  __typename?: 'PostCustomNumberField';
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** The autocomplete attribute for the field. */
  autocompleteAttribute?: Maybe<Scalars['String']>;
  /** The formula used for the number field. */
  calculationFormula?: Maybe<Scalars['String']>;
  /** Specifies to how many decimal places the number should be rounded. This is available when isCalculation is true, but is not available when the chosen format is “Currency”. */
  calculationRounding?: Maybe<Scalars['Int']>;
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Whether autocomplete should be enabled for this field. */
  hasAutocomplete?: Maybe<Scalars['Boolean']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']>;
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** Indicates whether the number field is a calculation. */
  isCalculation?: Maybe<Scalars['Boolean']>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** Specifies the format allowed for the number field. */
  numberFormat?: Maybe<NumberFieldFormatEnum>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']>;
  /** The post meta key to which the value should be assigned. */
  postMetaFieldName?: Maybe<Scalars['String']>;
  /** Maximum allowed value for a number field. Values higher than the number specified by this property will cause the field to fail validation. */
  rangeMax?: Maybe<Scalars['Float']>;
  /** Minimum allowed value for a number field. Values lower than the number specified by this property will cause the field to fail validation. */
  rangeMin?: Maybe<Scalars['Float']>;
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']>;
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** A Gravity Forms  post_custom_field field. */
export type PostCustomPhoneField = FormField & PostCustomField & {
  __typename?: 'PostCustomPhoneField';
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** The autocomplete attribute for the field. */
  autocompleteAttribute?: Maybe<Scalars['String']>;
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Whether autocomplete should be enabled for this field. */
  hasAutocomplete?: Maybe<Scalars['Boolean']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']>;
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** Determines the allowed format for phones. If the phone value does not conform with the specified format, the field will fail validation. */
  phoneFormat?: Maybe<PhoneFieldFormatEnum>;
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']>;
  /** The post meta key to which the value should be assigned. */
  postMetaFieldName?: Maybe<Scalars['String']>;
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']>;
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** A Gravity Forms  post_custom_field field. */
export type PostCustomRadioField = FormField & PostCustomField & {
  __typename?: 'PostCustomRadioField';
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** The available choices for the post_custom_field field. */
  choices?: Maybe<Array<Maybe<PostCustomRadioFieldChoice>>>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
  hasChoiceValue?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the &#039;Enable &quot;other&quot; choice&#039; option is checked in the editor. */
  hasOtherChoice?: Maybe<Scalars['Boolean']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']>;
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** The post meta key to which the value should be assigned. */
  postMetaFieldName?: Maybe<Scalars['String']>;
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']>;
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** Post_custom_field choice values. */
export type PostCustomRadioFieldChoice = {
  __typename?: 'PostCustomRadioFieldChoice';
  /** Indicates the radio button item is the “Other” choice. */
  isOtherChoice?: Maybe<Scalars['Boolean']>;
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']>;
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']>;
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']>;
};

/** A Gravity Forms  post_custom_field field. */
export type PostCustomSelectField = FormField & PostCustomField & {
  __typename?: 'PostCustomSelectField';
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** The autocomplete attribute for the field. */
  autocompleteAttribute?: Maybe<Scalars['String']>;
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** The available choices for the post_custom_field field. */
  choices?: Maybe<Array<Maybe<PostCustomSelectFieldChoice>>>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Whether autocomplete should be enabled for this field. */
  hasAutocomplete?: Maybe<Scalars['Boolean']>;
  /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
  hasChoiceValue?: Maybe<Scalars['Boolean']>;
  /** When set to true, the &quot;Chosen&quot; jQuery script will be applied to this field, enabling search capabilities to Drop Down fields and a more user-friendly interface for Multi Select fields. */
  hasEnhancedUI?: Maybe<Scalars['Boolean']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']>;
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']>;
  /** The post meta key to which the value should be assigned. */
  postMetaFieldName?: Maybe<Scalars['String']>;
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']>;
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** Post_custom_field choice values. */
export type PostCustomSelectFieldChoice = {
  __typename?: 'PostCustomSelectFieldChoice';
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']>;
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']>;
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']>;
};

/** A Gravity Forms  post_custom_field field. */
export type PostCustomTextAreaField = FormField & PostCustomField & {
  __typename?: 'PostCustomTextAreaField';
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Indicates whether the field uses the rich text editor interface. */
  hasRichTextEditor?: Maybe<Scalars['Boolean']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']>;
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** Specifies the maximum number of characters allowed in a text or textarea (paragraph) field. */
  maxLength?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']>;
  /** The post meta key to which the value should be assigned. */
  postMetaFieldName?: Maybe<Scalars['String']>;
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']>;
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** A Gravity Forms  post_custom_field field. */
export type PostCustomTextField = FormField & PostCustomField & {
  __typename?: 'PostCustomTextField';
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** The autocomplete attribute for the field. */
  autocompleteAttribute?: Maybe<Scalars['String']>;
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Whether autocomplete should be enabled for this field. */
  hasAutocomplete?: Maybe<Scalars['Boolean']>;
  /** Whether the field has an input mask. */
  hasInputMask?: Maybe<Scalars['Boolean']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** The pattern used for the input mask. */
  inputMaskValue?: Maybe<Scalars['String']>;
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']>;
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** Determines if a text field input tag should be created with a &quot;password&quot; type. */
  isPasswordInput?: Maybe<Scalars['Boolean']>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** Specifies the maximum number of characters allowed in a text or textarea (paragraph) field. */
  maxLength?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']>;
  /** The post meta key to which the value should be assigned. */
  postMetaFieldName?: Maybe<Scalars['String']>;
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']>;
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** A Gravity Forms  post_custom_field field. */
export type PostCustomTimeField = FormField & PostCustomField & {
  __typename?: 'PostCustomTimeField';
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']>;
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** An array of the available properties for each input of the post_custom_field field. */
  inputs?: Maybe<Array<Maybe<PostCustomInputProperty>>>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** The post meta key to which the value should be assigned. */
  postMetaFieldName?: Maybe<Scalars['String']>;
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']>;
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>;
  /** The placement of the labels for the subfields within the group. This setting controls all of the subfields, they cannot be set individually. They may be aligned above or below the inputs. If this property is not set, the “Sub-Label Placement” setting on the Form Settings-&gt;Form Layout page is used. If no setting is specified, the default is above inputs. */
  subLabelPlacement?: Maybe<FormFieldSubLabelPlacementEnum>;
  /** Determines how the time is displayed. */
  timeFormat?: Maybe<TimeFieldFormatEnum>;
  /** Time field value. */
  timeValues?: Maybe<TimeFieldValue>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** A Gravity Forms  post_custom_field field. */
export type PostCustomWebsiteField = FormField & PostCustomField & {
  __typename?: 'PostCustomWebsiteField';
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']>;
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']>;
  /** The post meta key to which the value should be assigned. */
  postMetaFieldName?: Maybe<Scalars['String']>;
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']>;
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** A Gravity Forms   field. */
export type PostExcerptField = FormField & {
  __typename?: 'PostExcerptField';
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']>;
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** Specifies the maximum number of characters allowed in a text or textarea (paragraph) field. */
  maxLength?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']>;
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** The postFormat type */
export type PostFormat = DatabaseIdentifier & Node & TermNode & UniformResourceIdentifiable & {
  __typename?: 'PostFormat';
  /** Connection between the postFormat type and the ContentNode type */
  contentNodes?: Maybe<PostFormatToContentNodeConnection>;
  /** The number of objects connected to the object */
  count?: Maybe<Scalars['Int']>;
  /** The unique identifier stored in the database */
  databaseId: Scalars['Int'];
  /** The description of the object */
  description?: Maybe<Scalars['String']>;
  /** Connection between the TermNode type and the EnqueuedScript type */
  enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>;
  /** Connection between the TermNode type and the EnqueuedStylesheet type */
  enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>;
  /** The unique resource identifier path */
  id: Scalars['ID'];
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean'];
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']>;
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean'];
  /** The link to the term */
  link?: Maybe<Scalars['String']>;
  /** The human friendly name of the object. */
  name?: Maybe<Scalars['String']>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of databaseId
   */
  postFormatId?: Maybe<Scalars['Int']>;
  /** Connection between the postFormat type and the post type */
  posts?: Maybe<PostFormatToPostConnection>;
  /** An alphanumeric identifier for the object unique to its type. */
  slug?: Maybe<Scalars['String']>;
  /** Connection between the postFormat type and the Taxonomy type */
  taxonomy?: Maybe<PostFormatToTaxonomyConnectionEdge>;
  /** The name of the taxonomy that the object is associated with */
  taxonomyName?: Maybe<Scalars['String']>;
  /** The ID of the term group that this term object belongs to */
  termGroupId?: Maybe<Scalars['Int']>;
  /** The taxonomy ID that the object is associated with */
  termTaxonomyId?: Maybe<Scalars['Int']>;
  /** The unique resource identifier path */
  uri?: Maybe<Scalars['String']>;
};


/** The postFormat type */
export type PostFormatContentNodesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostFormatToContentNodeConnectionWhereArgs>;
};


/** The postFormat type */
export type PostFormatEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The postFormat type */
export type PostFormatEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The postFormat type */
export type PostFormatPostsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostFormatToPostConnectionWhereArgs>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum PostFormatIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI'
}

/** Connection between the postFormat type and the ContentNode type */
export type PostFormatToContentNodeConnection = {
  __typename?: 'PostFormatToContentNodeConnection';
  /** Edges for the PostFormatToContentNodeConnection connection */
  edges?: Maybe<Array<Maybe<PostFormatToContentNodeConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentNode>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type PostFormatToContentNodeConnectionEdge = {
  __typename?: 'PostFormatToContentNodeConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<ContentNode>;
};

/** Arguments for filtering the PostFormatToContentNodeConnection connection */
export type PostFormatToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfPostFormatEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** Connection between the postFormat type and the post type */
export type PostFormatToPostConnection = {
  __typename?: 'PostFormatToPostConnection';
  /** Edges for the PostFormatToPostConnection connection */
  edges?: Maybe<Array<Maybe<PostFormatToPostConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Post>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type PostFormatToPostConnectionEdge = {
  __typename?: 'PostFormatToPostConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<Post>;
};

/** Arguments for filtering the PostFormatToPostConnection connection */
export type PostFormatToPostConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Category ID */
  categoryId?: InputMaybe<Scalars['Int']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Use Category Slug */
  categoryName?: InputMaybe<Scalars['String']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars['String']>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars['String']>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of tag slugs, used to display objects from one tag OR another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of tag slugs, used to exclude objects in specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** Connection between the postFormat type and the Taxonomy type */
export type PostFormatToTaxonomyConnectionEdge = {
  __typename?: 'PostFormatToTaxonomyConnectionEdge';
  /** The node of the connection, without the edges */
  node?: Maybe<Taxonomy>;
};

/** List of possible post formats. */
export enum PostFormatTypeEnum {
  /** A standard post format. */
  Standard = 'STANDARD'
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum PostIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a resource by the URI. */
  Uri = 'URI'
}

/** A Gravity Forms   field. */
export type PostImageField = FormField & {
  __typename?: 'PostImageField';
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** A comma-delimited list of the file extensions which may be uploaded. */
  allowedExtensions?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Controls the visibility of the alt metadata for Post Image fields. */
  hasAlt?: Maybe<Scalars['Boolean']>;
  /** Controls the visibility of the caption metadata for Post Image fields. */
  hasCaption?: Maybe<Scalars['Boolean']>;
  /** Controls the visibility of the description metadata for Post Image fields. */
  hasDescription?: Maybe<Scalars['Boolean']>;
  /** Controls the visibility of the title metadata for Post Image fields. */
  hasTitle?: Maybe<Scalars['Boolean']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** Image field value. */
  imageValues?: Maybe<ImageFieldValue>;
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** Whether the image field should be used to set the post&#039;s Featured Image */
  isFeaturedImage?: Maybe<Scalars['Boolean']>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** The placement of the labels for the subfields within the group. This setting controls all of the subfields, they cannot be set individually. They may be aligned above or below the inputs. If this property is not set, the “Sub-Label Placement” setting on the Form Settings-&gt;Form Layout page is used. If no setting is specified, the default is above inputs. */
  subLabelPlacement?: Maybe<FormFieldSubLabelPlacementEnum>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** The format of post field data. */
export enum PostObjectFieldFormatEnum {
  /** Provide the field value directly from database. Null on unauthenticated requests. */
  Raw = 'RAW',
  /** Provide the field value as rendered by WordPress. Default. */
  Rendered = 'RENDERED'
}

/** The column to use when filtering by date */
export enum PostObjectsConnectionDateColumnEnum {
  /** The date the comment was created in local time. */
  Date = 'DATE',
  /** The most recent modification date of the comment. */
  Modified = 'MODIFIED'
}

/** Field to order the connection by */
export enum PostObjectsConnectionOrderbyEnum {
  /** Order by author */
  Author = 'AUTHOR',
  /** Order by the number of comments it has acquired */
  CommentCount = 'COMMENT_COUNT',
  /** Order by publish date */
  Date = 'DATE',
  /** Preserve the ID order given in the IN array */
  In = 'IN',
  /** Order by the menu order value */
  MenuOrder = 'MENU_ORDER',
  /** Order by last modified date */
  Modified = 'MODIFIED',
  /** Preserve slug order given in the NAME_IN array */
  NameIn = 'NAME_IN',
  /** Order by parent ID */
  Parent = 'PARENT',
  /** Order by slug */
  Slug = 'SLUG',
  /** Order by title */
  Title = 'TITLE'
}

/** Options for ordering the connection */
export type PostObjectsConnectionOrderbyInput = {
  /** The field to order the connection by */
  field: PostObjectsConnectionOrderbyEnum;
  /** Possible directions in which to order a list of items */
  order: OrderEnum;
};

/** Set relationships between the post to postFormats */
export type PostPostFormatsInput = {
  /** If true, this will append the postFormat to existing related postFormats. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<PostPostFormatsNodeInput>>>;
};

/** List of postFormats to connect the post to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type PostPostFormatsNodeInput = {
  /** The description of the postFormat. This field is used to set a description of the postFormat if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']>;
  /** The ID of the postFormat. If present, this will be used to connect to the post. If no existing postFormat exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']>;
  /** The name of the postFormat. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']>;
  /** The slug of the postFormat. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']>;
};

/** The status of the object. */
export enum PostStatusEnum {
  /** Objects with the acf-disabled status */
  AcfDisabled = 'ACF_DISABLED',
  /** Objects with the auto-draft status */
  AutoDraft = 'AUTO_DRAFT',
  /** Objects with the dp-rewrite-republish status */
  DpRewriteRepublish = 'DP_REWRITE_REPUBLISH',
  /** Objects with the draft status */
  Draft = 'DRAFT',
  /** Objects with the future status */
  Future = 'FUTURE',
  /** Objects with the inherit status */
  Inherit = 'INHERIT',
  /** Objects with the pending status */
  Pending = 'PENDING',
  /** Objects with the private status */
  Private = 'PRIVATE',
  /** Objects with the publish status */
  Publish = 'PUBLISH',
  /** Objects with the request-completed status */
  RequestCompleted = 'REQUEST_COMPLETED',
  /** Objects with the request-confirmed status */
  RequestConfirmed = 'REQUEST_CONFIRMED',
  /** Objects with the request-failed status */
  RequestFailed = 'REQUEST_FAILED',
  /** Objects with the request-pending status */
  RequestPending = 'REQUEST_PENDING',
  /** Objects with the trash status */
  Trash = 'TRASH',
  /** Objects with the wp_stream_disabled status */
  WpStreamDisabled = 'WP_STREAM_DISABLED',
  /** Objects with the wp_stream_enabled status */
  WpStreamEnabled = 'WP_STREAM_ENABLED'
}

/** A Gravity Forms  post_tags field. */
export type PostTagsCheckboxField = FormField & PostTagsField & {
  __typename?: 'PostTagsCheckboxField';
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** Checkbox field value. */
  checkboxValues?: Maybe<Array<Maybe<CheckboxFieldValue>>>;
  /** The available choices for the post_tags field. */
  choices?: Maybe<Array<Maybe<PostTagsCheckboxFieldChoice>>>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
  hasChoiceValue?: Maybe<Scalars['Boolean']>;
  /** Whether the \&quot;select all\&quot; choice should be displayed. */
  hasSelectAll?: Maybe<Scalars['Boolean']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']>;
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** An array of the available properties for each input of the post_tags field. */
  inputs?: Maybe<Array<Maybe<PostTagsInputProperty>>>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']>;
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** Post_tags choice values. */
export type PostTagsCheckboxFieldChoice = {
  __typename?: 'PostTagsCheckboxFieldChoice';
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']>;
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']>;
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']>;
};

/** A Gravity Forms   field. */
export type PostTagsField = {
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']>;
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']>;
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** Set relationships between the post to tags */
export type PostTagsInput = {
  /** If true, this will append the tag to existing related tags. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<PostTagsNodeInput>>>;
};

/** Post_tags input values. */
export type PostTagsInputProperty = {
  __typename?: 'PostTagsInputProperty';
  /** The input ID. Input IDs follow the following naming convention: FIELDID.INPUTID (i.e. 5.1), where FIELDID is the id of the containing field and INPUTID specifies the input field. */
  id?: Maybe<Scalars['Float']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  name?: Maybe<Scalars['String']>;
};

/** A Gravity Forms  post_tags field. */
export type PostTagsMultiSelectField = FormField & PostTagsField & {
  __typename?: 'PostTagsMultiSelectField';
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** The available choices for the post_tags field. */
  choices?: Maybe<Array<Maybe<PostTagsMultiSelectFieldChoice>>>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
  hasChoiceValue?: Maybe<Scalars['Boolean']>;
  /** When set to true, the &quot;Chosen&quot; jQuery script will be applied to this field, enabling search capabilities to Drop Down fields and a more user-friendly interface for Multi Select fields. */
  hasEnhancedUI?: Maybe<Scalars['Boolean']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']>;
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']>;
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** An array of field values. */
  values?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** Post_tags choice values. */
export type PostTagsMultiSelectFieldChoice = {
  __typename?: 'PostTagsMultiSelectFieldChoice';
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']>;
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']>;
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']>;
};

/** List of tags to connect the post to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type PostTagsNodeInput = {
  /** The description of the tag. This field is used to set a description of the tag if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']>;
  /** The ID of the tag. If present, this will be used to connect to the post. If no existing tag exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']>;
  /** The name of the tag. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']>;
  /** The slug of the tag. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']>;
};

/** A Gravity Forms  post_tags field. */
export type PostTagsRadioField = FormField & PostTagsField & {
  __typename?: 'PostTagsRadioField';
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** The available choices for the post_tags field. */
  choices?: Maybe<Array<Maybe<PostTagsRadioFieldChoice>>>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
  hasChoiceValue?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the &#039;Enable &quot;other&quot; choice&#039; option is checked in the editor. */
  hasOtherChoice?: Maybe<Scalars['Boolean']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']>;
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']>;
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']>;
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** Post_tags choice values. */
export type PostTagsRadioFieldChoice = {
  __typename?: 'PostTagsRadioFieldChoice';
  /** Indicates the radio button item is the “Other” choice. */
  isOtherChoice?: Maybe<Scalars['Boolean']>;
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']>;
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']>;
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']>;
};

/** A Gravity Forms  post_tags field. */
export type PostTagsSelectField = FormField & PostTagsField & {
  __typename?: 'PostTagsSelectField';
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** The autocomplete attribute for the field. */
  autocompleteAttribute?: Maybe<Scalars['String']>;
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** The available choices for the post_tags field. */
  choices?: Maybe<Array<Maybe<PostTagsSelectFieldChoice>>>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Whether autocomplete should be enabled for this field. */
  hasAutocomplete?: Maybe<Scalars['Boolean']>;
  /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
  hasChoiceValue?: Maybe<Scalars['Boolean']>;
  /** When set to true, the &quot;Chosen&quot; jQuery script will be applied to this field, enabling search capabilities to Drop Down fields and a more user-friendly interface for Multi Select fields. */
  hasEnhancedUI?: Maybe<Scalars['Boolean']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']>;
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']>;
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']>;
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** Post_tags choice values. */
export type PostTagsSelectFieldChoice = {
  __typename?: 'PostTagsSelectFieldChoice';
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']>;
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']>;
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']>;
};

/** A Gravity Forms  post_tags field. */
export type PostTagsTextField = FormField & PostTagsField & {
  __typename?: 'PostTagsTextField';
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** The autocomplete attribute for the field. */
  autocompleteAttribute?: Maybe<Scalars['String']>;
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Whether autocomplete should be enabled for this field. */
  hasAutocomplete?: Maybe<Scalars['Boolean']>;
  /** Whether the field has an input mask. */
  hasInputMask?: Maybe<Scalars['Boolean']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** The pattern used for the input mask. */
  inputMaskValue?: Maybe<Scalars['String']>;
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']>;
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** Determines if a text field input tag should be created with a &quot;password&quot; type. */
  isPasswordInput?: Maybe<Scalars['Boolean']>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** Specifies the maximum number of characters allowed in a text or textarea (paragraph) field. */
  maxLength?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']>;
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']>;
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** A Gravity Forms   field. */
export type PostTitleField = FormField & {
  __typename?: 'PostTitleField';
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']>;
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']>;
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** Connection between the post type and the category type */
export type PostToCategoryConnection = {
  __typename?: 'PostToCategoryConnection';
  /** Edges for the PostToCategoryConnection connection */
  edges?: Maybe<Array<Maybe<PostToCategoryConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Category>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type PostToCategoryConnectionEdge = {
  __typename?: 'PostToCategoryConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<Category>;
};

/** Arguments for filtering the PostToCategoryConnection connection */
export type PostToCategoryConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
};

/** Connection between the post type and the postFormat type */
export type PostToPostFormatConnection = {
  __typename?: 'PostToPostFormatConnection';
  /** Edges for the PostToPostFormatConnection connection */
  edges?: Maybe<Array<Maybe<PostToPostFormatConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<PostFormat>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type PostToPostFormatConnectionEdge = {
  __typename?: 'PostToPostFormatConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<PostFormat>;
};

/** Arguments for filtering the PostToPostFormatConnection connection */
export type PostToPostFormatConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
};

/** Connection between the post type and the post type */
export type PostToPreviewConnectionEdge = {
  __typename?: 'PostToPreviewConnectionEdge';
  /** The node of the connection, without the edges */
  node?: Maybe<Post>;
};

/** Connection between the post type and the post type */
export type PostToRevisionConnection = {
  __typename?: 'PostToRevisionConnection';
  /** Edges for the postToRevisionConnection connection */
  edges?: Maybe<Array<Maybe<PostToRevisionConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Post>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type PostToRevisionConnectionEdge = {
  __typename?: 'PostToRevisionConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<Post>;
};

/** Arguments for filtering the postToRevisionConnection connection */
export type PostToRevisionConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Category ID */
  categoryId?: InputMaybe<Scalars['Int']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Use Category Slug */
  categoryName?: InputMaybe<Scalars['String']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars['String']>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars['String']>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of tag slugs, used to display objects from one tag OR another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of tag slugs, used to exclude objects in specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** Connection between the post type and the tag type */
export type PostToTagConnection = {
  __typename?: 'PostToTagConnection';
  /** Edges for the PostToTagConnection connection */
  edges?: Maybe<Array<Maybe<PostToTagConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Tag>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type PostToTagConnectionEdge = {
  __typename?: 'PostToTagConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<Tag>;
};

/** Arguments for filtering the PostToTagConnection connection */
export type PostToTagConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
};

/** Connection between the post type and the TermNode type */
export type PostToTermNodeConnection = {
  __typename?: 'PostToTermNodeConnection';
  /** Edges for the PostToTermNodeConnection connection */
  edges?: Maybe<Array<Maybe<PostToTermNodeConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<TermNode>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type PostToTermNodeConnectionEdge = {
  __typename?: 'PostToTermNodeConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<TermNode>;
};

/** Arguments for filtering the PostToTermNodeConnection connection */
export type PostToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** The Taxonomy to filter terms by */
  taxonomies?: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
};

/** Details for labels of the PostType */
export type PostTypeLabelDetails = {
  __typename?: 'PostTypeLabelDetails';
  /** Default is ‘Add New’ for both hierarchical and non-hierarchical types. */
  addNew?: Maybe<Scalars['String']>;
  /** Label for adding a new singular item. */
  addNewItem?: Maybe<Scalars['String']>;
  /** Label to signify all items in a submenu link. */
  allItems?: Maybe<Scalars['String']>;
  /** Label for archives in nav menus */
  archives?: Maybe<Scalars['String']>;
  /** Label for the attributes meta box. */
  attributes?: Maybe<Scalars['String']>;
  /** Label for editing a singular item. */
  editItem?: Maybe<Scalars['String']>;
  /** Label for the Featured Image meta box title. */
  featuredImage?: Maybe<Scalars['String']>;
  /** Label for the table views hidden heading. */
  filterItemsList?: Maybe<Scalars['String']>;
  /** Label for the media frame button. */
  insertIntoItem?: Maybe<Scalars['String']>;
  /** Label for the table hidden heading. */
  itemsList?: Maybe<Scalars['String']>;
  /** Label for the table pagination hidden heading. */
  itemsListNavigation?: Maybe<Scalars['String']>;
  /** Label for the menu name. */
  menuName?: Maybe<Scalars['String']>;
  /** General name for the post type, usually plural. */
  name?: Maybe<Scalars['String']>;
  /** Label for the new item page title. */
  newItem?: Maybe<Scalars['String']>;
  /** Label used when no items are found. */
  notFound?: Maybe<Scalars['String']>;
  /** Label used when no items are in the trash. */
  notFoundInTrash?: Maybe<Scalars['String']>;
  /** Label used to prefix parents of hierarchical items. */
  parentItemColon?: Maybe<Scalars['String']>;
  /** Label for removing the featured image. */
  removeFeaturedImage?: Maybe<Scalars['String']>;
  /** Label for searching plural items. */
  searchItems?: Maybe<Scalars['String']>;
  /** Label for setting the featured image. */
  setFeaturedImage?: Maybe<Scalars['String']>;
  /** Name for one object of this post type. */
  singularName?: Maybe<Scalars['String']>;
  /** Label for the media frame filter. */
  uploadedToThisItem?: Maybe<Scalars['String']>;
  /** Label in the media frame for using a featured image. */
  useFeaturedImage?: Maybe<Scalars['String']>;
  /** Label for viewing a singular item. */
  viewItem?: Maybe<Scalars['String']>;
  /** Label for viewing post type archives. */
  viewItems?: Maybe<Scalars['String']>;
};

/** Field Group */
export type Post_Meta = {
  __typename?: 'Post_Meta';
  fieldGroupName?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  /** If left blank the page or post title will be used. */
  metaTitle?: Maybe<Scalars['String']>;
  ogDescription?: Maybe<Scalars['String']>;
  ogImage?: Maybe<MediaItem>;
  ogTitle?: Maybe<Scalars['String']>;
  twitterCardType?: Maybe<Scalars['String']>;
  twitterDescription?: Maybe<Scalars['String']>;
  twitterImage?: Maybe<MediaItem>;
  twitterTitle?: Maybe<Scalars['String']>;
};

/** Field Group */
export type Post_Newspostdetails = {
  __typename?: 'Post_Newspostdetails';
  endDate?: Maybe<Scalars['String']>;
  fieldGroupName?: Maybe<Scalars['String']>;
};

/** A Gravity Forms   field. */
export type RadioField = FormField & {
  __typename?: 'RadioField';
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** The available choices for the radio field. */
  choices?: Maybe<Array<Maybe<RadioFieldChoice>>>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
  hasChoiceValue?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the &#039;Enable &quot;other&quot; choice&#039; option is checked in the editor. */
  hasOtherChoice?: Maybe<Scalars['Boolean']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']>;
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** Radio choice values. */
export type RadioFieldChoice = {
  __typename?: 'RadioFieldChoice';
  /** Indicates the radio button item is the “Other” choice. */
  isOtherChoice?: Maybe<Scalars['Boolean']>;
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']>;
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']>;
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']>;
};

/** The reading setting type */
export type ReadingSettings = {
  __typename?: 'ReadingSettings';
  /** Blog pages show at most. */
  postsPerPage?: Maybe<Scalars['Int']>;
};

/** Determines which version of reCAPTCHA v2 will be used.  */
export enum RecaptchaTypeEnum {
  /** A checkbox reCAPTCHA type. */
  Checkbox = 'CHECKBOX',
  /** An invisible reCAPTCHA type. */
  Invisible = 'INVISIBLE'
}

/** Input for the refreshJwtAuthToken mutation */
export type RefreshJwtAuthTokenInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** A valid, previously issued JWT refresh token. If valid a new Auth token will be provided. If invalid, expired, revoked or otherwise invalid, a new AuthToken will not be provided. */
  jwtRefreshToken: Scalars['String'];
};

/** The payload for the refreshJwtAuthToken mutation */
export type RefreshJwtAuthTokenPayload = {
  __typename?: 'RefreshJwtAuthTokenPayload';
  /** JWT Token that can be used in future requests for Authentication */
  authToken?: Maybe<Scalars['String']>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** The region type */
export type Region = DatabaseIdentifier & HierarchicalTermNode & MenuItemLinkable & Node & TermNode & UniformResourceIdentifiable & {
  __typename?: 'Region';
  /** Connection between the region type and the account_manager type */
  accountManagers?: Maybe<RegionToAccount_ManagerConnection>;
  /** The ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
  ancestors?: Maybe<RegionToAncestorsRegionConnection>;
  /** Connection between the region type and the region type */
  children?: Maybe<RegionToRegionConnection>;
  /** Connection between the region type and the ContentNode type */
  contentNodes?: Maybe<RegionToContentNodeConnection>;
  /** The number of objects connected to the object */
  count?: Maybe<Scalars['Int']>;
  /** The unique resource identifier path */
  databaseId: Scalars['Int'];
  /** The description of the object */
  description?: Maybe<Scalars['String']>;
  /** Added to the GraphQL Schema because the ACF Field Group &quot;Account manager regions&quot; was assigned to the &quot;account_manager_region&quot; taxonomy */
  details?: Maybe<Region_Details>;
  /** Connection between the TermNode type and the EnqueuedScript type */
  enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>;
  /** Connection between the TermNode type and the EnqueuedStylesheet type */
  enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>;
  /** The unique resource identifier path */
  id: Scalars['ID'];
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean'];
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']>;
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean'];
  /** The link to the term */
  link?: Maybe<Scalars['String']>;
  /** The human friendly name of the object. */
  name?: Maybe<Scalars['String']>;
  /** Connection between the region type and the region type */
  parent?: Maybe<RegionToParentRegionConnectionEdge>;
  /** Database id of the parent node */
  parentDatabaseId?: Maybe<Scalars['Int']>;
  /** The globally unique identifier of the parent node. */
  parentId?: Maybe<Scalars['ID']>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of databaseId
   */
  regionId?: Maybe<Scalars['Int']>;
  /** An alphanumeric identifier for the object unique to its type. */
  slug?: Maybe<Scalars['String']>;
  /** Connection between the region type and the Taxonomy type */
  taxonomy?: Maybe<RegionToTaxonomyConnectionEdge>;
  /** The name of the taxonomy that the object is associated with */
  taxonomyName?: Maybe<Scalars['String']>;
  /** The ID of the term group that this term object belongs to */
  termGroupId?: Maybe<Scalars['Int']>;
  /** The taxonomy ID that the object is associated with */
  termTaxonomyId?: Maybe<Scalars['Int']>;
  /** The unique resource identifier path */
  uri?: Maybe<Scalars['String']>;
};


/** The region type */
export type RegionAccountManagersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RegionToAccount_ManagerConnectionWhereArgs>;
};


/** The region type */
export type RegionAncestorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The region type */
export type RegionChildrenArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RegionToRegionConnectionWhereArgs>;
};


/** The region type */
export type RegionContentNodesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RegionToContentNodeConnectionWhereArgs>;
};


/** The region type */
export type RegionEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The region type */
export type RegionEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum RegionIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI'
}

/** Connection between the region type and the account_manager type */
export type RegionToAccount_ManagerConnection = {
  __typename?: 'RegionToAccount_managerConnection';
  /** Edges for the RegionToAccount_managerConnection connection */
  edges?: Maybe<Array<Maybe<RegionToAccount_ManagerConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Account_Manager>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type RegionToAccount_ManagerConnectionEdge = {
  __typename?: 'RegionToAccount_managerConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<Account_Manager>;
};

/** Arguments for filtering the RegionToAccount_managerConnection connection */
export type RegionToAccount_ManagerConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** Connection between the region type and the region type */
export type RegionToAncestorsRegionConnection = {
  __typename?: 'RegionToAncestorsRegionConnection';
  /** Edges for the RegionToAncestorsRegionConnection connection */
  edges?: Maybe<Array<Maybe<RegionToAncestorsRegionConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Region>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type RegionToAncestorsRegionConnectionEdge = {
  __typename?: 'RegionToAncestorsRegionConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<Region>;
};

/** Connection between the region type and the ContentNode type */
export type RegionToContentNodeConnection = {
  __typename?: 'RegionToContentNodeConnection';
  /** Edges for the RegionToContentNodeConnection connection */
  edges?: Maybe<Array<Maybe<RegionToContentNodeConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentNode>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type RegionToContentNodeConnectionEdge = {
  __typename?: 'RegionToContentNodeConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<ContentNode>;
};

/** Arguments for filtering the RegionToContentNodeConnection connection */
export type RegionToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfRegionEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** Connection between the region type and the region type */
export type RegionToParentRegionConnectionEdge = {
  __typename?: 'RegionToParentRegionConnectionEdge';
  /** The node of the connection, without the edges */
  node?: Maybe<Region>;
};

/** Connection between the region type and the region type */
export type RegionToRegionConnection = {
  __typename?: 'RegionToRegionConnection';
  /** Edges for the RegionToRegionConnection connection */
  edges?: Maybe<Array<Maybe<RegionToRegionConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Region>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type RegionToRegionConnectionEdge = {
  __typename?: 'RegionToRegionConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<Region>;
};

/** Arguments for filtering the RegionToRegionConnection connection */
export type RegionToRegionConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
};

/** Connection between the region type and the Taxonomy type */
export type RegionToTaxonomyConnectionEdge = {
  __typename?: 'RegionToTaxonomyConnectionEdge';
  /** The node of the connection, without the edges */
  node?: Maybe<Taxonomy>;
};

/** Field Group */
export type Region_Details = {
  __typename?: 'Region_Details';
  fieldGroupName?: Maybe<Scalars['String']>;
  postcodes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Input for the registerUser mutation */
export type RegisterUserInput = {
  /** User's AOL IM account. */
  aim?: InputMaybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** A string containing content about the user. */
  description?: InputMaybe<Scalars['String']>;
  /** A string that will be shown on the site. Defaults to user's username. It is likely that you will want to change this, for both appearance and security through obscurity (that is if you dont use and delete the default admin user). */
  displayName?: InputMaybe<Scalars['String']>;
  /** A string containing the user's email address. */
  email?: InputMaybe<Scalars['String']>;
  /** 	The user's first name. */
  firstName?: InputMaybe<Scalars['String']>;
  /** User's Jabber account. */
  jabber?: InputMaybe<Scalars['String']>;
  /** The user's last name. */
  lastName?: InputMaybe<Scalars['String']>;
  /** User's locale. */
  locale?: InputMaybe<Scalars['String']>;
  /** A string that contains a URL-friendly name for the user. The default is the user's username. */
  nicename?: InputMaybe<Scalars['String']>;
  /** The user's nickname, defaults to the user's username. */
  nickname?: InputMaybe<Scalars['String']>;
  /** A string that contains the plain text password for the user. */
  password?: InputMaybe<Scalars['String']>;
  /** If true, this will refresh the users JWT secret. */
  refreshJwtUserSecret?: InputMaybe<Scalars['Boolean']>;
  /** The date the user registered. Format is Y-m-d H:i:s. */
  registered?: InputMaybe<Scalars['String']>;
  /** If true, this will revoke the users JWT secret. If false, this will unrevoke the JWT secret AND issue a new one. To revoke, the user must have proper capabilities to edit users JWT secrets. */
  revokeJwtUserSecret?: InputMaybe<Scalars['Boolean']>;
  /** A string for whether to enable the rich editor or not. False if not empty. */
  richEditing?: InputMaybe<Scalars['String']>;
  /** A string that contains the user's username. */
  username: Scalars['String'];
  /** A string containing the user's URL for the user's web site. */
  websiteUrl?: InputMaybe<Scalars['String']>;
  /** User's Yahoo IM account. */
  yim?: InputMaybe<Scalars['String']>;
};

/** The payload for the registerUser mutation */
export type RegisterUserPayload = {
  __typename?: 'RegisterUserPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The User object mutation type. */
  user?: Maybe<User>;
};

/** The logical relation between each item in the array when there are more than one. */
export enum RelationEnum {
  /** The logical AND condition returns true if both operands are true, otherwise, it returns false. */
  And = 'AND',
  /** The logical OR condition returns false if both operands are false, otherwise, it returns true. */
  Or = 'OR'
}

/** Input for the resetUserPassword mutation */
export type ResetUserPasswordInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Password reset key */
  key?: InputMaybe<Scalars['String']>;
  /** The user's login (username). */
  login?: InputMaybe<Scalars['String']>;
  /** The new password. */
  password?: InputMaybe<Scalars['String']>;
};

/** The payload for the resetUserPassword mutation */
export type ResetUserPasswordPayload = {
  __typename?: 'ResetUserPasswordPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The User object mutation type. */
  user?: Maybe<User>;
};

/** Input for the restoreComment mutation */
export type RestoreCommentInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The ID of the comment to be restored */
  id: Scalars['ID'];
};

/** The payload for the restoreComment mutation */
export type RestoreCommentPayload = {
  __typename?: 'RestoreCommentPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The restored comment object */
  comment?: Maybe<Comment>;
  /** The ID of the restored comment */
  restoredId?: Maybe<Scalars['ID']>;
};

/** The ReusableBlock type */
export type ReusableBlock = BlockEditorContentNode & ContentNode & DatabaseIdentifier & Node & NodeWithContentEditor & NodeWithRevisions & NodeWithTemplate & NodeWithTitle & UniformResourceIdentifiable & {
  __typename?: 'ReusableBlock';
  Meta?: Maybe<ReusableBlock_Meta>;
  /** Gutenberg blocks */
  blocks?: Maybe<Array<Block>>;
  /** Gutenberg blocks as json string */
  blocksJSON?: Maybe<Scalars['String']>;
  /** The content of the post. */
  content?: Maybe<Scalars['String']>;
  /** Connection between the ContentNode type and the ContentType type */
  contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /** The name of the Content Type the node belongs to */
  contentTypeName: Scalars['String'];
  /** The unique identifier stored in the database */
  databaseId: Scalars['Int'];
  /** Post publishing date. */
  date?: Maybe<Scalars['String']>;
  /** The publishing date set in GMT. */
  dateGmt?: Maybe<Scalars['String']>;
  /** The desired slug of the post */
  desiredSlug?: Maybe<Scalars['String']>;
  /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
  editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
  /** The RSS enclosure for the object */
  enclosure?: Maybe<Scalars['String']>;
  /** Connection between the ContentNode type and the EnqueuedScript type */
  enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
  /** Connection between the ContentNode type and the EnqueuedStylesheet type */
  enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
  guid?: Maybe<Scalars['String']>;
  /** The globally unique identifier of the wp_block object. */
  id: Scalars['ID'];
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean'];
  /** Whether the object is a node in the preview state */
  isPreview?: Maybe<Scalars['Boolean']>;
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']>;
  /** True if the node is a revision of another node */
  isRevision?: Maybe<Scalars['Boolean']>;
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean'];
  /** The user that most recently edited the node */
  lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
  /** The permalink of the post */
  link?: Maybe<Scalars['String']>;
  /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
  modified?: Maybe<Scalars['String']>;
  /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
  modifiedGmt?: Maybe<Scalars['String']>;
  /** Connection between the ReusableBlock type and the ReusableBlock type */
  preview?: Maybe<ReusableBlockToPreviewConnectionEdge>;
  /** Previewed gutenberg blocks */
  previewBlocks?: Maybe<Array<Block>>;
  /** Previewed gutenberg blocks */
  previewBlocksFrom?: Maybe<Array<Block>>;
  /** Previewed gutenberg blocks as json string */
  previewBlocksFromJSON?: Maybe<Scalars['String']>;
  /** Previewed Gutenberg blocks as json string */
  previewBlocksJSON?: Maybe<Scalars['String']>;
  /** The database id of the preview node */
  previewRevisionDatabaseId?: Maybe<Scalars['Int']>;
  /** Whether the object is a node in the preview state */
  previewRevisionId?: Maybe<Scalars['ID']>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of the databaseId field
   */
  reusableBlockId: Scalars['Int'];
  /** If the current node is a revision, this field exposes the node this is a revision of. Returns null if the node is not a revision of another node. */
  revisionOf?: Maybe<NodeWithRevisionsToContentNodeConnectionEdge>;
  /** Connection between the ReusableBlock type and the ReusableBlock type */
  revisions?: Maybe<ReusableBlockToRevisionConnection>;
  /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
  slug?: Maybe<Scalars['String']>;
  /** The current status of the object */
  status?: Maybe<Scalars['String']>;
  /** The template assigned to the node */
  template?: Maybe<ContentTemplate>;
  /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
  title?: Maybe<Scalars['String']>;
  /** The unique resource identifier path */
  uri?: Maybe<Scalars['String']>;
};


/** The ReusableBlock type */
export type ReusableBlockContentArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};


/** The ReusableBlock type */
export type ReusableBlockEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The ReusableBlock type */
export type ReusableBlockEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The ReusableBlock type */
export type ReusableBlockPreviewBlocksFromArgs = {
  databaseId: Scalars['Int'];
};


/** The ReusableBlock type */
export type ReusableBlockPreviewBlocksFromJsonArgs = {
  databaseId: Scalars['Int'];
};


/** The ReusableBlock type */
export type ReusableBlockRevisionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ReusableBlockToRevisionConnectionWhereArgs>;
};


/** The ReusableBlock type */
export type ReusableBlockTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum ReusableBlockIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a resource by the URI. */
  Uri = 'URI'
}

/** Connection between the ReusableBlock type and the ReusableBlock type */
export type ReusableBlockToPreviewConnectionEdge = {
  __typename?: 'ReusableBlockToPreviewConnectionEdge';
  /** The node of the connection, without the edges */
  node?: Maybe<ReusableBlock>;
};

/** Connection between the ReusableBlock type and the ReusableBlock type */
export type ReusableBlockToRevisionConnection = {
  __typename?: 'ReusableBlockToRevisionConnection';
  /** Edges for the ReusableBlockToRevisionConnection connection */
  edges?: Maybe<Array<Maybe<ReusableBlockToRevisionConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ReusableBlock>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type ReusableBlockToRevisionConnectionEdge = {
  __typename?: 'ReusableBlockToRevisionConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<ReusableBlock>;
};

/** Arguments for filtering the ReusableBlockToRevisionConnection connection */
export type ReusableBlockToRevisionConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** Field Group */
export type ReusableBlock_Meta = {
  __typename?: 'ReusableBlock_Meta';
  fieldGroupName?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  /** If left blank the page or post title will be used. */
  metaTitle?: Maybe<Scalars['String']>;
  ogDescription?: Maybe<Scalars['String']>;
  ogImage?: Maybe<MediaItem>;
  ogTitle?: Maybe<Scalars['String']>;
  twitterCardType?: Maybe<Scalars['String']>;
  twitterDescription?: Maybe<Scalars['String']>;
  twitterImage?: Maybe<MediaItem>;
  twitterTitle?: Maybe<Scalars['String']>;
};

/** The review type */
export type Review = ContentNode & DatabaseIdentifier & Node & NodeWithContentEditor & NodeWithTemplate & NodeWithTitle & UniformResourceIdentifiable & {
  __typename?: 'Review';
  Meta?: Maybe<Review_Meta>;
  ReviewData?: Maybe<Review_Reviewdata>;
  /** The content of the post. */
  content?: Maybe<Scalars['String']>;
  /** Connection between the ContentNode type and the ContentType type */
  contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /** The name of the Content Type the node belongs to */
  contentTypeName: Scalars['String'];
  /** The unique identifier stored in the database */
  databaseId: Scalars['Int'];
  /** Post publishing date. */
  date?: Maybe<Scalars['String']>;
  /** The publishing date set in GMT. */
  dateGmt?: Maybe<Scalars['String']>;
  /** The desired slug of the post */
  desiredSlug?: Maybe<Scalars['String']>;
  /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
  editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
  /** The RSS enclosure for the object */
  enclosure?: Maybe<Scalars['String']>;
  /** Connection between the ContentNode type and the EnqueuedScript type */
  enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
  /** Connection between the ContentNode type and the EnqueuedStylesheet type */
  enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
  guid?: Maybe<Scalars['String']>;
  /** The globally unique identifier of the review object. */
  id: Scalars['ID'];
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean'];
  /** Whether the object is a node in the preview state */
  isPreview?: Maybe<Scalars['Boolean']>;
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']>;
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean'];
  /** The user that most recently edited the node */
  lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
  /** The permalink of the post */
  link?: Maybe<Scalars['String']>;
  /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
  modified?: Maybe<Scalars['String']>;
  /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
  modifiedGmt?: Maybe<Scalars['String']>;
  /** Connection between the review type and the review type */
  preview?: Maybe<ReviewToPreviewConnectionEdge>;
  /** The database id of the preview node */
  previewRevisionDatabaseId?: Maybe<Scalars['Int']>;
  /** Whether the object is a node in the preview state */
  previewRevisionId?: Maybe<Scalars['ID']>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of the databaseId field
   */
  reviewId: Scalars['Int'];
  /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
  slug?: Maybe<Scalars['String']>;
  /** The current status of the object */
  status?: Maybe<Scalars['String']>;
  /** The template assigned to the node */
  template?: Maybe<ContentTemplate>;
  /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
  title?: Maybe<Scalars['String']>;
  /** The unique resource identifier path */
  uri?: Maybe<Scalars['String']>;
};


/** The review type */
export type ReviewContentArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};


/** The review type */
export type ReviewEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The review type */
export type ReviewEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The review type */
export type ReviewTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum ReviewIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a resource by the URI. */
  Uri = 'URI'
}

/** Connection between the review type and the review type */
export type ReviewToPreviewConnectionEdge = {
  __typename?: 'ReviewToPreviewConnectionEdge';
  /** The node of the connection, without the edges */
  node?: Maybe<Review>;
};

/** Field Group */
export type Review_Meta = {
  __typename?: 'Review_Meta';
  fieldGroupName?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  /** If left blank the page or post title will be used. */
  metaTitle?: Maybe<Scalars['String']>;
  ogDescription?: Maybe<Scalars['String']>;
  ogImage?: Maybe<MediaItem>;
  ogTitle?: Maybe<Scalars['String']>;
  twitterCardType?: Maybe<Scalars['String']>;
  twitterDescription?: Maybe<Scalars['String']>;
  twitterImage?: Maybe<MediaItem>;
  twitterTitle?: Maybe<Scalars['String']>;
};

/** Field Group */
export type Review_Reviewdata = {
  __typename?: 'Review_Reviewdata';
  fieldGroupName?: Maybe<Scalars['String']>;
  mapsUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Float']>;
  reviewCount?: Maybe<Scalars['Float']>;
  reviews?: Maybe<Array<Maybe<Review_Reviewdata_Reviews>>>;
};

/** Field Group */
export type Review_Reviewdata_Reviews = {
  __typename?: 'Review_Reviewdata_reviews';
  authorImage?: Maybe<Scalars['String']>;
  authorName?: Maybe<Scalars['String']>;
  authorUrl?: Maybe<Scalars['String']>;
  fieldGroupName?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['String']>;
  relativeTime?: Maybe<Scalars['String']>;
  reviewText?: Maybe<Scalars['String']>;
  reviewTime?: Maybe<Scalars['String']>;
};

/** The root mutation */
export type RootMutation = {
  __typename?: 'RootMutation';
  /** The payload for the createAccount_manager mutation */
  createAccountManager?: Maybe<CreateAccount_ManagerPayload>;
  /** The payload for the createActionMonitorAction mutation */
  createActionMonitorAction?: Maybe<CreateActionMonitorActionPayload>;
  /** The payload for the createAreaOfSpecialism mutation */
  createAreaOfSpecialism?: Maybe<CreateAreaOfSpecialismPayload>;
  /** The payload for the createCategory mutation */
  createCategory?: Maybe<CreateCategoryPayload>;
  /** The payload for the createComment mutation */
  createComment?: Maybe<CreateCommentPayload>;
  /** The payload for the createGroup mutation */
  createGroup?: Maybe<CreateGroupPayload>;
  /** The payload for the createMediaItem mutation */
  createMediaItem?: Maybe<CreateMediaItemPayload>;
  /** The payload for the createMember mutation */
  createMember?: Maybe<CreateMemberPayload>;
  /** The payload for the createPage mutation */
  createPage?: Maybe<CreatePagePayload>;
  /** The payload for the createPost mutation */
  createPost?: Maybe<CreatePostPayload>;
  /** The payload for the createPostFormat mutation */
  createPostFormat?: Maybe<CreatePostFormatPayload>;
  /** The payload for the createRegion mutation */
  createRegion?: Maybe<CreateRegionPayload>;
  /** The payload for the createReusableBlock mutation */
  createReusableBlock?: Maybe<CreateReusableBlockPayload>;
  /** The payload for the createReview mutation */
  createReview?: Maybe<CreateReviewPayload>;
  /** The payload for the createSupplier mutation */
  createSupplier?: Maybe<CreateSupplierPayload>;
  /** The payload for the createSupplierCategory mutation */
  createSupplierCategory?: Maybe<CreateSupplierCategoryPayload>;
  /** The payload for the createSupplierKeyword mutation */
  createSupplierKeyword?: Maybe<CreateSupplierKeywordPayload>;
  /** The payload for the createSupplier_news mutation */
  createSupplierNews?: Maybe<CreateSupplier_NewsPayload>;
  /** The payload for the createTag mutation */
  createTag?: Maybe<CreateTagPayload>;
  /** The payload for the createTestimonial mutation */
  createTestimonial?: Maybe<CreateTestimonialPayload>;
  /** The payload for the createTradingTime mutation */
  createTradingTime?: Maybe<CreateTradingTimePayload>;
  /** The payload for the createUser mutation */
  createUser?: Maybe<CreateUserPayload>;
  /** The payload for the deleteAccount_manager mutation */
  deleteAccountManager?: Maybe<DeleteAccount_ManagerPayload>;
  /** The payload for the deleteActionMonitorAction mutation */
  deleteActionMonitorAction?: Maybe<DeleteActionMonitorActionPayload>;
  /** The payload for the deleteAreaOfSpecialism mutation */
  deleteAreaOfSpecialism?: Maybe<DeleteAreaOfSpecialismPayload>;
  /** The payload for the deleteCategory mutation */
  deleteCategory?: Maybe<DeleteCategoryPayload>;
  /** The payload for the deleteComment mutation */
  deleteComment?: Maybe<DeleteCommentPayload>;
  /** The payload for the deleteGfDraftEntry mutation */
  deleteGfDraftEntry?: Maybe<DeleteGfDraftEntryPayload>;
  /** The payload for the deleteGfEntry mutation */
  deleteGfEntry?: Maybe<DeleteGfEntryPayload>;
  /** The payload for the deleteGroup mutation */
  deleteGroup?: Maybe<DeleteGroupPayload>;
  /** The payload for the deleteMediaItem mutation */
  deleteMediaItem?: Maybe<DeleteMediaItemPayload>;
  /** The payload for the deleteMember mutation */
  deleteMember?: Maybe<DeleteMemberPayload>;
  /** The payload for the deletePage mutation */
  deletePage?: Maybe<DeletePagePayload>;
  /** The payload for the deletePost mutation */
  deletePost?: Maybe<DeletePostPayload>;
  /** The payload for the deletePostFormat mutation */
  deletePostFormat?: Maybe<DeletePostFormatPayload>;
  /** The payload for the deleteRegion mutation */
  deleteRegion?: Maybe<DeleteRegionPayload>;
  /** The payload for the deleteReusableBlock mutation */
  deleteReusableBlock?: Maybe<DeleteReusableBlockPayload>;
  /** The payload for the deleteReview mutation */
  deleteReview?: Maybe<DeleteReviewPayload>;
  /** The payload for the deleteSupplier mutation */
  deleteSupplier?: Maybe<DeleteSupplierPayload>;
  /** The payload for the deleteSupplierCategory mutation */
  deleteSupplierCategory?: Maybe<DeleteSupplierCategoryPayload>;
  /** The payload for the deleteSupplierKeyword mutation */
  deleteSupplierKeyword?: Maybe<DeleteSupplierKeywordPayload>;
  /** The payload for the deleteSupplier_news mutation */
  deleteSupplierNews?: Maybe<DeleteSupplier_NewsPayload>;
  /** The payload for the deleteTag mutation */
  deleteTag?: Maybe<DeleteTagPayload>;
  /** The payload for the deleteTestimonial mutation */
  deleteTestimonial?: Maybe<DeleteTestimonialPayload>;
  /** The payload for the deleteTradingTime mutation */
  deleteTradingTime?: Maybe<DeleteTradingTimePayload>;
  /** The payload for the deleteUser mutation */
  deleteUser?: Maybe<DeleteUserPayload>;
  /** Increase the count. */
  increaseCount?: Maybe<Scalars['Int']>;
  /** The payload for the login mutation */
  login?: Maybe<LoginPayload>;
  /** The payload for the loginWithCookies mutation */
  loginWithCookies?: Maybe<LoginWithCookiesPayload>;
  /** The payload for the logout mutation */
  logout?: Maybe<LogoutPayload>;
  /** The payload for the refreshJwtAuthToken mutation */
  refreshJwtAuthToken?: Maybe<RefreshJwtAuthTokenPayload>;
  /** The payload for the registerUser mutation */
  registerUser?: Maybe<RegisterUserPayload>;
  /** The payload for the resetUserPassword mutation */
  resetUserPassword?: Maybe<ResetUserPasswordPayload>;
  /** The payload for the restoreComment mutation */
  restoreComment?: Maybe<RestoreCommentPayload>;
  /** The payload for the sendEmail mutation */
  sendEmail?: Maybe<SendEmailPayload>;
  /** The payload for the sendPasswordResetEmail mutation */
  sendPasswordResetEmail?: Maybe<SendPasswordResetEmailPayload>;
  /** The payload for the submitGfDraftEntry mutation */
  submitGfDraftEntry?: Maybe<SubmitGfDraftEntryPayload>;
  /** The payload for the submitGfForm mutation */
  submitGfForm?: Maybe<SubmitGfFormPayload>;
  /** The payload for the updateAccount_manager mutation */
  updateAccountManager?: Maybe<UpdateAccount_ManagerPayload>;
  /** The payload for the updateActionMonitorAction mutation */
  updateActionMonitorAction?: Maybe<UpdateActionMonitorActionPayload>;
  /** The payload for the UpdateAreaOfSpecialism mutation */
  updateAreaOfSpecialism?: Maybe<UpdateAreaOfSpecialismPayload>;
  /** The payload for the UpdateCategory mutation */
  updateCategory?: Maybe<UpdateCategoryPayload>;
  /** The payload for the updateComment mutation */
  updateComment?: Maybe<UpdateCommentPayload>;
  /** The payload for the updateGfDraftEntry mutation */
  updateGfDraftEntry?: Maybe<UpdateGfDraftEntryPayload>;
  /** The payload for the updateGfEntry mutation */
  updateGfEntry?: Maybe<UpdateGfEntryPayload>;
  /** The payload for the updateGroup mutation */
  updateGroup?: Maybe<UpdateGroupPayload>;
  /** The payload for the updateMediaItem mutation */
  updateMediaItem?: Maybe<UpdateMediaItemPayload>;
  /** The payload for the updateMember mutation */
  updateMember?: Maybe<UpdateMemberPayload>;
  /** The payload for the updatePage mutation */
  updatePage?: Maybe<UpdatePagePayload>;
  /** The payload for the updatePost mutation */
  updatePost?: Maybe<UpdatePostPayload>;
  /** The payload for the UpdatePostFormat mutation */
  updatePostFormat?: Maybe<UpdatePostFormatPayload>;
  /** The payload for the UpdateRegion mutation */
  updateRegion?: Maybe<UpdateRegionPayload>;
  /** The payload for the updateReusableBlock mutation */
  updateReusableBlock?: Maybe<UpdateReusableBlockPayload>;
  /** The payload for the updateReview mutation */
  updateReview?: Maybe<UpdateReviewPayload>;
  /** The payload for the updateSettings mutation */
  updateSettings?: Maybe<UpdateSettingsPayload>;
  /** The payload for the updateSupplier mutation */
  updateSupplier?: Maybe<UpdateSupplierPayload>;
  /** The payload for the UpdateSupplierCategory mutation */
  updateSupplierCategory?: Maybe<UpdateSupplierCategoryPayload>;
  /** The payload for the UpdateSupplierKeyword mutation */
  updateSupplierKeyword?: Maybe<UpdateSupplierKeywordPayload>;
  /** The payload for the updateSupplier_news mutation */
  updateSupplierNews?: Maybe<UpdateSupplier_NewsPayload>;
  /** The payload for the UpdateTag mutation */
  updateTag?: Maybe<UpdateTagPayload>;
  /** The payload for the updateTestimonial mutation */
  updateTestimonial?: Maybe<UpdateTestimonialPayload>;
  /** The payload for the UpdateTradingTime mutation */
  updateTradingTime?: Maybe<UpdateTradingTimePayload>;
  /** The payload for the updateUser mutation */
  updateUser?: Maybe<UpdateUserPayload>;
  /** The payload for the wpGatsbyRemotePreviewStatus mutation */
  wpGatsbyRemotePreviewStatus?: Maybe<WpGatsbyRemotePreviewStatusPayload>;
};


/** The root mutation */
export type RootMutationCreateAccountManagerArgs = {
  input: CreateAccount_ManagerInput;
};


/** The root mutation */
export type RootMutationCreateActionMonitorActionArgs = {
  input: CreateActionMonitorActionInput;
};


/** The root mutation */
export type RootMutationCreateAreaOfSpecialismArgs = {
  input: CreateAreaOfSpecialismInput;
};


/** The root mutation */
export type RootMutationCreateCategoryArgs = {
  input: CreateCategoryInput;
};


/** The root mutation */
export type RootMutationCreateCommentArgs = {
  input: CreateCommentInput;
};


/** The root mutation */
export type RootMutationCreateGroupArgs = {
  input: CreateGroupInput;
};


/** The root mutation */
export type RootMutationCreateMediaItemArgs = {
  input: CreateMediaItemInput;
};


/** The root mutation */
export type RootMutationCreateMemberArgs = {
  input: CreateMemberInput;
};


/** The root mutation */
export type RootMutationCreatePageArgs = {
  input: CreatePageInput;
};


/** The root mutation */
export type RootMutationCreatePostArgs = {
  input: CreatePostInput;
};


/** The root mutation */
export type RootMutationCreatePostFormatArgs = {
  input: CreatePostFormatInput;
};


/** The root mutation */
export type RootMutationCreateRegionArgs = {
  input: CreateRegionInput;
};


/** The root mutation */
export type RootMutationCreateReusableBlockArgs = {
  input: CreateReusableBlockInput;
};


/** The root mutation */
export type RootMutationCreateReviewArgs = {
  input: CreateReviewInput;
};


/** The root mutation */
export type RootMutationCreateSupplierArgs = {
  input: CreateSupplierInput;
};


/** The root mutation */
export type RootMutationCreateSupplierCategoryArgs = {
  input: CreateSupplierCategoryInput;
};


/** The root mutation */
export type RootMutationCreateSupplierKeywordArgs = {
  input: CreateSupplierKeywordInput;
};


/** The root mutation */
export type RootMutationCreateSupplierNewsArgs = {
  input: CreateSupplier_NewsInput;
};


/** The root mutation */
export type RootMutationCreateTagArgs = {
  input: CreateTagInput;
};


/** The root mutation */
export type RootMutationCreateTestimonialArgs = {
  input: CreateTestimonialInput;
};


/** The root mutation */
export type RootMutationCreateTradingTimeArgs = {
  input: CreateTradingTimeInput;
};


/** The root mutation */
export type RootMutationCreateUserArgs = {
  input: CreateUserInput;
};


/** The root mutation */
export type RootMutationDeleteAccountManagerArgs = {
  input: DeleteAccount_ManagerInput;
};


/** The root mutation */
export type RootMutationDeleteActionMonitorActionArgs = {
  input: DeleteActionMonitorActionInput;
};


/** The root mutation */
export type RootMutationDeleteAreaOfSpecialismArgs = {
  input: DeleteAreaOfSpecialismInput;
};


/** The root mutation */
export type RootMutationDeleteCategoryArgs = {
  input: DeleteCategoryInput;
};


/** The root mutation */
export type RootMutationDeleteCommentArgs = {
  input: DeleteCommentInput;
};


/** The root mutation */
export type RootMutationDeleteGfDraftEntryArgs = {
  input: DeleteGfDraftEntryInput;
};


/** The root mutation */
export type RootMutationDeleteGfEntryArgs = {
  input: DeleteGfEntryInput;
};


/** The root mutation */
export type RootMutationDeleteGroupArgs = {
  input: DeleteGroupInput;
};


/** The root mutation */
export type RootMutationDeleteMediaItemArgs = {
  input: DeleteMediaItemInput;
};


/** The root mutation */
export type RootMutationDeleteMemberArgs = {
  input: DeleteMemberInput;
};


/** The root mutation */
export type RootMutationDeletePageArgs = {
  input: DeletePageInput;
};


/** The root mutation */
export type RootMutationDeletePostArgs = {
  input: DeletePostInput;
};


/** The root mutation */
export type RootMutationDeletePostFormatArgs = {
  input: DeletePostFormatInput;
};


/** The root mutation */
export type RootMutationDeleteRegionArgs = {
  input: DeleteRegionInput;
};


/** The root mutation */
export type RootMutationDeleteReusableBlockArgs = {
  input: DeleteReusableBlockInput;
};


/** The root mutation */
export type RootMutationDeleteReviewArgs = {
  input: DeleteReviewInput;
};


/** The root mutation */
export type RootMutationDeleteSupplierArgs = {
  input: DeleteSupplierInput;
};


/** The root mutation */
export type RootMutationDeleteSupplierCategoryArgs = {
  input: DeleteSupplierCategoryInput;
};


/** The root mutation */
export type RootMutationDeleteSupplierKeywordArgs = {
  input: DeleteSupplierKeywordInput;
};


/** The root mutation */
export type RootMutationDeleteSupplierNewsArgs = {
  input: DeleteSupplier_NewsInput;
};


/** The root mutation */
export type RootMutationDeleteTagArgs = {
  input: DeleteTagInput;
};


/** The root mutation */
export type RootMutationDeleteTestimonialArgs = {
  input: DeleteTestimonialInput;
};


/** The root mutation */
export type RootMutationDeleteTradingTimeArgs = {
  input: DeleteTradingTimeInput;
};


/** The root mutation */
export type RootMutationDeleteUserArgs = {
  input: DeleteUserInput;
};


/** The root mutation */
export type RootMutationIncreaseCountArgs = {
  count?: InputMaybe<Scalars['Int']>;
};


/** The root mutation */
export type RootMutationLoginArgs = {
  input: LoginInput;
};


/** The root mutation */
export type RootMutationLoginWithCookiesArgs = {
  input: LoginWithCookiesInput;
};


/** The root mutation */
export type RootMutationLogoutArgs = {
  input: LogoutInput;
};


/** The root mutation */
export type RootMutationRefreshJwtAuthTokenArgs = {
  input: RefreshJwtAuthTokenInput;
};


/** The root mutation */
export type RootMutationRegisterUserArgs = {
  input: RegisterUserInput;
};


/** The root mutation */
export type RootMutationResetUserPasswordArgs = {
  input: ResetUserPasswordInput;
};


/** The root mutation */
export type RootMutationRestoreCommentArgs = {
  input: RestoreCommentInput;
};


/** The root mutation */
export type RootMutationSendEmailArgs = {
  input: SendEmailInput;
};


/** The root mutation */
export type RootMutationSendPasswordResetEmailArgs = {
  input: SendPasswordResetEmailInput;
};


/** The root mutation */
export type RootMutationSubmitGfDraftEntryArgs = {
  input: SubmitGfDraftEntryInput;
};


/** The root mutation */
export type RootMutationSubmitGfFormArgs = {
  input: SubmitGfFormInput;
};


/** The root mutation */
export type RootMutationUpdateAccountManagerArgs = {
  input: UpdateAccount_ManagerInput;
};


/** The root mutation */
export type RootMutationUpdateActionMonitorActionArgs = {
  input: UpdateActionMonitorActionInput;
};


/** The root mutation */
export type RootMutationUpdateAreaOfSpecialismArgs = {
  input: UpdateAreaOfSpecialismInput;
};


/** The root mutation */
export type RootMutationUpdateCategoryArgs = {
  input: UpdateCategoryInput;
};


/** The root mutation */
export type RootMutationUpdateCommentArgs = {
  input: UpdateCommentInput;
};


/** The root mutation */
export type RootMutationUpdateGfDraftEntryArgs = {
  input: UpdateGfDraftEntryInput;
};


/** The root mutation */
export type RootMutationUpdateGfEntryArgs = {
  input: UpdateGfEntryInput;
};


/** The root mutation */
export type RootMutationUpdateGroupArgs = {
  input: UpdateGroupInput;
};


/** The root mutation */
export type RootMutationUpdateMediaItemArgs = {
  input: UpdateMediaItemInput;
};


/** The root mutation */
export type RootMutationUpdateMemberArgs = {
  input: UpdateMemberInput;
};


/** The root mutation */
export type RootMutationUpdatePageArgs = {
  input: UpdatePageInput;
};


/** The root mutation */
export type RootMutationUpdatePostArgs = {
  input: UpdatePostInput;
};


/** The root mutation */
export type RootMutationUpdatePostFormatArgs = {
  input: UpdatePostFormatInput;
};


/** The root mutation */
export type RootMutationUpdateRegionArgs = {
  input: UpdateRegionInput;
};


/** The root mutation */
export type RootMutationUpdateReusableBlockArgs = {
  input: UpdateReusableBlockInput;
};


/** The root mutation */
export type RootMutationUpdateReviewArgs = {
  input: UpdateReviewInput;
};


/** The root mutation */
export type RootMutationUpdateSettingsArgs = {
  input: UpdateSettingsInput;
};


/** The root mutation */
export type RootMutationUpdateSupplierArgs = {
  input: UpdateSupplierInput;
};


/** The root mutation */
export type RootMutationUpdateSupplierCategoryArgs = {
  input: UpdateSupplierCategoryInput;
};


/** The root mutation */
export type RootMutationUpdateSupplierKeywordArgs = {
  input: UpdateSupplierKeywordInput;
};


/** The root mutation */
export type RootMutationUpdateSupplierNewsArgs = {
  input: UpdateSupplier_NewsInput;
};


/** The root mutation */
export type RootMutationUpdateTagArgs = {
  input: UpdateTagInput;
};


/** The root mutation */
export type RootMutationUpdateTestimonialArgs = {
  input: UpdateTestimonialInput;
};


/** The root mutation */
export type RootMutationUpdateTradingTimeArgs = {
  input: UpdateTradingTimeInput;
};


/** The root mutation */
export type RootMutationUpdateUserArgs = {
  input: UpdateUserInput;
};


/** The root mutation */
export type RootMutationWpGatsbyRemotePreviewStatusArgs = {
  input: WpGatsbyRemotePreviewStatusInput;
};

/** The root entry point into the Graph */
export type RootQuery = {
  __typename?: 'RootQuery';
  /** An object of the account_manager Type.  */
  accountManager?: Maybe<Account_Manager>;
  /**
   * A account_manager object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  accountManagerBy?: Maybe<Account_Manager>;
  /** Connection between the RootQuery type and the account_manager type */
  accountManagers?: Maybe<RootQueryToAccount_ManagerConnection>;
  /** An object of the ActionMonitorAction Type. Used to keep a log of actions in WordPress for cache invalidation in gatsby-source-wordpress. */
  actionMonitorAction?: Maybe<ActionMonitorAction>;
  /**
   * A ActionMonitorAction object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  actionMonitorActionBy?: Maybe<ActionMonitorAction>;
  /** Connection between the RootQuery type and the ActionMonitorAction type */
  actionMonitorActions?: Maybe<RootQueryToActionMonitorActionConnection>;
  /** Alert Box options */
  alertBox?: Maybe<AlertBox>;
  /** Entry point to get all settings for the site */
  allSettings?: Maybe<Settings>;
  /** A 0bject */
  areaOfSpecialism?: Maybe<AreaOfSpecialism>;
  /** Connection between the RootQuery type and the areaOfSpecialism type */
  areasOfSpecialism?: Maybe<RootQueryToAreaOfSpecialismConnection>;
  /** Connection between the RootQuery type and the BlockEditorContentNode type */
  blockEditorContentNodes?: Maybe<BlockEditorContentNodeConnection>;
  /** An object of the BlockEditorPreview Type.  */
  blockEditorPreview?: Maybe<BlockEditorPreview>;
  /**
   * A BlockEditorPreview object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  blockEditorPreviewBy?: Maybe<BlockEditorPreview>;
  /** Connection between the RootQuery type and the BlockEditorPreview type */
  blockEditorPreviews?: Maybe<RootQueryToBlockEditorPreviewConnection>;
  /** Business Information options */
  businessInformation?: Maybe<BusinessInformation>;
  /** Connection between the RootQuery type and the category type */
  categories?: Maybe<RootQueryToCategoryConnection>;
  /** A 0bject */
  category?: Maybe<Category>;
  /** Client Options options */
  clientOptions?: Maybe<ClientOptions>;
  /** Client Settings options */
  clientSettings?: Maybe<ClientSettings>;
  /** Returns a Comment */
  comment?: Maybe<Comment>;
  /** Connection between the RootQuery type and the Comment type */
  comments?: Maybe<RootQueryToCommentConnection>;
  /** A node used to manage content */
  contentNode?: Maybe<ContentNode>;
  /** Connection between the RootQuery type and the ContentNode type */
  contentNodes?: Maybe<RootQueryToContentNodeConnection>;
  /** Fetch a Content Type node by unique Identifier */
  contentType?: Maybe<ContentType>;
  /** Connection between the RootQuery type and the ContentType type */
  contentTypes?: Maybe<RootQueryToContentTypeConnection>;
  /** Fields of the &#039;DiscussionSettings&#039; settings group */
  discussionSettings?: Maybe<DiscussionSettings>;
  /** Fields of the &#039;GeneralSettings&#039; settings group */
  generalSettings?: Maybe<GeneralSettings>;
  /** Get a Gravity Forms entry. */
  gfDraftEntry?: Maybe<GfDraftEntry>;
  /** Connection between the RootQuery type and the GfEntry type */
  gfEntries?: Maybe<RootQueryToGfEntryConnection>;
  /** Get a Gravity Forms entry. */
  gfEntry?: Maybe<GfEntry>;
  /** Get a Gravity Forms form. */
  gfForm?: Maybe<GfForm>;
  /** Connection between the RootQuery type and the GfForm type */
  gfForms?: Maybe<RootQueryToGfFormConnection>;
  /** Gravity Forms settings. */
  gfSettings?: Maybe<GfSettings>;
  /** Connection between the RootQuery type and the GfSubmittedEntry type */
  gfSubmittedEntries?: Maybe<RootQueryToGfSubmittedEntryConnection>;
  /** Get a Gravity Forms entry. */
  gfSubmittedEntry?: Maybe<GfSubmittedEntry>;
  /** An object of the group Type.  */
  group?: Maybe<Group>;
  /**
   * A group object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  groupBy?: Maybe<Group>;
  /** Connection between the RootQuery type and the group type */
  groups?: Maybe<RootQueryToGroupConnection>;
  /** Confirms this is a WP Gatsby site */
  isWpGatsby?: Maybe<Scalars['Boolean']>;
  /** An object of the mediaItem Type.  */
  mediaItem?: Maybe<MediaItem>;
  /**
   * A mediaItem object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  mediaItemBy?: Maybe<MediaItem>;
  /** Connection between the RootQuery type and the mediaItem type */
  mediaItems?: Maybe<RootQueryToMediaItemConnection>;
  /** An object of the member Type.  */
  member?: Maybe<Member>;
  /**
   * A member object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  memberBy?: Maybe<Member>;
  /** Connection between the RootQuery type and the member type */
  members?: Maybe<RootQueryToMemberConnection>;
  /** A WordPress navigation menu */
  menu?: Maybe<Menu>;
  /** A WordPress navigation menu item */
  menuItem?: Maybe<MenuItem>;
  /** Connection between the RootQuery type and the MenuItem type */
  menuItems?: Maybe<RootQueryToMenuItemConnection>;
  /** Connection between the RootQuery type and the Menu type */
  menus?: Maybe<RootQueryToMenuConnection>;
  /** Fetches an object given its ID */
  node?: Maybe<Node>;
  /** Fetches an object given its Unique Resource Identifier */
  nodeByUri?: Maybe<UniformResourceIdentifiable>;
  /** An object of the page Type.  */
  page?: Maybe<Page>;
  /**
   * A page object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  pageBy?: Maybe<Page>;
  /** Connection between the RootQuery type and the page type */
  pages?: Maybe<RootQueryToPageConnection>;
  /** A WordPress plugin */
  plugin?: Maybe<Plugin>;
  /** Connection between the RootQuery type and the Plugin type */
  plugins?: Maybe<RootQueryToPluginConnection>;
  /** An object of the post Type.  */
  post?: Maybe<Post>;
  /**
   * A post object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  postBy?: Maybe<Post>;
  /** A 0bject */
  postFormat?: Maybe<PostFormat>;
  /** Connection between the RootQuery type and the postFormat type */
  postFormats?: Maybe<RootQueryToPostFormatConnection>;
  /** Connection between the RootQuery type and the post type */
  posts?: Maybe<RootQueryToPostConnection>;
  /** Fields of the &#039;ReadingSettings&#039; settings group */
  readingSettings?: Maybe<ReadingSettings>;
  /** A 0bject */
  region?: Maybe<Region>;
  /** Connection between the RootQuery type and the region type */
  regions?: Maybe<RootQueryToRegionConnection>;
  /** Connection between the RootQuery type and the EnqueuedScript type */
  registeredScripts?: Maybe<RootQueryToEnqueuedScriptConnection>;
  /** Connection between the RootQuery type and the EnqueuedStylesheet type */
  registeredStylesheets?: Maybe<RootQueryToEnqueuedStylesheetConnection>;
  /** An object of the ReusableBlock Type.  */
  reusableBlock?: Maybe<ReusableBlock>;
  /**
   * A ReusableBlock object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  reusableBlockBy?: Maybe<ReusableBlock>;
  /** Connection between the RootQuery type and the ReusableBlock type */
  reusableBlocks?: Maybe<RootQueryToReusableBlockConnection>;
  /** An object of the review Type.  */
  review?: Maybe<Review>;
  /**
   * A review object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  reviewBy?: Maybe<Review>;
  /** Connection between the RootQuery type and the review type */
  reviews?: Maybe<RootQueryToReviewConnection>;
  /** Connection between the RootQuery type and the ContentRevisionUnion type */
  revisions?: Maybe<RootQueryToContentRevisionUnionConnection>;
  /** Returns an MD5 hash of the schema, useful in determining if the schema has changed. */
  schemaMd5?: Maybe<Scalars['String']>;
  /** An object of the supplier Type.  */
  supplier?: Maybe<Supplier>;
  /**
   * A supplier object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  supplierBy?: Maybe<Supplier>;
  /** Connection between the RootQuery type and the supplierCategory type */
  supplierCategories?: Maybe<RootQueryToSupplierCategoryConnection>;
  /** A 0bject */
  supplierCategory?: Maybe<SupplierCategory>;
  /** A 0bject */
  supplierKeyword?: Maybe<SupplierKeyword>;
  /** Connection between the RootQuery type and the supplierKeyword type */
  supplierKeywords?: Maybe<RootQueryToSupplierKeywordConnection>;
  /** An object of the supplier_news Type.  */
  supplierNews?: Maybe<Supplier_News>;
  /**
   * A supplier_news object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  supplierNewsBy?: Maybe<Supplier_News>;
  /** Connection between the RootQuery type and the supplier_news type */
  supplierNewsItems?: Maybe<RootQueryToSupplier_NewsConnection>;
  /** Connection between the RootQuery type and the supplier type */
  suppliers?: Maybe<RootQueryToSupplierConnection>;
  /** A 0bject */
  tag?: Maybe<Tag>;
  /** Connection between the RootQuery type and the tag type */
  tags?: Maybe<RootQueryToTagConnection>;
  /** Connection between the RootQuery type and the Taxonomy type */
  taxonomies?: Maybe<RootQueryToTaxonomyConnection>;
  /** Fetch a Taxonomy node by unique Identifier */
  taxonomy?: Maybe<Taxonomy>;
  /** Template Content options */
  templateContent?: Maybe<TemplateContent>;
  /** A node in a taxonomy used to group and relate content nodes */
  termNode?: Maybe<TermNode>;
  /** Connection between the RootQuery type and the TermNode type */
  terms?: Maybe<RootQueryToTermNodeConnection>;
  /** An object of the testimonial Type.  */
  testimonial?: Maybe<Testimonial>;
  /**
   * A testimonial object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  testimonialBy?: Maybe<Testimonial>;
  /** Connection between the RootQuery type and the testimonial type */
  testimonials?: Maybe<RootQueryToTestimonialConnection>;
  /** A Theme object */
  theme?: Maybe<Theme>;
  /** Theme Options options */
  themeOptions?: Maybe<ThemeOptions>;
  /** Connection between the RootQuery type and the Theme type */
  themes?: Maybe<RootQueryToThemeConnection>;
  /** A 0bject */
  tradingTime?: Maybe<TradingTime>;
  /** Connection between the RootQuery type and the tradingTime type */
  tradingTimes?: Maybe<RootQueryToTradingTimeConnection>;
  /** Returns a user */
  user?: Maybe<User>;
  /** Returns a user role */
  userRole?: Maybe<UserRole>;
  /** Connection between the RootQuery type and the UserRole type */
  userRoles?: Maybe<RootQueryToUserRoleConnection>;
  /** Connection between the RootQuery type and the User type */
  users?: Maybe<RootQueryToUserConnection>;
  /** Returns the current user */
  viewer?: Maybe<User>;
  /** Information needed by gatsby-source-wordpress. */
  wpGatsby?: Maybe<WpGatsby>;
  /** Information about the compatibility of the WordPress server with a provided version of gatsby-source-wordpress. */
  wpGatsbyCompatibility?: Maybe<WpGatsbyCompatibility>;
  /** Fields of the &#039;WritingSettings&#039; settings group */
  writingSettings?: Maybe<WritingSettings>;
};


/** The root entry point into the Graph */
export type RootQueryAccountManagerArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  idType?: InputMaybe<Account_ManagerIdType>;
};


/** The root entry point into the Graph */
export type RootQueryAccountManagerByArgs = {
  account_managerId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
  uri?: InputMaybe<Scalars['String']>;
};


/** The root entry point into the Graph */
export type RootQueryAccountManagersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToAccount_ManagerConnectionWhereArgs>;
};


/** The root entry point into the Graph */
export type RootQueryActionMonitorActionArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  idType?: InputMaybe<ActionMonitorActionIdType>;
};


/** The root entry point into the Graph */
export type RootQueryActionMonitorActionByArgs = {
  actionMonitorActionId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
  uri?: InputMaybe<Scalars['String']>;
};


/** The root entry point into the Graph */
export type RootQueryActionMonitorActionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToActionMonitorActionConnectionWhereArgs>;
};


/** The root entry point into the Graph */
export type RootQueryAreaOfSpecialismArgs = {
  id: Scalars['ID'];
  idType?: InputMaybe<AreaOfSpecialismIdType>;
};


/** The root entry point into the Graph */
export type RootQueryAreasOfSpecialismArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToAreaOfSpecialismConnectionWhereArgs>;
};


/** The root entry point into the Graph */
export type RootQueryBlockEditorContentNodesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BlockEditorContentNodeConnectionWhereArgs>;
};


/** The root entry point into the Graph */
export type RootQueryBlockEditorPreviewArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  idType?: InputMaybe<BlockEditorPreviewIdType>;
};


/** The root entry point into the Graph */
export type RootQueryBlockEditorPreviewByArgs = {
  blockEditorPreviewId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
  uri?: InputMaybe<Scalars['String']>;
};


/** The root entry point into the Graph */
export type RootQueryBlockEditorPreviewsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToBlockEditorPreviewConnectionWhereArgs>;
};


/** The root entry point into the Graph */
export type RootQueryCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToCategoryConnectionWhereArgs>;
};


/** The root entry point into the Graph */
export type RootQueryCategoryArgs = {
  id: Scalars['ID'];
  idType?: InputMaybe<CategoryIdType>;
};


/** The root entry point into the Graph */
export type RootQueryCommentArgs = {
  id: Scalars['ID'];
  idType?: InputMaybe<CommentNodeIdTypeEnum>;
};


/** The root entry point into the Graph */
export type RootQueryCommentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToCommentConnectionWhereArgs>;
};


/** The root entry point into the Graph */
export type RootQueryContentNodeArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']>;
  contentType?: InputMaybe<ContentTypeEnum>;
  id: Scalars['ID'];
  idType?: InputMaybe<ContentNodeIdTypeEnum>;
};


/** The root entry point into the Graph */
export type RootQueryContentNodesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToContentNodeConnectionWhereArgs>;
};


/** The root entry point into the Graph */
export type RootQueryContentTypeArgs = {
  id: Scalars['ID'];
  idType?: InputMaybe<ContentTypeIdTypeEnum>;
};


/** The root entry point into the Graph */
export type RootQueryContentTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root entry point into the Graph */
export type RootQueryGfDraftEntryArgs = {
  id: Scalars['ID'];
  idType?: InputMaybe<DraftEntryIdTypeEnum>;
};


/** The root entry point into the Graph */
export type RootQueryGfEntriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToGfEntryConnectionWhereArgs>;
};


/** The root entry point into the Graph */
export type RootQueryGfEntryArgs = {
  id: Scalars['ID'];
  idType?: InputMaybe<EntryIdTypeEnum>;
};


/** The root entry point into the Graph */
export type RootQueryGfFormArgs = {
  id: Scalars['ID'];
  idType?: InputMaybe<FormIdTypeEnum>;
};


/** The root entry point into the Graph */
export type RootQueryGfFormsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToGfFormConnectionWhereArgs>;
};


/** The root entry point into the Graph */
export type RootQueryGfSubmittedEntriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToGfSubmittedEntryConnectionWhereArgs>;
};


/** The root entry point into the Graph */
export type RootQueryGfSubmittedEntryArgs = {
  id: Scalars['ID'];
  idType?: InputMaybe<SubmittedEntryIdTypeEnum>;
};


/** The root entry point into the Graph */
export type RootQueryGroupArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  idType?: InputMaybe<GroupIdType>;
};


/** The root entry point into the Graph */
export type RootQueryGroupByArgs = {
  groupId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
  uri?: InputMaybe<Scalars['String']>;
};


/** The root entry point into the Graph */
export type RootQueryGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToGroupConnectionWhereArgs>;
};


/** The root entry point into the Graph */
export type RootQueryMediaItemArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  idType?: InputMaybe<MediaItemIdType>;
};


/** The root entry point into the Graph */
export type RootQueryMediaItemByArgs = {
  id?: InputMaybe<Scalars['ID']>;
  mediaItemId?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  uri?: InputMaybe<Scalars['String']>;
};


/** The root entry point into the Graph */
export type RootQueryMediaItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToMediaItemConnectionWhereArgs>;
};


/** The root entry point into the Graph */
export type RootQueryMemberArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  idType?: InputMaybe<MemberIdType>;
};


/** The root entry point into the Graph */
export type RootQueryMemberByArgs = {
  id?: InputMaybe<Scalars['ID']>;
  memberId?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  uri?: InputMaybe<Scalars['String']>;
};


/** The root entry point into the Graph */
export type RootQueryMembersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToMemberConnectionWhereArgs>;
};


/** The root entry point into the Graph */
export type RootQueryMenuArgs = {
  id: Scalars['ID'];
  idType?: InputMaybe<MenuNodeIdTypeEnum>;
};


/** The root entry point into the Graph */
export type RootQueryMenuItemArgs = {
  id: Scalars['ID'];
  idType?: InputMaybe<MenuItemNodeIdTypeEnum>;
};


/** The root entry point into the Graph */
export type RootQueryMenuItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToMenuItemConnectionWhereArgs>;
};


/** The root entry point into the Graph */
export type RootQueryMenusArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToMenuConnectionWhereArgs>;
};


/** The root entry point into the Graph */
export type RootQueryNodeArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


/** The root entry point into the Graph */
export type RootQueryNodeByUriArgs = {
  uri: Scalars['String'];
};


/** The root entry point into the Graph */
export type RootQueryPageArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  idType?: InputMaybe<PageIdType>;
};


/** The root entry point into the Graph */
export type RootQueryPageByArgs = {
  id?: InputMaybe<Scalars['ID']>;
  pageId?: InputMaybe<Scalars['Int']>;
  uri?: InputMaybe<Scalars['String']>;
};


/** The root entry point into the Graph */
export type RootQueryPagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToPageConnectionWhereArgs>;
};


/** The root entry point into the Graph */
export type RootQueryPluginArgs = {
  id: Scalars['ID'];
};


/** The root entry point into the Graph */
export type RootQueryPluginsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToPluginConnectionWhereArgs>;
};


/** The root entry point into the Graph */
export type RootQueryPostArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  idType?: InputMaybe<PostIdType>;
};


/** The root entry point into the Graph */
export type RootQueryPostByArgs = {
  id?: InputMaybe<Scalars['ID']>;
  postId?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  uri?: InputMaybe<Scalars['String']>;
};


/** The root entry point into the Graph */
export type RootQueryPostFormatArgs = {
  id: Scalars['ID'];
  idType?: InputMaybe<PostFormatIdType>;
};


/** The root entry point into the Graph */
export type RootQueryPostFormatsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToPostFormatConnectionWhereArgs>;
};


/** The root entry point into the Graph */
export type RootQueryPostsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToPostConnectionWhereArgs>;
};


/** The root entry point into the Graph */
export type RootQueryRegionArgs = {
  id: Scalars['ID'];
  idType?: InputMaybe<RegionIdType>;
};


/** The root entry point into the Graph */
export type RootQueryRegionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToRegionConnectionWhereArgs>;
};


/** The root entry point into the Graph */
export type RootQueryRegisteredScriptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root entry point into the Graph */
export type RootQueryRegisteredStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root entry point into the Graph */
export type RootQueryReusableBlockArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  idType?: InputMaybe<ReusableBlockIdType>;
};


/** The root entry point into the Graph */
export type RootQueryReusableBlockByArgs = {
  id?: InputMaybe<Scalars['ID']>;
  reusableBlockId?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  uri?: InputMaybe<Scalars['String']>;
};


/** The root entry point into the Graph */
export type RootQueryReusableBlocksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToReusableBlockConnectionWhereArgs>;
};


/** The root entry point into the Graph */
export type RootQueryReviewArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  idType?: InputMaybe<ReviewIdType>;
};


/** The root entry point into the Graph */
export type RootQueryReviewByArgs = {
  id?: InputMaybe<Scalars['ID']>;
  reviewId?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  uri?: InputMaybe<Scalars['String']>;
};


/** The root entry point into the Graph */
export type RootQueryReviewsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToReviewConnectionWhereArgs>;
};


/** The root entry point into the Graph */
export type RootQueryRevisionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToContentRevisionUnionConnectionWhereArgs>;
};


/** The root entry point into the Graph */
export type RootQuerySupplierArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  idType?: InputMaybe<SupplierIdType>;
};


/** The root entry point into the Graph */
export type RootQuerySupplierByArgs = {
  id?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
  supplierId?: InputMaybe<Scalars['Int']>;
  uri?: InputMaybe<Scalars['String']>;
};


/** The root entry point into the Graph */
export type RootQuerySupplierCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToSupplierCategoryConnectionWhereArgs>;
};


/** The root entry point into the Graph */
export type RootQuerySupplierCategoryArgs = {
  id: Scalars['ID'];
  idType?: InputMaybe<SupplierCategoryIdType>;
};


/** The root entry point into the Graph */
export type RootQuerySupplierKeywordArgs = {
  id: Scalars['ID'];
  idType?: InputMaybe<SupplierKeywordIdType>;
};


/** The root entry point into the Graph */
export type RootQuerySupplierKeywordsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToSupplierKeywordConnectionWhereArgs>;
};


/** The root entry point into the Graph */
export type RootQuerySupplierNewsArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  idType?: InputMaybe<Supplier_NewsIdType>;
};


/** The root entry point into the Graph */
export type RootQuerySupplierNewsByArgs = {
  id?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
  supplier_newsId?: InputMaybe<Scalars['Int']>;
  uri?: InputMaybe<Scalars['String']>;
};


/** The root entry point into the Graph */
export type RootQuerySupplierNewsItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToSupplier_NewsConnectionWhereArgs>;
};


/** The root entry point into the Graph */
export type RootQuerySuppliersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToSupplierConnectionWhereArgs>;
};


/** The root entry point into the Graph */
export type RootQueryTagArgs = {
  id: Scalars['ID'];
  idType?: InputMaybe<TagIdType>;
};


/** The root entry point into the Graph */
export type RootQueryTagsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToTagConnectionWhereArgs>;
};


/** The root entry point into the Graph */
export type RootQueryTaxonomiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root entry point into the Graph */
export type RootQueryTaxonomyArgs = {
  id: Scalars['ID'];
  idType?: InputMaybe<TaxonomyIdTypeEnum>;
};


/** The root entry point into the Graph */
export type RootQueryTermNodeArgs = {
  id: Scalars['ID'];
  idType?: InputMaybe<TermNodeIdTypeEnum>;
  taxonomy?: InputMaybe<TaxonomyEnum>;
};


/** The root entry point into the Graph */
export type RootQueryTermsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToTermNodeConnectionWhereArgs>;
};


/** The root entry point into the Graph */
export type RootQueryTestimonialArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  idType?: InputMaybe<TestimonialIdType>;
};


/** The root entry point into the Graph */
export type RootQueryTestimonialByArgs = {
  id?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
  testimonialId?: InputMaybe<Scalars['Int']>;
  uri?: InputMaybe<Scalars['String']>;
};


/** The root entry point into the Graph */
export type RootQueryTestimonialsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToTestimonialConnectionWhereArgs>;
};


/** The root entry point into the Graph */
export type RootQueryThemeArgs = {
  id: Scalars['ID'];
};


/** The root entry point into the Graph */
export type RootQueryThemesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root entry point into the Graph */
export type RootQueryTradingTimeArgs = {
  id: Scalars['ID'];
  idType?: InputMaybe<TradingTimeIdType>;
};


/** The root entry point into the Graph */
export type RootQueryTradingTimesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToTradingTimeConnectionWhereArgs>;
};


/** The root entry point into the Graph */
export type RootQueryUserArgs = {
  id: Scalars['ID'];
  idType?: InputMaybe<UserNodeIdTypeEnum>;
};


/** The root entry point into the Graph */
export type RootQueryUserRoleArgs = {
  id: Scalars['ID'];
};


/** The root entry point into the Graph */
export type RootQueryUserRolesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The root entry point into the Graph */
export type RootQueryUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToUserConnectionWhereArgs>;
};


/** The root entry point into the Graph */
export type RootQueryWpGatsbyCompatibilityArgs = {
  wpGQLVersionRange: Scalars['String'];
  wpGatsbyVersionRange: Scalars['String'];
};

/** Connection between the RootQuery type and the account_manager type */
export type RootQueryToAccount_ManagerConnection = {
  __typename?: 'RootQueryToAccount_managerConnection';
  /** Edges for the RootQueryToAccount_managerConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToAccount_ManagerConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Account_Manager>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type RootQueryToAccount_ManagerConnectionEdge = {
  __typename?: 'RootQueryToAccount_managerConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<Account_Manager>;
};

/** Arguments for filtering the RootQueryToAccount_managerConnection connection */
export type RootQueryToAccount_ManagerConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** Connection between the RootQuery type and the ActionMonitorAction type */
export type RootQueryToActionMonitorActionConnection = {
  __typename?: 'RootQueryToActionMonitorActionConnection';
  /** Edges for the RootQueryToActionMonitorActionConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToActionMonitorActionConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ActionMonitorAction>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type RootQueryToActionMonitorActionConnectionEdge = {
  __typename?: 'RootQueryToActionMonitorActionConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<ActionMonitorAction>;
};

/** Arguments for filtering the RootQueryToActionMonitorActionConnection connection */
export type RootQueryToActionMonitorActionConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** List Actions of the PREVIEW stream type. */
  previewStream?: InputMaybe<Scalars['Boolean']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** List Actions performed since a timestamp. */
  sinceTimestamp?: InputMaybe<Scalars['Float']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** Connection between the RootQuery type and the areaOfSpecialism type */
export type RootQueryToAreaOfSpecialismConnection = {
  __typename?: 'RootQueryToAreaOfSpecialismConnection';
  /** Edges for the RootQueryToAreaOfSpecialismConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToAreaOfSpecialismConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<AreaOfSpecialism>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type RootQueryToAreaOfSpecialismConnectionEdge = {
  __typename?: 'RootQueryToAreaOfSpecialismConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<AreaOfSpecialism>;
};

/** Arguments for filtering the RootQueryToAreaOfSpecialismConnection connection */
export type RootQueryToAreaOfSpecialismConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
};

/** Connection between the RootQuery type and the BlockEditorPreview type */
export type RootQueryToBlockEditorPreviewConnection = {
  __typename?: 'RootQueryToBlockEditorPreviewConnection';
  /** Edges for the RootQueryToBlockEditorPreviewConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToBlockEditorPreviewConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<BlockEditorPreview>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type RootQueryToBlockEditorPreviewConnectionEdge = {
  __typename?: 'RootQueryToBlockEditorPreviewConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<BlockEditorPreview>;
};

/** Arguments for filtering the RootQueryToBlockEditorPreviewConnection connection */
export type RootQueryToBlockEditorPreviewConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  previewedDatabaseId?: InputMaybe<Scalars['Int']>;
  previewedParentDatabaseId?: InputMaybe<Scalars['Int']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** Connection between the RootQuery type and the category type */
export type RootQueryToCategoryConnection = {
  __typename?: 'RootQueryToCategoryConnection';
  /** Edges for the RootQueryToCategoryConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToCategoryConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Category>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type RootQueryToCategoryConnectionEdge = {
  __typename?: 'RootQueryToCategoryConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<Category>;
};

/** Arguments for filtering the RootQueryToCategoryConnection connection */
export type RootQueryToCategoryConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
};

/** Connection between the RootQuery type and the Comment type */
export type RootQueryToCommentConnection = {
  __typename?: 'RootQueryToCommentConnection';
  /** Edges for the RootQueryToCommentConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToCommentConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Comment>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type RootQueryToCommentConnectionEdge = {
  __typename?: 'RootQueryToCommentConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<Comment>;
};

/** Arguments for filtering the RootQueryToCommentConnection connection */
export type RootQueryToCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail?: InputMaybe<Scalars['String']>;
  /** Array of author IDs to include comments for. */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Comment author URL. */
  authorUrl?: InputMaybe<Scalars['String']>;
  /** Array of comment IDs to include. */
  commentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Include comments of a given type. */
  commentType?: InputMaybe<Scalars['String']>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: InputMaybe<Scalars['String']>;
  /** Content object author ID to limit results by. */
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: InputMaybe<Scalars['ID']>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Content object name to retrieve affiliated comments for. */
  contentName?: InputMaybe<Scalars['String']>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: InputMaybe<Scalars['Int']>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: InputMaybe<Scalars['Int']>;
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: InputMaybe<Scalars['String']>;
  /** Comment status to limit results by. */
  status?: InputMaybe<Scalars['String']>;
  /** Include comments for a specific user ID. */
  userId?: InputMaybe<Scalars['ID']>;
};

/** Connection between the RootQuery type and the ContentNode type */
export type RootQueryToContentNodeConnection = {
  __typename?: 'RootQueryToContentNodeConnection';
  /** Edges for the RootQueryToContentNodeConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToContentNodeConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentNode>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type RootQueryToContentNodeConnectionEdge = {
  __typename?: 'RootQueryToContentNodeConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<ContentNode>;
};

/** Arguments for filtering the RootQueryToContentNodeConnection connection */
export type RootQueryToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** Connection between the RootQuery type and the ContentRevisionUnion type */
export type RootQueryToContentRevisionUnionConnection = {
  __typename?: 'RootQueryToContentRevisionUnionConnection';
  /** Edges for the RootQueryToContentRevisionUnionConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToContentRevisionUnionConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentRevisionUnion>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type RootQueryToContentRevisionUnionConnectionEdge = {
  __typename?: 'RootQueryToContentRevisionUnionConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<ContentRevisionUnion>;
};

/** Arguments for filtering the RootQueryToContentRevisionUnionConnection connection */
export type RootQueryToContentRevisionUnionConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** Connection between the RootQuery type and the ContentType type */
export type RootQueryToContentTypeConnection = {
  __typename?: 'RootQueryToContentTypeConnection';
  /** Edges for the RootQueryToContentTypeConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToContentTypeConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentType>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type RootQueryToContentTypeConnectionEdge = {
  __typename?: 'RootQueryToContentTypeConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<ContentType>;
};

/** Connection between the RootQuery type and the EnqueuedScript type */
export type RootQueryToEnqueuedScriptConnection = {
  __typename?: 'RootQueryToEnqueuedScriptConnection';
  /** Edges for the RootQueryToEnqueuedScriptConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToEnqueuedScriptConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<EnqueuedScript>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type RootQueryToEnqueuedScriptConnectionEdge = {
  __typename?: 'RootQueryToEnqueuedScriptConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<EnqueuedScript>;
};

/** Connection between the RootQuery type and the EnqueuedStylesheet type */
export type RootQueryToEnqueuedStylesheetConnection = {
  __typename?: 'RootQueryToEnqueuedStylesheetConnection';
  /** Edges for the RootQueryToEnqueuedStylesheetConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToEnqueuedStylesheetConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<EnqueuedStylesheet>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type RootQueryToEnqueuedStylesheetConnectionEdge = {
  __typename?: 'RootQueryToEnqueuedStylesheetConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<EnqueuedStylesheet>;
};

/** Connection between the RootQuery type and the GfEntry type */
export type RootQueryToGfEntryConnection = {
  __typename?: 'RootQueryToGfEntryConnection';
  /** Edges for the RootQueryToGfEntryConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToGfEntryConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<GfEntry>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type RootQueryToGfEntryConnectionEdge = {
  __typename?: 'RootQueryToGfEntryConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<GfEntry>;
};

/** Arguments for filtering the RootQueryToGfEntryConnection connection */
export type RootQueryToGfEntryConnectionWhereArgs = {
  /** Date filters to apply. */
  dateFilters?: InputMaybe<EntriesDateFiltersInput>;
  /** Entry status. Default is `SUBMITTED`. Currently no other types are supported. */
  entryType?: InputMaybe<EntryTypeEnum>;
  /** Field-specific filters to apply. */
  fieldFilters?: InputMaybe<Array<InputMaybe<EntriesFieldFiltersInput>>>;
  /** Whether to filter by ALL or ANY of the field filters. Default is ALL. */
  fieldFiltersMode?: InputMaybe<FieldFiltersModeEnum>;
  /** Array of form IDs to limit the entries to. Exclude this argument to query all forms. */
  formIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** How to sort the entries. */
  orderby?: InputMaybe<EntriesConnectionOrderbyInput>;
  /** Entry status. Default is "ACTIVE". */
  status?: InputMaybe<EntryStatusEnum>;
};

/** Connection between the RootQuery type and the GfForm type */
export type RootQueryToGfFormConnection = {
  __typename?: 'RootQueryToGfFormConnection';
  /** Edges for the RootQueryToGfFormConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToGfFormConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<GfForm>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type RootQueryToGfFormConnectionEdge = {
  __typename?: 'RootQueryToGfFormConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<GfForm>;
};

/** Arguments for filtering the RootQueryToGfFormConnection connection */
export type RootQueryToGfFormConnectionWhereArgs = {
  /** Array of form IDs to return. Exclude this argument to query all forms. */
  formIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** How to sort the entries. */
  orderby?: InputMaybe<FormsConnectionOrderbyInput>;
  /** Status of the forms to get. */
  status?: InputMaybe<FormStatusEnum>;
};

/** Connection between the RootQuery type and the GfSubmittedEntry type */
export type RootQueryToGfSubmittedEntryConnection = {
  __typename?: 'RootQueryToGfSubmittedEntryConnection';
  /** Edges for the RootQueryToGfSubmittedEntryConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToGfSubmittedEntryConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<GfSubmittedEntry>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type RootQueryToGfSubmittedEntryConnectionEdge = {
  __typename?: 'RootQueryToGfSubmittedEntryConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<GfSubmittedEntry>;
};

/** Arguments for filtering the RootQueryToGfSubmittedEntryConnection connection */
export type RootQueryToGfSubmittedEntryConnectionWhereArgs = {
  /** Date filters to apply. */
  dateFilters?: InputMaybe<EntriesDateFiltersInput>;
  /** Field-specific filters to apply. */
  fieldFilters?: InputMaybe<Array<InputMaybe<EntriesFieldFiltersInput>>>;
  /** Whether to filter by ALL or ANY of the field filters. Default is ALL. */
  fieldFiltersMode?: InputMaybe<FieldFiltersModeEnum>;
  /** Array of form IDs to limit the entries to. Exclude this argument to query all forms. */
  formIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** How to sort the entries. */
  orderby?: InputMaybe<EntriesConnectionOrderbyInput>;
  /** Entry status. Default is "ACTIVE". */
  status?: InputMaybe<EntryStatusEnum>;
};

/** Connection between the RootQuery type and the group type */
export type RootQueryToGroupConnection = {
  __typename?: 'RootQueryToGroupConnection';
  /** Edges for the RootQueryToGroupConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToGroupConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Group>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type RootQueryToGroupConnectionEdge = {
  __typename?: 'RootQueryToGroupConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<Group>;
};

/** Arguments for filtering the RootQueryToGroupConnection connection */
export type RootQueryToGroupConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** Connection between the RootQuery type and the mediaItem type */
export type RootQueryToMediaItemConnection = {
  __typename?: 'RootQueryToMediaItemConnection';
  /** Edges for the RootQueryToMediaItemConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToMediaItemConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<MediaItem>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type RootQueryToMediaItemConnectionEdge = {
  __typename?: 'RootQueryToMediaItemConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<MediaItem>;
};

/** Arguments for filtering the RootQueryToMediaItemConnection connection */
export type RootQueryToMediaItemConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** Connection between the RootQuery type and the member type */
export type RootQueryToMemberConnection = {
  __typename?: 'RootQueryToMemberConnection';
  /** Edges for the RootQueryToMemberConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToMemberConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Member>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type RootQueryToMemberConnectionEdge = {
  __typename?: 'RootQueryToMemberConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<Member>;
};

/** Arguments for filtering the RootQueryToMemberConnection connection */
export type RootQueryToMemberConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** Connection between the RootQuery type and the Menu type */
export type RootQueryToMenuConnection = {
  __typename?: 'RootQueryToMenuConnection';
  /** Edges for the RootQueryToMenuConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToMenuConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Menu>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type RootQueryToMenuConnectionEdge = {
  __typename?: 'RootQueryToMenuConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<Menu>;
};

/** Arguments for filtering the RootQueryToMenuConnection connection */
export type RootQueryToMenuConnectionWhereArgs = {
  /** The database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** The menu location for the menu being queried */
  location?: InputMaybe<MenuLocationEnum>;
  /** The slug of the menu to query items for */
  slug?: InputMaybe<Scalars['String']>;
};

/** Connection between the RootQuery type and the MenuItem type */
export type RootQueryToMenuItemConnection = {
  __typename?: 'RootQueryToMenuItemConnection';
  /** Edges for the RootQueryToMenuItemConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToMenuItemConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<MenuItem>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type RootQueryToMenuItemConnectionEdge = {
  __typename?: 'RootQueryToMenuItemConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<MenuItem>;
};

/** Arguments for filtering the RootQueryToMenuItemConnection connection */
export type RootQueryToMenuItemConnectionWhereArgs = {
  /** The database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** The menu location for the menu being queried */
  location?: InputMaybe<MenuLocationEnum>;
  /** The database ID of the parent menu object */
  parentDatabaseId?: InputMaybe<Scalars['Int']>;
  /** The ID of the parent menu object */
  parentId?: InputMaybe<Scalars['ID']>;
};

/** Connection between the RootQuery type and the page type */
export type RootQueryToPageConnection = {
  __typename?: 'RootQueryToPageConnection';
  /** Edges for the RootQueryToPageConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToPageConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Page>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type RootQueryToPageConnectionEdge = {
  __typename?: 'RootQueryToPageConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<Page>;
};

/** Arguments for filtering the RootQueryToPageConnection connection */
export type RootQueryToPageConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** Connection between the RootQuery type and the Plugin type */
export type RootQueryToPluginConnection = {
  __typename?: 'RootQueryToPluginConnection';
  /** Edges for the RootQueryToPluginConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToPluginConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Plugin>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type RootQueryToPluginConnectionEdge = {
  __typename?: 'RootQueryToPluginConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<Plugin>;
};

/** Arguments for filtering the RootQueryToPluginConnection connection */
export type RootQueryToPluginConnectionWhereArgs = {
  /** Show plugin based on a keyword search. */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve plugins where plugin status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PluginStatusEnum>>>;
  /** Show plugins with a specific status. */
  status?: InputMaybe<PluginStatusEnum>;
};

/** Connection between the RootQuery type and the post type */
export type RootQueryToPostConnection = {
  __typename?: 'RootQueryToPostConnection';
  /** Edges for the RootQueryToPostConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToPostConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Post>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type RootQueryToPostConnectionEdge = {
  __typename?: 'RootQueryToPostConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<Post>;
};

/** Arguments for filtering the RootQueryToPostConnection connection */
export type RootQueryToPostConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Category ID */
  categoryId?: InputMaybe<Scalars['Int']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Use Category Slug */
  categoryName?: InputMaybe<Scalars['String']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to only include sticky posts */
  isSticky?: InputMaybe<Scalars['Boolean']>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars['String']>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars['String']>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of tag slugs, used to display objects from one tag OR another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of tag slugs, used to exclude objects in specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** Connection between the RootQuery type and the postFormat type */
export type RootQueryToPostFormatConnection = {
  __typename?: 'RootQueryToPostFormatConnection';
  /** Edges for the RootQueryToPostFormatConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToPostFormatConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<PostFormat>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type RootQueryToPostFormatConnectionEdge = {
  __typename?: 'RootQueryToPostFormatConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<PostFormat>;
};

/** Arguments for filtering the RootQueryToPostFormatConnection connection */
export type RootQueryToPostFormatConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
};

/** Connection between the RootQuery type and the region type */
export type RootQueryToRegionConnection = {
  __typename?: 'RootQueryToRegionConnection';
  /** Edges for the RootQueryToRegionConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToRegionConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Region>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type RootQueryToRegionConnectionEdge = {
  __typename?: 'RootQueryToRegionConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<Region>;
};

/** Arguments for filtering the RootQueryToRegionConnection connection */
export type RootQueryToRegionConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
};

/** Connection between the RootQuery type and the ReusableBlock type */
export type RootQueryToReusableBlockConnection = {
  __typename?: 'RootQueryToReusableBlockConnection';
  /** Edges for the RootQueryToReusableBlockConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToReusableBlockConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ReusableBlock>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type RootQueryToReusableBlockConnectionEdge = {
  __typename?: 'RootQueryToReusableBlockConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<ReusableBlock>;
};

/** Arguments for filtering the RootQueryToReusableBlockConnection connection */
export type RootQueryToReusableBlockConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** Connection between the RootQuery type and the review type */
export type RootQueryToReviewConnection = {
  __typename?: 'RootQueryToReviewConnection';
  /** Edges for the RootQueryToReviewConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToReviewConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Review>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type RootQueryToReviewConnectionEdge = {
  __typename?: 'RootQueryToReviewConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<Review>;
};

/** Arguments for filtering the RootQueryToReviewConnection connection */
export type RootQueryToReviewConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** Connection between the RootQuery type and the supplierCategory type */
export type RootQueryToSupplierCategoryConnection = {
  __typename?: 'RootQueryToSupplierCategoryConnection';
  /** Edges for the RootQueryToSupplierCategoryConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToSupplierCategoryConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<SupplierCategory>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type RootQueryToSupplierCategoryConnectionEdge = {
  __typename?: 'RootQueryToSupplierCategoryConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<SupplierCategory>;
};

/** Arguments for filtering the RootQueryToSupplierCategoryConnection connection */
export type RootQueryToSupplierCategoryConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
};

/** Connection between the RootQuery type and the supplier type */
export type RootQueryToSupplierConnection = {
  __typename?: 'RootQueryToSupplierConnection';
  /** Edges for the RootQueryToSupplierConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToSupplierConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Supplier>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type RootQueryToSupplierConnectionEdge = {
  __typename?: 'RootQueryToSupplierConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<Supplier>;
};

/** Arguments for filtering the RootQueryToSupplierConnection connection */
export type RootQueryToSupplierConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** Connection between the RootQuery type and the supplierKeyword type */
export type RootQueryToSupplierKeywordConnection = {
  __typename?: 'RootQueryToSupplierKeywordConnection';
  /** Edges for the RootQueryToSupplierKeywordConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToSupplierKeywordConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<SupplierKeyword>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type RootQueryToSupplierKeywordConnectionEdge = {
  __typename?: 'RootQueryToSupplierKeywordConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<SupplierKeyword>;
};

/** Arguments for filtering the RootQueryToSupplierKeywordConnection connection */
export type RootQueryToSupplierKeywordConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
};

/** Connection between the RootQuery type and the supplier_news type */
export type RootQueryToSupplier_NewsConnection = {
  __typename?: 'RootQueryToSupplier_newsConnection';
  /** Edges for the RootQueryToSupplier_newsConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToSupplier_NewsConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Supplier_News>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type RootQueryToSupplier_NewsConnectionEdge = {
  __typename?: 'RootQueryToSupplier_newsConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<Supplier_News>;
};

/** Arguments for filtering the RootQueryToSupplier_newsConnection connection */
export type RootQueryToSupplier_NewsConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** Connection between the RootQuery type and the tag type */
export type RootQueryToTagConnection = {
  __typename?: 'RootQueryToTagConnection';
  /** Edges for the RootQueryToTagConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToTagConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Tag>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type RootQueryToTagConnectionEdge = {
  __typename?: 'RootQueryToTagConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<Tag>;
};

/** Arguments for filtering the RootQueryToTagConnection connection */
export type RootQueryToTagConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
};

/** Connection between the RootQuery type and the Taxonomy type */
export type RootQueryToTaxonomyConnection = {
  __typename?: 'RootQueryToTaxonomyConnection';
  /** Edges for the RootQueryToTaxonomyConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToTaxonomyConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Taxonomy>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type RootQueryToTaxonomyConnectionEdge = {
  __typename?: 'RootQueryToTaxonomyConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<Taxonomy>;
};

/** Connection between the RootQuery type and the TermNode type */
export type RootQueryToTermNodeConnection = {
  __typename?: 'RootQueryToTermNodeConnection';
  /** Edges for the RootQueryToTermNodeConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToTermNodeConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<TermNode>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type RootQueryToTermNodeConnectionEdge = {
  __typename?: 'RootQueryToTermNodeConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<TermNode>;
};

/** Arguments for filtering the RootQueryToTermNodeConnection connection */
export type RootQueryToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** The Taxonomy to filter terms by */
  taxonomies?: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
};

/** Connection between the RootQuery type and the testimonial type */
export type RootQueryToTestimonialConnection = {
  __typename?: 'RootQueryToTestimonialConnection';
  /** Edges for the RootQueryToTestimonialConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToTestimonialConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Testimonial>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type RootQueryToTestimonialConnectionEdge = {
  __typename?: 'RootQueryToTestimonialConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<Testimonial>;
};

/** Arguments for filtering the RootQueryToTestimonialConnection connection */
export type RootQueryToTestimonialConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** Connection between the RootQuery type and the Theme type */
export type RootQueryToThemeConnection = {
  __typename?: 'RootQueryToThemeConnection';
  /** Edges for the RootQueryToThemeConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToThemeConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Theme>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type RootQueryToThemeConnectionEdge = {
  __typename?: 'RootQueryToThemeConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<Theme>;
};

/** Connection between the RootQuery type and the tradingTime type */
export type RootQueryToTradingTimeConnection = {
  __typename?: 'RootQueryToTradingTimeConnection';
  /** Edges for the RootQueryToTradingTimeConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToTradingTimeConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<TradingTime>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type RootQueryToTradingTimeConnectionEdge = {
  __typename?: 'RootQueryToTradingTimeConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<TradingTime>;
};

/** Arguments for filtering the RootQueryToTradingTimeConnection connection */
export type RootQueryToTradingTimeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
};

/** Connection between the RootQuery type and the User type */
export type RootQueryToUserConnection = {
  __typename?: 'RootQueryToUserConnection';
  /** Edges for the RootQueryToUserConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToUserConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<User>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type RootQueryToUserConnectionEdge = {
  __typename?: 'RootQueryToUserConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<User>;
};

/** Arguments for filtering the RootQueryToUserConnection connection */
export type RootQueryToUserConnectionWhereArgs = {
  /** Array of userIds to exclude. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** Pass an array of post types to filter results to users who have published posts in those post types. */
  hasPublishedPosts?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of userIds to include. */
  include?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** The user login. */
  login?: InputMaybe<Scalars['String']>;
  /** An array of logins to include. Users matching one of these logins will be included in results. */
  loginIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** An array of logins to exclude. Users matching one of these logins will not be included in results. */
  loginNotIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** The user nicename. */
  nicename?: InputMaybe<Scalars['String']>;
  /** An array of nicenames to include. Users matching one of these nicenames will be included in results. */
  nicenameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** An array of nicenames to exclude. Users matching one of these nicenames will not be included in results. */
  nicenameNotIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<UsersConnectionOrderbyInput>>>;
  /** An array of role names that users must match to be included in results. Note that this is an inclusive list: users must match *each* role. */
  role?: InputMaybe<UserRoleEnum>;
  /** An array of role names. Matched users must have at least one of these roles. */
  roleIn?: InputMaybe<Array<InputMaybe<UserRoleEnum>>>;
  /** An array of role names to exclude. Users matching one or more of these roles will not be included in results. */
  roleNotIn?: InputMaybe<Array<InputMaybe<UserRoleEnum>>>;
  /** Search keyword. Searches for possible string matches on columns. When "searchColumns" is left empty, it tries to determine which column to search in based on search string. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of column names to be searched. Accepts 'ID', 'login', 'nicename', 'email', 'url'. */
  searchColumns?: InputMaybe<Array<InputMaybe<UsersConnectionSearchColumnEnum>>>;
};

/** Connection between the RootQuery type and the UserRole type */
export type RootQueryToUserRoleConnection = {
  __typename?: 'RootQueryToUserRoleConnection';
  /** Edges for the RootQueryToUserRoleConnection connection */
  edges?: Maybe<Array<Maybe<RootQueryToUserRoleConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<UserRole>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type RootQueryToUserRoleConnectionEdge = {
  __typename?: 'RootQueryToUserRoleConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<UserRole>;
};

/** A Gravity Forms   field. */
export type SectionField = FormField & {
  __typename?: 'SectionField';
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** A Gravity Forms   field. */
export type SelectField = FormField & {
  __typename?: 'SelectField';
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** The autocomplete attribute for the field. */
  autocompleteAttribute?: Maybe<Scalars['String']>;
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** The available choices for the select field. */
  choices?: Maybe<Array<Maybe<SelectFieldChoice>>>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Whether autocomplete should be enabled for this field. */
  hasAutocomplete?: Maybe<Scalars['Boolean']>;
  /** Determines if the field (checkbox, select or radio) have choice values enabled, which allows the field to have choice values different from the labels that are displayed to the user. */
  hasChoiceValue?: Maybe<Scalars['Boolean']>;
  /** When set to true, the &quot;Chosen&quot; jQuery script will be applied to this field, enabling search capabilities to Drop Down fields and a more user-friendly interface for Multi Select fields. */
  hasEnhancedUI?: Maybe<Scalars['Boolean']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']>;
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']>;
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']>;
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** Select choice values. */
export type SelectFieldChoice = {
  __typename?: 'SelectFieldChoice';
  /** Determines if this choice should be selected by default when displayed. The value true will select the choice, whereas false will display it unselected. */
  isSelected?: Maybe<Scalars['Boolean']>;
  /** The text to be displayed to the user when displaying this choice. */
  text?: Maybe<Scalars['String']>;
  /** The value to be stored in the database when this choice is selected. Note: This property is only supported by the Drop Down and Post Category fields. Checkboxes and Radio fields will store the text property in the database regardless of the value property. */
  value?: Maybe<Scalars['String']>;
};

/** Input for the sendEmail mutation */
export type SendEmailInput = {
  /** Body of email */
  body?: InputMaybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Who to send the email from */
  from?: InputMaybe<Scalars['String']>;
  /** Reply to address */
  replyTo?: InputMaybe<Scalars['String']>;
  /** Subject of email */
  subject?: InputMaybe<Scalars['String']>;
  /** Who to send the email to */
  to?: InputMaybe<Scalars['String']>;
};

/** The payload for the sendEmail mutation */
export type SendEmailPayload = {
  __typename?: 'SendEmailPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Message */
  message?: Maybe<Scalars['String']>;
  /** Origin that sent the request */
  origin?: Maybe<Scalars['String']>;
  /** reply To address used */
  replyTo?: Maybe<Scalars['String']>;
  /** Was the email sent */
  sent?: Maybe<Scalars['Boolean']>;
  /** Who the email got sent to */
  to?: Maybe<Scalars['String']>;
};

/** Input for the sendPasswordResetEmail mutation */
export type SendPasswordResetEmailInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** A string that contains the user's username or email address. */
  username: Scalars['String'];
};

/** The payload for the sendPasswordResetEmail mutation */
export type SendPasswordResetEmailPayload = {
  __typename?: 'SendPasswordResetEmailPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The user that the password reset email was sent to */
  user?: Maybe<User>;
};

/** All of the registered settings */
export type Settings = {
  __typename?: 'Settings';
  /** Settings of the the string Settings Group */
  discussionSettingsDefaultCommentStatus?: Maybe<Scalars['String']>;
  /** Settings of the the string Settings Group */
  discussionSettingsDefaultPingStatus?: Maybe<Scalars['String']>;
  /** Settings of the the string Settings Group */
  generalSettingsDateFormat?: Maybe<Scalars['String']>;
  /** Settings of the the string Settings Group */
  generalSettingsDescription?: Maybe<Scalars['String']>;
  /** Settings of the the string Settings Group */
  generalSettingsEmail?: Maybe<Scalars['String']>;
  /** Settings of the the string Settings Group */
  generalSettingsLanguage?: Maybe<Scalars['String']>;
  /** Settings of the the integer Settings Group */
  generalSettingsStartOfWeek?: Maybe<Scalars['Int']>;
  /** Settings of the the string Settings Group */
  generalSettingsTimeFormat?: Maybe<Scalars['String']>;
  /** Settings of the the string Settings Group */
  generalSettingsTimezone?: Maybe<Scalars['String']>;
  /** Settings of the the string Settings Group */
  generalSettingsTitle?: Maybe<Scalars['String']>;
  /** Settings of the the string Settings Group */
  generalSettingsUrl?: Maybe<Scalars['String']>;
  /** Settings of the the integer Settings Group */
  readingSettingsPostsPerPage?: Maybe<Scalars['Int']>;
  /** Settings of the the integer Settings Group */
  writingSettingsDefaultCategory?: Maybe<Scalars['Int']>;
  /** Settings of the the string Settings Group */
  writingSettingsDefaultPostFormat?: Maybe<Scalars['String']>;
  /** Settings of the the boolean Settings Group */
  writingSettingsUseSmilies?: Maybe<Scalars['Boolean']>;
};

/** The Confirmation object returned on submission. Null if the submission was not successful. */
export type SubmissionConfirmation = {
  __typename?: 'SubmissionConfirmation';
  /** Contains the confirmation message HTML to display. Only applicable when type is set to `MESSAGE`. */
  message?: Maybe<Scalars['String']>;
  /** Connection between the SubmissionConfirmation type and the Page type */
  page?: Maybe<SubmissionConfirmationToPageConnectionEdge>;
  /** Contains the Id of the WordPress page that the browser will be redirected to. Only applicable when type is set to `PAGE`. */
  pageId?: Maybe<Scalars['Int']>;
  /** Contains the query string to be appended to the redirection url. Only applicable when type is set to `REDIRECT` or `PAGE` . */
  queryString?: Maybe<Scalars['String']>;
  /** Determines the type of confirmation to be used. */
  type?: Maybe<SubmissionConfirmationTypeEnum>;
  /** The URL the submission should redirect to. Only applicable when type is set to `REDIRECT`. */
  url?: Maybe<Scalars['String']>;
};

/** Connection between the SubmissionConfirmation type and the Page type */
export type SubmissionConfirmationToPageConnectionEdge = {
  __typename?: 'SubmissionConfirmationToPageConnectionEdge';
  /** The node of the connection, without the edges */
  node?: Maybe<Page>;
};

/** Type of confirmation returned by the submission. */
export enum SubmissionConfirmationTypeEnum {
  /** A confirmation "message". */
  Message = 'MESSAGE',
  /** A "redirect" to a given URL. */
  Redirect = 'REDIRECT'
}

/** A Gravity Forms   field. */
export type SubmitField = FormField & {
  __typename?: 'SubmitField';
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** Entry meta input fields for submitting Gravity Forms forms. */
export type SubmitFormMetaInput = {
  /** ID of the user that submitted of the form if a logged in user submitted the form. */
  createdById?: InputMaybe<Scalars['Int']>;
  /** The UTC date the entry was created, in `Y-m-d H:i:s` format. */
  dateCreatedGmt?: InputMaybe<Scalars['String']>;
  /** Client IP of user who submitted the form. */
  ip?: InputMaybe<Scalars['String']>;
  /** Used to overwrite the sourceUrl the form was submitted from. */
  sourceUrl?: InputMaybe<Scalars['String']>;
  /** The name and version of both the browser and operating system from which the entry was submitted. */
  userAgent?: InputMaybe<Scalars['String']>;
};

/** Input for the submitGfDraftEntry mutation */
export type SubmitGfDraftEntryInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Either the global ID of the draft entry, or its resume token. */
  id: Scalars['ID'];
  /** The ID type for the draft entry. Defaults to `ID` . */
  idType?: InputMaybe<DraftEntryIdTypeEnum>;
};

/** The payload for the submitGfDraftEntry mutation */
export type SubmitGfDraftEntryPayload = {
  __typename?: 'SubmitGfDraftEntryPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The form confirmation data. Null if the submission has `errors` */
  confirmation?: Maybe<SubmissionConfirmation>;
  /** The entry that was created. */
  entry?: Maybe<GfSubmittedEntry>;
  /** Field errors. */
  errors?: Maybe<Array<Maybe<FieldError>>>;
};

/** Input for the submitGfForm mutation */
export type SubmitGfFormInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The entry meta associated with the submission. */
  entryMeta?: InputMaybe<SubmitFormMetaInput>;
  /** The field ids and their values. */
  fieldValues: Array<InputMaybe<FormFieldValuesInput>>;
  /** The form ID. Accepts either a global or Database ID. */
  id: Scalars['ID'];
  /** Set to `true` if submitting a draft entry. Defaults to `false`. */
  saveAsDraft?: InputMaybe<Scalars['Boolean']>;
  /** Useful for multi-page forms to indicate which page of the form was just submitted. */
  sourcePage?: InputMaybe<Scalars['Int']>;
  /** Useful for multi-page forms to indicate which page is to be loaded if the current page passes validation. */
  targetPage?: InputMaybe<Scalars['Int']>;
};

/** The payload for the submitGfForm mutation */
export type SubmitGfFormPayload = {
  __typename?: 'SubmitGfFormPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The form confirmation data. Null if the submission has `errors` */
  confirmation?: Maybe<SubmissionConfirmation>;
  /** The entry that was created. */
  entry?: Maybe<GfEntry>;
  /** Field errors. */
  errors?: Maybe<Array<Maybe<FieldError>>>;
  /** Draft resume URL. Null if submitting an entry. If the &quot;Referer&quot; header is not included in the request, this will be an empty string. */
  resumeUrl?: Maybe<Scalars['String']>;
};

/** The Type of Identifier used to fetch a single resource. */
export enum SubmittedEntryIdTypeEnum {
  /** The database ID assigned by Gravity Forms. Used by submitted entries. */
  DatabaseId = 'DATABASE_ID',
  /** Unique global ID for the object. */
  Id = 'ID'
}

/** The supplier type */
export type Supplier = ContentNode & DatabaseIdentifier & MenuItemLinkable & Node & NodeWithFeaturedImage & NodeWithTemplate & NodeWithTitle & UniformResourceIdentifiable & {
  __typename?: 'Supplier';
  Meta?: Maybe<Supplier_Meta>;
  /** Connection between the ContentNode type and the ContentType type */
  contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /** The name of the Content Type the node belongs to */
  contentTypeName: Scalars['String'];
  /** The unique resource identifier path */
  databaseId: Scalars['Int'];
  /** Post publishing date. */
  date?: Maybe<Scalars['String']>;
  /** The publishing date set in GMT. */
  dateGmt?: Maybe<Scalars['String']>;
  /** The desired slug of the post */
  desiredSlug?: Maybe<Scalars['String']>;
  /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
  editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
  /** The RSS enclosure for the object */
  enclosure?: Maybe<Scalars['String']>;
  /** Connection between the ContentNode type and the EnqueuedScript type */
  enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
  /** Connection between the ContentNode type and the EnqueuedStylesheet type */
  enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /** Connection between the NodeWithFeaturedImage type and the MediaItem type */
  featuredImage?: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
  /** The database identifier for the featured image node assigned to the content node */
  featuredImageDatabaseId?: Maybe<Scalars['Int']>;
  /** Globally unique ID of the featured image assigned to the node */
  featuredImageId?: Maybe<Scalars['ID']>;
  /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
  guid?: Maybe<Scalars['String']>;
  /** The globally unique identifier of the supplier object. */
  id: Scalars['ID'];
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean'];
  /** Whether the object is a node in the preview state */
  isPreview?: Maybe<Scalars['Boolean']>;
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']>;
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean'];
  /** The user that most recently edited the node */
  lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
  /** The permalink of the post */
  link?: Maybe<Scalars['String']>;
  /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
  modified?: Maybe<Scalars['String']>;
  /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
  modifiedGmt?: Maybe<Scalars['String']>;
  /** Connection between the supplier type and the supplier type */
  preview?: Maybe<SupplierToPreviewConnectionEdge>;
  /** The database id of the preview node */
  previewRevisionDatabaseId?: Maybe<Scalars['Int']>;
  /** Whether the object is a node in the preview state */
  previewRevisionId?: Maybe<Scalars['ID']>;
  /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
  slug?: Maybe<Scalars['String']>;
  /** The current status of the object */
  status?: Maybe<Scalars['String']>;
  /** Connection between the supplier type and the supplierCategory type */
  supplierCategories?: Maybe<SupplierToSupplierCategoryConnection>;
  supplierDetails?: Maybe<Supplier_Supplierdetails>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of the databaseId field
   */
  supplierId: Scalars['Int'];
  /** Connection between the supplier type and the supplierKeyword type */
  supplierKeywords?: Maybe<SupplierToSupplierKeywordConnection>;
  /** The template assigned to a node of content */
  template?: Maybe<ContentTemplate>;
  /** Connection between the supplier type and the TermNode type */
  terms?: Maybe<SupplierToTermNodeConnection>;
  /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
  title?: Maybe<Scalars['String']>;
  /** The unique resource identifier path */
  uri?: Maybe<Scalars['String']>;
};


/** The supplier type */
export type SupplierEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The supplier type */
export type SupplierEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The supplier type */
export type SupplierSupplierCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SupplierToSupplierCategoryConnectionWhereArgs>;
};


/** The supplier type */
export type SupplierSupplierKeywordsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SupplierToSupplierKeywordConnectionWhereArgs>;
};


/** The supplier type */
export type SupplierTermsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SupplierToTermNodeConnectionWhereArgs>;
};


/** The supplier type */
export type SupplierTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** The supplierCategory type */
export type SupplierCategory = DatabaseIdentifier & HierarchicalTermNode & MenuItemLinkable & Node & TermNode & UniformResourceIdentifiable & {
  __typename?: 'SupplierCategory';
  /** The ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
  ancestors?: Maybe<SupplierCategoryToAncestorsSupplierCategoryConnection>;
  /** Connection between the supplierCategory type and the supplierCategory type */
  children?: Maybe<SupplierCategoryToSupplierCategoryConnection>;
  /** Connection between the supplierCategory type and the ContentNode type */
  contentNodes?: Maybe<SupplierCategoryToContentNodeConnection>;
  /** The number of objects connected to the object */
  count?: Maybe<Scalars['Int']>;
  /** The unique resource identifier path */
  databaseId: Scalars['Int'];
  /** The description of the object */
  description?: Maybe<Scalars['String']>;
  /** Connection between the TermNode type and the EnqueuedScript type */
  enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>;
  /** Connection between the TermNode type and the EnqueuedStylesheet type */
  enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>;
  /** The unique resource identifier path */
  id: Scalars['ID'];
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean'];
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']>;
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean'];
  /** The link to the term */
  link?: Maybe<Scalars['String']>;
  /** The human friendly name of the object. */
  name?: Maybe<Scalars['String']>;
  /** Connection between the supplierCategory type and the supplierCategory type */
  parent?: Maybe<SupplierCategoryToParentSupplierCategoryConnectionEdge>;
  /** Database id of the parent node */
  parentDatabaseId?: Maybe<Scalars['Int']>;
  /** The globally unique identifier of the parent node. */
  parentId?: Maybe<Scalars['ID']>;
  /** An alphanumeric identifier for the object unique to its type. */
  slug?: Maybe<Scalars['String']>;
  /** Added to the GraphQL Schema because the ACF Field Group &quot;Supplier Category Image&quot; was assigned to the &quot;supplier_category&quot; taxonomy */
  supCatImage?: Maybe<SupplierCategory_Supcatimage>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of databaseId
   */
  supplierCategoryId?: Maybe<Scalars['Int']>;
  /** Connection between the supplierCategory type and the supplier type */
  suppliers?: Maybe<SupplierCategoryToSupplierConnection>;
  /** Connection between the supplierCategory type and the Taxonomy type */
  taxonomy?: Maybe<SupplierCategoryToTaxonomyConnectionEdge>;
  /** The name of the taxonomy that the object is associated with */
  taxonomyName?: Maybe<Scalars['String']>;
  /** The ID of the term group that this term object belongs to */
  termGroupId?: Maybe<Scalars['Int']>;
  /** The taxonomy ID that the object is associated with */
  termTaxonomyId?: Maybe<Scalars['Int']>;
  /** The unique resource identifier path */
  uri?: Maybe<Scalars['String']>;
};


/** The supplierCategory type */
export type SupplierCategoryAncestorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The supplierCategory type */
export type SupplierCategoryChildrenArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SupplierCategoryToSupplierCategoryConnectionWhereArgs>;
};


/** The supplierCategory type */
export type SupplierCategoryContentNodesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SupplierCategoryToContentNodeConnectionWhereArgs>;
};


/** The supplierCategory type */
export type SupplierCategoryEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The supplierCategory type */
export type SupplierCategoryEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The supplierCategory type */
export type SupplierCategorySuppliersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SupplierCategoryToSupplierConnectionWhereArgs>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum SupplierCategoryIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI'
}

/** Connection between the supplierCategory type and the supplierCategory type */
export type SupplierCategoryToAncestorsSupplierCategoryConnection = {
  __typename?: 'SupplierCategoryToAncestorsSupplierCategoryConnection';
  /** Edges for the SupplierCategoryToAncestorsSupplierCategoryConnection connection */
  edges?: Maybe<Array<Maybe<SupplierCategoryToAncestorsSupplierCategoryConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<SupplierCategory>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type SupplierCategoryToAncestorsSupplierCategoryConnectionEdge = {
  __typename?: 'SupplierCategoryToAncestorsSupplierCategoryConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<SupplierCategory>;
};

/** Connection between the supplierCategory type and the ContentNode type */
export type SupplierCategoryToContentNodeConnection = {
  __typename?: 'SupplierCategoryToContentNodeConnection';
  /** Edges for the SupplierCategoryToContentNodeConnection connection */
  edges?: Maybe<Array<Maybe<SupplierCategoryToContentNodeConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentNode>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type SupplierCategoryToContentNodeConnectionEdge = {
  __typename?: 'SupplierCategoryToContentNodeConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<ContentNode>;
};

/** Arguments for filtering the SupplierCategoryToContentNodeConnection connection */
export type SupplierCategoryToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfSupplierCategoryEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** Connection between the supplierCategory type and the supplierCategory type */
export type SupplierCategoryToParentSupplierCategoryConnectionEdge = {
  __typename?: 'SupplierCategoryToParentSupplierCategoryConnectionEdge';
  /** The node of the connection, without the edges */
  node?: Maybe<SupplierCategory>;
};

/** Connection between the supplierCategory type and the supplierCategory type */
export type SupplierCategoryToSupplierCategoryConnection = {
  __typename?: 'SupplierCategoryToSupplierCategoryConnection';
  /** Edges for the SupplierCategoryToSupplierCategoryConnection connection */
  edges?: Maybe<Array<Maybe<SupplierCategoryToSupplierCategoryConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<SupplierCategory>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type SupplierCategoryToSupplierCategoryConnectionEdge = {
  __typename?: 'SupplierCategoryToSupplierCategoryConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<SupplierCategory>;
};

/** Arguments for filtering the SupplierCategoryToSupplierCategoryConnection connection */
export type SupplierCategoryToSupplierCategoryConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
};

/** Connection between the supplierCategory type and the supplier type */
export type SupplierCategoryToSupplierConnection = {
  __typename?: 'SupplierCategoryToSupplierConnection';
  /** Edges for the SupplierCategoryToSupplierConnection connection */
  edges?: Maybe<Array<Maybe<SupplierCategoryToSupplierConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Supplier>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type SupplierCategoryToSupplierConnectionEdge = {
  __typename?: 'SupplierCategoryToSupplierConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<Supplier>;
};

/** Arguments for filtering the SupplierCategoryToSupplierConnection connection */
export type SupplierCategoryToSupplierConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** Connection between the supplierCategory type and the Taxonomy type */
export type SupplierCategoryToTaxonomyConnectionEdge = {
  __typename?: 'SupplierCategoryToTaxonomyConnectionEdge';
  /** The node of the connection, without the edges */
  node?: Maybe<Taxonomy>;
};

/** Field Group */
export type SupplierCategory_Supcatimage = {
  __typename?: 'SupplierCategory_Supcatimage';
  fieldGroupName?: Maybe<Scalars['String']>;
  image?: Maybe<MediaItem>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum SupplierIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a resource by the URI. */
  Uri = 'URI'
}

/** The supplierKeyword type */
export type SupplierKeyword = DatabaseIdentifier & MenuItemLinkable & Node & TermNode & UniformResourceIdentifiable & {
  __typename?: 'SupplierKeyword';
  /** Connection between the supplierKeyword type and the ContentNode type */
  contentNodes?: Maybe<SupplierKeywordToContentNodeConnection>;
  /** The number of objects connected to the object */
  count?: Maybe<Scalars['Int']>;
  /** The unique resource identifier path */
  databaseId: Scalars['Int'];
  /** The description of the object */
  description?: Maybe<Scalars['String']>;
  /** Connection between the TermNode type and the EnqueuedScript type */
  enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>;
  /** Connection between the TermNode type and the EnqueuedStylesheet type */
  enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>;
  /** The unique resource identifier path */
  id: Scalars['ID'];
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean'];
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']>;
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean'];
  /** The link to the term */
  link?: Maybe<Scalars['String']>;
  /** The human friendly name of the object. */
  name?: Maybe<Scalars['String']>;
  /** An alphanumeric identifier for the object unique to its type. */
  slug?: Maybe<Scalars['String']>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of databaseId
   */
  supplierKeywordId?: Maybe<Scalars['Int']>;
  /** Connection between the supplierKeyword type and the supplier type */
  suppliers?: Maybe<SupplierKeywordToSupplierConnection>;
  /** Connection between the supplierKeyword type and the Taxonomy type */
  taxonomy?: Maybe<SupplierKeywordToTaxonomyConnectionEdge>;
  /** The name of the taxonomy that the object is associated with */
  taxonomyName?: Maybe<Scalars['String']>;
  /** The ID of the term group that this term object belongs to */
  termGroupId?: Maybe<Scalars['Int']>;
  /** The taxonomy ID that the object is associated with */
  termTaxonomyId?: Maybe<Scalars['Int']>;
  /** The unique resource identifier path */
  uri?: Maybe<Scalars['String']>;
};


/** The supplierKeyword type */
export type SupplierKeywordContentNodesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SupplierKeywordToContentNodeConnectionWhereArgs>;
};


/** The supplierKeyword type */
export type SupplierKeywordEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The supplierKeyword type */
export type SupplierKeywordEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The supplierKeyword type */
export type SupplierKeywordSuppliersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SupplierKeywordToSupplierConnectionWhereArgs>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum SupplierKeywordIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI'
}

/** Connection between the supplierKeyword type and the ContentNode type */
export type SupplierKeywordToContentNodeConnection = {
  __typename?: 'SupplierKeywordToContentNodeConnection';
  /** Edges for the SupplierKeywordToContentNodeConnection connection */
  edges?: Maybe<Array<Maybe<SupplierKeywordToContentNodeConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentNode>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type SupplierKeywordToContentNodeConnectionEdge = {
  __typename?: 'SupplierKeywordToContentNodeConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<ContentNode>;
};

/** Arguments for filtering the SupplierKeywordToContentNodeConnection connection */
export type SupplierKeywordToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfSupplierKeywordEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** Connection between the supplierKeyword type and the supplier type */
export type SupplierKeywordToSupplierConnection = {
  __typename?: 'SupplierKeywordToSupplierConnection';
  /** Edges for the SupplierKeywordToSupplierConnection connection */
  edges?: Maybe<Array<Maybe<SupplierKeywordToSupplierConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Supplier>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type SupplierKeywordToSupplierConnectionEdge = {
  __typename?: 'SupplierKeywordToSupplierConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<Supplier>;
};

/** Arguments for filtering the SupplierKeywordToSupplierConnection connection */
export type SupplierKeywordToSupplierConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** Connection between the supplierKeyword type and the Taxonomy type */
export type SupplierKeywordToTaxonomyConnectionEdge = {
  __typename?: 'SupplierKeywordToTaxonomyConnectionEdge';
  /** The node of the connection, without the edges */
  node?: Maybe<Taxonomy>;
};

/** Set relationships between the supplier to supplierCategories */
export type SupplierSupplierCategoriesInput = {
  /** If true, this will append the supplierCategory to existing related supplierCategories. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<SupplierSupplierCategoriesNodeInput>>>;
};

/** List of supplierCategories to connect the supplier to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type SupplierSupplierCategoriesNodeInput = {
  /** The description of the supplierCategory. This field is used to set a description of the supplierCategory if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']>;
  /** The ID of the supplierCategory. If present, this will be used to connect to the supplier. If no existing supplierCategory exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']>;
  /** The name of the supplierCategory. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']>;
  /** The slug of the supplierCategory. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']>;
};

/** Set relationships between the supplier to supplierKeywords */
export type SupplierSupplierKeywordsInput = {
  /** If true, this will append the supplierKeyword to existing related supplierKeywords. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<SupplierSupplierKeywordsNodeInput>>>;
};

/** List of supplierKeywords to connect the supplier to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type SupplierSupplierKeywordsNodeInput = {
  /** The description of the supplierKeyword. This field is used to set a description of the supplierKeyword if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']>;
  /** The ID of the supplierKeyword. If present, this will be used to connect to the supplier. If no existing supplierKeyword exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']>;
  /** The name of the supplierKeyword. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']>;
  /** The slug of the supplierKeyword. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']>;
};

/** Connection between the supplier type and the supplier type */
export type SupplierToPreviewConnectionEdge = {
  __typename?: 'SupplierToPreviewConnectionEdge';
  /** The node of the connection, without the edges */
  node?: Maybe<Supplier>;
};

/** Connection between the supplier type and the supplierCategory type */
export type SupplierToSupplierCategoryConnection = {
  __typename?: 'SupplierToSupplierCategoryConnection';
  /** Edges for the SupplierToSupplierCategoryConnection connection */
  edges?: Maybe<Array<Maybe<SupplierToSupplierCategoryConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<SupplierCategory>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type SupplierToSupplierCategoryConnectionEdge = {
  __typename?: 'SupplierToSupplierCategoryConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<SupplierCategory>;
};

/** Arguments for filtering the SupplierToSupplierCategoryConnection connection */
export type SupplierToSupplierCategoryConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
};

/** Connection between the supplier type and the supplierKeyword type */
export type SupplierToSupplierKeywordConnection = {
  __typename?: 'SupplierToSupplierKeywordConnection';
  /** Edges for the SupplierToSupplierKeywordConnection connection */
  edges?: Maybe<Array<Maybe<SupplierToSupplierKeywordConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<SupplierKeyword>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type SupplierToSupplierKeywordConnectionEdge = {
  __typename?: 'SupplierToSupplierKeywordConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<SupplierKeyword>;
};

/** Arguments for filtering the SupplierToSupplierKeywordConnection connection */
export type SupplierToSupplierKeywordConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
};

/** Connection between the supplier type and the TermNode type */
export type SupplierToTermNodeConnection = {
  __typename?: 'SupplierToTermNodeConnection';
  /** Edges for the SupplierToTermNodeConnection connection */
  edges?: Maybe<Array<Maybe<SupplierToTermNodeConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<TermNode>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type SupplierToTermNodeConnectionEdge = {
  __typename?: 'SupplierToTermNodeConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<TermNode>;
};

/** Arguments for filtering the SupplierToTermNodeConnection connection */
export type SupplierToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** The Taxonomy to filter terms by */
  taxonomies?: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
};

/** Field Group */
export type Supplier_Meta = {
  __typename?: 'Supplier_Meta';
  fieldGroupName?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  /** If left blank the page or post title will be used. */
  metaTitle?: Maybe<Scalars['String']>;
  ogDescription?: Maybe<Scalars['String']>;
  ogImage?: Maybe<MediaItem>;
  ogTitle?: Maybe<Scalars['String']>;
  twitterCardType?: Maybe<Scalars['String']>;
  twitterDescription?: Maybe<Scalars['String']>;
  twitterImage?: Maybe<MediaItem>;
  twitterTitle?: Maybe<Scalars['String']>;
};

/** Field Group */
export type Supplier_Supplierdetails = {
  __typename?: 'Supplier_Supplierdetails';
  description?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  documents?: Maybe<Array<Maybe<Supplier_Supplierdetails_Documents>>>;
  emailAddress?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['Boolean']>;
  fieldGroupName?: Maybe<Scalars['String']>;
  heroBanner?: Maybe<MediaItem>;
  introduction?: Maybe<Scalars['String']>;
  memberBenefits?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<MediaItem>;
  termsAndConditions?: Maybe<Scalars['String']>;
  videoUrl?: Maybe<Scalars['String']>;
};

/** Field Group */
export type Supplier_Supplierdetails_Documents = {
  __typename?: 'Supplier_Supplierdetails_documents';
  fieldGroupName?: Maybe<Scalars['String']>;
  file?: Maybe<MediaItem>;
  title?: Maybe<Scalars['String']>;
};

/** The supplier_news type */
export type Supplier_News = ContentNode & DatabaseIdentifier & MenuItemLinkable & Node & NodeWithFeaturedImage & NodeWithTemplate & NodeWithTitle & UniformResourceIdentifiable & {
  __typename?: 'Supplier_news';
  Meta?: Maybe<Supplier_News_Meta>;
  /** Connection between the ContentNode type and the ContentType type */
  contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /** The name of the Content Type the node belongs to */
  contentTypeName: Scalars['String'];
  /** The unique resource identifier path */
  databaseId: Scalars['Int'];
  /** Post publishing date. */
  date?: Maybe<Scalars['String']>;
  /** The publishing date set in GMT. */
  dateGmt?: Maybe<Scalars['String']>;
  /** The desired slug of the post */
  desiredSlug?: Maybe<Scalars['String']>;
  /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
  editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
  /** The RSS enclosure for the object */
  enclosure?: Maybe<Scalars['String']>;
  /** Connection between the ContentNode type and the EnqueuedScript type */
  enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
  /** Connection between the ContentNode type and the EnqueuedStylesheet type */
  enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /** Connection between the NodeWithFeaturedImage type and the MediaItem type */
  featuredImage?: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
  /** The database identifier for the featured image node assigned to the content node */
  featuredImageDatabaseId?: Maybe<Scalars['Int']>;
  /** Globally unique ID of the featured image assigned to the node */
  featuredImageId?: Maybe<Scalars['ID']>;
  /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
  guid?: Maybe<Scalars['String']>;
  /** The globally unique identifier of the supplier_news object. */
  id: Scalars['ID'];
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean'];
  /** Whether the object is a node in the preview state */
  isPreview?: Maybe<Scalars['Boolean']>;
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']>;
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean'];
  /** The user that most recently edited the node */
  lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
  /** The permalink of the post */
  link?: Maybe<Scalars['String']>;
  /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
  modified?: Maybe<Scalars['String']>;
  /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
  modifiedGmt?: Maybe<Scalars['String']>;
  newsPost?: Maybe<Supplier_News_Newspost>;
  /** Connection between the supplier_news type and the supplier_news type */
  preview?: Maybe<Supplier_NewsToPreviewConnectionEdge>;
  /** The database id of the preview node */
  previewRevisionDatabaseId?: Maybe<Scalars['Int']>;
  /** Whether the object is a node in the preview state */
  previewRevisionId?: Maybe<Scalars['ID']>;
  /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
  slug?: Maybe<Scalars['String']>;
  /** The current status of the object */
  status?: Maybe<Scalars['String']>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of the databaseId field
   */
  supplier_newsId: Scalars['Int'];
  /** The template assigned to a node of content */
  template?: Maybe<ContentTemplate>;
  /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
  title?: Maybe<Scalars['String']>;
  /** The unique resource identifier path */
  uri?: Maybe<Scalars['String']>;
};


/** The supplier_news type */
export type Supplier_NewsEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The supplier_news type */
export type Supplier_NewsEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The supplier_news type */
export type Supplier_NewsTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum Supplier_NewsIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a resource by the URI. */
  Uri = 'URI'
}

/** Connection between the supplier_news type and the supplier_news type */
export type Supplier_NewsToPreviewConnectionEdge = {
  __typename?: 'Supplier_newsToPreviewConnectionEdge';
  /** The node of the connection, without the edges */
  node?: Maybe<Supplier_News>;
};

/** Field Group */
export type Supplier_News_Meta = {
  __typename?: 'Supplier_news_Meta';
  fieldGroupName?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  /** If left blank the page or post title will be used. */
  metaTitle?: Maybe<Scalars['String']>;
  ogDescription?: Maybe<Scalars['String']>;
  ogImage?: Maybe<MediaItem>;
  ogTitle?: Maybe<Scalars['String']>;
  twitterCardType?: Maybe<Scalars['String']>;
  twitterDescription?: Maybe<Scalars['String']>;
  twitterImage?: Maybe<MediaItem>;
  twitterTitle?: Maybe<Scalars['String']>;
};

/** Field Group */
export type Supplier_News_Newspost = {
  __typename?: 'Supplier_news_Newspost';
  approvalStatus?: Maybe<Scalars['Boolean']>;
  documents?: Maybe<Array<Maybe<Supplier_News_Newspost_Documents>>>;
  expiryDate?: Maybe<Scalars['String']>;
  fieldGroupName?: Maybe<Scalars['String']>;
  headerUrlLink?: Maybe<Scalars['String']>;
  inReview?: Maybe<Scalars['Boolean']>;
  linkedSupplier?: Maybe<Array<Maybe<Supplier_News_Newspost_LinkedSupplier>>>;
  postContent?: Maybe<Scalars['String']>;
  publishedStatus?: Maybe<Scalars['Boolean']>;
  videoUrl?: Maybe<Scalars['String']>;
};

export type Supplier_News_Newspost_LinkedSupplier = Supplier;

/** Field Group */
export type Supplier_News_Newspost_Documents = {
  __typename?: 'Supplier_news_Newspost_documents';
  fieldGroupName?: Maybe<Scalars['String']>;
  file?: Maybe<MediaItem>;
  title?: Maybe<Scalars['String']>;
};

/** The tag type */
export type Tag = DatabaseIdentifier & MenuItemLinkable & Node & TermNode & UniformResourceIdentifiable & {
  __typename?: 'Tag';
  /** Connection between the tag type and the ContentNode type */
  contentNodes?: Maybe<TagToContentNodeConnection>;
  /** The number of objects connected to the object */
  count?: Maybe<Scalars['Int']>;
  /** The unique resource identifier path */
  databaseId: Scalars['Int'];
  /** The description of the object */
  description?: Maybe<Scalars['String']>;
  /** Connection between the TermNode type and the EnqueuedScript type */
  enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>;
  /** Connection between the TermNode type and the EnqueuedStylesheet type */
  enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>;
  /** The unique resource identifier path */
  id: Scalars['ID'];
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean'];
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']>;
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean'];
  /** The link to the term */
  link?: Maybe<Scalars['String']>;
  /** The human friendly name of the object. */
  name?: Maybe<Scalars['String']>;
  /** Connection between the tag type and the post type */
  posts?: Maybe<TagToPostConnection>;
  /** An alphanumeric identifier for the object unique to its type. */
  slug?: Maybe<Scalars['String']>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of databaseId
   */
  tagId?: Maybe<Scalars['Int']>;
  /** Connection between the tag type and the Taxonomy type */
  taxonomy?: Maybe<TagToTaxonomyConnectionEdge>;
  /** The name of the taxonomy that the object is associated with */
  taxonomyName?: Maybe<Scalars['String']>;
  /** The ID of the term group that this term object belongs to */
  termGroupId?: Maybe<Scalars['Int']>;
  /** The taxonomy ID that the object is associated with */
  termTaxonomyId?: Maybe<Scalars['Int']>;
  /** The unique resource identifier path */
  uri?: Maybe<Scalars['String']>;
};


/** The tag type */
export type TagContentNodesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TagToContentNodeConnectionWhereArgs>;
};


/** The tag type */
export type TagEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The tag type */
export type TagEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The tag type */
export type TagPostsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TagToPostConnectionWhereArgs>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum TagIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI'
}

/** Connection between the tag type and the ContentNode type */
export type TagToContentNodeConnection = {
  __typename?: 'TagToContentNodeConnection';
  /** Edges for the TagToContentNodeConnection connection */
  edges?: Maybe<Array<Maybe<TagToContentNodeConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentNode>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type TagToContentNodeConnectionEdge = {
  __typename?: 'TagToContentNodeConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<ContentNode>;
};

/** Arguments for filtering the TagToContentNodeConnection connection */
export type TagToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfTagEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** Connection between the tag type and the post type */
export type TagToPostConnection = {
  __typename?: 'TagToPostConnection';
  /** Edges for the TagToPostConnection connection */
  edges?: Maybe<Array<Maybe<TagToPostConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Post>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type TagToPostConnectionEdge = {
  __typename?: 'TagToPostConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<Post>;
};

/** Arguments for filtering the TagToPostConnection connection */
export type TagToPostConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Category ID */
  categoryId?: InputMaybe<Scalars['Int']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Use Category Slug */
  categoryName?: InputMaybe<Scalars['String']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars['String']>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars['String']>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of tag slugs, used to display objects from one tag OR another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of tag slugs, used to exclude objects in specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** Connection between the tag type and the Taxonomy type */
export type TagToTaxonomyConnectionEdge = {
  __typename?: 'TagToTaxonomyConnectionEdge';
  /** The node of the connection, without the edges */
  node?: Maybe<Taxonomy>;
};

/** A taxonomy object */
export type Taxonomy = Node & {
  __typename?: 'Taxonomy';
  /** The url path of the first page of the archive page for this content type. */
  archivePath?: Maybe<Scalars['String']>;
  /** List of Content Types associated with the Taxonomy */
  connectedContentTypes?: Maybe<TaxonomyToContentTypeConnection>;
  /** Description of the taxonomy. This field is equivalent to WP_Taxonomy-&gt;description */
  description?: Maybe<Scalars['String']>;
  /** The plural name of the post type within the GraphQL Schema. */
  graphqlPluralName?: Maybe<Scalars['String']>;
  /** The singular name of the post type within the GraphQL Schema. */
  graphqlSingleName?: Maybe<Scalars['String']>;
  /** Whether the taxonomy is hierarchical */
  hierarchical?: Maybe<Scalars['Boolean']>;
  /** The globally unique identifier of the taxonomy object. */
  id: Scalars['ID'];
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']>;
  /** Name of the taxonomy shown in the menu. Usually plural. */
  label?: Maybe<Scalars['String']>;
  /** The display name of the taxonomy. This field is equivalent to WP_Taxonomy-&gt;label */
  name?: Maybe<Scalars['String']>;
  /** Whether the taxonomy is publicly queryable */
  public?: Maybe<Scalars['Boolean']>;
  /** Name of content type to diplay in REST API &quot;wp/v2&quot; namespace. */
  restBase?: Maybe<Scalars['String']>;
  /** The REST Controller class assigned to handling this content type. */
  restControllerClass?: Maybe<Scalars['String']>;
  /** Whether to show the taxonomy as part of a tag cloud widget. This field is equivalent to WP_Taxonomy-&gt;show_tagcloud */
  showCloud?: Maybe<Scalars['Boolean']>;
  /** Whether to display a column for the taxonomy on its post type listing screens. */
  showInAdminColumn?: Maybe<Scalars['Boolean']>;
  /** Whether to add the post type to the GraphQL Schema. */
  showInGraphql?: Maybe<Scalars['Boolean']>;
  /** Whether to show the taxonomy in the admin menu */
  showInMenu?: Maybe<Scalars['Boolean']>;
  /** Whether the taxonomy is available for selection in navigation menus. */
  showInNavMenus?: Maybe<Scalars['Boolean']>;
  /** Whether to show the taxonomy in the quick/bulk edit panel. */
  showInQuickEdit?: Maybe<Scalars['Boolean']>;
  /** Whether to add the post type route in the REST API &quot;wp/v2&quot; namespace. */
  showInRest?: Maybe<Scalars['Boolean']>;
  /** Whether to generate and allow a UI for managing terms in this taxonomy in the admin */
  showUi?: Maybe<Scalars['Boolean']>;
};


/** A taxonomy object */
export type TaxonomyConnectedContentTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** Allowed taxonomies */
export enum TaxonomyEnum {
  /** Taxonomy enum member_specialism */
  Areaofspecialism = 'AREAOFSPECIALISM',
  /** Taxonomy enum category */
  Category = 'CATEGORY',
  /** Taxonomy enum post_format */
  Postformat = 'POSTFORMAT',
  /** Taxonomy enum account_manager_region */
  Region = 'REGION',
  /** Taxonomy enum supplier_category */
  Suppliercategory = 'SUPPLIERCATEGORY',
  /** Taxonomy enum supplier_keywords */
  Supplierkeyword = 'SUPPLIERKEYWORD',
  /** Taxonomy enum post_tag */
  Tag = 'TAG',
  /** Taxonomy enum member_trading_time */
  Tradingtime = 'TRADINGTIME'
}

/** The Type of Identifier used to fetch a single Taxonomy node. To be used along with the "id" field. Default is "ID". */
export enum TaxonomyIdTypeEnum {
  /** The globally unique ID */
  Id = 'ID',
  /** The name of the taxonomy */
  Name = 'NAME'
}

/** Connection between the Taxonomy type and the ContentType type */
export type TaxonomyToContentTypeConnection = {
  __typename?: 'TaxonomyToContentTypeConnection';
  /** Edges for the TaxonomyToContentTypeConnection connection */
  edges?: Maybe<Array<Maybe<TaxonomyToContentTypeConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentType>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type TaxonomyToContentTypeConnectionEdge = {
  __typename?: 'TaxonomyToContentTypeConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<ContentType>;
};

/** Template Content options */
export type TemplateContent = {
  __typename?: 'TemplateContent';
  pageSlug?: Maybe<Scalars['String']>;
  pageTitle?: Maybe<Scalars['String']>;
  templateContent?: Maybe<TemplateContent_Templatecontent>;
};

/** Field Group */
export type TemplateContent_Templatecontent = {
  __typename?: 'TemplateContent_Templatecontent';
  callBackJoinUs?: Maybe<TemplateContent_Templatecontent_CallBackJoinUs>;
  callBackThankYou?: Maybe<TemplateContent_Templatecontent_CallBackThankYou>;
  /** The ID of the form */
  callbackForm?: Maybe<Scalars['String']>;
  callbackMeta?: Maybe<TemplateContent_Templatecontent_CallbackMeta>;
  contactUs?: Maybe<TemplateContent_Templatecontent_ContactUs>;
  /** The ID of the form */
  contactUsForm?: Maybe<Scalars['String']>;
  /** The ID of the form */
  contactUsFormSuppliers?: Maybe<Scalars['String']>;
  contactUsThankYou?: Maybe<TemplateContent_Templatecontent_ContactUsThankYou>;
  fieldGroupName?: Maybe<Scalars['String']>;
  joinUsAssurance?: Maybe<TemplateContent_Templatecontent_JoinUsAssurance>;
  joinUsMeta?: Maybe<TemplateContent_Templatecontent_JoinUsMeta>;
  logInMembersFeatures?: Maybe<Array<Maybe<TemplateContent_Templatecontent_LogInMembersFeatures>>>;
  logInMembersText?: Maybe<Scalars['String']>;
  logInMeta?: Maybe<TemplateContent_Templatecontent_LogInMeta>;
  logInSuppliersFeatures?: Maybe<Array<Maybe<TemplateContent_Templatecontent_LogInSuppliersFeatures>>>;
  logInSuppliersText?: Maybe<Scalars['String']>;
  logInText?: Maybe<Scalars['String']>;
  memberPortal?: Maybe<TemplateContent_Templatecontent_MemberPortal>;
  memberPortalMeta?: Maybe<TemplateContent_Templatecontent_MemberPortalMeta>;
  membersCallBackThankYou?: Maybe<TemplateContent_Templatecontent_MembersCallBackThankYou>;
  membersCallback?: Maybe<TemplateContent_Templatecontent_MembersCallback>;
  membersCallbackMeta?: Maybe<TemplateContent_Templatecontent_MembersCallbackMeta>;
  membersCallbackThankYouMeta?: Maybe<TemplateContent_Templatecontent_MembersCallbackThankYouMeta>;
  newsArticleDeleteModal?: Maybe<TemplateContent_Templatecontent_NewsArticleDeleteModal>;
  signUpJoinUs?: Maybe<TemplateContent_Templatecontent_SignUpJoinUs>;
  signUpThankYou?: Maybe<TemplateContent_Templatecontent_SignUpThankYou>;
  /** The ID of the form */
  signupForm?: Maybe<Scalars['String']>;
  supplierPortal?: Maybe<TemplateContent_Templatecontent_SupplierPortal>;
  supplierPortalAdvertise?: Maybe<TemplateContent_Templatecontent_SupplierPortalAdvertise>;
  supplierPortalMeta?: Maybe<TemplateContent_Templatecontent_SupplierPortalMeta>;
  supplierPortalText?: Maybe<Scalars['String']>;
  supplierPortalTitle?: Maybe<Scalars['String']>;
  suppliersCallBackThankYou?: Maybe<TemplateContent_Templatecontent_SuppliersCallBackThankYou>;
  suppliersCallback?: Maybe<TemplateContent_Templatecontent_SuppliersCallback>;
  suppliersCallbackMeta?: Maybe<TemplateContent_Templatecontent_SuppliersCallbackMeta>;
  suppliersCallbackThankYouMeta?: Maybe<TemplateContent_Templatecontent_SuppliersCallbackThankYouMeta>;
  thankYouCallbackMeta?: Maybe<TemplateContent_Templatecontent_ThankYouCallbackMeta>;
  thankYouContactUsMeta?: Maybe<TemplateContent_Templatecontent_ThankYouContactUsMeta>;
  thankYouSignUpMeta?: Maybe<TemplateContent_Templatecontent_ThankYouSignUpMeta>;
};

/** Field Group */
export type TemplateContent_Templatecontent_CallBackJoinUs = {
  __typename?: 'TemplateContent_Templatecontent_CallBackJoinUs';
  fieldGroupName?: Maybe<Scalars['String']>;
  pageTitle?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Field Group */
export type TemplateContent_Templatecontent_CallBackThankYou = {
  __typename?: 'TemplateContent_Templatecontent_CallBackThankYou';
  fieldGroupName?: Maybe<Scalars['String']>;
  steps?: Maybe<Array<Maybe<TemplateContent_Templatecontent_CallBackThankYou_Steps>>>;
  stepsTitle?: Maybe<Scalars['String']>;
  /** Use {{email}} to show user email in text */
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Field Group */
export type TemplateContent_Templatecontent_CallBackThankYou_Steps = {
  __typename?: 'TemplateContent_Templatecontent_CallBackThankYou_steps';
  /** &lt;em&gt;Required to link to team member&lt;/em&gt; */
  addTeamMember?: Maybe<Scalars['Boolean']>;
  fieldGroupName?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

/** Field Group */
export type TemplateContent_Templatecontent_CallbackMeta = {
  __typename?: 'TemplateContent_Templatecontent_CallbackMeta';
  description?: Maybe<Scalars['String']>;
  fieldGroupName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Field Group */
export type TemplateContent_Templatecontent_ContactUs = {
  __typename?: 'TemplateContent_Templatecontent_ContactUs';
  fieldGroupName?: Maybe<Scalars['String']>;
  meta?: Maybe<TemplateContent_Templatecontent_ContactUs_Meta>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Field Group */
export type TemplateContent_Templatecontent_ContactUsThankYou = {
  __typename?: 'TemplateContent_Templatecontent_ContactUsThankYou';
  fieldGroupName?: Maybe<Scalars['String']>;
  steps?: Maybe<Array<Maybe<TemplateContent_Templatecontent_ContactUsThankYou_Steps>>>;
  stepsTitle?: Maybe<Scalars['String']>;
  /** Use {{email}} to show user email in text */
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Field Group */
export type TemplateContent_Templatecontent_ContactUsThankYou_Steps = {
  __typename?: 'TemplateContent_Templatecontent_ContactUsThankYou_steps';
  fieldGroupName?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

/** Field Group */
export type TemplateContent_Templatecontent_ContactUs_Meta = {
  __typename?: 'TemplateContent_Templatecontent_ContactUs_Meta';
  description?: Maybe<Scalars['String']>;
  fieldGroupName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Field Group */
export type TemplateContent_Templatecontent_JoinUsAssurance = {
  __typename?: 'TemplateContent_Templatecontent_JoinUsAssurance';
  fieldGroupName?: Maybe<Scalars['String']>;
  image?: Maybe<MediaItem>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Field Group */
export type TemplateContent_Templatecontent_JoinUsMeta = {
  __typename?: 'TemplateContent_Templatecontent_JoinUsMeta';
  description?: Maybe<Scalars['String']>;
  fieldGroupName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Field Group */
export type TemplateContent_Templatecontent_LogInMeta = {
  __typename?: 'TemplateContent_Templatecontent_LogInMeta';
  description?: Maybe<Scalars['String']>;
  fieldGroupName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Field Group */
export type TemplateContent_Templatecontent_MemberPortal = {
  __typename?: 'TemplateContent_Templatecontent_MemberPortal';
  /** The ID of the form */
  callbackForm?: Maybe<Scalars['String']>;
  fieldGroupName?: Maybe<Scalars['String']>;
  support?: Maybe<TemplateContent_Templatecontent_MemberPortal_Support>;
};

/** Field Group */
export type TemplateContent_Templatecontent_MemberPortalMeta = {
  __typename?: 'TemplateContent_Templatecontent_MemberPortalMeta';
  description?: Maybe<Scalars['String']>;
  fieldGroupName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Field Group */
export type TemplateContent_Templatecontent_MemberPortal_Support = {
  __typename?: 'TemplateContent_Templatecontent_MemberPortal_Support';
  buttonLabel?: Maybe<Scalars['String']>;
  fieldGroupName?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Field Group */
export type TemplateContent_Templatecontent_MembersCallBackThankYou = {
  __typename?: 'TemplateContent_Templatecontent_MembersCallBackThankYou';
  fieldGroupName?: Maybe<Scalars['String']>;
  steps?: Maybe<Array<Maybe<TemplateContent_Templatecontent_MembersCallBackThankYou_Steps>>>;
  stepsTitle?: Maybe<Scalars['String']>;
  /** Use {{email}} to show user email in text */
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Field Group */
export type TemplateContent_Templatecontent_MembersCallBackThankYou_Steps = {
  __typename?: 'TemplateContent_Templatecontent_MembersCallBackThankYou_steps';
  /** &lt;em&gt;Required to link to team member&lt;/em&gt; */
  addTeamMember?: Maybe<Scalars['Boolean']>;
  fieldGroupName?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

/** Field Group */
export type TemplateContent_Templatecontent_MembersCallback = {
  __typename?: 'TemplateContent_Templatecontent_MembersCallback';
  fieldGroupName?: Maybe<Scalars['String']>;
  pageTitle?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Field Group */
export type TemplateContent_Templatecontent_MembersCallbackMeta = {
  __typename?: 'TemplateContent_Templatecontent_MembersCallbackMeta';
  description?: Maybe<Scalars['String']>;
  fieldGroupName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Field Group */
export type TemplateContent_Templatecontent_MembersCallbackThankYouMeta = {
  __typename?: 'TemplateContent_Templatecontent_MembersCallbackThankYouMeta';
  description?: Maybe<Scalars['String']>;
  fieldGroupName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Field Group */
export type TemplateContent_Templatecontent_NewsArticleDeleteModal = {
  __typename?: 'TemplateContent_Templatecontent_NewsArticleDeleteModal';
  fieldGroupName?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Field Group */
export type TemplateContent_Templatecontent_SignUpJoinUs = {
  __typename?: 'TemplateContent_Templatecontent_SignUpJoinUs';
  fieldGroupName?: Maybe<Scalars['String']>;
  pageTitle?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Field Group */
export type TemplateContent_Templatecontent_SignUpThankYou = {
  __typename?: 'TemplateContent_Templatecontent_SignUpThankYou';
  fieldGroupName?: Maybe<Scalars['String']>;
  steps?: Maybe<Array<Maybe<TemplateContent_Templatecontent_SignUpThankYou_Steps>>>;
  stepsTitle?: Maybe<Scalars['String']>;
  /** Use {{email}} to show user email in text */
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Field Group */
export type TemplateContent_Templatecontent_SignUpThankYou_Steps = {
  __typename?: 'TemplateContent_Templatecontent_SignUpThankYou_steps';
  fieldGroupName?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

/** Field Group */
export type TemplateContent_Templatecontent_SupplierPortal = {
  __typename?: 'TemplateContent_Templatecontent_SupplierPortal';
  /** The ID of the form */
  callbackForm?: Maybe<Scalars['String']>;
  editNewsText?: Maybe<Scalars['String']>;
  editNewsTitle?: Maybe<Scalars['String']>;
  editProfileText?: Maybe<Scalars['String']>;
  editProfileTitle?: Maybe<Scalars['String']>;
  fieldGroupName?: Maybe<Scalars['String']>;
};

/** Field Group */
export type TemplateContent_Templatecontent_SupplierPortalAdvertise = {
  __typename?: 'TemplateContent_Templatecontent_SupplierPortalAdvertise';
  buttonLabel?: Maybe<Scalars['String']>;
  fieldGroupName?: Maybe<Scalars['String']>;
  packages?: Maybe<Array<Maybe<TemplateContent_Templatecontent_SupplierPortalAdvertise_Packages>>>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Field Group */
export type TemplateContent_Templatecontent_SupplierPortalAdvertise_Packages = {
  __typename?: 'TemplateContent_Templatecontent_SupplierPortalAdvertise_packages';
  features?: Maybe<Array<Maybe<TemplateContent_Templatecontent_SupplierPortalAdvertise_Packages_Features>>>;
  fieldGroupName?: Maybe<Scalars['String']>;
  icon?: Maybe<MediaItem>;
  price?: Maybe<Scalars['String']>;
  recommended?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
};

/** Field Group */
export type TemplateContent_Templatecontent_SupplierPortalAdvertise_Packages_Features = {
  __typename?: 'TemplateContent_Templatecontent_SupplierPortalAdvertise_packages_features';
  fieldGroupName?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

/** Field Group */
export type TemplateContent_Templatecontent_SupplierPortalMeta = {
  __typename?: 'TemplateContent_Templatecontent_SupplierPortalMeta';
  description?: Maybe<Scalars['String']>;
  fieldGroupName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Field Group */
export type TemplateContent_Templatecontent_SuppliersCallBackThankYou = {
  __typename?: 'TemplateContent_Templatecontent_SuppliersCallBackThankYou';
  fieldGroupName?: Maybe<Scalars['String']>;
  steps?: Maybe<Array<Maybe<TemplateContent_Templatecontent_SuppliersCallBackThankYou_Steps>>>;
  stepsTitle?: Maybe<Scalars['String']>;
  /** Use {{email}} to show user email in text */
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Field Group */
export type TemplateContent_Templatecontent_SuppliersCallBackThankYou_Steps = {
  __typename?: 'TemplateContent_Templatecontent_SuppliersCallBackThankYou_steps';
  /** &lt;em&gt;Required to link to team member&lt;/em&gt; */
  addTeamMember?: Maybe<Scalars['Boolean']>;
  fieldGroupName?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

/** Field Group */
export type TemplateContent_Templatecontent_SuppliersCallback = {
  __typename?: 'TemplateContent_Templatecontent_SuppliersCallback';
  fieldGroupName?: Maybe<Scalars['String']>;
  pageTitle?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Field Group */
export type TemplateContent_Templatecontent_SuppliersCallbackMeta = {
  __typename?: 'TemplateContent_Templatecontent_SuppliersCallbackMeta';
  description?: Maybe<Scalars['String']>;
  fieldGroupName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Field Group */
export type TemplateContent_Templatecontent_SuppliersCallbackThankYouMeta = {
  __typename?: 'TemplateContent_Templatecontent_SuppliersCallbackThankYouMeta';
  description?: Maybe<Scalars['String']>;
  fieldGroupName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Field Group */
export type TemplateContent_Templatecontent_ThankYouCallbackMeta = {
  __typename?: 'TemplateContent_Templatecontent_ThankYouCallbackMeta';
  description?: Maybe<Scalars['String']>;
  fieldGroupName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Field Group */
export type TemplateContent_Templatecontent_ThankYouContactUsMeta = {
  __typename?: 'TemplateContent_Templatecontent_ThankYouContactUsMeta';
  description?: Maybe<Scalars['String']>;
  fieldGroupName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Field Group */
export type TemplateContent_Templatecontent_ThankYouSignUpMeta = {
  __typename?: 'TemplateContent_Templatecontent_ThankYouSignUpMeta';
  description?: Maybe<Scalars['String']>;
  fieldGroupName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Field Group */
export type TemplateContent_Templatecontent_LogInMembersFeatures = {
  __typename?: 'TemplateContent_Templatecontent_logInMembersFeatures';
  fieldGroupName?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

/** Field Group */
export type TemplateContent_Templatecontent_LogInSuppliersFeatures = {
  __typename?: 'TemplateContent_Templatecontent_logInSuppliersFeatures';
  fieldGroupName?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

/** Terms are nodes within a Taxonomy, used to group and relate other nodes. */
export type TermNode = {
  /** The number of objects connected to the object */
  count?: Maybe<Scalars['Int']>;
  /** Identifies the primary key from the database. */
  databaseId: Scalars['Int'];
  /** The description of the object */
  description?: Maybe<Scalars['String']>;
  /** Connection between the TermNode type and the EnqueuedScript type */
  enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>;
  /** Connection between the TermNode type and the EnqueuedStylesheet type */
  enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>;
  /** The unique resource identifier path */
  id: Scalars['ID'];
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean'];
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']>;
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean'];
  /** The link to the term */
  link?: Maybe<Scalars['String']>;
  /** The human friendly name of the object. */
  name?: Maybe<Scalars['String']>;
  /** An alphanumeric identifier for the object unique to its type. */
  slug?: Maybe<Scalars['String']>;
  /** The name of the taxonomy that the object is associated with */
  taxonomyName?: Maybe<Scalars['String']>;
  /** The ID of the term group that this term object belongs to */
  termGroupId?: Maybe<Scalars['Int']>;
  /** The taxonomy ID that the object is associated with */
  termTaxonomyId?: Maybe<Scalars['Int']>;
  /** The unique resource identifier path */
  uri?: Maybe<Scalars['String']>;
};


/** Terms are nodes within a Taxonomy, used to group and relate other nodes. */
export type TermNodeEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** Terms are nodes within a Taxonomy, used to group and relate other nodes. */
export type TermNodeEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** The Type of Identifier used to fetch a single resource. Default is "ID". To be used along with the "id" field. */
export enum TermNodeIdTypeEnum {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI'
}

/** Connection between the TermNode type and the EnqueuedScript type */
export type TermNodeToEnqueuedScriptConnection = {
  __typename?: 'TermNodeToEnqueuedScriptConnection';
  /** Edges for the TermNodeToEnqueuedScriptConnection connection */
  edges?: Maybe<Array<Maybe<TermNodeToEnqueuedScriptConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<EnqueuedScript>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type TermNodeToEnqueuedScriptConnectionEdge = {
  __typename?: 'TermNodeToEnqueuedScriptConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<EnqueuedScript>;
};

/** Connection between the TermNode type and the EnqueuedStylesheet type */
export type TermNodeToEnqueuedStylesheetConnection = {
  __typename?: 'TermNodeToEnqueuedStylesheetConnection';
  /** Edges for the TermNodeToEnqueuedStylesheetConnection connection */
  edges?: Maybe<Array<Maybe<TermNodeToEnqueuedStylesheetConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<EnqueuedStylesheet>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type TermNodeToEnqueuedStylesheetConnectionEdge = {
  __typename?: 'TermNodeToEnqueuedStylesheetConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<EnqueuedStylesheet>;
};

/** Options for ordering the connection by */
export enum TermObjectsConnectionOrderbyEnum {
  /** Order the connection by item count. */
  Count = 'COUNT',
  /** Order the connection by description. */
  Description = 'DESCRIPTION',
  /** Order the connection by name. */
  Name = 'NAME',
  /** Order the connection by slug. */
  Slug = 'SLUG',
  /** Order the connection by term group. */
  TermGroup = 'TERM_GROUP',
  /** Order the connection by term id. */
  TermId = 'TERM_ID',
  /** Order the connection by term order. */
  TermOrder = 'TERM_ORDER'
}

/** The testimonial type */
export type Testimonial = ContentNode & DatabaseIdentifier & MenuItemLinkable & Node & NodeWithPageAttributes & NodeWithTemplate & NodeWithTitle & UniformResourceIdentifiable & {
  __typename?: 'Testimonial';
  Meta?: Maybe<Testimonial_Meta>;
  /** Connection between the ContentNode type and the ContentType type */
  contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /** The name of the Content Type the node belongs to */
  contentTypeName: Scalars['String'];
  /** The unique resource identifier path */
  databaseId: Scalars['Int'];
  /** Post publishing date. */
  date?: Maybe<Scalars['String']>;
  /** The publishing date set in GMT. */
  dateGmt?: Maybe<Scalars['String']>;
  /** The desired slug of the post */
  desiredSlug?: Maybe<Scalars['String']>;
  /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
  editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
  /** The RSS enclosure for the object */
  enclosure?: Maybe<Scalars['String']>;
  /** Connection between the ContentNode type and the EnqueuedScript type */
  enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
  /** Connection between the ContentNode type and the EnqueuedStylesheet type */
  enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
  guid?: Maybe<Scalars['String']>;
  /** The globally unique identifier of the testimonial object. */
  id: Scalars['ID'];
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean'];
  /** Whether the object is a node in the preview state */
  isPreview?: Maybe<Scalars['Boolean']>;
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']>;
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean'];
  /** The user that most recently edited the node */
  lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
  /** The permalink of the post */
  link?: Maybe<Scalars['String']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: Maybe<Scalars['Int']>;
  /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
  modified?: Maybe<Scalars['String']>;
  /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
  modifiedGmt?: Maybe<Scalars['String']>;
  /** Connection between the testimonial type and the testimonial type */
  preview?: Maybe<TestimonialToPreviewConnectionEdge>;
  /** The database id of the preview node */
  previewRevisionDatabaseId?: Maybe<Scalars['Int']>;
  /** Whether the object is a node in the preview state */
  previewRevisionId?: Maybe<Scalars['ID']>;
  /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
  slug?: Maybe<Scalars['String']>;
  /** The current status of the object */
  status?: Maybe<Scalars['String']>;
  /** The template assigned to the node */
  template?: Maybe<ContentTemplate>;
  testimonial?: Maybe<Testimonial_Testimonial>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of the databaseId field
   */
  testimonialId: Scalars['Int'];
  /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
  title?: Maybe<Scalars['String']>;
  /** The unique resource identifier path */
  uri?: Maybe<Scalars['String']>;
};


/** The testimonial type */
export type TestimonialEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The testimonial type */
export type TestimonialEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The testimonial type */
export type TestimonialTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum TestimonialIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a resource by the URI. */
  Uri = 'URI'
}

/** Connection between the testimonial type and the testimonial type */
export type TestimonialToPreviewConnectionEdge = {
  __typename?: 'TestimonialToPreviewConnectionEdge';
  /** The node of the connection, without the edges */
  node?: Maybe<Testimonial>;
};

/** Field Group */
export type Testimonial_Meta = {
  __typename?: 'Testimonial_Meta';
  fieldGroupName?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  /** If left blank the page or post title will be used. */
  metaTitle?: Maybe<Scalars['String']>;
  ogDescription?: Maybe<Scalars['String']>;
  ogImage?: Maybe<MediaItem>;
  ogTitle?: Maybe<Scalars['String']>;
  twitterCardType?: Maybe<Scalars['String']>;
  twitterDescription?: Maybe<Scalars['String']>;
  twitterImage?: Maybe<MediaItem>;
  twitterTitle?: Maybe<Scalars['String']>;
};

/** Field Group */
export type Testimonial_Testimonial = {
  __typename?: 'Testimonial_Testimonial';
  fieldGroupName?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  strapline?: Maybe<Scalars['String']>;
  testimonialText?: Maybe<Scalars['String']>;
};

/** A Gravity Forms   field. */
export type TextAreaField = FormField & {
  __typename?: 'TextAreaField';
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Indicates whether the field uses the rich text editor interface. */
  hasRichTextEditor?: Maybe<Scalars['Boolean']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']>;
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** Specifies the maximum number of characters allowed in a text or textarea (paragraph) field. */
  maxLength?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']>;
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']>;
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** A Gravity Forms   field. */
export type TextField = FormField & {
  __typename?: 'TextField';
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** The autocomplete attribute for the field. */
  autocompleteAttribute?: Maybe<Scalars['String']>;
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Whether autocomplete should be enabled for this field. */
  hasAutocomplete?: Maybe<Scalars['Boolean']>;
  /** Whether the field has an input mask. */
  hasInputMask?: Maybe<Scalars['Boolean']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** The pattern used for the input mask. */
  inputMaskValue?: Maybe<Scalars['String']>;
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']>;
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** Determines if a text field input tag should be created with a &quot;password&quot; type. */
  isPasswordInput?: Maybe<Scalars['Boolean']>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** Specifies the maximum number of characters allowed in a text or textarea (paragraph) field. */
  maxLength?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']>;
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']>;
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** A theme object */
export type Theme = Node & {
  __typename?: 'Theme';
  /** Name of the theme author(s), could also be a company name. This field is equivalent to WP_Theme-&gt;get( &quot;Author&quot; ). */
  author?: Maybe<Scalars['String']>;
  /** URI for the author/company website. This field is equivalent to WP_Theme-&gt;get( &quot;AuthorURI&quot; ). */
  authorUri?: Maybe<Scalars['String']>;
  /** The description of the theme. This field is equivalent to WP_Theme-&gt;get( &quot;Description&quot; ). */
  description?: Maybe<Scalars['String']>;
  /** The globally unique identifier of the theme object. */
  id: Scalars['ID'];
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']>;
  /** Display name of the theme. This field is equivalent to WP_Theme-&gt;get( &quot;Name&quot; ). */
  name?: Maybe<Scalars['String']>;
  /** The URL of the screenshot for the theme. The screenshot is intended to give an overview of what the theme looks like. This field is equivalent to WP_Theme-&gt;get_screenshot(). */
  screenshot?: Maybe<Scalars['String']>;
  /** The theme slug is used to internally match themes. Theme slugs can have subdirectories like: my-theme/sub-theme. This field is equivalent to WP_Theme-&gt;get_stylesheet(). */
  slug?: Maybe<Scalars['String']>;
  /** URI for the author/company website. This field is equivalent to WP_Theme-&gt;get( &quot;Tags&quot; ). */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A URI if the theme has a website associated with it. The Theme URI is handy for directing users to a theme site for support etc. This field is equivalent to WP_Theme-&gt;get( &quot;ThemeURI&quot; ). */
  themeUri?: Maybe<Scalars['String']>;
  /** The current version of the theme. This field is equivalent to WP_Theme-&gt;get( &quot;Version&quot; ). */
  version?: Maybe<Scalars['String']>;
};

/** Theme Options options */
export type ThemeOptions = {
  __typename?: 'ThemeOptions';
  ThemeOptions?: Maybe<ThemeOptions_Themeoptions>;
  /** The active theme */
  activeTheme?: Maybe<Scalars['String']>;
  /** URL for blog listing page */
  blogPath?: Maybe<Scalars['String']>;
  /** URL for brand wrapping page */
  brandPage?: Maybe<Scalars['String']>;
  /** URL for job wrapping page */
  jobPage?: Maybe<Scalars['String']>;
  pageSlug?: Maybe<Scalars['String']>;
  pageTitle?: Maybe<Scalars['String']>;
  /** URL for person wrapping page */
  personPage?: Maybe<Scalars['String']>;
  /** Number of posts per page on lists */
  postsPerPage?: Maybe<Scalars['Int']>;
};

/** Field Group */
export type ThemeOptions_Themeoptions = {
  __typename?: 'ThemeOptions_Themeoptions';
  defaultImages?: Maybe<ThemeOptions_Themeoptions_DefaultImages>;
  facebookUrl?: Maybe<Scalars['String']>;
  fieldGroupName?: Maybe<Scalars['String']>;
  googleAnalyticsCode?: Maybe<Scalars['String']>;
  instagramHandle?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  primaryButton?: Maybe<ThemeOptions_Themeoptions_PrimaryButton>;
  twitterHandle?: Maybe<Scalars['String']>;
};

/** Field Group */
export type ThemeOptions_Themeoptions_DefaultImages = {
  __typename?: 'ThemeOptions_Themeoptions_DefaultImages';
  favicon?: Maybe<MediaItem>;
  fieldGroupName?: Maybe<Scalars['String']>;
  postFeaturedImage?: Maybe<MediaItem>;
  schemaAndShareImage?: Maybe<MediaItem>;
};

/** Field Group */
export type ThemeOptions_Themeoptions_PrimaryButton = {
  __typename?: 'ThemeOptions_Themeoptions_PrimaryButton';
  fieldGroupName?: Maybe<Scalars['String']>;
  /** &lt;small&gt;Icons can be used from the &lt;a href=&quot;https://material-ui.com/components/material-icons/&quot; target=&quot;blank&quot;&gt;Material Icons Library&lt;/a&gt; please select the icon you&#039;d like to use and enter the name here in &quot;snake_case&quot;.&lt;/small&gt; */
  icon?: Maybe<Scalars['String']>;
  iconLocation?: Maybe<Scalars['String']>;
};

/** A Gravity Forms   field. */
export type TimeField = FormField & {
  __typename?: 'TimeField';
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** An array of the available properties for each input of the time field. */
  inputs?: Maybe<Array<Maybe<TimeInputProperty>>>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']>;
  /** The placement of the labels for the subfields within the group. This setting controls all of the subfields, they cannot be set individually. They may be aligned above or below the inputs. If this property is not set, the “Sub-Label Placement” setting on the Form Settings-&gt;Form Layout page is used. If no setting is specified, the default is above inputs. */
  subLabelPlacement?: Maybe<FormFieldSubLabelPlacementEnum>;
  /** Determines how the time is displayed. */
  timeFormat?: Maybe<TimeFieldFormatEnum>;
  /** Time field value. */
  timeValues?: Maybe<TimeFieldValue>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** How the time is displayed. */
export enum TimeFieldFormatEnum {
  /** 12-hour time format. */
  H12 = 'H12',
  /** 24-hour time format. */
  H24 = 'H24'
}

/** The individual properties for each element of the Time value field. */
export type TimeFieldValue = {
  __typename?: 'TimeFieldValue';
  /** AM or PM. */
  amPm?: Maybe<AmPmEnum>;
  /** The full display value in 12-hour format. Example: &quot;08:25 am&quot;. */
  displayValue?: Maybe<Scalars['String']>;
  /** The hours, in this format: hh. */
  hours?: Maybe<Scalars['String']>;
  /** The minutes, in this format: mm. */
  minutes?: Maybe<Scalars['String']>;
};

/** Time input values. */
export type TimeInputProperty = {
  __typename?: 'TimeInputProperty';
  /** The autocomplete attribute for the field. */
  autocompleteAttribute?: Maybe<Scalars['String']>;
  /** The custom label for the input. When set, this is used in place of the label. */
  customLabel?: Maybe<Scalars['String']>;
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']>;
  /** The input ID. Input IDs follow the following naming convention: FIELDID.INPUTID (i.e. 5.1), where FIELDID is the id of the containing field and INPUTID specifies the input field. */
  id?: Maybe<Scalars['Float']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']>;
};

/** The tradingTime type */
export type TradingTime = DatabaseIdentifier & HierarchicalTermNode & MenuItemLinkable & Node & TermNode & UniformResourceIdentifiable & {
  __typename?: 'TradingTime';
  /** The ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
  ancestors?: Maybe<TradingTimeToAncestorsTradingTimeConnection>;
  /** Connection between the tradingTime type and the tradingTime type */
  children?: Maybe<TradingTimeToTradingTimeConnection>;
  /** Connection between the tradingTime type and the ContentNode type */
  contentNodes?: Maybe<TradingTimeToContentNodeConnection>;
  /** The number of objects connected to the object */
  count?: Maybe<Scalars['Int']>;
  /** The unique resource identifier path */
  databaseId: Scalars['Int'];
  /** The description of the object */
  description?: Maybe<Scalars['String']>;
  /** Connection between the TermNode type and the EnqueuedScript type */
  enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>;
  /** Connection between the TermNode type and the EnqueuedStylesheet type */
  enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>;
  /** The unique resource identifier path */
  id: Scalars['ID'];
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean'];
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']>;
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean'];
  /** The link to the term */
  link?: Maybe<Scalars['String']>;
  /** Connection between the tradingTime type and the member type */
  members?: Maybe<TradingTimeToMemberConnection>;
  /** The human friendly name of the object. */
  name?: Maybe<Scalars['String']>;
  /** Connection between the tradingTime type and the tradingTime type */
  parent?: Maybe<TradingTimeToParentTradingTimeConnectionEdge>;
  /** Database id of the parent node */
  parentDatabaseId?: Maybe<Scalars['Int']>;
  /** The globally unique identifier of the parent node. */
  parentId?: Maybe<Scalars['ID']>;
  /** An alphanumeric identifier for the object unique to its type. */
  slug?: Maybe<Scalars['String']>;
  /** Connection between the tradingTime type and the Taxonomy type */
  taxonomy?: Maybe<TradingTimeToTaxonomyConnectionEdge>;
  /** The name of the taxonomy that the object is associated with */
  taxonomyName?: Maybe<Scalars['String']>;
  /** The ID of the term group that this term object belongs to */
  termGroupId?: Maybe<Scalars['Int']>;
  /** The taxonomy ID that the object is associated with */
  termTaxonomyId?: Maybe<Scalars['Int']>;
  /**
   * The id field matches the WP_Post-&gt;ID field.
   * @deprecated Deprecated in favor of databaseId
   */
  tradingTimeId?: Maybe<Scalars['Int']>;
  /** The unique resource identifier path */
  uri?: Maybe<Scalars['String']>;
};


/** The tradingTime type */
export type TradingTimeAncestorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The tradingTime type */
export type TradingTimeChildrenArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TradingTimeToTradingTimeConnectionWhereArgs>;
};


/** The tradingTime type */
export type TradingTimeContentNodesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TradingTimeToContentNodeConnectionWhereArgs>;
};


/** The tradingTime type */
export type TradingTimeEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The tradingTime type */
export type TradingTimeEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The tradingTime type */
export type TradingTimeMembersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TradingTimeToMemberConnectionWhereArgs>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum TradingTimeIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI'
}

/** Connection between the tradingTime type and the tradingTime type */
export type TradingTimeToAncestorsTradingTimeConnection = {
  __typename?: 'TradingTimeToAncestorsTradingTimeConnection';
  /** Edges for the TradingTimeToAncestorsTradingTimeConnection connection */
  edges?: Maybe<Array<Maybe<TradingTimeToAncestorsTradingTimeConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<TradingTime>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type TradingTimeToAncestorsTradingTimeConnectionEdge = {
  __typename?: 'TradingTimeToAncestorsTradingTimeConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<TradingTime>;
};

/** Connection between the tradingTime type and the ContentNode type */
export type TradingTimeToContentNodeConnection = {
  __typename?: 'TradingTimeToContentNodeConnection';
  /** Edges for the TradingTimeToContentNodeConnection connection */
  edges?: Maybe<Array<Maybe<TradingTimeToContentNodeConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentNode>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type TradingTimeToContentNodeConnectionEdge = {
  __typename?: 'TradingTimeToContentNodeConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<ContentNode>;
};

/** Arguments for filtering the TradingTimeToContentNodeConnection connection */
export type TradingTimeToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfTradingTimeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** Connection between the tradingTime type and the member type */
export type TradingTimeToMemberConnection = {
  __typename?: 'TradingTimeToMemberConnection';
  /** Edges for the TradingTimeToMemberConnection connection */
  edges?: Maybe<Array<Maybe<TradingTimeToMemberConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Member>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type TradingTimeToMemberConnectionEdge = {
  __typename?: 'TradingTimeToMemberConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<Member>;
};

/** Arguments for filtering the TradingTimeToMemberConnection connection */
export type TradingTimeToMemberConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** Connection between the tradingTime type and the tradingTime type */
export type TradingTimeToParentTradingTimeConnectionEdge = {
  __typename?: 'TradingTimeToParentTradingTimeConnectionEdge';
  /** The node of the connection, without the edges */
  node?: Maybe<TradingTime>;
};

/** Connection between the tradingTime type and the Taxonomy type */
export type TradingTimeToTaxonomyConnectionEdge = {
  __typename?: 'TradingTimeToTaxonomyConnectionEdge';
  /** The node of the connection, without the edges */
  node?: Maybe<Taxonomy>;
};

/** Connection between the tradingTime type and the tradingTime type */
export type TradingTimeToTradingTimeConnection = {
  __typename?: 'TradingTimeToTradingTimeConnection';
  /** Edges for the TradingTimeToTradingTimeConnection connection */
  edges?: Maybe<Array<Maybe<TradingTimeToTradingTimeConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<TradingTime>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type TradingTimeToTradingTimeConnectionEdge = {
  __typename?: 'TradingTimeToTradingTimeConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<TradingTime>;
};

/** Arguments for filtering the TradingTimeToTradingTimeConnection connection */
export type TradingTimeToTradingTimeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
};

/** Any node that has a URI */
export type UniformResourceIdentifiable = {
  /** The unique resource identifier path */
  id: Scalars['ID'];
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean'];
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean'];
  /** The unique resource identifier path */
  uri?: Maybe<Scalars['String']>;
};

/** Input for the updateAccount_manager mutation */
export type UpdateAccount_ManagerInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']>;
  /** The ID of the account_manager object */
  id: Scalars['ID'];
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']>;
  /** Set connections between the account_manager and regions */
  regions?: InputMaybe<Account_ManagerRegionsInput>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** The payload for the updateAccount_manager mutation */
export type UpdateAccount_ManagerPayload = {
  __typename?: 'UpdateAccount_managerPayload';
  /** The Post object mutation type. */
  account_manager?: Maybe<Account_Manager>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Input for the updateActionMonitorAction mutation */
export type UpdateActionMonitorActionInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The content of the object */
  content?: InputMaybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']>;
  /** The ID of the ActionMonitorAction object */
  id: Scalars['ID'];
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** The payload for the updateActionMonitorAction mutation */
export type UpdateActionMonitorActionPayload = {
  __typename?: 'UpdateActionMonitorActionPayload';
  /** The Post object mutation type. */
  actionMonitorAction?: Maybe<ActionMonitorAction>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Input for the UpdateAreaOfSpecialism mutation */
export type UpdateAreaOfSpecialismInput = {
  /** The slug that the member_specialism will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The description of the member_specialism object */
  description?: InputMaybe<Scalars['String']>;
  /** The ID of the areaOfSpecialism object to update */
  id: Scalars['ID'];
  /** The name of the member_specialism object to mutate */
  name?: InputMaybe<Scalars['String']>;
  /** The ID of the member_specialism that should be set as the parent */
  parentId?: InputMaybe<Scalars['ID']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']>;
};

/** The payload for the UpdateAreaOfSpecialism mutation */
export type UpdateAreaOfSpecialismPayload = {
  __typename?: 'UpdateAreaOfSpecialismPayload';
  /** The created member_specialism */
  areaOfSpecialism?: Maybe<AreaOfSpecialism>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Input for the UpdateCategory mutation */
export type UpdateCategoryInput = {
  /** The slug that the category will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The description of the category object */
  description?: InputMaybe<Scalars['String']>;
  /** The ID of the category object to update */
  id: Scalars['ID'];
  /** The name of the category object to mutate */
  name?: InputMaybe<Scalars['String']>;
  /** The ID of the category that should be set as the parent */
  parentId?: InputMaybe<Scalars['ID']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']>;
};

/** The payload for the UpdateCategory mutation */
export type UpdateCategoryPayload = {
  __typename?: 'UpdateCategoryPayload';
  /** The created category */
  category?: Maybe<Category>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Input for the updateComment mutation */
export type UpdateCommentInput = {
  /** The approval status of the comment. */
  approved?: InputMaybe<Scalars['String']>;
  /** The name of the comment's author. */
  author?: InputMaybe<Scalars['String']>;
  /** The email of the comment's author. */
  authorEmail?: InputMaybe<Scalars['String']>;
  /** The url of the comment's author. */
  authorUrl?: InputMaybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The database ID of the post object the comment belongs to. */
  commentOn?: InputMaybe<Scalars['Int']>;
  /** Content of the comment. */
  content?: InputMaybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day ( e.g. 01/31/2017 ) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']>;
  /** The ID of the comment being updated. */
  id: Scalars['ID'];
  /** Parent comment ID of current comment. */
  parent?: InputMaybe<Scalars['ID']>;
  /** Type of comment. */
  type?: InputMaybe<Scalars['String']>;
};

/** The payload for the updateComment mutation */
export type UpdateCommentPayload = {
  __typename?: 'UpdateCommentPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The comment that was created */
  comment?: Maybe<Comment>;
  /** Whether the mutation succeeded. If the comment is not approved, the server will not return the comment to a non authenticated user, but a success message can be returned if the create succeeded, and the client can optimistically add the comment to the client cache */
  success?: Maybe<Scalars['Boolean']>;
};

/** Entry meta input fields for updating draft Gravity Forms entries. */
export type UpdateDraftEntryMetaInput = {
  /** ID of the user that submitted of the form if a logged in user submitted the form. */
  createdById?: InputMaybe<Scalars['Int']>;
  /** The UTC date the entry was created, in `Y-m-d H:i:s` format. */
  dateCreatedGmt?: InputMaybe<Scalars['String']>;
  /** Client IP of user who submitted the form. */
  ip?: InputMaybe<Scalars['String']>;
  /** Used to overwrite the sourceUrl the form was submitted from. */
  sourceUrl?: InputMaybe<Scalars['String']>;
  /** The name and version of both the browser and operating system from which the entry was submitted. */
  userAgent?: InputMaybe<Scalars['String']>;
};

/** Entry meta input fields for updating Gravity Forms entries. */
export type UpdateEntryMetaInput = {
  /** ID of the user that submitted of the form if a logged in user submitted the form. */
  createdById?: InputMaybe<Scalars['Int']>;
  /** The UTC date the entry was created, in `Y-m-d H:i:s` format. */
  dateCreatedGmt?: InputMaybe<Scalars['String']>;
  /** Client IP of user who submitted the form. */
  ip?: InputMaybe<Scalars['String']>;
  /** Whether the entry has been read. */
  isRead?: InputMaybe<Scalars['Boolean']>;
  /** Whether the entry has been starred (i.e marked with a star). */
  isStarred?: InputMaybe<Scalars['Boolean']>;
  /** Used to overwrite the sourceUrl the form was submitted from. */
  sourceUrl?: InputMaybe<Scalars['String']>;
  /** The current status of the entry. */
  status?: InputMaybe<EntryStatusEnum>;
  /** The name and version of both the browser and operating system from which the entry was submitted. */
  userAgent?: InputMaybe<Scalars['String']>;
};

/** Input for the updateGfDraftEntry mutation */
export type UpdateGfDraftEntryInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The entry meta values to update. */
  entryMeta?: InputMaybe<UpdateDraftEntryMetaInput>;
  /** The field ids and their values. */
  fieldValues?: InputMaybe<Array<InputMaybe<FormFieldValuesInput>>>;
  /** Either the global ID of the draft entry, or its resume token. */
  id: Scalars['ID'];
  /** The ID type for the draft entry. Defaults to `ID` . */
  idType?: InputMaybe<DraftEntryIdTypeEnum>;
  /** Whether the field values should be validated on submission. Defaults to false. */
  shouldValidate?: InputMaybe<Scalars['Boolean']>;
};

/** The payload for the updateGfDraftEntry mutation */
export type UpdateGfDraftEntryPayload = {
  __typename?: 'UpdateGfDraftEntryPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The draft entry after the update mutation has been applied. If a validation error occurred, the draft entry will NOT have been updated with the invalid value provided. */
  draftEntry?: Maybe<GfDraftEntry>;
  /** Field validation errors. */
  errors?: Maybe<Array<Maybe<FieldError>>>;
  /** Draft resume URL. If the &quot;Referer&quot; header is not included in the request, this will be an empty string. */
  resumeUrl?: Maybe<Scalars['String']>;
};

/** Input for the updateGfEntry mutation */
export type UpdateGfEntryInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The entry meta values to update. */
  entryMeta?: InputMaybe<UpdateEntryMetaInput>;
  /** The field ids and their values to update. */
  fieldValues?: InputMaybe<Array<InputMaybe<FormFieldValuesInput>>>;
  /** ID of the entry to update, either a global or database ID. */
  id: Scalars['ID'];
  /** Whether the field values should be validated on submission. Defaults to false. */
  shouldValidate?: InputMaybe<Scalars['Boolean']>;
};

/** The payload for the updateGfEntry mutation */
export type UpdateGfEntryPayload = {
  __typename?: 'UpdateGfEntryPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The entry that was created. */
  entry?: Maybe<GfSubmittedEntry>;
  /** Field errors. */
  errors?: Maybe<Array<Maybe<FieldError>>>;
};

/** Input for the updateGroup mutation */
export type UpdateGroupInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The content of the object */
  content?: InputMaybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']>;
  /** The ID of the group object */
  id: Scalars['ID'];
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** The payload for the updateGroup mutation */
export type UpdateGroupPayload = {
  __typename?: 'UpdateGroupPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The Post object mutation type. */
  group?: Maybe<Group>;
};

/** Input for the updateMediaItem mutation */
export type UpdateMediaItemInput = {
  /** Alternative text to display when mediaItem is not displayed */
  altText?: InputMaybe<Scalars['String']>;
  /** The userId to assign as the author of the mediaItem */
  authorId?: InputMaybe<Scalars['ID']>;
  /** The caption for the mediaItem */
  caption?: InputMaybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The comment status for the mediaItem */
  commentStatus?: InputMaybe<Scalars['String']>;
  /** The date of the mediaItem */
  date?: InputMaybe<Scalars['String']>;
  /** The date (in GMT zone) of the mediaItem */
  dateGmt?: InputMaybe<Scalars['String']>;
  /** Description of the mediaItem */
  description?: InputMaybe<Scalars['String']>;
  /** The file name of the mediaItem */
  filePath?: InputMaybe<Scalars['String']>;
  /** The file type of the mediaItem */
  fileType?: InputMaybe<MimeTypeEnum>;
  /** The ID of the mediaItem object */
  id: Scalars['ID'];
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars['ID']>;
  /** The ping status for the mediaItem */
  pingStatus?: InputMaybe<Scalars['String']>;
  /** The slug of the mediaItem */
  slug?: InputMaybe<Scalars['String']>;
  /** The status of the mediaItem */
  status?: InputMaybe<MediaItemStatusEnum>;
  /** The title of the mediaItem */
  title?: InputMaybe<Scalars['String']>;
};

/** The payload for the updateMediaItem mutation */
export type UpdateMediaItemPayload = {
  __typename?: 'UpdateMediaItemPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The MediaItem object mutation type. */
  mediaItem?: Maybe<MediaItem>;
};

/** Input for the updateMember mutation */
export type UpdateMemberInput = {
  /** Set connections between the member and areasOfSpecialism */
  areasOfSpecialism?: InputMaybe<MemberAreasOfSpecialismInput>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']>;
  /** The ID of the member object */
  id: Scalars['ID'];
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']>;
  /** Set connections between the member and tradingTimes */
  tradingTimes?: InputMaybe<MemberTradingTimesInput>;
};

/** The payload for the updateMember mutation */
export type UpdateMemberPayload = {
  __typename?: 'UpdateMemberPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The Post object mutation type. */
  member?: Maybe<Member>;
};

/** Input for the updatePage mutation */
export type UpdatePageInput = {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars['ID']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The content of the object */
  content?: InputMaybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']>;
  /** The excerpt of the object */
  excerpt?: InputMaybe<Scalars['String']>;
  /** The ID of the page object */
  id: Scalars['ID'];
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']>;
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars['ID']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** The payload for the updatePage mutation */
export type UpdatePagePayload = {
  __typename?: 'UpdatePagePayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The Post object mutation type. */
  page?: Maybe<Page>;
};

/** Input for the UpdatePostFormat mutation */
export type UpdatePostFormatInput = {
  /** The slug that the post_format will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The description of the post_format object */
  description?: InputMaybe<Scalars['String']>;
  /** The ID of the postFormat object to update */
  id: Scalars['ID'];
  /** The name of the post_format object to mutate */
  name?: InputMaybe<Scalars['String']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']>;
};

/** The payload for the UpdatePostFormat mutation */
export type UpdatePostFormatPayload = {
  __typename?: 'UpdatePostFormatPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The created post_format */
  postFormat?: Maybe<PostFormat>;
};

/** Input for the updatePost mutation */
export type UpdatePostInput = {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars['ID']>;
  /** Set connections between the post and categories */
  categories?: InputMaybe<PostCategoriesInput>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The content of the object */
  content?: InputMaybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']>;
  /** The excerpt of the object */
  excerpt?: InputMaybe<Scalars['String']>;
  /** The ID of the post object */
  id: Scalars['ID'];
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']>;
  /** The ping status for the object */
  pingStatus?: InputMaybe<Scalars['String']>;
  /** URLs that have been pinged. */
  pinged?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Set connections between the post and postFormats */
  postFormats?: InputMaybe<PostPostFormatsInput>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** Set connections between the post and tags */
  tags?: InputMaybe<PostTagsInput>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']>;
  /** URLs queued to be pinged. */
  toPing?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** The payload for the updatePost mutation */
export type UpdatePostPayload = {
  __typename?: 'UpdatePostPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The Post object mutation type. */
  post?: Maybe<Post>;
};

/** Input for the UpdateRegion mutation */
export type UpdateRegionInput = {
  /** The slug that the account_manager_region will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The description of the account_manager_region object */
  description?: InputMaybe<Scalars['String']>;
  /** The ID of the region object to update */
  id: Scalars['ID'];
  /** The name of the account_manager_region object to mutate */
  name?: InputMaybe<Scalars['String']>;
  /** The ID of the account_manager_region that should be set as the parent */
  parentId?: InputMaybe<Scalars['ID']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']>;
};

/** The payload for the UpdateRegion mutation */
export type UpdateRegionPayload = {
  __typename?: 'UpdateRegionPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The created account_manager_region */
  region?: Maybe<Region>;
};

/** Input for the updateReusableBlock mutation */
export type UpdateReusableBlockInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The content of the object */
  content?: InputMaybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']>;
  /** The ID of the ReusableBlock object */
  id: Scalars['ID'];
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** The payload for the updateReusableBlock mutation */
export type UpdateReusableBlockPayload = {
  __typename?: 'UpdateReusableBlockPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The Post object mutation type. */
  reusableBlock?: Maybe<ReusableBlock>;
};

/** Input for the updateReview mutation */
export type UpdateReviewInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The content of the object */
  content?: InputMaybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']>;
  /** The ID of the review object */
  id: Scalars['ID'];
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** The payload for the updateReview mutation */
export type UpdateReviewPayload = {
  __typename?: 'UpdateReviewPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The Post object mutation type. */
  review?: Maybe<Review>;
};

/** Input for the updateSettings mutation */
export type UpdateSettingsInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Allow people to submit comments on new posts. */
  discussionSettingsDefaultCommentStatus?: InputMaybe<Scalars['String']>;
  /** Allow link notifications from other blogs (pingbacks and trackbacks) on new articles. */
  discussionSettingsDefaultPingStatus?: InputMaybe<Scalars['String']>;
  /** A date format for all date strings. */
  generalSettingsDateFormat?: InputMaybe<Scalars['String']>;
  /** Site tagline. */
  generalSettingsDescription?: InputMaybe<Scalars['String']>;
  /** This address is used for admin purposes, like new user notification. */
  generalSettingsEmail?: InputMaybe<Scalars['String']>;
  /** WordPress locale code. */
  generalSettingsLanguage?: InputMaybe<Scalars['String']>;
  /** A day number of the week that the week should start on. */
  generalSettingsStartOfWeek?: InputMaybe<Scalars['Int']>;
  /** A time format for all time strings. */
  generalSettingsTimeFormat?: InputMaybe<Scalars['String']>;
  /** A city in the same timezone as you. */
  generalSettingsTimezone?: InputMaybe<Scalars['String']>;
  /** Site title. */
  generalSettingsTitle?: InputMaybe<Scalars['String']>;
  /** Site URL. */
  generalSettingsUrl?: InputMaybe<Scalars['String']>;
  /** Blog pages show at most. */
  readingSettingsPostsPerPage?: InputMaybe<Scalars['Int']>;
  /** Default post category. */
  writingSettingsDefaultCategory?: InputMaybe<Scalars['Int']>;
  /** Default post format. */
  writingSettingsDefaultPostFormat?: InputMaybe<Scalars['String']>;
  /** Convert emoticons like :-) and :-P to graphics on display. */
  writingSettingsUseSmilies?: InputMaybe<Scalars['Boolean']>;
};

/** The payload for the updateSettings mutation */
export type UpdateSettingsPayload = {
  __typename?: 'UpdateSettingsPayload';
  /** Update all settings. */
  allSettings?: Maybe<Settings>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Update the DiscussionSettings setting. */
  discussionSettings?: Maybe<DiscussionSettings>;
  /** Update the GeneralSettings setting. */
  generalSettings?: Maybe<GeneralSettings>;
  /** Update the ReadingSettings setting. */
  readingSettings?: Maybe<ReadingSettings>;
  /** Update the WritingSettings setting. */
  writingSettings?: Maybe<WritingSettings>;
};

/** Input for the UpdateSupplierCategory mutation */
export type UpdateSupplierCategoryInput = {
  /** The slug that the supplier_category will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The description of the supplier_category object */
  description?: InputMaybe<Scalars['String']>;
  /** The ID of the supplierCategory object to update */
  id: Scalars['ID'];
  /** The name of the supplier_category object to mutate */
  name?: InputMaybe<Scalars['String']>;
  /** The ID of the supplier_category that should be set as the parent */
  parentId?: InputMaybe<Scalars['ID']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']>;
};

/** The payload for the UpdateSupplierCategory mutation */
export type UpdateSupplierCategoryPayload = {
  __typename?: 'UpdateSupplierCategoryPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The created supplier_category */
  supplierCategory?: Maybe<SupplierCategory>;
};

/** Input for the updateSupplier mutation */
export type UpdateSupplierInput = {
  /** Supplier business category */
  businessCategory?: InputMaybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']>;
  /** Supplier description */
  description?: InputMaybe<Scalars['String']>;
  /** Supplier documents */
  documents?: InputMaybe<Scalars['String']>;
  /** Supplier News document titles */
  documentsTitles?: InputMaybe<Scalars['String']>;
  /** Supplier email */
  email?: InputMaybe<Scalars['String']>;
  /** Supplier hero banner */
  heroBanner?: InputMaybe<Scalars['String']>;
  /** The ID of the supplier object */
  id: Scalars['ID'];
  /** Supplier introduction */
  introduction?: InputMaybe<Scalars['String']>;
  /** Supplier member benefits */
  memberBenefits?: InputMaybe<Scalars['String']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']>;
  /** Supplier profile picture */
  profilePicture?: InputMaybe<Scalars['String']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** Supplier sub business category */
  subBusinessCategory?: InputMaybe<Scalars['String']>;
  /** Set connections between the supplier and supplierCategories */
  supplierCategories?: InputMaybe<SupplierSupplierCategoriesInput>;
  /** Set connections between the supplier and supplierKeywords */
  supplierKeywords?: InputMaybe<SupplierSupplierKeywordsInput>;
  /** Supplier terms and conditions */
  termsAndConditions?: InputMaybe<Scalars['String']>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']>;
  /** Supplier video url */
  videoUrl?: InputMaybe<Scalars['String']>;
};

/** Input for the UpdateSupplierKeyword mutation */
export type UpdateSupplierKeywordInput = {
  /** The slug that the supplier_keywords will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The description of the supplier_keywords object */
  description?: InputMaybe<Scalars['String']>;
  /** The ID of the supplierKeyword object to update */
  id: Scalars['ID'];
  /** The name of the supplier_keywords object to mutate */
  name?: InputMaybe<Scalars['String']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']>;
};

/** The payload for the UpdateSupplierKeyword mutation */
export type UpdateSupplierKeywordPayload = {
  __typename?: 'UpdateSupplierKeywordPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The created supplier_keywords */
  supplierKeyword?: Maybe<SupplierKeyword>;
};

/** The payload for the updateSupplier mutation */
export type UpdateSupplierPayload = {
  __typename?: 'UpdateSupplierPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The Post object mutation type. */
  supplier?: Maybe<Supplier>;
};

/** Input for the updateSupplier_news mutation */
export type UpdateSupplier_NewsInput = {
  /** Supplier News approval status */
  approvalStatus?: InputMaybe<Scalars['Boolean']>;
  /** Supplier News category */
  category?: InputMaybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']>;
  /** Supplier News documents */
  documents?: InputMaybe<Scalars['String']>;
  /** Supplier News document titles */
  documentsTitles?: InputMaybe<Scalars['String']>;
  /** Supplier News expiry date */
  expiryDate?: InputMaybe<Scalars['String']>;
  /** Supplier News header url link */
  headerUrlLink?: InputMaybe<Scalars['String']>;
  /** The ID of the supplier_news object */
  id: Scalars['ID'];
  /** Supplier News image */
  image?: InputMaybe<Scalars['String']>;
  /** Supplier News review status */
  inReview?: InputMaybe<Scalars['Boolean']>;
  /** Supplier News linked supplier */
  linkedSupplier?: InputMaybe<Scalars['ID']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']>;
  /** Supplier News post content */
  postContent?: InputMaybe<Scalars['String']>;
  /** Supplier News published status */
  publishedStatus?: InputMaybe<Scalars['Boolean']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']>;
  /** Supplier News video url */
  videoUrl?: InputMaybe<Scalars['String']>;
};

/** The payload for the updateSupplier_news mutation */
export type UpdateSupplier_NewsPayload = {
  __typename?: 'UpdateSupplier_newsPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The Post object mutation type. */
  supplier_news?: Maybe<Supplier_News>;
};

/** Input for the UpdateTag mutation */
export type UpdateTagInput = {
  /** The slug that the post_tag will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The description of the post_tag object */
  description?: InputMaybe<Scalars['String']>;
  /** The ID of the tag object to update */
  id: Scalars['ID'];
  /** The name of the post_tag object to mutate */
  name?: InputMaybe<Scalars['String']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']>;
};

/** The payload for the UpdateTag mutation */
export type UpdateTagPayload = {
  __typename?: 'UpdateTagPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The created post_tag */
  tag?: Maybe<Tag>;
};

/** Input for the updateTestimonial mutation */
export type UpdateTestimonialInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']>;
  /** The ID of the testimonial object */
  id: Scalars['ID'];
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** The payload for the updateTestimonial mutation */
export type UpdateTestimonialPayload = {
  __typename?: 'UpdateTestimonialPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The Post object mutation type. */
  testimonial?: Maybe<Testimonial>;
};

/** Input for the UpdateTradingTime mutation */
export type UpdateTradingTimeInput = {
  /** The slug that the member_trading_time will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The description of the member_trading_time object */
  description?: InputMaybe<Scalars['String']>;
  /** The ID of the tradingTime object to update */
  id: Scalars['ID'];
  /** The name of the member_trading_time object to mutate */
  name?: InputMaybe<Scalars['String']>;
  /** The ID of the member_trading_time that should be set as the parent */
  parentId?: InputMaybe<Scalars['ID']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']>;
};

/** The payload for the UpdateTradingTime mutation */
export type UpdateTradingTimePayload = {
  __typename?: 'UpdateTradingTimePayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The created member_trading_time */
  tradingTime?: Maybe<TradingTime>;
};

/** Input for the updateUser mutation */
export type UpdateUserInput = {
  /** User's AOL IM account. */
  aim?: InputMaybe<Scalars['String']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** A string containing content about the user. */
  description?: InputMaybe<Scalars['String']>;
  /** A string that will be shown on the site. Defaults to user's username. It is likely that you will want to change this, for both appearance and security through obscurity (that is if you dont use and delete the default admin user). */
  displayName?: InputMaybe<Scalars['String']>;
  /** A string containing the user's email address. */
  email?: InputMaybe<Scalars['String']>;
  /** 	The user's first name. */
  firstName?: InputMaybe<Scalars['String']>;
  /** The ID of the user */
  id: Scalars['ID'];
  /** User's Jabber account. */
  jabber?: InputMaybe<Scalars['String']>;
  /** The user's last name. */
  lastName?: InputMaybe<Scalars['String']>;
  /** User's locale. */
  locale?: InputMaybe<Scalars['String']>;
  /** A string that contains a URL-friendly name for the user. The default is the user's username. */
  nicename?: InputMaybe<Scalars['String']>;
  /** The user's nickname, defaults to the user's username. */
  nickname?: InputMaybe<Scalars['String']>;
  /** A string that contains the plain text password for the user. */
  password?: InputMaybe<Scalars['String']>;
  /** If true, this will refresh the users JWT secret. */
  refreshJwtUserSecret?: InputMaybe<Scalars['Boolean']>;
  /** The date the user registered. Format is Y-m-d H:i:s. */
  registered?: InputMaybe<Scalars['String']>;
  /** If true, this will revoke the users JWT secret. If false, this will unrevoke the JWT secret AND issue a new one. To revoke, the user must have proper capabilities to edit users JWT secrets. */
  revokeJwtUserSecret?: InputMaybe<Scalars['Boolean']>;
  /** A string for whether to enable the rich editor or not. False if not empty. */
  richEditing?: InputMaybe<Scalars['String']>;
  /** An array of roles to be assigned to the user. */
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** A string containing the user's URL for the user's web site. */
  websiteUrl?: InputMaybe<Scalars['String']>;
  /** User's Yahoo IM account. */
  yim?: InputMaybe<Scalars['String']>;
};

/** The payload for the updateUser mutation */
export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The User object mutation type. */
  user?: Maybe<User>;
};

/** A User object */
export type User = Commenter & DatabaseIdentifier & Node & UniformResourceIdentifiable & {
  __typename?: 'User';
  /** Avatar object for user. The avatar object can be retrieved in different sizes by specifying the size argument. */
  avatar?: Maybe<Avatar>;
  /** Connection between the User type and the BlockEditorPreview type */
  blockEditorPreviews?: Maybe<UserToBlockEditorPreviewConnection>;
  /** User metadata option name. Usually it will be &quot;wp_capabilities&quot;. */
  capKey?: Maybe<Scalars['String']>;
  /** A list of capabilities (permissions) granted to the user */
  capabilities?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Connection between the User type and the Comment type */
  comments?: Maybe<UserToCommentConnection>;
  /** Identifies the primary key from the database. */
  databaseId: Scalars['Int'];
  /** Description of the user. */
  description?: Maybe<Scalars['String']>;
  /** Email address of the user. This is equivalent to the WP_User-&gt;user_email property. */
  email?: Maybe<Scalars['String']>;
  /** Connection between the User type and the EnqueuedScript type */
  enqueuedScripts?: Maybe<UserToEnqueuedScriptConnection>;
  /** Connection between the User type and the EnqueuedStylesheet type */
  enqueuedStylesheets?: Maybe<UserToEnqueuedStylesheetConnection>;
  /** A complete list of capabilities including capabilities inherited from a role. This is equivalent to the array keys of WP_User-&gt;allcaps. */
  extraCapabilities?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** First name of the user. This is equivalent to the WP_User-&gt;user_first_name property. */
  firstName?: Maybe<Scalars['String']>;
  /** The globally unique identifier for the user object. */
  id: Scalars['ID'];
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean'];
  /** Whether the JWT User secret has been revoked. If the secret has been revoked, auth tokens will not be issued until an admin, or user with proper capabilities re-issues a secret for the user. */
  isJwtAuthSecretRevoked: Scalars['Boolean'];
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']>;
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean'];
  /** The expiration for the JWT Token for the user. If not set custom for the user, it will use the default sitewide expiration setting */
  jwtAuthExpiration?: Maybe<Scalars['String']>;
  /** A JWT token that can be used in future requests for authentication/authorization */
  jwtAuthToken?: Maybe<Scalars['String']>;
  /** A JWT token that can be used in future requests to get a refreshed jwtAuthToken. If the refresh token used in a request is revoked or otherwise invalid, a valid Auth token will NOT be issued in the response headers. */
  jwtRefreshToken?: Maybe<Scalars['String']>;
  /** A unique secret tied to the users JWT token that can be revoked or refreshed. Revoking the secret prevents JWT tokens from being issued to the user. Refreshing the token invalidates previously issued tokens, but allows new tokens to be issued. */
  jwtUserSecret?: Maybe<Scalars['String']>;
  /** Last name of the user. This is equivalent to the WP_User-&gt;user_last_name property. */
  lastName?: Maybe<Scalars['String']>;
  /** The preferred language locale set for the user. Value derived from get_user_locale(). */
  locale?: Maybe<Scalars['String']>;
  /** Connection between the User type and the mediaItem type */
  mediaItems?: Maybe<UserToMediaItemConnection>;
  /** Display name of the user. This is equivalent to the WP_User-&gt;dispaly_name property. */
  name?: Maybe<Scalars['String']>;
  /** The nicename for the user. This field is equivalent to WP_User-&gt;user_nicename */
  nicename?: Maybe<Scalars['String']>;
  /** Nickname of the user. */
  nickname?: Maybe<Scalars['String']>;
  /** Connection between the User type and the page type */
  pages?: Maybe<UserToPageConnection>;
  /** Connection between the User type and the post type */
  posts?: Maybe<UserToPostConnection>;
  /** The date the user registered or was created. The field follows a full ISO8601 date string format. */
  registeredDate?: Maybe<Scalars['String']>;
  /** Connection between the User and Revisions authored by the user */
  revisions?: Maybe<UserToContentRevisionUnionConnection>;
  /** Connection between the User type and the UserRole type */
  roles?: Maybe<UserToUserRoleConnection>;
  /** The slug for the user. This field is equivalent to WP_User-&gt;user_nicename */
  slug?: Maybe<Scalars['String']>;
  /** The unique resource identifier path */
  uri?: Maybe<Scalars['String']>;
  /** A website url that is associated with the user. */
  url?: Maybe<Scalars['String']>;
  /** Added to the GraphQL Schema because the ACF Field Group &quot;User Group&quot; was assigned to Users edit or register form */
  userGroup?: Maybe<User_Usergroup>;
  /**
   * The Id of the user. Equivalent to WP_User-&gt;ID
   * @deprecated Deprecated in favor of the databaseId field
   */
  userId?: Maybe<Scalars['Int']>;
  /** Username for the user. This field is equivalent to WP_User-&gt;user_login. */
  username?: Maybe<Scalars['String']>;
};


/** A User object */
export type UserAvatarArgs = {
  forceDefault?: InputMaybe<Scalars['Boolean']>;
  rating?: InputMaybe<AvatarRatingEnum>;
  size?: InputMaybe<Scalars['Int']>;
};


/** A User object */
export type UserBlockEditorPreviewsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserToBlockEditorPreviewConnectionWhereArgs>;
};


/** A User object */
export type UserCommentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserToCommentConnectionWhereArgs>;
};


/** A User object */
export type UserEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** A User object */
export type UserEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** A User object */
export type UserMediaItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserToMediaItemConnectionWhereArgs>;
};


/** A User object */
export type UserPagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserToPageConnectionWhereArgs>;
};


/** A User object */
export type UserPostsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserToPostConnectionWhereArgs>;
};


/** A User object */
export type UserRevisionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserToContentRevisionUnionConnectionWhereArgs>;
};


/** A User object */
export type UserRolesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** The Type of Identifier used to fetch a single User node. To be used along with the "id" field. Default is "ID". */
export enum UserNodeIdTypeEnum {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The Email of the User */
  Email = 'EMAIL',
  /** The hashed Global ID */
  Id = 'ID',
  /** The slug of the User */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
  /** The username the User uses to login with */
  Username = 'USERNAME'
}

/** A user role object */
export type UserRole = Node & {
  __typename?: 'UserRole';
  /** The capabilities that belong to this role */
  capabilities?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The display name of the role */
  displayName?: Maybe<Scalars['String']>;
  /** The globally unique identifier for the user role object. */
  id: Scalars['ID'];
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']>;
  /** The registered name of the role */
  name?: Maybe<Scalars['String']>;
};

/** Names of available user roles */
export enum UserRoleEnum {
  /** User role with specific capabilities */
  Administrator = 'ADMINISTRATOR',
  /** User role with specific capabilities */
  Author = 'AUTHOR',
  /** User role with specific capabilities */
  Contributor = 'CONTRIBUTOR',
  /** User role with specific capabilities */
  Editor = 'EDITOR',
  /** User role with specific capabilities */
  Member = 'MEMBER',
  /** User role with specific capabilities */
  Subscriber = 'SUBSCRIBER',
  /** User role with specific capabilities */
  Supplier = 'SUPPLIER'
}

/** Connection between the User type and the BlockEditorPreview type */
export type UserToBlockEditorPreviewConnection = {
  __typename?: 'UserToBlockEditorPreviewConnection';
  /** Edges for the UserToBlockEditorPreviewConnection connection */
  edges?: Maybe<Array<Maybe<UserToBlockEditorPreviewConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<BlockEditorPreview>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type UserToBlockEditorPreviewConnectionEdge = {
  __typename?: 'UserToBlockEditorPreviewConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<BlockEditorPreview>;
};

/** Arguments for filtering the UserToBlockEditorPreviewConnection connection */
export type UserToBlockEditorPreviewConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** Connection between the User type and the Comment type */
export type UserToCommentConnection = {
  __typename?: 'UserToCommentConnection';
  /** Edges for the UserToCommentConnection connection */
  edges?: Maybe<Array<Maybe<UserToCommentConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Comment>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type UserToCommentConnectionEdge = {
  __typename?: 'UserToCommentConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<Comment>;
};

/** Arguments for filtering the UserToCommentConnection connection */
export type UserToCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail?: InputMaybe<Scalars['String']>;
  /** Array of author IDs to include comments for. */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Comment author URL. */
  authorUrl?: InputMaybe<Scalars['String']>;
  /** Array of comment IDs to include. */
  commentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Include comments of a given type. */
  commentType?: InputMaybe<Scalars['String']>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: InputMaybe<Scalars['String']>;
  /** Content object author ID to limit results by. */
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: InputMaybe<Scalars['ID']>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Content object name to retrieve affiliated comments for. */
  contentName?: InputMaybe<Scalars['String']>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: InputMaybe<Scalars['Int']>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: InputMaybe<Scalars['Int']>;
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: InputMaybe<Scalars['Int']>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: InputMaybe<Scalars['String']>;
  /** Comment status to limit results by. */
  status?: InputMaybe<Scalars['String']>;
  /** Include comments for a specific user ID. */
  userId?: InputMaybe<Scalars['ID']>;
};

/** Connection between the User type and the ContentRevisionUnion type */
export type UserToContentRevisionUnionConnection = {
  __typename?: 'UserToContentRevisionUnionConnection';
  /** Edges for the UserToContentRevisionUnionConnection connection */
  edges?: Maybe<Array<Maybe<UserToContentRevisionUnionConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<ContentRevisionUnion>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type UserToContentRevisionUnionConnectionEdge = {
  __typename?: 'UserToContentRevisionUnionConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<ContentRevisionUnion>;
};

/** Arguments for filtering the UserToContentRevisionUnionConnection connection */
export type UserToContentRevisionUnionConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** Connection between the User type and the EnqueuedScript type */
export type UserToEnqueuedScriptConnection = {
  __typename?: 'UserToEnqueuedScriptConnection';
  /** Edges for the UserToEnqueuedScriptConnection connection */
  edges?: Maybe<Array<Maybe<UserToEnqueuedScriptConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<EnqueuedScript>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type UserToEnqueuedScriptConnectionEdge = {
  __typename?: 'UserToEnqueuedScriptConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<EnqueuedScript>;
};

/** Connection between the User type and the EnqueuedStylesheet type */
export type UserToEnqueuedStylesheetConnection = {
  __typename?: 'UserToEnqueuedStylesheetConnection';
  /** Edges for the UserToEnqueuedStylesheetConnection connection */
  edges?: Maybe<Array<Maybe<UserToEnqueuedStylesheetConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<EnqueuedStylesheet>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type UserToEnqueuedStylesheetConnectionEdge = {
  __typename?: 'UserToEnqueuedStylesheetConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<EnqueuedStylesheet>;
};

/** Connection between the User type and the mediaItem type */
export type UserToMediaItemConnection = {
  __typename?: 'UserToMediaItemConnection';
  /** Edges for the UserToMediaItemConnection connection */
  edges?: Maybe<Array<Maybe<UserToMediaItemConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<MediaItem>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type UserToMediaItemConnectionEdge = {
  __typename?: 'UserToMediaItemConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<MediaItem>;
};

/** Arguments for filtering the UserToMediaItemConnection connection */
export type UserToMediaItemConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** Connection between the User type and the page type */
export type UserToPageConnection = {
  __typename?: 'UserToPageConnection';
  /** Edges for the UserToPageConnection connection */
  edges?: Maybe<Array<Maybe<UserToPageConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Page>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type UserToPageConnectionEdge = {
  __typename?: 'UserToPageConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<Page>;
};

/** Arguments for filtering the UserToPageConnection connection */
export type UserToPageConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** Connection between the User type and the post type */
export type UserToPostConnection = {
  __typename?: 'UserToPostConnection';
  /** Edges for the UserToPostConnection connection */
  edges?: Maybe<Array<Maybe<UserToPostConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<Post>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type UserToPostConnectionEdge = {
  __typename?: 'UserToPostConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<Post>;
};

/** Arguments for filtering the UserToPostConnection connection */
export type UserToPostConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Category ID */
  categoryId?: InputMaybe<Scalars['Int']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Use Category Slug */
  categoryName?: InputMaybe<Scalars['String']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars['String']>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars['String']>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Array of tag slugs, used to display objects from one tag OR another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Array of tag slugs, used to exclude objects in specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']>;
};

/** Connection between the User type and the UserRole type */
export type UserToUserRoleConnection = {
  __typename?: 'UserToUserRoleConnection';
  /** Edges for the UserToUserRoleConnection connection */
  edges?: Maybe<Array<Maybe<UserToUserRoleConnectionEdge>>>;
  /** The nodes of the connection, without the edges */
  nodes?: Maybe<Array<Maybe<UserRole>>>;
  /** Information about pagination in a connection. */
  pageInfo?: Maybe<WpPageInfo>;
};

/** An edge in a connection */
export type UserToUserRoleConnectionEdge = {
  __typename?: 'UserToUserRoleConnectionEdge';
  /** A cursor for use in pagination */
  cursor?: Maybe<Scalars['String']>;
  /** The item at the end of the edge */
  node?: Maybe<UserRole>;
};

/** Field Group */
export type User_Usergroup = {
  __typename?: 'User_Usergroup';
  fieldGroupName?: Maybe<Scalars['String']>;
  /** Groups can be created using the Groups option in the main menu and assigned below to users. This is a required field. */
  group?: Maybe<Scalars['String']>;
  /** Only valid if group is members */
  linkedMember?: Maybe<Array<Maybe<User_Usergroup_LinkedMember>>>;
  /** Only valid if group is suppliers */
  linkedSupplier?: Maybe<Array<Maybe<User_Usergroup_LinkedSupplier>>>;
};

export type User_Usergroup_LinkedMember = Member;

export type User_Usergroup_LinkedSupplier = Supplier;

/** Field to order the connection by */
export enum UsersConnectionOrderbyEnum {
  /** Order by display name */
  DisplayName = 'DISPLAY_NAME',
  /** Order by email address */
  Email = 'EMAIL',
  /** Order by login */
  Login = 'LOGIN',
  /** Preserve the login order given in the LOGIN_IN array */
  LoginIn = 'LOGIN_IN',
  /** Order by nice name */
  NiceName = 'NICE_NAME',
  /** Preserve the nice name order given in the NICE_NAME_IN array */
  NiceNameIn = 'NICE_NAME_IN',
  /** Order by registration date */
  Registered = 'REGISTERED',
  /** Order by URL */
  Url = 'URL'
}

/** Options for ordering the connection */
export type UsersConnectionOrderbyInput = {
  /** The field name used to sort the results. */
  field: UsersConnectionOrderbyEnum;
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>;
};

/** Column used for searching for users. */
export enum UsersConnectionSearchColumnEnum {
  /** The user's email address. */
  Email = 'EMAIL',
  /** The globally unique ID. */
  Id = 'ID',
  /** The username the User uses to login with. */
  Login = 'LOGIN',
  /** A URL-friendly name for the user. The default is the user's username. */
  Nicename = 'NICENAME',
  /** The URL of the user\s website. */
  Url = 'URL'
}

/** Information needed by gatsby-source-wordpress. */
export type WpGatsby = {
  __typename?: 'WPGatsby';
  /** Returns wether or not pretty permalinks are enabled. */
  arePrettyPermalinksEnabled?: Maybe<Scalars['Boolean']>;
  /** The current status of a Gatsby Preview. */
  gatsbyPreviewStatus?: Maybe<WpGatsbyPreviewStatus>;
  /** Wether or not the Preview frontend URL is online. */
  isPreviewFrontendOnline?: Maybe<Scalars['Boolean']>;
};


/** Information needed by gatsby-source-wordpress. */
export type WpGatsbyGatsbyPreviewStatusArgs = {
  nodeId: Scalars['Float'];
};

/** Check compatibility with a given version of gatsby-source-wordpress and the WordPress source site. */
export type WpGatsbyCompatibility = {
  __typename?: 'WPGatsbyCompatibility';
  satisfies?: Maybe<WpGatsbySatisfies>;
};

/** A previewed Gatsby page node. */
export type WpGatsbyPageNode = {
  __typename?: 'WPGatsbyPageNode';
  path?: Maybe<Scalars['String']>;
};

/** Check compatibility with a given version of gatsby-source-wordpress and the WordPress source site. */
export type WpGatsbyPreviewStatus = {
  __typename?: 'WPGatsbyPreviewStatus';
  modifiedLocal?: Maybe<Scalars['String']>;
  modifiedRemote?: Maybe<Scalars['String']>;
  pageNode?: Maybe<WpGatsbyPageNode>;
  remoteStatus?: Maybe<WpGatsbyRemotePreviewStatusEnum>;
  statusContext?: Maybe<Scalars['String']>;
  statusType?: Maybe<WpGatsbyWpPreviewedNodeStatus>;
};

/** The different statuses a Gatsby Preview can be in for a single node. */
export enum WpGatsbyRemotePreviewStatusEnum {
  GatsbyPreviewProcessError = 'GATSBY_PREVIEW_PROCESS_ERROR',
  NoPageCreatedForPreviewedNode = 'NO_PAGE_CREATED_FOR_PREVIEWED_NODE',
  PreviewSuccess = 'PREVIEW_SUCCESS',
  ReceivedPreviewDataFromWrongUrl = 'RECEIVED_PREVIEW_DATA_FROM_WRONG_URL'
}

/** Check compatibility with WPGatsby and WPGraphQL. */
export type WpGatsbySatisfies = {
  __typename?: 'WPGatsbySatisfies';
  /** Whether the provided version range requirement for WPGraphQL is met by this WP instance. */
  wpGQL?: Maybe<Scalars['Boolean']>;
  /** Whether the provided version range requirement for WPGatsby is met by this WP instance. */
  wpGatsby?: Maybe<Scalars['Boolean']>;
};

/** The different statuses a Gatsby Preview can be in for a single node. */
export enum WpGatsbyWpPreviewedNodeStatus {
  NoNodeFound = 'NO_NODE_FOUND',
  NoPreviewPathFound = 'NO_PREVIEW_PATH_FOUND',
  PreviewPageUpdatedButNotYetDeployed = 'PREVIEW_PAGE_UPDATED_BUT_NOT_YET_DEPLOYED',
  PreviewReady = 'PREVIEW_READY',
  ReceivedPreviewDataFromWrongUrl = 'RECEIVED_PREVIEW_DATA_FROM_WRONG_URL',
  RemoteNodeNotYetUpdated = 'REMOTE_NODE_NOT_YET_UPDATED'
}

/** Information about pagination in a connection. */
export type WpPageInfo = {
  __typename?: 'WPPageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

/** A Gravity Forms   field. */
export type WebsiteField = FormField & {
  __typename?: 'WebsiteField';
  /** When specified, the value of this property will be used on the admin pages instead of the label. It is useful for fields with long labels. */
  adminLabel?: Maybe<Scalars['String']>;
  /** Determines if the field’s value can be pre-populated dynamically. */
  canPrepopulate?: Maybe<Scalars['Boolean']>;
  /** Controls the visibility of the field based on values selected by the user. */
  conditionalLogic?: Maybe<ConditionalLogic>;
  /** String containing the custom CSS classes to be added to the &lt;li&gt; tag that contains the field. Useful for applying custom formatting to specific fields. */
  cssClass?: Maybe<Scalars['String']>;
  /** Contains the default value for the field. When specified, the field&#039;s value will be populated with the contents of this property when the form is displayed. */
  defaultValue?: Maybe<Scalars['String']>;
  /** Field description. */
  description?: Maybe<Scalars['String']>;
  /** The placement of the field description. */
  descriptionPlacement?: Maybe<FormFieldDescriptionPlacementEnum>;
  /** Indicates the field is only displayed and its contents are not submitted with the form/saved with the entry. This is set to true. */
  displayOnly?: Maybe<Scalars['Boolean']>;
  /** Contains the message that is displayed for fields that fail validation. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Field ID. */
  id: Scalars['Int'];
  /** Assigns a name to this field so that it can be populated dynamically via this input name. Only applicable when canPrepopulate is `true`. */
  inputName?: Maybe<Scalars['String']>;
  /** The base form field type used to display the input. A good example is the Post Custom Field that can be displayed as various different types of fields. */
  inputType?: Maybe<FormFieldTypeEnum>;
  /** Determines if the field requires the user to enter a value. Fields marked as required will prevent the form from being submitted if the user has not entered a value in it. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Field label that will be displayed on the form and on the admin pages. */
  label?: Maybe<Scalars['String']>;
  /** The field label position. */
  labelPlacement?: Maybe<FormFieldLabelPlacementEnum>;
  /** The number of CSS grid columns the field should span. */
  layoutGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The number of CSS grid columns the spacer field following this one should span. */
  layoutSpacerGridColumnSpan?: Maybe<Scalars['Int']>;
  /** The form page this field is located on. Default is 1. */
  pageNumber?: Maybe<Scalars['Int']>;
  /** The form field-specifc policies for exporting and erasing personal data. */
  personalData?: Maybe<FormFieldDataPolicy>;
  /** Placeholder text to give the user a hint on how to fill out the field. This is not submitted with the form. */
  placeholder?: Maybe<Scalars['String']>;
  /** Determines if the field allows duplicate submissions. */
  shouldAllowDuplicates?: Maybe<Scalars['Boolean']>;
  /** Determines the size of the field when displayed on the page. */
  size?: Maybe<FormFieldSizeEnum>;
  /** The type of field to be displayed. */
  type?: Maybe<FormFieldTypeEnum>;
  /** The string-formatted entry value for the `formField`. For complex fields this might be a JSON-encoded or serialized array. */
  value?: Maybe<Scalars['String']>;
  /** Field visibility. */
  visibility?: Maybe<FormFieldVisibilityEnum>;
};

/** Input for the wpGatsbyRemotePreviewStatus mutation */
export type WpGatsbyRemotePreviewStatusInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The modified date of the latest revision for this preview. */
  modified?: InputMaybe<Scalars['String']>;
  /** The Gatsby page path for this preview. */
  pagePath?: InputMaybe<Scalars['String']>;
  /** The previewed revisions post parent id */
  parentDatabaseId?: InputMaybe<Scalars['Float']>;
  /** The remote status of the previewed node */
  status: WpGatsbyRemotePreviewStatusEnum;
  /** Additional context about the preview status */
  statusContext?: InputMaybe<Scalars['String']>;
};

/** The payload for the wpGatsbyRemotePreviewStatus mutation */
export type WpGatsbyRemotePreviewStatusPayload = {
  __typename?: 'WpGatsbyRemotePreviewStatusPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Wether or not the revision mutation was successful */
  success?: Maybe<Scalars['Boolean']>;
};

/** The writing setting type */
export type WritingSettings = {
  __typename?: 'WritingSettings';
  /** Default post category. */
  defaultCategory?: Maybe<Scalars['Int']>;
  /** Default post format. */
  defaultPostFormat?: Maybe<Scalars['String']>;
  /** Convert emoticons like :-) and :-P to graphics on display. */
  useSmilies?: Maybe<Scalars['Boolean']>;
};

export type AddSupplierNewsItemMutationVariables = Exact<{
  publishedStatus?: InputMaybe<Scalars['Boolean']>;
  title: Scalars['String'];
  slug: Scalars['String'];
  image?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  postContent?: InputMaybe<Scalars['String']>;
  videoUrl?: InputMaybe<Scalars['String']>;
  headerUrlLink?: InputMaybe<Scalars['String']>;
  documents?: InputMaybe<Scalars['String']>;
  documentsTitles?: InputMaybe<Scalars['String']>;
  expiryDate?: InputMaybe<Scalars['String']>;
  linkedSupplier?: InputMaybe<Scalars['ID']>;
}>;


export type AddSupplierNewsItemMutation = { __typename?: 'RootMutation', createSupplierNews?: { __typename?: 'CreateSupplier_newsPayload', supplier_news?: { __typename?: 'Supplier_news', id: string, slug?: string | null, title?: string | null, modified?: string | null, featuredImage?: { __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge', node?: { __typename?: 'MediaItem', mediaItemUrl?: string | null, id: string, uri?: string | null } | null } | null, newsPost?: { __typename?: 'Supplier_news_Newspost', approvalStatus?: boolean | null, postContent?: string | null, publishedStatus?: boolean | null, videoUrl?: string | null, headerUrlLink?: string | null, expiryDate?: string | null, linkedSupplier?: Array<{ __typename?: 'Supplier', id: string, title?: string | null, uri?: string | null } | null> | null, documents?: Array<{ __typename?: 'Supplier_news_Newspost_documents', file?: { __typename?: 'MediaItem', mediaItemUrl?: string | null } | null } | null> | null } | null } | null } | null };

export type DeleteSupplierNewsItemMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteSupplierNewsItemMutation = { __typename?: 'RootMutation', deleteSupplierNews?: { __typename?: 'DeleteSupplier_newsPayload', deletedId?: string | null, supplier_news?: { __typename?: 'Supplier_news', title?: string | null } | null } | null };

export type GetPostsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPostsQuery = { __typename?: 'RootQuery', posts?: { __typename?: 'RootQueryToPostConnection', edges?: Array<{ __typename?: 'RootQueryToPostConnectionEdge', node?: { __typename?: 'Post', id: string, title?: string | null, content?: string | null } | null } | null> | null } | null };

export type GetSupplierQueryVariables = Exact<{
  slug: Scalars['ID'];
}>;


export type GetSupplierQuery = { __typename?: 'RootQuery', supplier?: { __typename?: 'Supplier', id: string, title?: string | null, slug?: string | null, supplierDetails?: { __typename?: 'Supplier_Supplierdetails', description?: string | null, emailAddress?: string | null, introduction?: string | null, memberBenefits?: string | null, termsAndConditions?: string | null, videoUrl?: string | null, documents?: Array<{ __typename?: 'Supplier_Supplierdetails_documents', title?: string | null, file?: { __typename?: 'MediaItem', id: string, databaseId: number, fileName?: string | null, fileSize?: number | null, mediaItemUrl?: string | null } | null } | null> | null, heroBanner?: { __typename?: 'MediaItem', mediaItemUrl?: string | null, fileName?: string | null, fileSize?: number | null, altText?: string | null } | null, profilePicture?: { __typename?: 'MediaItem', mediaItemUrl?: string | null, fileName?: string | null, fileSize?: number | null, altText?: string | null } | null } | null, supplierCategories?: { __typename?: 'SupplierToSupplierCategoryConnection', nodes?: Array<{ __typename?: 'SupplierCategory', id: string, name?: string | null, slug?: string | null, parentId?: string | null } | null> | null } | null, supplierKeywords?: { __typename?: 'SupplierToSupplierKeywordConnection', nodes?: Array<{ __typename?: 'SupplierKeyword', id: string, name?: string | null, slug?: string | null } | null> | null } | null } | null };

export type GetSupplierNewsItemQueryVariables = Exact<{
  slug: Scalars['ID'];
}>;


export type GetSupplierNewsItemQuery = { __typename?: 'RootQuery', supplierNews?: { __typename?: 'Supplier_news', id: string, slug?: string | null, title?: string | null, modified?: string | null, databaseId: number, featuredImage?: { __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge', node?: { __typename?: 'MediaItem', id: string, fileName?: string | null, mediaItemUrl?: string | null } | null } | null, newsPost?: { __typename?: 'Supplier_news_Newspost', inReview?: boolean | null, approvalStatus?: boolean | null, postContent?: string | null, publishedStatus?: boolean | null, videoUrl?: string | null, headerUrlLink?: string | null, expiryDate?: string | null, linkedSupplier?: Array<{ __typename?: 'Supplier', id: string, title?: string | null, supplierDetails?: { __typename?: 'Supplier_Supplierdetails', profilePicture?: { __typename?: 'MediaItem', mediaItemUrl?: string | null, fileName?: string | null } | null } | null } | null> | null, documents?: Array<{ __typename?: 'Supplier_news_Newspost_documents', file?: { __typename?: 'MediaItem', mediaItemUrl?: string | null, fileName?: string | null, fileSize?: number | null } | null } | null> | null } | null } | null };

export type GetSuppliersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSuppliersQuery = { __typename?: 'RootQuery', suppliers?: { __typename?: 'RootQueryToSupplierConnection', edges?: Array<{ __typename?: 'RootQueryToSupplierConnectionEdge', node?: { __typename?: 'Supplier', id: string, title?: string | null, slug?: string | null, supplierDetails?: { __typename?: 'Supplier_Supplierdetails', introduction?: string | null, featured?: boolean | null, heroBanner?: { __typename?: 'MediaItem', id: string, mediaItemUrl?: string | null } | null } | null, supplierCategories?: { __typename?: 'SupplierToSupplierCategoryConnection', nodes?: Array<{ __typename?: 'SupplierCategory', id: string, name?: string | null, slug?: string | null } | null> | null } | null, supplierKeywords?: { __typename?: 'SupplierToSupplierKeywordConnection', nodes?: Array<{ __typename?: 'SupplierKeyword', id: string, name?: string | null, slug?: string | null } | null> | null } | null } | null } | null> | null } | null };

export type SendEmailMutationVariables = Exact<{
  to: Scalars['String'];
  from: Scalars['String'];
  subject: Scalars['String'];
  body: Scalars['String'];
  replyTo?: InputMaybe<Scalars['String']>;
}>;


export type SendEmailMutation = { __typename?: 'RootMutation', sendEmail?: { __typename?: 'SendEmailPayload', to?: string | null, sent?: boolean | null, replyTo?: string | null, origin?: string | null, message?: string | null } | null };

export type UpdateSupplierNewsItemMutationVariables = Exact<{
  id: Scalars['ID'];
  publishedStatus?: InputMaybe<Scalars['Boolean']>;
  approvalStatus?: InputMaybe<Scalars['Boolean']>;
  inReview?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  postContent?: InputMaybe<Scalars['String']>;
  videoUrl?: InputMaybe<Scalars['String']>;
  headerUrlLink?: InputMaybe<Scalars['String']>;
  documents?: InputMaybe<Scalars['String']>;
  documentsTitles?: InputMaybe<Scalars['String']>;
  expiryDate?: InputMaybe<Scalars['String']>;
}>;


export type UpdateSupplierNewsItemMutation = { __typename?: 'RootMutation', updateSupplierNews?: { __typename?: 'UpdateSupplier_newsPayload', supplier_news?: { __typename?: 'Supplier_news', id: string, slug?: string | null, title?: string | null, modified?: string | null, databaseId: number, featuredImage?: { __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge', node?: { __typename?: 'MediaItem', id: string, fileName?: string | null, mediaItemUrl?: string | null } | null } | null, newsPost?: { __typename?: 'Supplier_news_Newspost', inReview?: boolean | null, approvalStatus?: boolean | null, postContent?: string | null, publishedStatus?: boolean | null, videoUrl?: string | null, headerUrlLink?: string | null, expiryDate?: string | null, linkedSupplier?: Array<{ __typename?: 'Supplier', id: string, title?: string | null, uri?: string | null, supplierDetails?: { __typename?: 'Supplier_Supplierdetails', profilePicture?: { __typename?: 'MediaItem', mediaItemUrl?: string | null, fileName?: string | null } | null } | null } | null> | null, documents?: Array<{ __typename?: 'Supplier_news_Newspost_documents', file?: { __typename?: 'MediaItem', mediaItemUrl?: string | null, fileName?: string | null, fileSize?: number | null } | null } | null> | null } | null } | null } | null };


export const AddSupplierNewsItemDocument = gql`
    mutation addSupplierNewsItem($publishedStatus: Boolean, $title: String!, $slug: String!, $image: String, $category: String, $postContent: String, $videoUrl: String, $headerUrlLink: String, $documents: String, $documentsTitles: String, $expiryDate: String, $linkedSupplier: ID) {
  createSupplierNews(
    input: {title: $title, slug: $slug, publishedStatus: $publishedStatus, image: $image, category: $category, postContent: $postContent, videoUrl: $videoUrl, headerUrlLink: $headerUrlLink, documents: $documents, documentsTitles: $documentsTitles, expiryDate: $expiryDate, status: PUBLISH, linkedSupplier: $linkedSupplier}
  ) {
    supplier_news {
      id
      slug
      title(format: RENDERED)
      modified
      featuredImage {
        node {
          mediaItemUrl
          id
          uri
        }
      }
      newsPost {
        approvalStatus
        linkedSupplier {
          ... on Supplier {
            id
            title
            uri
          }
        }
        postContent
        publishedStatus
        videoUrl
        headerUrlLink
        documents {
          file {
            mediaItemUrl
          }
        }
        expiryDate
      }
    }
  }
}
    `;
export type AddSupplierNewsItemMutationFn = Apollo.MutationFunction<AddSupplierNewsItemMutation, AddSupplierNewsItemMutationVariables>;

/**
 * __useAddSupplierNewsItemMutation__
 *
 * To run a mutation, you first call `useAddSupplierNewsItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSupplierNewsItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSupplierNewsItemMutation, { data, loading, error }] = useAddSupplierNewsItemMutation({
 *   variables: {
 *      publishedStatus: // value for 'publishedStatus'
 *      title: // value for 'title'
 *      slug: // value for 'slug'
 *      image: // value for 'image'
 *      category: // value for 'category'
 *      postContent: // value for 'postContent'
 *      videoUrl: // value for 'videoUrl'
 *      headerUrlLink: // value for 'headerUrlLink'
 *      documents: // value for 'documents'
 *      documentsTitles: // value for 'documentsTitles'
 *      expiryDate: // value for 'expiryDate'
 *      linkedSupplier: // value for 'linkedSupplier'
 *   },
 * });
 */
export function useAddSupplierNewsItemMutation(baseOptions?: Apollo.MutationHookOptions<AddSupplierNewsItemMutation, AddSupplierNewsItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSupplierNewsItemMutation, AddSupplierNewsItemMutationVariables>(AddSupplierNewsItemDocument, options);
      }
export type AddSupplierNewsItemMutationHookResult = ReturnType<typeof useAddSupplierNewsItemMutation>;
export type AddSupplierNewsItemMutationResult = Apollo.MutationResult<AddSupplierNewsItemMutation>;
export type AddSupplierNewsItemMutationOptions = Apollo.BaseMutationOptions<AddSupplierNewsItemMutation, AddSupplierNewsItemMutationVariables>;
export const DeleteSupplierNewsItemDocument = gql`
    mutation deleteSupplierNewsItem($id: ID!) {
  deleteSupplierNews(input: {id: $id}) {
    deletedId
    supplier_news {
      title(format: RENDERED)
    }
  }
}
    `;
export type DeleteSupplierNewsItemMutationFn = Apollo.MutationFunction<DeleteSupplierNewsItemMutation, DeleteSupplierNewsItemMutationVariables>;

/**
 * __useDeleteSupplierNewsItemMutation__
 *
 * To run a mutation, you first call `useDeleteSupplierNewsItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSupplierNewsItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSupplierNewsItemMutation, { data, loading, error }] = useDeleteSupplierNewsItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSupplierNewsItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSupplierNewsItemMutation, DeleteSupplierNewsItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSupplierNewsItemMutation, DeleteSupplierNewsItemMutationVariables>(DeleteSupplierNewsItemDocument, options);
      }
export type DeleteSupplierNewsItemMutationHookResult = ReturnType<typeof useDeleteSupplierNewsItemMutation>;
export type DeleteSupplierNewsItemMutationResult = Apollo.MutationResult<DeleteSupplierNewsItemMutation>;
export type DeleteSupplierNewsItemMutationOptions = Apollo.BaseMutationOptions<DeleteSupplierNewsItemMutation, DeleteSupplierNewsItemMutationVariables>;
export const GetPostsDocument = gql`
    query getPosts {
  posts {
    edges {
      node {
        id
        title
        content
      }
    }
  }
}
    `;

/**
 * __useGetPostsQuery__
 *
 * To run a query within a React component, call `useGetPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPostsQuery(baseOptions?: Apollo.QueryHookOptions<GetPostsQuery, GetPostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPostsQuery, GetPostsQueryVariables>(GetPostsDocument, options);
      }
export function useGetPostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPostsQuery, GetPostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPostsQuery, GetPostsQueryVariables>(GetPostsDocument, options);
        }
export type GetPostsQueryHookResult = ReturnType<typeof useGetPostsQuery>;
export type GetPostsLazyQueryHookResult = ReturnType<typeof useGetPostsLazyQuery>;
export type GetPostsQueryResult = Apollo.QueryResult<GetPostsQuery, GetPostsQueryVariables>;
export const GetSupplierDocument = gql`
    query getSupplier($slug: ID!) {
  supplier(id: $slug, idType: SLUG) {
    id
    title
    slug
    supplierDetails {
      description
      emailAddress
      introduction
      memberBenefits
      termsAndConditions
      videoUrl
      documents {
        title
        file {
          id
          databaseId
          fileName
          fileSize
          mediaItemUrl
        }
      }
      heroBanner {
        mediaItemUrl
        fileName
        fileSize
        altText
      }
      profilePicture {
        mediaItemUrl
        fileName
        fileSize
        altText
      }
    }
    supplierCategories {
      nodes {
        id
        name
        slug
        parentId
      }
    }
    supplierKeywords {
      nodes {
        id
        name
        slug
      }
    }
  }
}
    `;

/**
 * __useGetSupplierQuery__
 *
 * To run a query within a React component, call `useGetSupplierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetSupplierQuery(baseOptions: Apollo.QueryHookOptions<GetSupplierQuery, GetSupplierQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSupplierQuery, GetSupplierQueryVariables>(GetSupplierDocument, options);
      }
export function useGetSupplierLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSupplierQuery, GetSupplierQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSupplierQuery, GetSupplierQueryVariables>(GetSupplierDocument, options);
        }
export type GetSupplierQueryHookResult = ReturnType<typeof useGetSupplierQuery>;
export type GetSupplierLazyQueryHookResult = ReturnType<typeof useGetSupplierLazyQuery>;
export type GetSupplierQueryResult = Apollo.QueryResult<GetSupplierQuery, GetSupplierQueryVariables>;
export const GetSupplierNewsItemDocument = gql`
    query getSupplierNewsItem($slug: ID!) {
  supplierNews(id: $slug, idType: SLUG) {
    id
    slug
    title(format: RENDERED)
    modified
    databaseId
    featuredImage {
      node {
        id
        fileName
        mediaItemUrl
      }
    }
    newsPost {
      inReview
      approvalStatus
      linkedSupplier {
        ... on Supplier {
          id
          title
          supplierDetails {
            profilePicture {
              mediaItemUrl
              fileName
            }
          }
        }
      }
      postContent
      publishedStatus
      videoUrl
      headerUrlLink
      documents {
        file {
          mediaItemUrl
          fileName
          fileSize
        }
      }
      expiryDate
    }
  }
}
    `;

/**
 * __useGetSupplierNewsItemQuery__
 *
 * To run a query within a React component, call `useGetSupplierNewsItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierNewsItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierNewsItemQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetSupplierNewsItemQuery(baseOptions: Apollo.QueryHookOptions<GetSupplierNewsItemQuery, GetSupplierNewsItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSupplierNewsItemQuery, GetSupplierNewsItemQueryVariables>(GetSupplierNewsItemDocument, options);
      }
export function useGetSupplierNewsItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSupplierNewsItemQuery, GetSupplierNewsItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSupplierNewsItemQuery, GetSupplierNewsItemQueryVariables>(GetSupplierNewsItemDocument, options);
        }
export type GetSupplierNewsItemQueryHookResult = ReturnType<typeof useGetSupplierNewsItemQuery>;
export type GetSupplierNewsItemLazyQueryHookResult = ReturnType<typeof useGetSupplierNewsItemLazyQuery>;
export type GetSupplierNewsItemQueryResult = Apollo.QueryResult<GetSupplierNewsItemQuery, GetSupplierNewsItemQueryVariables>;
export const GetSuppliersDocument = gql`
    query getSuppliers {
  suppliers {
    edges {
      node {
        id
        title
        slug
        supplierDetails {
          heroBanner {
            id
            mediaItemUrl
          }
          introduction
          featured
        }
        supplierCategories {
          nodes {
            id
            name
            slug
          }
        }
        supplierKeywords {
          nodes {
            id
            name
            slug
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetSuppliersQuery__
 *
 * To run a query within a React component, call `useGetSuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSuppliersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSuppliersQuery(baseOptions?: Apollo.QueryHookOptions<GetSuppliersQuery, GetSuppliersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSuppliersQuery, GetSuppliersQueryVariables>(GetSuppliersDocument, options);
      }
export function useGetSuppliersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSuppliersQuery, GetSuppliersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSuppliersQuery, GetSuppliersQueryVariables>(GetSuppliersDocument, options);
        }
export type GetSuppliersQueryHookResult = ReturnType<typeof useGetSuppliersQuery>;
export type GetSuppliersLazyQueryHookResult = ReturnType<typeof useGetSuppliersLazyQuery>;
export type GetSuppliersQueryResult = Apollo.QueryResult<GetSuppliersQuery, GetSuppliersQueryVariables>;
export const SendEmailDocument = gql`
    mutation sendEmail($to: String!, $from: String!, $subject: String!, $body: String!, $replyTo: String) {
  sendEmail(
    input: {to: $to, from: $from, subject: $subject, body: $body, replyTo: $replyTo}
  ) {
    to
    sent
    replyTo
    origin
    message
  }
}
    `;
export type SendEmailMutationFn = Apollo.MutationFunction<SendEmailMutation, SendEmailMutationVariables>;

/**
 * __useSendEmailMutation__
 *
 * To run a mutation, you first call `useSendEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailMutation, { data, loading, error }] = useSendEmailMutation({
 *   variables: {
 *      to: // value for 'to'
 *      from: // value for 'from'
 *      subject: // value for 'subject'
 *      body: // value for 'body'
 *      replyTo: // value for 'replyTo'
 *   },
 * });
 */
export function useSendEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendEmailMutation, SendEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendEmailMutation, SendEmailMutationVariables>(SendEmailDocument, options);
      }
export type SendEmailMutationHookResult = ReturnType<typeof useSendEmailMutation>;
export type SendEmailMutationResult = Apollo.MutationResult<SendEmailMutation>;
export type SendEmailMutationOptions = Apollo.BaseMutationOptions<SendEmailMutation, SendEmailMutationVariables>;
export const UpdateSupplierNewsItemDocument = gql`
    mutation updateSupplierNewsItem($id: ID!, $publishedStatus: Boolean, $approvalStatus: Boolean, $inReview: Boolean, $title: String, $image: String, $category: String, $postContent: String, $videoUrl: String, $headerUrlLink: String, $documents: String, $documentsTitles: String, $expiryDate: String) {
  updateSupplierNews(
    input: {id: $id, title: $title, publishedStatus: $publishedStatus, approvalStatus: $approvalStatus, inReview: $inReview, image: $image, category: $category, postContent: $postContent, videoUrl: $videoUrl, headerUrlLink: $headerUrlLink, documents: $documents, documentsTitles: $documentsTitles, expiryDate: $expiryDate}
  ) {
    supplier_news {
      id
      slug
      title(format: RENDERED)
      modified
      databaseId
      featuredImage {
        node {
          id
          fileName
          mediaItemUrl
        }
      }
      newsPost {
        inReview
        approvalStatus
        linkedSupplier {
          ... on Supplier {
            id
            title
            uri
            supplierDetails {
              profilePicture {
                mediaItemUrl
                fileName
              }
            }
          }
        }
        postContent
        publishedStatus
        videoUrl
        headerUrlLink
        documents {
          file {
            mediaItemUrl
            fileName
            fileSize
          }
        }
        expiryDate
      }
    }
  }
}
    `;
export type UpdateSupplierNewsItemMutationFn = Apollo.MutationFunction<UpdateSupplierNewsItemMutation, UpdateSupplierNewsItemMutationVariables>;

/**
 * __useUpdateSupplierNewsItemMutation__
 *
 * To run a mutation, you first call `useUpdateSupplierNewsItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSupplierNewsItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSupplierNewsItemMutation, { data, loading, error }] = useUpdateSupplierNewsItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      publishedStatus: // value for 'publishedStatus'
 *      approvalStatus: // value for 'approvalStatus'
 *      inReview: // value for 'inReview'
 *      title: // value for 'title'
 *      image: // value for 'image'
 *      category: // value for 'category'
 *      postContent: // value for 'postContent'
 *      videoUrl: // value for 'videoUrl'
 *      headerUrlLink: // value for 'headerUrlLink'
 *      documents: // value for 'documents'
 *      documentsTitles: // value for 'documentsTitles'
 *      expiryDate: // value for 'expiryDate'
 *   },
 * });
 */
export function useUpdateSupplierNewsItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSupplierNewsItemMutation, UpdateSupplierNewsItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSupplierNewsItemMutation, UpdateSupplierNewsItemMutationVariables>(UpdateSupplierNewsItemDocument, options);
      }
export type UpdateSupplierNewsItemMutationHookResult = ReturnType<typeof useUpdateSupplierNewsItemMutation>;
export type UpdateSupplierNewsItemMutationResult = Apollo.MutationResult<UpdateSupplierNewsItemMutation>;
export type UpdateSupplierNewsItemMutationOptions = Apollo.BaseMutationOptions<UpdateSupplierNewsItemMutation, UpdateSupplierNewsItemMutationVariables>;